export class SupportReferralModel {
    supportServiceId: string;
    category: string;
    name: string;
    sortOrder: number;
    isEnabled: boolean;

    constructor(obj: SupportReferralModel = null) { if (obj) { Object.assign(this, obj); } }
}

export class SupportReferralSubmissionModel {
    supportReferralId: number;
    submissionId: number;
    supportServiceId: string;
    supportService: string;
    other: string;
    crmSupportReferralId: number;
    category: string;

    constructor(obj: SupportReferralSubmissionModel = null) { if (obj) { Object.assign(this, obj); } }
}
