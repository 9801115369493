export class DriverLicenceFormModel {
  hasDriverLicence: boolean = null;
  personDriverLicenceId?: number = null;
  licenceGivenName = '';
  licenceMiddleName = '';
  licenceSurname = '';
  dateOfBirth: Date = null;
  licenceNumber: number = null;
  licenceCardNumber: number = null;
  licenceStateOfIssue: string = null;
  licenceExpiryDate: Date = null;
  constructor(obj: DriverLicenceFormModel = null) { if (obj) { Object.assign(this, obj); } }
}
