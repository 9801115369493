import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators, ValidatorFn, FormControl } from '@angular/forms';

// Base Classes
import { BaseChildForm } from 'src/app/shared/base-classes/base-child-form';

// Utils
import { util } from 'src/app/shared/util/util';
import { formUtil } from 'src/app/shared/util/form-util';

// Constants
import { regexDescription } from 'src/app/shared/constants/regex-description';
import { regex } from 'src/app/shared/constants/regex';
import { lookupLists } from 'src/app/shared/constants/lookup-lists';

// Validators
import { dateIsInFutureValidator } from 'src/app/shared/validators/dateIsInFuture-validator.directive';

// Models
import { PersonDriverLicenceModel } from 'src/app/models/person-driver-licence.model';
import { DriverLicenceFormModel } from './driver-licence-form.model';



@Component({
  selector: 'app-driver-licence',
  templateUrl: './driver-licence.component.html'
})
export class DriverLicenceComponent extends BaseChildForm implements OnInit {

  // ---------------------------------------------------------------------------
  //    Properties
  // ---------------------------------------------------------------------------

  titleLicence = 'My Driver Licence';


  // -------------------------------------
  //    Lookups

  lookup_auStates = lookupLists.australianStates;

  // -------------------------------------
  //    Form

  form: FormGroup;
  private fgModel = {
    hasDriverLicence: [null],
    personDriverLicenceId: [null],
    licenceGivenName: [{ value: null, disabled: true }],
    licenceMiddleName: [{ value: null, disabled: true }],
    licenceSurname: [{ value: null, disabled: true }],
    dateOfBirth: [{ value: null, disabled: true }],
    licenceNumber: [null],
    licenceCardNumber: [null],
    licenceStateOfIssue: [null],
    licenceExpiryDate: [null],
  };
  hasAttemptedToProgress: boolean;

  regexDescription = regexDescription;

  stateSelection = null;

  // -------------------------------------
  //    Getters

  get f() { return this.form.controls; }
  get hasDriverLicence(): boolean { return this.f.hasDriverLicence.value; }
  get model() { return new PersonDriverLicenceModel(this.form.value); }

  // ---------------------------------------------------------------------------
  //    Constructor
  // ---------------------------------------------------------------------------

  constructor(private fb: FormBuilder) {
    super();
    this.componentId = 'driverLicence';
    this.componentInitialized = new EventEmitter<DriverLicenceComponent>();
  }


  // ---------------------------------------------------------------------------
  //    Methods
  // ---------------------------------------------------------------------------


  onChange_hasDriverLicence() {
    this.toggleFormFieldValidators(this.hasDriverLicence);
  }

  // -------------------------------------
  //  Public Methods

  bindModelToForm(model: DriverLicenceFormModel) {
    formUtil.mapModelToForm(model, this.f, {
      // must convert date string to date object to bind it to control
      dateOfBirth: function (val) { return (val ? util.tryParseDate(val) : null); },
      licenceExpiryDate: function (val) { return (val ? new Date(val) : null); },
      // If State Of Issue is null (not set), default to QLD (Queensland)
      licenceStateOfIssue: function (val) { return (val ? val : 'QLD'); }
    });

    // implement validation
    this.toggleFormFieldValidators(this.hasDriverLicence);
  }

  revalidate(hasAttemptedToProgress: boolean) {
    this.hasAttemptedToProgress = hasAttemptedToProgress;
    if (hasAttemptedToProgress) {
      formUtil.validation.markAsTouched_allFormFields(this.form);
    } else {
      formUtil.validation.markAsPristine_allFormFields(this.form);
    }
  }


  // -------------------------------------
  //  Private Methods

  public initForm() {

    // create form group
    this.form = this.fb.group(this.fgModel);

    // initialize hasDriverLicence to required
    formUtil.validation.toggleRequired(this.f.hasDriverLicence, true);



  }

  private toggleFormFieldValidators(hasDriverLicence: boolean) {
    const enableValidators = (hasDriverLicence === true);

    formUtil.validation.toggleControlsValidators(this.form, {
      'licenceNumber': [Validators.required, Validators.pattern(regex.alphaNumeric), Validators.minLength(6), Validators.maxLength(10)],
      'licenceExpiryDate': [Validators.required, dateIsInFutureValidator(true)],
      'licenceCardNumber': [Validators.pattern(regex.alphaNumeric), Validators.maxLength(10), Validators.required],
    }, enableValidators);
    // toggle state validator
    formUtil.validation.toggleControlValidators(this.f.licenceStateOfIssue, (enableValidators ? [Validators.required] : null), true);
  }


}
