﻿import { environment } from './../../../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

// Utils
import { formUtil } from 'src/app/shared/util/form-util';
import { util } from 'src/app/shared/util/util';

// Services
import { UserService } from '../../../../../services/user.service';
import { SubmissionService } from 'src/app/services/submission.service';

// Constants
import { regex } from '../../../../../shared/constants/regex';
import { sessionData } from '../../../../../shared/storage/session-data';

// Models
import { IAMUserModel } from 'src/app/models/iam-user.model';
import { UserPersonModel } from 'src/app/models/user-person.model';
import { ResultModel } from 'src/app/models/result.model';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss']
})
export class EditContactComponent implements OnInit {


  // ----------------------------------
  //  Properties
  // ----------------------------------

  titleContact = 'Contact Details';

  model: IAMUserModel;

  result: ResultModel<any>;

  hasAttemptedToProgress = false;

  isSubmitInProgress = false;

  form = this.fb.group({
    firstName: [{ value: '', disabled: true }, Validators.required],
    lastName: [{ value: '', disabled: true }, Validators.required],
    email: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(regex.email)]],
    phone: ['', [Validators.pattern(regex.phone)]]
  });

  // ---------------
  //    Getters

  get fm() { return this.form.controls; }

  get applicantSubmissions() { return this.submissionService.userSubmissions; }


  // ----------------------------------
  //  Constructor
  // ----------------------------------

  constructor(private router: Router
    , private fb: FormBuilder
    , private userService: UserService
    , private submissionService: SubmissionService
    , private spinner: NgxSpinnerService
  ) { }


  // ----------------------------------
  //  Methods
  // ----------------------------------


  // ----------------
  //  Events

  ngOnInit() {
    this.loadUser();
  }

  onSubmit() {
    this.hasAttemptedToProgress = true;
    if (this.form.valid) {
      this.saveContactDetails();
    }
  }

  onClick_dismissAlert() {
    this.result = null;
  }


  // ----------------
  //  Other


  loadUser() {
    this.userService.getIAMUser().subscribe(
      (iamUser) => {

        // Bind model to form
        this.model = (iamUser ? iamUser : new IAMUserModel());
        formUtil.mapModelToForm(this.model, this.form.controls);
      },
      (error) => { } // todo: handle error
    );
  }


  saveContactDetails() {
    // Bind form to model
    const formModel = this.form.getRawValue();
    this.model = new IAMUserModel(formModel);

    this.isSubmitInProgress = true;

    // Save User details
    this.userService.saveIAMUser(this.model)
      .pipe(finalize(() => {
        this.isSubmitInProgress = false;
      }))
      .subscribe(
        (isSuccess) => {
          if (isSuccess) {

            const userSession = sessionData.user();
            userSession.phone = this.model.phone;
            sessionData.user(userSession);

            this.result = new ResultModel<any>({ isSuccess: true, message: 'Contact details have been updated successfully.' });
          }

          this.checkIfUserHasAnySubmissions();
        },
        (errоr) => {
          this.result = new ResultModel<any>({ isSuccess: false, message: 'Contact details not saved.' });
        }
      );
  }

  /// route to generic grant landing page if generic grant is enabled
  showGenericGrantAssessmentMessage() {
      this.router.navigate(['landing']);
  }

  /**
   * check if the user has no application then redirect to the new application page otherwise dashboard
   */
  checkIfUserHasAnySubmissions() {

      if (!this.applicantSubmissions || this.applicantSubmissions.length === 0) {

        if (environment.isGenericGrantEnabled) {
          this.showGenericGrantAssessmentMessage();
        } else {
          //  Navigate to new application
          const user = sessionData.user();
          const submissionUser = new UserPersonModel();
          util.mapTo(submissionUser, user);
          this.submissionService.goToApplication(submissionUser, null, null);
        }
      }
  }

  // ----------------
  //  Validation

  getInputCssClass(controlName: string) {
    const control = this.form.controls[controlName];
    return formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
  }

  getInputGroupCssClass(controlName: string) {
    const control = this.form.controls[controlName];
    return formUtil.validation.getCssClass_invalidInputGroup(control, this.hasAttemptedToProgress);
  }



}
