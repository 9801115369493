import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';
import { sessionData } from '../../../shared/storage/session-data';
import { IAMUserModel } from 'src/app/models/iam-user.model';
import { enums } from 'src/app/shared/enums/enums';
import { AuthSessionModel } from 'src/app/models/auth-session.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  title = 'Registering new Account.. please wait.';
  isBasicGrantEnabled : boolean;
  constructor(private router: Router
    , private spinner: NgxSpinnerService
    , private authService: AuthService
    , private userService: UserService
  ) { }

  ngOnInit() {
    this.isBasicGrantEnabled = environment.isGenericGrantEnabled;
    this.spinner.show();

    // during a proper login, authSession should not exist in session data at this point.
    //  as this can be a public terminal, attempting to access the /login or /register when signed in will force a logout.
    //  to reach this point a user would have used manual navigation by typing the URLS directly.
    const hasDirtyLogin = sessionData.authSession();

    if (hasDirtyLogin) {
      // BAD - if login session has been retained at this point force logout
      this.handleRegistrationFailure('Dirty login retained');

    } else {

      // Do set session variables form auth 0 successful registration
      this.authService.handleAuthentication((authSession: AuthSessionModel) => {
        this.authenticateUserRegistration(authSession);
      });
    }
  }

  authenticateUserRegistration(authSession: AuthSessionModel) {

    sessionData.authSession(authSession);

    const isApplicant = true;

    this.userService.registerNewIAMUser(authSession.idToken, isApplicant)  // service logs in automatically
      .subscribe(

        // onSuccess
        (iamUser) => {
          this.handleRegistrationSuccess(iamUser, authSession);
        },

        // onError
        error => {
          this.handleRegistrationFailure('Portal registration failed');
        },

        // onComplete
        () => { setTimeout(() => { this.spinner.hide(); }, 1000); }

      ); // ------ // End: userService.registerNewIAMUser.subscribe()   //-----------

  }

  handleRegistrationSuccess(iamUser: IAMUserModel, session: AuthSessionModel) {
    // store IAMUser object and userFulName into sessionStorage
    sessionData.user(iamUser);
    // clear authSession idToken
    session.idToken = null;
    sessionData.authSession(session);
     // inject generic grant acceptance message

     if (this.isBasicGrantEnabled) {
      this.showGenericGrantAssessmentMessage();
      return;
    }

    // navigate to user dasboard
    this.router.navigate(['/dashboard', { fromReg: 'true' }]);
  }

  handleRegistrationFailure(error: any = null) {
    // error is currently used for debugging only
    console.log(error);
    // service logout will remove local storage data for auth
    this.userService.logout(enums.sessionState.error_registration);
  }

  /// route to generic grant landing page if generic grant is enabled
  showGenericGrantAssessmentMessage() {
      this.router.navigate(['landing']);
  }

}
