﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sessionData } from '../../shared/storage/session-data';
import { Location } from '@angular/common';

// Services
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { SubmissionService } from 'src/app/services/submission.service';

// Utils
import { util } from 'src/app/shared/util/util';

// Constants
import { routes } from 'src/app/shared/constants/routes';
import { routes as supportRoutes } from 'src/app/modules/support/_constants/routes';
import { icons } from 'src/app/shared/constants/icons';

@Component({
  selector: 'app-user-navigation',
  templateUrl: './user-navigation.component.html',
  styleUrls: ['./user-navigation.component.scss']
})
export class UserNavigationComponent implements OnInit {

  // ------------------------------------------
  //    Properties
  // ------------------------------------------

  icons = icons;

  isLoggedIn = false;
  currentUser_fullName = '';
  dropDownTitle = 'My Account';
  support = false;

  get isSupportUser_onBehalfOfApplicant() { return this.submissionSvc.isSupportUser_onBehalfOfApplicant; }

  // ------------------------------------------
  //    Constructor
  // ------------------------------------------

  constructor(
    private submissionSvc: SubmissionService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) {

    this.router.events.subscribe((val) => {
      const isCurrentUrlOnSupportPortal = (this.location.path() != '' && this.location.path().indexOf('/support') > -1);

      if (isCurrentUrlOnSupportPortal || this.isSupportUser_onBehalfOfApplicant) {
        this.dropDownTitle = 'Dashboard';
        this.support = true;
      }
    });

  }

  // ------------------------------------------
  //    Methods
  // ------------------------------------------

  ngOnInit() {

    const userSession = sessionData.user();
    this.isLoggedIn = util.hasValue(userSession);
    if (this.isLoggedIn) {
      this.currentUser_fullName = `${userSession.firstName} ${userSession.lastName}`;
    }

    // if there is no user session then this should force a logout.
    this.authService.isAuthenticatedHandler();
  }


  onClick_menuItem(stepName) {
    switch (stepName) {

      case ('Logout'): { this.support ? this.logoutSupport() : this.logout(); this.logout(); break; }

      case ('Dashboard'): {

        const dashboardUrl = (this.support ? supportRoutes.dashboard : routes.dashboard);

        this.router.navigateByUrl(dashboardUrl);

        break;
      }

      default: { break; }

    }
  }


  logout() {
    this.userService.logout();
  }

  logoutSupport(){
    this.userService.logoutSupport();
  }

}

