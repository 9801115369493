import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

import { Submission, Step } from 'src/app/models/submission.model';
import { enums } from 'src/app/shared/enums/enums';
import { ApplicationStep } from './application-step.enum';
import { SubmissionService } from 'src/app/services/submission.service';


@Injectable({
  providedIn: 'root'
})
/**
 * This service provides ability for application steps to communicate with parent application component.
 * It allows application wizard parent to control the flow of the application in a customized way.
 */
export class ApplicationService {


  // ----------------------------------------
  //    Properties
  // ----------------------------------------

  currentStep: ApplicationStep = null;

  error = '';

  // ---------------
  //    Observables

  private loadingIndicatorChange_subject$ = new BehaviorSubject<string | boolean>(false);
  private goToStep_subject$ = new Subject<ApplicationStep>();
  private goToNextStep_subject$ = new Subject();
  private goToPreviousStep_subject$ = new Subject();
  private completeApplication_subject$ = new Subject();

  get loadingIndicatorChange$() { return this.loadingIndicatorChange_subject$; }
  private loadingIndicatorChange_emit(input: string | boolean) { this.loadingIndicatorChange_subject$.next(input); }

  get goToStep$() { return this.goToStep_subject$; }
  get goToNextStep$() { return this.goToNextStep_subject$; }
  get goToPreviousStep$() { return this.goToPreviousStep_subject$; }
  get completeApplication$() { return this.completeApplication_subject$; }


  // ----------------------------------------
  //    Constructor
  // ----------------------------------------

  constructor(
    private submissionService: SubmissionService) { }

  init() {
    this.currentStep = null;
  }

  // ----------------------------------------
  //    Methods
  // ----------------------------------------

  /**
   * display loading indicator
   * @param message message to display inside loading panel
   */
  showLoading(message: string = null) {
    this.loadingIndicatorChange_emit(message || true);
  }

  /**
   * Hide loading indicator
   */
  hideLoading() {
    this.loadingIndicatorChange_emit(false);
  }

  goToStep(stepIndex: ApplicationStep) {
    this.goToStep_subject$.next(stepIndex);
  }

  goToNextStep() {
    this.goToNextStep_subject$.next();
  }

  goToPreviousStep() {
    this.goToPreviousStep_subject$.next();
  }

  /**
   *  Use this method instead of goToNextStep if you need to implement custom logic for navigating forward.
   *  The custom logic decides what will be the next step to display.
   * @param model
   */
  goForward() {
    switch (this.currentStep) {
      case ApplicationStep.ApplicantDetails: break;
      case ApplicationStep.AddressDetails: this.goForwardFrom_AddressDetails(); break;
      case ApplicationStep.EventSelection: break;
      case ApplicationStep.InitialOutcome: this.goForwardFrom_InitialOutcome(); break;
      case ApplicationStep.IdentityCheck: break;
      case ApplicationStep.Dependants: break;
      case ApplicationStep.ImpactAssessment: this.goForwardFrom_ImpactAssessmentTo_ImpactDescription(); break;
      case ApplicationStep.PaymentMethod: break;
      case ApplicationStep.SupportReferral: break;
      case ApplicationStep.ImpactDescription: break;
      case ApplicationStep.Preview: break;
      case ApplicationStep.Declaration: break;
    }
  }

  /**
   *  Use this method instead of goToPreviousStep if you need to implement custom logic for navigating step backward
   *  The custom logic decides what will be the next step to display.
   * @param model
   */
  goBackward(step: ApplicationStep) {
    // if previous step is set, then go to that step
    if (step) {
      this.goToStep(step);

      // otherwise go to default prevous step (with index one less than current)
    } else {
      this.goToPreviousStep();
    }
  }

  completeApplication() { this.completeApplication_subject$.next(); }


  // ---------------------------------------- Private Methods ----------------------------------------


  private goForwardFrom_AddressDetails() {

    const model = this.submissionService.submissionModel;

    // Has PolygonIds - got to next step which should be Initial Outcome
    if (model.polygonIds) {
      this.goToStep(ApplicationStep.EventSelection);

      // No PolygonIds => go to Impact Description
    } else {
      this.goToStep(ApplicationStep.ImpactDescription);
    }
  }

  private goForwardFrom_InitialOutcome() {

    const model = this.submissionService.submissionModel;

    // No Grants selected => go to SupportReferral
    if (model.requestsNoGrants) {
      this.goToStep(ApplicationStep.SupportReferral);

      // At least one Grant selected - got to next step
    } else {
      this.goToNextStep();
    }
  }

  private goForwardFrom_ImpactAssessmentTo_ImpactDescription() {

    const model = this.submissionService.submissionModel;

    if (model.hasActivatedGrantsAvailable && !model.requestsNoGrants && model.grants.length === 1) {
        if(model.grants[0].eha !== null){
          if (model.grants[0].eha.experiencingHardship === false) {
            this.goToStep(ApplicationStep.ImpactDescription);
          } else {
            this.goToNextStep();
          }
        }
     } else {
      this.goToNextStep();
     }

  }

  // private goBackwardFrom_SupportReferral_Or_ImpactDescription(model: Submission) {

  //   // There are no activated grants available => GOTO: Address Details
  //   if (!model.hasActivatedGrantsAvailable) {
  //     this.goToStep(ApplicationStep.AddressDetails);
  //   }

  //   // There are activated Grants available and at least one was selected by user => GOTO: Previous Step
  //   if (model.hasActivatedGrantsAvailable && !model.requestsNoGrants) {
  //     this.goToPreviousStep();
  //   }

  //   // There are activated grants but No Grants option was selected by user => GOTO: Initial Outcome
  //   if (model.hasActivatedGrantsAvailable && model.requestsNoGrants) {
  //     this.goToStep(ApplicationStep.InitialOutcome);
  //   }
  // }




}
