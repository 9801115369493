import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Pass in any custom functionality dynamically
 * @param {Function} validationFunction delegate function with custom functionality to execute at control evaluation time.
 *                                      returns TRUE if some logical condition/criteria is valid/met in regards to this control
 *                                      returns FALSE if condition is not met
 *                                      MUST return a boolean value.
 * @param {string} errorName name of the error returned
 * @example USAGE:
 *
 * TS:
 *
 *      this.fb.group({
 *          ...
 *          hasDependants: [
 *              false,
 *              [
 *                  Validators.required,
 *                  customFunctionValidator(
 *                      (): boolean => {
 *                          // evaluate to true if when hasDependants=true, number of depandants is more than 1
 *                          return this.form && this.fm.hasDependants.value && this.dependantsCtrls ? this.dependantsCtrls.length > 1 : false;
 *                      },
 *                      'dependantsDetailsNotSupplied')
 *               ]
 *          ],
 *          dependants: this.fb.array([])
 *      })
 *
 * HTML:
 *
 *      <div class="invalid-feedback"
 *          *ngIf="myFormControl.errors?.dependantsDetailsNotSupplied" >
 *          You have to add at least one dependant.
 *      </div>
 */
export function customFunctionValidator(validationFunction: Function, errorName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const strValidator = '{"' + errorName + '" : { "value": false } }';
        const validatorObj = JSON.parse(strValidator);
        const isValid = validationFunction();
        return isValid ? null : validatorObj;
    };
}
