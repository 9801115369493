
import { localStorageUtil } from '../util/local-storage-util';
import { AuthSessionModel } from 'src/app/models/auth-session.model';
import { IAMUserModel } from 'src/app/models/iam-user.model';

/**
 *  Property-functions - for altering specific localStorage items without using strings and fiddling with json parsing.
 *  Encapsulates localStorage item name.
 *  Add here new items as the system grows. *
 *
 * @example USAGE:
 * GET      : sessionData.essentialServices();
 * DELETE   : sessionData.essentialServices(null);
 * ADD/EDIT : sessionData.essentialServices([
 *                  { "essentialServiceId": 1, "name": "Electricity"},
 *                  { "essentialServiceId": 2, "name": "Gas" }
 *                  ...
 *              ]);
 *
 */
export const localData = {

    // --------------------
    //  Lookup Lists

    lookups: {

        /**
         * @example USAGE:
         * GET      : localData.lookup_essentialServices();
         * DELETE   : localData.lookup_essentialServices(null);
         * ADD/EDIT : localData.lookup_essentialServices([
         *                      {languageId: 100000016, name: " Spanish - español", sortOrder: null },
         *                      {languageId: 100000017, name: " Tagalog", sortOrder: null  }
         *                  ...]);
         * @param value object to store
         */
        languages(value: any = {}) { return localStorageUtil.json('lookup_languages', value); },

        essentialServices(value: any = {}) { return localStorageUtil.json('lookup_essentialServices', value); },

        nameTitles(value: any = {}) { return localStorageUtil.json('lookup_nameTitles', value); },

        propertyTypes(value: any = {}) { return localStorageUtil.json('lookup_propertyTypes', value); },

        addressTypes(value: any = {}) { return localStorageUtil.json('lookup_addressTypes', value); }

    }



};
