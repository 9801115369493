import { ScrollerService } from './../../../core/helpers/view-scroller.service';
import { LookupService } from './../../../services/lookup.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { WizardComponent } from 'angular-archwizard';
import { Subscription } from 'rxjs';

// Models
import { Submission } from 'src/app/models/submission.model';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { FileUploadBasicService } from '../../../services/file-upload-basic.service';
import { SubmissionBasicService } from 'src/app/services/submission-basic.service';

// Constants
import { FileUploadModel, FileUploadResultModel } from 'src/app/models/file-upload.model';
import { enums } from 'src/app/shared/enums/enums';

// Utils
import { util } from 'src/app/shared/util/util';
import { AppService } from 'src/app/services/app.service';
import { UserPersonModel } from 'src/app/models/user-person.model';

@Component({
  selector: 'app-applicant-identity-basic',
  templateUrl: './applicant-identity-basic.component.html',
  styleUrls: ['./applicant-identity-basic.component.scss']
})
export class ApplicantIdentityBasicComponent implements OnInit {

  model: Submission;

  form: FormGroup;
  fileToUpload: File = null;
  uploadForm: FormGroup;
  imageError: any;
  cardImageBase64: any;
  isImageSaved: boolean;
  uploaddedfile: any;

  passportName: string = '';
  isPassportChosen: boolean = false;
  passportUriOfFile: string = '';
  passportError: string = '';
  passportUploadBtnText: string = '';

  coeName: string = '';
  isCoeChosen: boolean = false;
  coeUriOfFile: string = '';
  coeError: string = '';
  coeUploadBtnText: string = '';

  proofOfAddressName: string = '';
  isProofOfAddressChosen: boolean = false;
  proofOfAddressUriOfFile: string = '';
  proofOfAddressError: string = '';
  proofOfAddressUploadBtnText: string = '';

  bankStatementName: string = '';
  isBankStatementChosen: boolean = false;
  bankStatementUriOfFile: string = '';
  bankStatementError: string = '';
  bankStatementUploadBtnText: string = '';

  vevoName: string = '';
  isVevoChosen: boolean = false;
  vevoUriOfFile: string = '';
  vevoError: string = '';
  vevoUploadBtnText: string = '';

  isLoading: boolean = true;
  isNextPressed: boolean = false;


  // ---------------
  //  Getters

  //#region Getters

  get f() { return this.form.controls; } // getter for view
  get documentTypes() { return enums.documentTypes; }
  messages: any[] = [];
  subscription: Subscription;

  //#endregion

  constructor(private formBuilder: FormBuilder,
    private fileUploadBasicService: FileUploadBasicService,
    private submissionBasicService: SubmissionBasicService,
    private submissionService: SubmissionService,
    private scrollerService:ScrollerService,
    private awWizard:WizardComponent, private appService: AppService)
    {
      this.subscription = this.submissionBasicService.getFiles().subscribe(message => {
        if (message) {
          this.isLoading = true;
          this.loadFiles();
        } else {
          // clear messages when empty message received
          this.messages = [];
        }
      });
    }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      profile: new FormControl('')
    });
  }

  loadFiles() {
    this.model = this.submissionService.submissionModel;
    if (this.model != null) {
      var referenceNumber = this.model.referenceNumber;
      if (referenceNumber != null) {
        this.fileUploadBasicService.loadFile(referenceNumber).subscribe(result => {
          if (result != null && result.length > 0) {
            result.forEach(s => {
              if (s.uriOfFile != null) {
                if (s.uriOfFile.indexOf('/') > -1 && s.uriOfFile.indexOf('-') > -1) {
                  var documentType = s.uriOfFile.split('/')[4].split('-')[1];
                  this.setFileDetails(documentType, s.filename, s.uriOfFile);
                }
              }
            });
            this.isLoading = false;
          }

          return result;
        });
      }
    }
  }

  onClick_Next() {
    this.isNextPressed = true;

    if (!this.isPassportChosen || !this.isCoeChosen || !this.isPassportChosen || !this.isBankStatementChosen || !this.isVevoChosen) {
      return false;
    }

    this.model = this.submissionService.submissionModel;
    this.scrollerService.scrollViewToTop();

    this.appService.loadingOverlayShow('Saving details, please wait...');
    this.submissionService.submissionUser = new UserPersonModel();
      this.submissionService.submissionUser.userId = this.model.userId;
    this.submissionService.save(this.model).subscribe(result => {
      this.appService.loadingOverlayHide();
      this.submissionService.submissionModel = this.model;
      this.forceLoadPaymentsMethod();
      this.awWizard.navigation.goToNextStep();
    },
      error => {
        console.log(error);
        this.appService.loadingOverlayHide();
      },
      () => {
        this.appService.loadingOverlayHide();
      }
    );

  }
  onFileSelect(event) {

     const f = this.uploadForm.value;
     const file = event.target.files[0];
     this.uploaddedfile = file;

    const formData = new FormData();
    formData.append('file', this.uploaddedfile);

  }

  onFileChange(event, documentType){
    this.setLoadingText(documentType, 'uploading...');
    const file = event.target.files[0];
    this.uploaddedfile = file;
    this.saveFile(event, documentType);
  }

  saveFile(fileInput: any, documentType) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
        // Size Filter Bytes
        const max_size = 3145728;
        const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
        const max_height = 15200;
        const max_width = 25600;

        if (fileInput.target.files[0].size > max_size) {
            this.setImageError(documentType, 'Maximum size allowed is ' + max_size / 1024 / 1024 + 'MB');
            this.setLoadingText(documentType, '');
            return false;
        }

        if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
            this.setImageError(documentType, 'Only these files are allowed ( JPG | PNG | PDF )');
            this.setLoadingText(documentType, '');
            return false;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
            //const image = new Image();
            //image.src = e.target.result;
            //image.onload = rs => {};
            const imgBase64Path = e.target.result;
            if (imgBase64Path.indexOf(',') > -1) {
              this.cardImageBase64 = imgBase64Path.split(',')[1];
            } else {
              this.cardImageBase64 = imgBase64Path;
            }
            this.isImageSaved = true;

            const fileUploadModel = new FileUploadModel({
              submissionId: this.submissionService.submissionModel.submissionId,
              referenceNumber: this.submissionService.submissionModel.referenceNumber,
              documentType: documentType,
              fileName: this.uploaddedfile.name,
              fileDataBase64Encoded: this.cardImageBase64,
              uriOfFileToRemove: ""
            });

            this.fileUploadBasicService.saveFile(fileUploadModel).subscribe(result => {
              this.setFileDetails(documentType, fileUploadModel.fileName, result.body.uriOfFile);
              this.setImageError(documentType, '');
              this.setLoadingText(documentType, '');
              return result;
              });
        };

      const bse64 =   reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  setFileDetails(documentType, fileName, urioffile) {
    switch (documentType) {
      case this.documentTypes.Passport:
          this.isPassportChosen = true;
          this.passportName = fileName;
          this.passportUriOfFile = urioffile;
          break;
      case this.documentTypes.COE:
          this.isCoeChosen = true;
          this.coeName = fileName;
          this.coeUriOfFile = urioffile;
          break;
      case this.documentTypes.ProofOfAddress:
          this.isProofOfAddressChosen = true;
          this.proofOfAddressName = fileName;
          this.proofOfAddressUriOfFile = urioffile;
          break;
      case this.documentTypes.BankStatement:
          this.isBankStatementChosen = true;
          this.bankStatementName = fileName;
          this.bankStatementUriOfFile = urioffile;
          break;
      case this.documentTypes.VEVO:
          this.isVevoChosen = true;
          this.vevoName = fileName;
          this.vevoUriOfFile = urioffile;
          break;
    }
  }

  deleteFile(documentType) {
    if (confirm('Are sure you want to delete this file?')) {
      var uriOfFile = this.getUriOfFile(documentType);
      this.fileUploadBasicService.deleteFile(uriOfFile).subscribe(result => {
        this.resetFileUpload(documentType);
        return result;
       });
    }
  }

  getUriOfFile(documentType) {
    switch (documentType) {
      case this.documentTypes.Passport:
          return this.passportUriOfFile;
      case this.documentTypes.COE:
          return this.coeUriOfFile;
      case this.documentTypes.ProofOfAddress:
          return this.proofOfAddressUriOfFile;
      case this.documentTypes.BankStatement:
          return this.bankStatementUriOfFile;
      case this.documentTypes.VEVO:
          return this.vevoUriOfFile;
    }
  }

  resetFileUpload(documentType) {
    switch (documentType) {
      case this.documentTypes.Passport:
          this.isPassportChosen = false;
          break;
      case this.documentTypes.COE:
          this.isCoeChosen = false;
          break;
      case this.documentTypes.ProofOfAddress:
          this.isProofOfAddressChosen = false;
          break;
      case this.documentTypes.BankStatement:
          this.isBankStatementChosen = false;
          break;
      case this.documentTypes.VEVO:
          this.isVevoChosen = false;
          break;
    }
  }

  forceLoadPaymentsMethod(): void {
    // send message to subscribers via observable subject
    this.submissionBasicService.loadPaymentData(true);
  }

  setImageError(documentType, errorMsg) {
    switch (documentType) {
      case this.documentTypes.Passport:
          this.passportError = errorMsg;
          break;
      case this.documentTypes.COE:
          this.coeError = errorMsg;
          break;
      case this.documentTypes.ProofOfAddress:
          this.proofOfAddressError = errorMsg;
          break;
      case this.documentTypes.BankStatement:
          this.bankStatementError = errorMsg;
          break;
      case this.documentTypes.VEVO:
          this.vevoError = errorMsg;
          break;
    }
  }

  setLoadingText(documentType, loadingText) {
    switch (documentType) {
      case this.documentTypes.Passport:
          this.passportUploadBtnText = loadingText;
          break;
      case this.documentTypes.COE:
          this.coeUploadBtnText = loadingText;
          break;
      case this.documentTypes.ProofOfAddress:
          this.proofOfAddressUploadBtnText = loadingText;
          break;
      case this.documentTypes.BankStatement:
          this.bankStatementUploadBtnText = loadingText;
          break;
      case this.documentTypes.VEVO:
          this.vevoUploadBtnText = loadingText;
          break;
    }
  }

}
