import { MedicareCardComponent } from '../id-check/partials/medicare-card/medicare-card.component';

/**
 * Class that keeps track of properties related to the current state of the editor form.
 * Editor form that is displayed either on selection of grid item or click of add button.
 * It is used for editing items in the grid.
 */
export class DependantFormState {
    isVisible: boolean;
    editItemIndex: number;
    saveClicked: boolean;
    isMedicareButtonClicked: boolean;

    constructor(obj: DependantFormState = null) { if (obj) { Object.assign(this, obj); } }
}
