﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,  } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { enums } from 'src/app/shared/enums/enums';
import { sessionData } from 'src/app/shared/storage/session-data';

@Injectable({ providedIn: 'root' })
export class AuthRoute implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  // if user is authenticated already, and tries to access a PRE-login page (options/apply/register/etc.)
  //  then redirect to dashboard with an expiry check
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const targeturl = state.url;
    const isValidSession = this.authService.isAuthenticatedHandler(null, enums.sessionState.state_expired, targeturl);
    if (isValidSession) {
      if (sessionData.loginLocation() == enums.loginTypes.Recovery) {
        this.router.navigate(['/dashboard']);
      }
      else {
        this.router.navigate(['/support/dashboard']);
      }
    }
    // always true as this is a forward route.
    return true;
  }
}
