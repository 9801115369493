import { FormGroup, Validators, AbstractControl, ValidatorFn, FormControl } from '@angular/forms';

/**
 * Reusable utility functions for working with Reactive Forms
 */
export const formUtil = {

    mapModelToForm(
        model: any,
        controls: { [key: string]: AbstractControl },
        onAssign: { [key: string]: Function } = null
    ) {
        for (const field in model) {
            if (controls.hasOwnProperty(field)) {
                const control = controls[field];
                const isFormControl = (!control.hasOwnProperty('controls'));
                if (isFormControl) {
                    let value = model[field];
                    // process value in a custom way by using a callback function
                    if (onAssign && onAssign.hasOwnProperty(field)) {
                        value = onAssign[field](value);
                    }
                    control.setValue(value);
                }
            }
        }
    },

    /**
     * Properties and methods related to reactive form validation
     */
    validation: {

        /**
         * List of Botstrap 4 css classes used for marking invalid fields
         */
        cssClasses: {
            /**
             * use this to color in red input control when it is invalid
             */
            invalidInputControl: 'is-invalid',
            /**
             * use this to color in red bootastrap input group element, when input control inside it is invalid
             */
            invalidInputGroup: 'has-error',
            /**
             * when form is not valid, show button as grayed out using this class
             */
            buttonDisabled: 'btn-outline-secondary',
            /**
             * when form is valid, show button as alive and active using this class
             */
            buttonSubmit: 'btn-outline-primary'
        },

        /**
          * Generic rule that decides whether to mark field as invalid, based on field statuses. Should be used in most scenarios
          *
          * @param {AbstractControl} control AbstractControl object of the Reactive Form
          * @param {boolean} formSubmitAttempted
          *              Optional.
          *              Defines if the form containing the validated control has been attempted to be submitted ie. submit button clicked.
          *              If provided and true, the validation will highlight field immediatly if its state is invalid
          *
          * @returns {boolean} Returns boolean flag that says if field is invalid
         */
        isControlInvalid(control: AbstractControl, formSubmitAttempted: boolean = null): boolean {
            const controlInvalidAndDirtyOrTouched = (control.invalid && (control.dirty || control.touched));
            return (
                formSubmitAttempted != null ?
                    ((formSubmitAttempted && control.invalid) || controlInvalidAndDirtyOrTouched) :
                    controlInvalidAndDirtyOrTouched
            );
        },

        /**
         * Get validation class for the given input field by using common validation rule
         *
         * @param {AbstractControl} control AbstractControl object of the Reactive Form
         * @param {boolean} formSubmitAttempted
         *              Optional parameter.
         *              Defines if the form containing the validated control has been attempted to be submitted ie. submit button clicked.
         *              If provided and true, the validation will highlight field immediatly if its state is invalid
         *
         * @returns {string} Returns css class that marks given Form Control as invalid
         *                  if the validity rule for the control returns invalid=true
         */
        getCssClass_invalidInputControl(control: AbstractControl, formSubmitAttempted: boolean = null): string {
            return formUtil.validation.isControlInvalid(control, formSubmitAttempted) ?
                formUtil.validation.cssClasses.invalidInputControl : '';
        },

        /**
         * Used inside bootstrap element marked with class class="input-group
         *
         * @param {AbstractControl} control AbstractControl object of the Reactive Form
         *
         * @returns {string} Returns css class that marks parent input-group
         *                  of the given Form Control as invalid if the validity rule for the control returns invalid=true
         */
        getCssClass_invalidInputGroup(control: AbstractControl, formSubmitAttempted: boolean = null): string {
            return formUtil.validation.isControlInvalid(control, formSubmitAttempted) ?
                formUtil.validation.cssClasses.invalidInputGroup : '';
        },

        /**
         * Trigger Validation highlighting: Recursively sets all child controls to Touched.
         * @param formGroup
         */
        markAsTouched_allFormFields(formGroup: FormGroup) {
            Object.keys(formGroup.controls).forEach(field => {
                const control = formGroup.get(field);
                if (control instanceof FormControl) {
                    control.markAsTouched({ onlySelf: true });
                } else if (control instanceof FormGroup) {
                    this.markAsTouched_allFormFields(control);
                }
            });
        },

        /**
         * Remove Validation highlighting: Recursively sets all child controls to Pristine.
         * @param formGroup
         */
        markAsPristine_allFormFields(formGroup: FormGroup) {
            Object.keys(formGroup.controls).forEach(field => {
                const control = formGroup.get(field);
                if (control instanceof FormControl) {
                    control.markAsPristine({ onlySelf: true });
                } else if (control instanceof FormGroup) {
                    this.markAsPristine_allFormFields(control);
                }
            });
        },

        /**
         * Convenience method to Set or Clear validators for the given form control.
         * It also updates Value and Validity of the control to reflect updated validation in the user interface.
         * @param control Form Control
         * @param validators Validators of the form control - only if setting the new validators.
         *                    If clearing existing validators do not provide this parameter.
         * @param {boolean} clearValueWhenTurnedOff Clear the value of the control when validator is cleared
         *
         * @example
         * CLEAR Validators:
         *      this.validation_toggleControlValidators(this.fm.hasDependants);
         *
         * SET Validators
         *      this.validation_toggleControlValidators(this.fm.hasDependants, this.vdtr_hasDependants);
         */
        toggleControlValidators(control: AbstractControl, validators: ValidatorFn[] = null, clearValueWhenTurnedOff = false): void {

            control.clearValidators();

            // if validators provided then set them for this control
            if (validators !== null) {
                control.setValidators(validators);

            } else {

                // if flag to clear value is true then clear the value as well
                if (clearValueWhenTurnedOff) {
                    control.setValue(null);
                }
            }
            control.updateValueAndValidity();
        },

        /**
         * Convenience method to Set or Clear validators for multiple form controls.
         *
         * @param {FormGroup} form Parent form group containing controls
         * @param { [key: string]: Array<ValidatorFn> } controlValidators key-value pair object, containing control names as keys
         *                                                                and array of validators for each control as value
         * @param {boolean} on flag that decides whether to turn validators on or off
         *
         */
        toggleControlsValidators(form: FormGroup, controlsValidators: { [key: string]: Array<ValidatorFn> }, on: boolean, clearValueWhenTurnedOff = false) {
            for (const ctrlName in controlsValidators) {
                if (controlsValidators.hasOwnProperty(ctrlName) && form.controls.hasOwnProperty(ctrlName)) {
                    const control = form.controls[ctrlName];

                    if (on) {
                        const validators = controlsValidators[ctrlName];
                        formUtil.validation.toggleControlValidators(control, validators, clearValueWhenTurnedOff);
                    } else {
                        formUtil.validation.toggleControlValidators(control);
                    }
                }
            }
        },

        /**
         * Convenience method that allows you to dynamically set required validator ON/OFF on multiple controls .
         * Use this only for controls that contain only reuired validator.
         * NOTE: This will call updateValueAndValidity() so no need to call it separately 
         * 
         * @param {AbstractControl} control - control to which the required field validator is applied
         * @param {boolean} on              - flag which specify whether to turn validator on or off
         */
        toggleRequired(control: AbstractControl, on: boolean, clearValueWhenTurnedOff = false) {
            if (on) {
                control.setValidators(Validators.required);
            } else {
                control.clearValidators();
                // if flag to clear value is true then clear the value as well
                if (clearValueWhenTurnedOff) {
                    control.setValue(null);
                }
            }
            control.updateValueAndValidity();
        },
        /**
         * Toggles required field validator for multiple controls
         * @param controls
         * @param on
         */
        toggleRequiredMulti(controls: AbstractControl[], on: boolean, clearValueWhenTurnedOff = false) {
            controls.forEach((c: AbstractControl) => {
                formUtil.validation.toggleRequired(c, on, clearValueWhenTurnedOff);
            });
        },

        /**
         * Dynamically clears all first level child controls inside FormGroup.
         * NOTE: This will call updateValueAndValidity() so no need to call it separately
         * @param {FormGroup} form parent form
         */
        clearFormValidators(form: FormGroup) {
            for (const ctrlName in form.controls) {
                if (form.controls.hasOwnProperty(ctrlName)) {
                    const ctrl = form.controls[ctrlName];
                    ctrl.clearValidators();
                }
            }
            form.updateValueAndValidity();
        },

        /**
         * Dynamically clears all controls passed in and updates their validity.
         * NOTE: This will call updateValueAndValidity() so no need to call it separately
         */
        clearControlsValidators(...controls: AbstractControl[]) {
            for (let i = 0; i < controls.length; i++) {
                const ctrl = controls[i];
                ctrl.clearValidators();
                ctrl.updateValueAndValidity();
            }
        },

        /**
         * Use this to remove validators inside nested subform.
         * This could be used effectively in CONDITIONAL-MANDATORY validation scenario
         *  (e.g. subForm displayed only when Yes/No question in parent form is answered Yes ).
         * NOTE: This will call updateValueAndValidity() so no need to call it separately
         *
         * @param childForm
         * @param parentForm
         */
        clearChildFormValidators(childForm: FormGroup, parentForm: FormGroup): void {
            for (const key in childForm.controls) {
                if (childForm.controls.hasOwnProperty(key)) {
                    const control = childForm.get(key);
                    formUtil.validation.toggleControlValidators(control);
                }
            }
            formUtil.validation.toggleControlValidators(childForm);
            parentForm.updateValueAndValidity();
        }

    }
    // -------------------------// End Form Validation methods and properties // ---------------------



};

