import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

// Utils
import { formUtil } from 'src/app/shared/util/form-util';

// Models
import { CenterlinkCardFormModel } from './centerlink-card-form.model';

// Base Classes
import { BaseChildForm } from 'src/app/shared/base-classes/base-child-form';

// Constants
import { regex } from 'src/app/shared/constants/regex';
import { regexDescription } from 'src/app/shared/constants/regex-description';

@Component({
  selector: 'app-centrelink-card',
  templateUrl: './centrelink-card.component.html',
  styleUrls: ['./centrelink-card.component.scss']
})
export class CentrelinkCardComponent extends BaseChildForm implements OnInit {


  // ---------------------------------------------------------------------------
  //      Properties
  // ---------------------------------------------------------------------------

  titleCentrelink = 'Concession and Health Care Cards';

  regexDescription = regexDescription;


  // -------------------------------------
  //  Form

  form: FormGroup;
  fgModel = {
    hasCentrelinkCard: [null],
    centrelinkNumber: [null]
  };
  hasAttemptedToProgress: boolean;

  // -------------------------------------
  //  Getters

  get f() { return this.form.controls; }
  get hasCentrelinkCard(): boolean { return this.f.hasCentrelinkCard.value; }
  get model() { return this.form.value.centrelinkNumber as number || null; }

  // ---------------------------------------------------------------------------
  //      Constructor
  // ---------------------------------------------------------------------------

  constructor(private fb: FormBuilder) {
    super();
    // initialize default component id. Id must be unique fro every instance otherwise clashes are possible
    this.componentId = 'centerlinkCard';
    this.componentInitialized = new EventEmitter<CentrelinkCardComponent>();
  }

  // ---------------------------------------------------------------------------
  //      Methods
  // ---------------------------------------------------------------------------



  // -------------------------------------
  //  Public Methods

  bindModelToForm(model: CenterlinkCardFormModel) {
    formUtil.mapModelToForm(model, this.f);
    this.toggleFormFieldValidators(this.hasCentrelinkCard);
  }


  // -------------------------------------
  //  Private Methods

  public initForm() {

    // create form group
    this.form = this.fb.group(this.fgModel);

    // initialize hasCentrelinkCard to required
    formUtil.validation.toggleRequired(this.f.hasCentrelinkCard, true);
  }

  onChange_hasCentrelinkCard() {
    this.toggleFormFieldValidators(this.hasCentrelinkCard);
  }

  private toggleFormFieldValidators(hasCentrelinkCard: boolean) {
    formUtil.validation.toggleControlsValidators(this.form, {
      'centrelinkNumber': [Validators.required, Validators.pattern(regex.centerlink_number)],
    }, hasCentrelinkCard);
  }




}
