import { LookupListItem } from 'src/app/models/lookup-list-item.model';

declare global {
    interface Array<T> {
        GetTextFromId(this: LookupListItem[], id): string;
    }
}

Array.prototype.GetTextFromId = function(id) {
    const result = this.find(x => x.id === id);
    return result === undefined ? '' : result.name;
}