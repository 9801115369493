﻿

// Framework imports
import { FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { MovingDirection } from 'angular-archwizard';

// Models
import { SubmissionOutcome, Submission } from '../../models/submission.model';
import { LookupListItem } from 'src/app/models/lookup-list-item.model';

// Services
import { SubmissionService } from '../../services/submission.service';
import { ScrollerService } from '../../core/helpers/view-scroller.service';
import { AuthService } from 'src/app/services/auth.service';

// Constants
import { environment } from '../../../environments/environment';
import { formUtil } from '../util/form-util';
import { ApplicationService } from 'src/app/pages/application/application.service';
import { ApplicationStep } from 'src/app/pages/application/application-step.enum';

/**
 * Put here optional abstract members
 */
export interface BaseApplicationStep {

    /**
    * Lifecycle Hook: Implement custom actions that are executed when submission data is loaded
    */
    wizStep_onLoad?(): void;

    /**
    * Lifecycle Hook: Implement custom actions that are executed on each step enter regardless of MovingDirection.
    * For example : reset hasAttemptedToProgress flag of child controls,
    * so that invalid fields are not highlighted until user clicks on button Next.
     * @param direction *optional - accept direction parameter optionally
     */
    wizStep_onStepEnter?(direction?: MovingDirection): void;

    /**
    * Lifecycle Hook: Implement custom actions that are executed when canExitStep gets called
    */
    wizStep_onCanExitStep?(isValid: boolean): boolean;

    /**
     * Lifecycle Hook: EXTRA Validation logic - besides the form validators.
     * Allows for custom validation before proceeding onto next step.
     * Called inside last validation function inside WizardStepBase, before attempting to move on to the next step.
     * Use only if you need extra custom validation or you need to validate forms that are outside of form.
     * MUST RETURN boolean
     */
    wizStep_onValidate?(): boolean;

    /**
     * Lifecycle Hook: Implement custom actions that are executed after succesfull save method execution.
     *
     * @param {any} response response received from server
     */
    wizStep_onSaveSuccess?(response: any): void;

    /**
     * Lifecycle Hook: Implement custom actions that are executed if form is not submited because it has not been changed.
     */
    wizStep_onNotSave?(): void;

}
/**
 * Encapsulates common logic for the components used as a step in a wizard
 */
export abstract class BaseApplicationStep {


    // ------------------------------------------------------
    //                    Properties
    // ------------------------------------------------------


    // ----------------------------
    //      Other

    /** This step's index in order of steps in the wizard */
    public stepIndex: ApplicationStep;

    /**
     *  Previous step to which this step should navigate.
     *  This is used for custom navigation with complex business logic which decides the flow
     * */
    public previousStep: ApplicationStep;

    /** MAIN FORM - Main or root form of the wizard step. MUST IMPLEMENT in component that extends from this abstract class */
    public abstract form: FormGroup;

    /** Set to true once the user has clicked on button Next to progress to next step. */
    protected hasAttemptedToProgress = false;


    /**
     * Used during design stage to ignore the validation so developer can pass onto next step without form getting validated
     * TODO: I strongly recommend removing this.
     *          Developer can do any kind of value alterations inside DevTools in Chrome,
     *          which makes these kind of "HACK" strategies completely unnecessary (VEX 2018-09-04)
     */
    private bypassWizard: boolean = environment.disableWizardValidation;


    // These are optional settings for wizard steps that
    // save only model containing data relevant to current step/page by calling their dedicated page api end point
    public pageApiUrl = null;
    public pageModel = null;


    // ----------------------------
    //      Getters

    /** MAIN MODEL - Main submission model that gets passed from step to step of the wizard and form data is bound to it. */
    public get model() { return this.submissionService.submissionModel; }

    /** Getter for form validity. Decides whether the application can progress onto next step. */
    get isFormValid(): boolean { return this.bypassWizard || this.validate(); }

    get isFirstSubmission(): boolean { return this.submissionService.isFirstSubmission; }

    get lookup_languages(): LookupListItem[] { return this.submissionService.lookup_languages; }
    get lookup_nameTitles(): LookupListItem[] { return this.submissionService.lookup_nameTitles; }
    get lookup_propertyTypes(): LookupListItem[] { return this.submissionService.lookup_propertyTypes; }
    get lookup_addressTypes(): LookupListItem[] { return this.submissionService.lookup_addressTypes; }


    // ------------------------------------------------------
    //    Constructor
    // ------------------------------------------------------

    constructor(
        stepOrderNumber: ApplicationStep,
        public appService: ApplicationService,
        public submissionService: SubmissionService,
        public authService: AuthService,
        private scrollerService: ScrollerService = null
    ) {
        this.stepIndex = stepOrderNumber;
    }


    // ------------------------------------------------------
    //    Methods
    // ------------------------------------------------------

    /**
     * NOTE: Call this method inside ngOnInit(), because that is when form controls will be completely loaded.
     * this method gets submission data model either from server or from client if exists and calls bindModelToForm().
     */
    protected load() {

        // If load is to be ignored, then exit without further processing
        if (this.submissionService.ignoreLoad) {
            return;
        }

        // ------------------------
        // Load Existing submission

        if (!this.submissionService.isNewSubmission
            && !this.submissionService.isSubmissionLoaded
            && !this.submissionService.inProgress_gettingSubmissionData) {

            const submissionId = this.submissionService.submissionId;

            // get submission model from serverside
            this.submissionService.getSubmissionById(submissionId).subscribe(
                // On Success
                r => {
                    this.bindModelToForm();

                    if (this.wizStep_onLoad) {
                        this.wizStep_onLoad();
                    }
                },
                // On Error
                error => {
                    this.onApiCallError(error);
                },
                () => { } // On Complete
            );

            // ------------------------
            // Load New Submission

        } else {
            // get submission model from serverside
           // const submissionId = this.submissionService.submissionId;
            //if(submissionId !== 0){
              this.submissionService.getLastSubmission().subscribe(
                (lastSubmission) => {

                    // Always set applicant details from user session data
                    this.submissionService.loadSubmissionData_fromSubmissionUser();

                    // if there was a submission made by this user before, copy the details into new application
                    if (lastSubmission) {
                        this.submissionService.loadSubmissionData_fromLastSubmission(lastSubmission);

                        // mark form as dirty to make sure that form is saved even if the user does not change any details
                        this.form.markAsDirty();
                    }

                    this.bindModelToForm();

                    if (this.wizStep_onLoad) {
                        this.wizStep_onLoad();
                    }
                },
                // On Error
                error => {
                    this.onApiCallError(error);
                }, () => { }
            );
           // }

        }
    }

    /**
     * Calls API :: Saves model to Database.
     * NOTE: Implement onSuccess code inside wizStep_onSaveSuccess
     */
    private save(loadingMessage: string = '') {

        this.appService.showLoading(loadingMessage);

        if (this.pageApiUrl) {
            this.submissionService.savePage(this.pageApiUrl, this.pageModel)
                .pipe(finalize(() => { this.appService.hideLoading(); }))
                .subscribe(
                    // onSuccess
                    (r: any) => {
                        this.onSaveSuccess();
                        if (this.wizStep_onSaveSuccess) {
                            this.wizStep_onSaveSuccess(r);
                        }
                    },
                    // On Error
                    error => {
                        this.onApiCallError(error);
                    },
                    () => { } // onComplete - TODO: do something on complete
                );
        } else {
            this.submissionService.save()
                .pipe(finalize(() => { this.appService.hideLoading(); }))
                .subscribe(
                    // onSuccess
                    (r: SubmissionOutcome) => {
                        this.onSaveSuccess();
                        if (this.wizStep_onSaveSuccess) {
                            this.wizStep_onSaveSuccess(r);
                        }
                    },
                    // On Error
                    error => {
                        this.onApiCallError(error);
                    },
                    () => { } // onComplete - TODO: do something on complete
                );
        }
    }
    private onSaveSuccess() {

        // mark form as pristine after submission,
        // so that it doesnt get submitted again if user comes back to it but doesn't change anything
        this.form.markAsPristine();
    }
    private onApiCallError(error) {
        this.appService.error = error.message || error;
    }


    // -----------------------
    //  Abstract methods

    /**
    * DATA BINDING - Bind model values to the form fields.
    * Implement concrete method for this abstract contract inside child class.
    */
    abstract bindModelToForm(): void;

    /**
     * DATA BINDING - Bind field values of form to properties of model object.
     * Implement concrete method for this abstract contract inside child class.
     */
    abstract bindFormToModel(): void;


    // -----------------------
    //  Events


    /**
     * Call this inside your wizard step template:
     *
     * USAGE ::    <aw-wizard-step (stepEnter)="yourStepComponent.onStepEnter" ... > ... </aw-wizard-step>
     *
     * If moving forward, will set hasAttemptedToProgress=true, and will call isFormValid,
     * which will trigger validation to show error messages
     */
    onStepEnter(direction: MovingDirection) {

        // Clear any errors left-over from before
        this.appService.error = '';

        if (direction === MovingDirection.Forwards) {
            this.previousStep = this.appService.currentStep;
        }

        // let app service know what step we are on so that app service knows how to handle any custom logic
        this.appService.currentStep = this.stepIndex;

        // if we were on this step before and scrolled down,
        // we have to make sure when we return to this step that content is scrolled to the top
        this.scrollToTop();

        if (this.wizStep_onStepEnter) {
            this.wizStep_onStepEnter(direction);
        }

        // if moving forward then bind model to form
        if (direction === MovingDirection.Forwards && !this.submissionService.inProgress_gettingSubmissionData) {
            this.bindModelToForm();
        }
    }


    /**
     * Call this inside your wizard step template:
     *
     * USAGE ::    <aw-wizard-step [canExit]="yourStepComponent.canExitStep" ... > ... </aw-wizard-step>
     *
     * If moving forward, will set hasAttemptedToProgress=true, and will call isFormValid,
     * which will trigger validation to show error messages
     */
    canExitStep: (MovingDirection) => boolean = (direction) => {
        // only attempt to leave any wizard step if passes check for session validity
        if (this.authService.isAuthenticatedHandler()) {
            switch (direction) {
                case MovingDirection.Forwards:
                    this.hasAttemptedToProgress = true;
                    const isValid = this.isFormValid;

                    if (this.wizStep_onCanExitStep) {
                        return this.wizStep_onCanExitStep(isValid) && isValid;
                    }
                    return isValid;
                case MovingDirection.Backwards:
                    return true;
                case MovingDirection.Stay:
                    return true;
            }
        }
    }

    /**
     * Call this inside your wizard step template :
     *
     * USAGE ::     <aw-wizard-step (stepExit)="yourStepComponent.onStepExit($event)" ... > ... </aw-wizard-step>
     *
     * CALLS bindFormToModel() method
     * Gets called on click of the button Next if canExitStep is true
     * @param direction direction of moving, either to Previous or to Next step
     * @param {boolean} save if true, submission service calls serverside api and saves model to database
     */
    onStepExit(direction: MovingDirection, save: boolean = false): void {

        // --------
        // Previous Click

        if (direction === MovingDirection.Backwards) {

            this.bindFormToModel();

            // if scroller service passed in, scroll the form to top
            this.scrollToTop();
        }

        // --------
        // Next Click

        if (direction === MovingDirection.Forwards) {
            this.saveChanges();
        }
    }

    onLastStep(save: boolean = false) {
        this.hasAttemptedToProgress = true;
        if (this.isFormValid) {
            this.saveChanges(true, false);
        }
    }

    /**
     * Save step form data into service model and update to server if saveToServer flag is true.
     * NOTE: calls bindFormToModel() ...
     * NOTE: calls wizStep_onSaveSuccess() callback , if defined
     * @param saveToServer
     * @param onlySaveToServerIfDirty
     */
    protected saveChanges(saveToServer: boolean = true, onlySaveToServerIfDirty = true, loadingMessage: string = '') {

        this.bindFormToModel();

        saveToServer = (saveToServer && (onlySaveToServerIfDirty ? this.form.dirty : true));

        // if scroller service passed in, scroll the form to top
        this.scrollToTop();

        // if save is set to true, save model to database
        if (saveToServer) {
            this.save(loadingMessage);
        } else if (this.wizStep_onNotSave) {
            this.wizStep_onNotSave();
        }
    }

    /**
     * Scroll content to top
     */
    protected scrollToTop() {
        if (this.scrollerService) {
            this.scrollerService.scrollViewToTop();
        }
    }

    // -----------------------
    //  Validation

    /**
     * Final validation before attempting to proceede to next step
     */
    private validate() {
        this.form.updateValueAndValidity();
        let isValid = this.form.valid;
        // if wizStep_onValidate is defined, then logical-and (&&) its result with validity of main form
        // allows for custom validation before proceeding onto next step
        if (this.wizStep_onValidate) { isValid = isValid && this.wizStep_onValidate(); }
        return isValid;
    }

    /**
     * Generic rule that decides whether to mark field as invalid, based on field statuses. Should be used in most scenarios
     * @param field FormControl object of the Reactive Form
     * @returns {boolean} Returns boolean flag that says if field is invalid
     */
    protected validation_isFieldInvalid(field: AbstractControl): boolean {
        return formUtil.validation.isControlInvalid(field, this.hasAttemptedToProgress);
    }
    /**
     * Get validation class for the given input field by using common validation rule
     * @param field FormControl object of the Reactive Form
     * @returns {string} Returns css class that marks given Form Control as invalid
     *                  if the validity rule for the control returns invalid=true
     */
    protected validation_cssClass_forInputField(field: AbstractControl): string {
        return this.validation_isFieldInvalid(field) ? formUtil.validation.cssClasses.invalidInputControl : '';
    }
    /**
     *  Used inside bootstrap element with class class="input-group
     * @param field FormControl object of the Reactive Form
     * @returns {string} Returns css class that marks parent input-group
     *                  of the given Form Control as invalid if the validity rule for the control returns invalid=true
     */
    protected validation_cssClass_forInputGroup(field: AbstractControl): string {
        return this.validation_isFieldInvalid(field) ? formUtil.validation.cssClasses.invalidInputGroup : '';
    }

    /**
     * Convenience method to Set or Clear validators for the given form control.
     * It also updates Value and Validity of the control to reflect updated validation in the user interface.
     * @param control Form Control
     * @param validators Validators of the form control - only if setting the new validators.
     *                    If clearing existing validators do not provide this parameter.
     *
     * @example
     * CLEAR Validators:
     *      this.validation_toggleControlValidators(this.fm.hasDependants);
     *
     * SET Validators
     *      this.validation_toggleControlValidators(this.fm.hasDependants, this.vdtr_hasDependants);
     */
    protected validation_toggleControlValidators(control: AbstractControl, validators: ValidatorFn[] = null): void {

        // if validators provided then set them, but only if control does not already have them
        if (validators && (control.validator == null || control.validator.length < 1)) {
            control.setValidators(validators);
            control.updateValueAndValidity();

            // if no validators provided then clear all validators in this control
        } else {
            control.clearValidators();
            control.updateValueAndValidity();
        }
    }

    /**
     * Use this to remove validators inside nested subform in CONDITIONAL-MANDATORY validation scenario
     * (e.g. form displayed only when Yes/No question is answered Yes )
     * NOTE: updates Value and Validity of the Main Form
     * @param form subform inside the main form
     */
    protected validation_removeFormValidators(form: FormGroup): void {
        for (const key in form.controls) {
            if (form.controls.hasOwnProperty(key)) {
                const control = form.get(key);
                this.validation_toggleControlValidators(control);
            }
        }
        this.validation_toggleControlValidators(form);
        this.form.updateValueAndValidity();
    }

    public validation_getInputCssClass(controlName: string) {
        const control = this.form.controls[controlName];
        return this.validation_cssClass_forInputField(control);
    }

    public validation_getInputGroupCssClass(controlName: string) {
        const control = this.form.controls[controlName];
        return this.validation_cssClass_forInputGroup(control);
    }


}
