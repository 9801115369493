import { Component, OnInit } from '@angular/core';
import { FormGroup,  Validators, FormBuilder,  FormArray, AbstractControl } from '@angular/forms';


// Constants
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { regex } from 'src/app/shared/constants/regex';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { AuthService } from 'src/app/services/auth.service';

// Base Classes
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';
import { util } from 'src/app/shared/util/util';
import { formUtil } from 'src/app/shared/util/form-util';

// Models
import { enums } from 'src/app/shared/enums/enums';
import { SubmissionCulture } from 'src/app/models/submission.model';
import { isAtLeastOneSelectedValidator } from 'src/app/shared/validators/isAtLeastOneSelected-validator.directive';

// Validators
import { ageIsLessThanMaxAgeValidator } from 'src/app/shared/validators/ageIsLessThanMaxAge-validator.directive';
import { ageIsGreaterThanMinAgeValidator } from 'src/app/shared/validators/ageIsGreaterThanMinAge-validator.directive';
import { LookupListItem } from 'src/app/models/lookup-list-item.model';
import { regexDescription } from 'src/app/shared/constants/regex-description';
import { ApplicationService } from '../../application.service';
import { ApplicationStep } from '../../application-step.enum';
import { SubmissionPersonModel } from 'src/app/models/submission-person.model';

@Component({
  selector: 'app-applicant-details',
  templateUrl: './applicant-details.component.html',
  styleUrls: ['./applicant-details.component.scss']
})

export class ApplicantDetailsComponent extends BaseApplicationStep implements OnInit {

  // ----------------------------------------
  //          Properties
  // ----------------------------------------


  // ---------------
  //  Subscriptions

  urlSubscription;



  // --------------
  //  Lookup Lists

  lookup_genders = lookupLists.genders;
  lookup_contactMethods = (Object.keys(enums.contactMethodTypes).map(e => enums.contactMethodTypes[e])).sort();
  lookup_culturalGroups = lookupLists.culturalGroups;
  isLoading_languages = false;

  typeahead_nameTitles: LookupListItem[] = [];
  cultures:any[];

  // -------
  //  Headings

  headingDetails = 'Your Details';
  headingContactDetails = 'Contact Details';
  headingCulturalGroups = 'Cultural Groups';
  headingInterpreterServices = 'Interpreter Services';

  // -------
  // Form

  form: FormGroup;

  private validator_otherCultureName = [Validators.required, Validators.maxLength(100)];
  private validator_otherInterpreterLanguage = [Validators.required, Validators.maxLength(100)];
  private validator_dateOfBirth = [Validators.required, ageIsGreaterThanMinAgeValidator(18, true), ageIsLessThanMaxAgeValidator(120)];

  regexDescription = regexDescription;

  // -------
  // Other

  isOtherLanguageSelected = false;
  language_Other = 100000019;
  maxDOB: Date;

  // --------
  // Getters

  get f() { return this.form.controls; }
  get dobString() {
    let date = this.f.dateOfBirth.value;
    if (date) {
      date = util.getDateInFormat(date, 'dd MMM yyyy');
    }
    return date;
  }
  get gender() {
    const gender = lookupLists.genders.find(g => g.value == this.f.genderId.value);
    return gender ? gender.name : '';
  }
  get cultureOptionsArray(): FormArray { return this.form.controls.cultureOptions as FormArray; }
  get isOtherCultureSelected(): AbstractControl { return this.cultureOptionsArray.controls[3].value; }
  get otherCultureName(): AbstractControl { return this.form.controls.cultureOther; }
  get otherInterpreterLanguage(): AbstractControl { return this.form.controls.interpreterLanguageOther; }
  get phone(): AbstractControl { return this.form.controls.phoneNumber; }
  get email(): AbstractControl { return this.form.controls.emailAddress; }
  get requiresInterpreter(): boolean { return this.f.requiresInterpreter.value; }
  get languageOptionsArray(): FormArray { return this.form.controls.languageOptions as FormArray; }


  // ----------------------------------------
  //          Constructor
  // ----------------------------------------

  constructor(
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.ApplicantDetails, appSvc, submissionService, authService, scrollerService);
    this.initForm();
  }


  // ----------------------------------------
  //          Methods
  // ----------------------------------------

  ngOnInit() {
    this.maxDOB = new Date();
    this.maxDOB.setDate(new Date().getDate() - 1);
    this.load();
  }

  wizStep_onLoad() {
    // this will forward to preview page if completed.
    // this needs to occur after entering the application once the data has been loaded to determine completed status.
    // application component does not hold the model so it is done in the first step.
    if (this.submissionService.isCompletedSubmission) {
      this.appService.goToStep(ApplicationStep.Preview);
    }
  }

  private initForm() {
    // Set up array if checkboxes for Cultural Options
    const options = this.lookup_culturalGroups.map(o => this.fb.control(false));
    const languageOptions = this.lookup_languages.map(o => this.fb.control(false));

    // create form group for applicaton details
    this.form = this.fb.group({
      title: ['', Validators.required],
      givenName: ['', [Validators.required, Validators.pattern(regex.name_person), Validators.maxLength(27)]],
      middleName: ['', [Validators.pattern(regex.name_person), Validators.maxLength(27)]],
      surname: ['', [Validators.required, Validators.pattern(regex.name_person), Validators.maxLength(27)]],
      dateOfBirth: ['', this.validator_dateOfBirth],
      genderId: ['', Validators.required],
      // Validation is set based on the contact method chosen - if contact method is Phone then phone number is required
      phoneNumber: ['', Validators.pattern(regex.phone)],
      alternativePhoneNumber: ['', Validators.pattern(regex.phone)],
      // Validation is set based on the contact method chosen - if contact method is Email then email address is required
      emailAddress: [''],
      contactMethod: ['', Validators.required],
      cultureOptions: this.fb.array(options, isAtLeastOneSelectedValidator()),
      cultureOther: [''],
      requiresInterpreter: [null, Validators.required],
      interpreterLanguageSpoken: [''],
      interpreterLanguage: [''],
      languageOptions: this.fb.array(languageOptions),
      interpreterLanguageOther: ['']
    });

    // Set validator for interpreterLanguageSpoken when requiresInterpreter is set to true
    this.f.requiresInterpreter.valueChanges.subscribe(
      (isInterpreterRequired: boolean) => {
        formUtil.validation.toggleRequired(this.f.interpreterLanguageSpoken, isInterpreterRequired);
        formUtil.validation.toggleRequired(this.f.interpreterLanguageOther, this.model.interpreterLanguageSpoken == this.language_Other);
        if (isInterpreterRequired == false) {
          this.f.interpreterLanguageSpoken.setValue('');
          this.f.interpreterLanguage.setValue(null);
          this.isOtherLanguageSelected = false;
        }
      }
    );

    // Set validators for contact details based on contact method
    this.f.contactMethod.valueChanges.subscribe(
      (method: string) => {

        // Clear validators
        this.phone.clearValidators();
        this.email.clearValidators();

        // Initialize validators array, add format validator for phone and email
        const phoneValidators = [Validators.pattern(regex.phone)];
        const emailValidators = [Validators.pattern(regex.email)];

        // Phone contact preferred, add required validator
        if (method === enums.contactMethodTypes.Phone) {
          phoneValidators.push(Validators.required);
        }

        // Email contact preferred, add required validator
        if (method === enums.contactMethodTypes.Email) {
          emailValidators.push(Validators.required);
        }

        // Set validators
        this.phone.setValidators(phoneValidators);
        this.email.setValidators(emailValidators);

        // Upadate field value and validity
        this.phone.updateValueAndValidity();
        this.email.updateValueAndValidity();
      }
    );
  }

  // Applicant Title typeahead event handler
  onInput_applicantTitle(e) {
    const term = e.target.value;
    this.typeahead_nameTitles = this.lookup_nameTitles.filter(item => item.name.toLowerCase().startsWith(term.toLowerCase()));
  }


  cultureOption_isOther(index: number): boolean { return index === this.cultureOptionsArray.length - 3; }
  cultureOption_isDoNotDisclose(index: number): boolean { return index === this.cultureOptionsArray.length - 2; }
  cultureOption_isLast(index: number): boolean { return index === this.cultureOptionsArray.length - 1; }

  onChange_cultureOptions(event: any, index: number): void {
    const isChecked = event.target.checked;
    const length = this.cultureOptionsArray.length;
    const lastIndex = length - 1;
    const isLast = this.cultureOption_isLast(index);
    const isOther = this.cultureOption_isOther(index);
    // None :: unselect all other checkboxes
    if (isLast && isChecked) {
      for (let i = 0; i < length; i++) {
        if (i !== index) {
          this.cultureOptionsArray.controls[i].setValue(false);
        }
      }
      formUtil.validation.toggleControlValidators(this.otherCultureName, null, true);
      return;
    }
    // Not None :: if not last checked then uncheck last
    if (!isLast) {
      this.cultureOptionsArray.controls[lastIndex].setValue(false);
    }
    // Other :: toggle other culture input field
    formUtil.validation.toggleControlValidators(this.otherCultureName, (((isOther && isChecked) || this.isOtherCultureSelected) ? this.validator_otherCultureName : null), true);
  }

  onChange_languageOptions(id: number) {
    if (id == this.language_Other) {
      this.isOtherLanguageSelected = true;
    } else {
      this.isOtherLanguageSelected = false;
    }
    formUtil.validation.toggleControlValidators(this.otherInterpreterLanguage, (this.isOtherLanguageSelected ? this.validator_otherInterpreterLanguage : null), true);
  }

  /**
   * Called at the start when model is binding to form
   * @param cultures
   */
  bindSelectedCultures(cultures: SubmissionCulture[]): void {
    for (let i = 0; i < this.lookup_culturalGroups.length; i++) {
      const option = this.lookup_culturalGroups[i];
      const selectedCulture = cultures.find(c => c.cultureId === option.id);
      const isOther = this.cultureOption_isOther(i);
      const isSelected = selectedCulture ? true : false;
      const checkBox = this.cultureOptionsArray.controls[i];

      // bind only if selected
      if (isSelected) {
        checkBox.setValue(isSelected);
        // Other Culture - show other culture input textbox and set validator for it
        if (isOther) {
          formUtil.validation.toggleControlValidators(this.otherCultureName, this.validator_otherCultureName);
          this.otherCultureName.setValue(selectedCulture.name);
        }
      }


    }
  }

  getSelectedCultures(): SubmissionCulture[] {
    const selectedCulturalGroups: SubmissionCulture[] = [];
    for (let i = 0; i < this.cultureOptionsArray.length; i++) {
      const culture = this.lookup_culturalGroups[i];
      const isChecked = this.cultureOptionsArray.controls[i].value;
      const isOther = this.cultureOption_isOther(i);

      if (isChecked) {
        const submissionId = this.model.submissionId;
        const submissionCulture = new SubmissionCulture({
          submissionId: submissionId,
          cultureId: Number(culture.id),
          name: (isOther ? this.otherCultureName.value : null)
        });
        selectedCulturalGroups.push(submissionCulture);
      }
    }
    return selectedCulturalGroups;
  }

  bindModelToForm() {

    formUtil.mapModelToForm(this.model, this.f);

    if(this.model.cultures !== null){
    //if(!this.submissionService.isNewSubmission){
      this.bindSelectedCultures(this.model.cultures);
    }




    // Map applicant data
    if (this.model.applicant) {
      formUtil.mapModelToForm(this.model.applicant, this.f, {
        // must convert date string to date object to bind it to control
        dateOfBirth: function (val) {
          const date = (util.hasValue(val) && val !== '0001-01-01T00:00:00' ? new Date(val) : null);
          return date;
        }
      });
    } else {
      this.model.applicant = new SubmissionPersonModel();
    }

    if (this.model.interpreterLanguageSpoken == this.language_Other) {
      this.onChange_languageOptions(this.language_Other);
    }
  //}
  }

  bindFormToModel() {
    const formModel = this.form.getRawValue();

    // Map Applicant data
    util.mapTo(this.model.applicant, formModel, true, true);
    this.model.applicant.typeId = enums.personTypes.Applicant;

    // Map other fields
    util.mapTo(this.model, formModel);
    this.model.cultures = this.getSelectedCultures();

    // Map language
    if (this.f.interpreterLanguageSpoken.value) {
      this.model.interpreterLanguage = this.lookup_languages.find(x => x.id == +this.f.interpreterLanguageSpoken.value).name;
    }

    // Set to not integrate Submission with CRM
    this.model.integrateWithCRM = false;

    if (this.submissionService.isCompletedSubmission) {
      this.appService.goToStep(ApplicationStep.Preview);
    }
  }
}
