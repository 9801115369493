import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

// Constants
import { routes } from 'src/app/shared/constants/routes';

// Services
import { UserService } from '../../services/user.service';
import { sessionData } from 'src/app/shared/storage/session-data';
import { SubmissionService } from 'src/app/services/submission.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'portal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  // ---------------------
  //    Properties
  // ---------------------

  routes = routes;

  public isCollapsed = false;

  public isSearchCollapsed = false;

  activeLink = 'Community Recovery Grants Portal';
  showEnvLabel = environment.showEnvLabel;
  environment = environment.environment;



  // ------------------
  //  Getters

  // do not allow user to create new applications if incomplete applications exist
  get showNewApplicationLink() { return this.submissionService && !this.submissionService.hasPendingSubmissions && !this.submissionService.hasNonEssentialGrants; }

  get isLoggedIn() { return (sessionData.user() ? true : false); }



  // ---------------------
  //    Constructor
  // ---------------------

  constructor(private router: Router,
    private userService: UserService,
    private submissionService: SubmissionService,
    private location: Location
  ) {
    router.events.subscribe((val) => {
      if (this.location.path() !== '' && this.location.path().indexOf('/support') > -1) {
        this.activeLink = 'Support Portal';
      }
    });
  }

  // ---------------------
  //    Methods
  // ---------------------

  ngOnInit() {
  }

  onClick_logout() {
    this.userService.logout();
  }


}
