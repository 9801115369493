import { LookupListItem } from './lookup-list-item.model';

/**
 * Generic Grant object caters for all types of grants.
 */
export class GrantModel {
    grantId: number = null;
    activatedGrantId: string = null;
    eventId: string = null;
    name: string = null;
    eventName: string = null;
    type: string = null;
    description: string = null;
    uncheckAllOthers: boolean = null;
    paymentOption: string = null;
    allowEftpos: boolean = false;
    allowDebit: boolean = false;
    allowCheque: boolean = false;
    defaultEligibilityId: number = null;
    maxGrantLimit: number = null;
    eligibility: string = null;
    eftDetails: EftDetailsModel = new EftDetailsModel();
    /** Describes if the grant has been previously submitted by user */
    hasBeenSubmitted: boolean = null;
    isITGGrant: boolean = null; // Is the grant ITG or unrestricted?
    polyId: number = null;
    /**
     * Specific grant objects a populated based on grantType - for example type = EHCG - ehcg has object, all others are null as default
     */
    ehcg: GrantEhcgModel = null;
    essrs: GrantEssrsModel = null;
    esha: GrantEshaModel = null;
    eha: GrantEhaModel = null;
    sag: GrantSagModel = null;
    constructor(obj: GrantModel = null) { if (obj) { Object.assign(this, obj); } }
}

export class GrantEhaModel {
    experiencingHardship: boolean = null;
    needs: LookupListItem[] = null;
    constructor(obj: GrantEhaModel = null) { if (obj) { Object.assign(this, obj); } }
}

export class GrantEshaModel {
    impactedServices: LookupListItem[] = null;
    constructor(obj: GrantEshaModel = null) { if (obj) { Object.assign(this, obj); } }
}

export class GrantEhcgModel {
    ehcgAvailable: boolean = null;
    contentsIsHomeOwner: boolean = null;
    contentsInsuranceType: string = null;
    affectedRooms: LookupListItem[] = null;
    constructor(obj: GrantEhcgModel = null) { if (obj) { Object.assign(this, obj); } }
}

export class GrantEssrsModel {
    electricityService: boolean = null;
    gasService: boolean = null;
    waterService: boolean = null;
    sewerageService: boolean = null;
    servicesInsuredForDisaster: string = null;
    constructor(obj: GrantEssrsModel = null) { if (obj) { Object.assign(this, obj); } }
}

export class GrantSagModel {
    structuralInsuredForDisaster: string = null;
    structuralDamage: string = null;
    constructor(obj: GrantSagModel = null) { if (obj) { Object.assign(this, obj); } }
}



export class EftDetailsModel {
    eftBankName: '';
    eftAccountName: '';
    eftBsb: '';
    eftAccountNumber: '';
    constructor(obj: EftDetailsModel = null) { if (obj) { Object.assign(this, obj); } }
}

export interface UserSubmissionModel {
    submissionId: number;
    /**
     * submission reference number
     */
    referenceNumber: string;
    /**
     * grant type
     */
    name: string;
    /**
     * 1-pending, 2-completed
     */
    applicationstatus: number;
    iamId: string;

    hasDeclaredProvidedTrueCorrectInformation: boolean;
}
