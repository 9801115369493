import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { sessionData } from '../shared/storage/session-data';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Add Bearer token to header of the requests made to Api urls
        if (request.url.indexOf(environment.APIUrl) > -1) {

            // add authorization header with jwt token if available
            const session = sessionData.authSession();
            if (session && session.accessToken) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${session.accessToken}`,
                        IAMToken: `${session.idToken}`
                    }
                });
            }
        }
        return next.handle(request);

    }
}
