
/**
 * This object literal contains all the regex patterns mainly used for data input format validation.
 */
export const regex = {

    /** numbers only */
    numeric: /^[\d]*$/,

    /** alphanumeric with spaces  */
    alphaNumeric: /^[a-z\d]*$/i,

    /** alphanumeric with spaces  */
    alphaNumericWithSpaces: /^[a-z\d\s]*$/i,

    
    /** letters and spaces  */
    alphaWithSpaces: /^[a-z\s]*$/i,

    email: /^(([^<>()\[\]\\.,;:\s@]+(\.[^<>()\[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

    /** numeric, leading digit is 0, 10 digits in length */
    phone: /^0[0-9]{9}$/,

    /** date in format : dd/mm/yyyy */
    date_ddmmyyyy: /^([0-2][0-9]||3[0-1])\/(0[0-9]||1[0-2])\/([0-9][0-9])?[0-9][0-9]$/,


    /** alphanumeric, 9 digits followed by 1 letter */
    centerlink_number: /^\d{9}[a-z]{1}$/i,

    /** numeric, 1 digit in length */
    medicare_individualReference: /^[1-9]{1}$/,

    /**
     * alphabetic, dots(.), dashes(-), apostrophes('), spaces( )
     * @example  Valid input: "John Smith" ,  "O'Reilly",  "Mary-Ann", "John Jr."
     */
    name_person: /^[a-z\s'\-.]*$/i,

    /**
    * alphabetic uppercase & lowercase, numeric, spaces( )
    */
    name_bank: /^[_A-z0-9]*((\s)*[_A-z0-9])*$/,


    /** numeric, 6 digits in length with an optional dash */
    bsbNumber: /^[0-9]{3}-?[0-9]{3}$/,

    /** numeric, 6 to 10 digits in length */
    bankAccountNumber: /^[0-9]{6,10}$/,




};
