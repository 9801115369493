
export const environment = {
    production: false,

    APIUrl: 'https://crapiuat.azurewebsites.net/api', //commrec api


    addressSuggestUrl: 'https://secure.communities.qld.gov.au/apw-systest/p/restful/address/(suggest)/com.cordys.web.rest.RESTGateway.wcp',  // URL for ESB address suggestion (typeahead)
    addressSuggestUrlByPassESB: 'https://crgiswrapperuat.azurewebsites.net/api/arcgis/addresssuggestions',
    addressDetailsUrl: 'https://secure.communities.qld.gov.au/apw-systest/p/restful/address/(details)/com.cordys.web.rest.RESTGateway.wcp',  // URL for ESB address details (geocode)
    addressDetailsUrlByPassESB: 'https://crgiswrapperuat.azurewebsites.net/api/arcgis/addressdetails',
    addressFindByGeoUrl: 'https://secure.communities.qld.gov.au/apw-systest/u/restful/aoi/(findByGeocodes)/(CR)/com.cordys.web.rest.RESTGateway.wcp', // Address polygons (find by geocode)
    addressFindByGeoUrlByPassESB:'https://crgiswrapperuat.azurewebsites.net/api/arcgis/findaddressbygeo',
    disableWizardValidation: false, //toggle this to enable or disable the blocking of canExit (step progression) checks in wizard
  //Auth0

  auth0LoginRedirectURL: 'https://uat.portal.communityrecovery.qld.gov.au/login/',
  auth0RegisterRedirectURL: 'https://uat.portal.communityrecovery.qld.gov.au/register/',
  auth0LogoutReturnURL: 'https://dcsywtest02.au.auth0.com/v2/logout?returnTo=https%3A%2F%2Fuat.portal.communityrecovery.qld.gov.au',
 // auth0LoginRedirectURL: 'https://dcsywtest02.au.auth0.com/v2/logout?returnTo=https%3A%2F%2Fuat.portal.communityrecovery.qld.gov.au',
  auth0LogoutSupportReturnURL: 'https://dcsywtest02.au.auth0.com/v2/logout?returnTo=https%3A%2F%2Fuat.portal.communityrecovery.qld.gov.au%2Fsupport%3Blogout%3Dtrue',



  auth0clientID: 'mnL82ZRlcpq3nmFEsFaiG9YLHOP0HNpB',
  auth0domain: 'dcsywtest02.au.auth0.com',
  auth0audience: 'https://portal-api',
  versionNum: '15.0.00.6',
  isActivated: true,
  showEnvLabel: true,
  environment: 'UAT-CHDE',
  isGenericGrantEnabled: false,
  bypassESB: true
  };

