﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Constants
import { sessionData } from '../../../shared/storage/session-data';
import { enums } from 'src/app/shared/enums/enums';
import { apis } from 'src/app/shared/constants/apis';

// Models
import { AuthSessionModel } from 'src/app/models/auth-session.model';
import { IAMUserModel } from 'src/app/models/iam-user.model';

// Services
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';
import { UserRoleEnum } from 'src/app/shared/enums/user-role.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // --------------------------------------
  //    Properties
  // --------------------------------------

  loginProgressMessage = 'Logging in. Please wait ...';
  isBasicGrantEnabled : boolean;


  // --------------------------------------
  //    Constructor
  // --------------------------------------

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) { }

  // --------------------------------------
  //    Methods
  // --------------------------------------

  ngOnInit() {

   // this.isBasicGrantEnabled = environment.isGenericGrantEnabled;

    // during a proper registration, authSession should not exist in session data at this point.
    //  as this can be a public terminal, attempting to access the /login or /register when signed in will force a logout.
    //  to reach this point a user would have used manual navigation by typing the URLS directly.
    const hasDirtyLogin = sessionData.authSession();

    if (hasDirtyLogin) {
      // BAD - if some login information has been retained at this point force logout to clear this with logout notification.
      this.handleLoginFailure('Dirty login retained');

    } else {

      // PROPER - if no login information in session data this is a proper login
      this.authService.handleAuthentication((authSession: AuthSessionModel) => {
        this.authenticateUserLogin(authSession);
      });
    }
  }

  authenticateUserLogin(session: AuthSessionModel) {

    // service logs in automatically
    this.userService.loginIAMUser(session.accessToken, this.loginProgressMessage)
      .subscribe(
        // onSuccess
        (iamUser) => {
          this.handleLoginSuccess(iamUser, session);
        },
        // onError
        error => { this.handleLoginFailure(error); }
      );
  }

  handleLoginSuccess(iamUser: IAMUserModel, session: AuthSessionModel) {
    sessionData.user(iamUser);

    // inject generic grant acceptance message

    if (this.isBasicGrantEnabled) {
    //  this.showGenericGrantAssessmentMessage();
     // return;
    }

    // if admin : navigate to partner portal
    if (iamUser.roleId > UserRoleEnum.GENERAL_PUBLIC) {
      this.router.navigate([apis.authentication.partnerPortal]);

      // if general public : navigate to user dasboard
    } else {
      this.router.navigate([apis.authentication.publicPortal]);
    }
  }

  handleLoginFailure(error: any = null) {
    // error is currently used for debugging only
    console.log(error);
    // service logout will remove local storage data for auth
    this.userService.logout(error.lastIndexOf('deactivated') < 0 ? enums.sessionState.error_login : enums.sessionState.error_inactive);
  }

   /// route to generic grant landing page if generic grant is enabled
  showGenericGrantAssessmentMessage() {
    this.router.navigate(['landing']);
  }

}
