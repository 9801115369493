import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';
import { ApplicationStep } from '../../application-step.enum';

import { SubmissionService } from 'src/app/services/submission.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { AuthService } from 'src/app/services/auth.service';
import { enums } from 'src/app/shared/enums/enums';
import { ApplicationService } from '../../application.service';

@Component({
  selector: 'app-impact-description',
  templateUrl: './impact-description.component.html',
  styleUrls: ['./impact-description.component.scss']
})
export class ImpactDescriptionComponent extends BaseApplicationStep implements OnInit {

  // ---------------------------------
  //        Properties
  // ---------------------------------

  titleImpactDescription = 'Impact Description';
  submitted = false;
  form: FormGroup;
  hidePrevButton = false;


  // ---------------------------------
  //        Constructor
  // ---------------------------------
  constructor(
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.ImpactDescription, appSvc, submissionService, authService, scrollerService);
    this.createform();
  }

  // dynamic validators
  private validator_impactdescription = [Validators.maxLength(10000)];

  // ---------------------------------
  //        Methods
  // ---------------------------------
  createform() {
    this.form = this.fb.group({
      impactDescription: new FormControl(' ', this.validator_impactdescription)
    });
  }

  get f() { return this.form.controls; } // getter for view

  ngOnInit() {
  }

  onClick_Previous() {
    this.appService.goBackward(this.previousStep);
  }

  bindModelToForm() {

    // if submission date and address selection resulted in no grants available,
    // then hide the previous button to stop user from altering their address and date details
    this.hidePrevButton = !this.model.hasActivatedGrantsAvailable;

    const descText = this.model.impactDescription;
    this.form.controls.impactDescription.setValue(descText);
  }

  bindFormToModel() {
    this.model.impactDescription = this.form.value.impactDescription;
  }


}
