import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'help-links',
  templateUrl: './help-links.component.html',
  styleUrls: ['./help-links.component.scss']
})
export class HelpLinksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
