export class ResultModel<T> {

    /**
     * Response success status. If true then success, else error happened
     */
    isSuccess: boolean = null;

    /**
     * Success or Error message returned from the server.
     */
    message?: string = null;

    /**
     * Result object returned from the server.
     */
    object?: T = null;

    constructor(obj: ResultModel<T> = null) { if (obj) { Object.assign(this, obj); } }
}
