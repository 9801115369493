import { SubmissionOutcome } from 'src/app/models/submission.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';


// Models
import { Submission } from '../models/submission.model';

// Base Classes
import { BaseApiService } from '../shared/base-classes/base-api-service';

// Constants
import { apis } from '../shared/constants/apis';
import { sessionData } from '../shared/storage/session-data';
import { routes } from 'src/app/shared/constants/routes';

// Storage
import { localData } from '../shared/storage/local-data';
import { LookupListItem } from '../models/lookup-list-item.model';
import { environment } from 'src/environments/environment';
import { UserSubmissionModel } from '../models/grant.model';
import { UserPersonModel } from '../models/user-person.model';
import { LookupService } from './lookup.service';




@Injectable({
  providedIn: 'root'
})
export class SubmissionBasicService extends BaseApiService {

  subjectGrant = new Subject<any>();
  subjectInitialAssessment = new Subject<any>();
  subjectIdentityCheck = new Subject<any>();
  subjectPayment = new Subject<any>();
  subjectPreview = new Subject<any>();
  subjectDeclaration = new Subject<any>();



  // ----------------------------
  //      Properties
  // ----------------------------
  API_URL = environment.APIUrl;

  userSubmissions: UserSubmissionModel[];

  submissionModel: Submission = null;

  submissionUser: UserPersonModel;

  lookup_propertyTypes: LookupListItem[] = [];

  lookup_addressTypes: LookupListItem[] = [];

  /**
   * used inside BaseApplicationStep to ingore loading if parent component Application has redirected back to dashboard
   */
  ignoreLoad = false;

  /**
   * MAIN MODEL - Main submission model that gets passed from step to step of the wizard and form data is bound to it.
   */
  protected model: Submission;
  lookup_languages: any;
  lookup_nameTitles: any;

  get submissionId() {
    const idFromSession = sessionData.submissionId();
    return Number(idFromSession ? idFromSession : 0);
  }

  get isNewSubmission(): boolean { return this.submissionId === 0; }

  get isFirstSubmission(): boolean {

    // no other submissions
    if (!this.userSubmissions || this.userSubmissions.length === 0) { return true; }

    // new submission and there are others
    if (this.isNewSubmission) { return false; }

    // existing submission and there are others
    const minId =
      this.userSubmissions.reduce((min, s) => s.submissionId < min ? s.submissionId : min, this.userSubmissions[0].submissionId);
    return this.submissionId === minId;
  }

  // ----------------------------
  //      Constructor
  // ----------------------------

  constructor(
    httpClient: HttpClient,
    private lookupService: LookupService,
    private router: Router
  ) {
    super(httpClient);
  }


  loadImpactAssessment(shouldLoad: boolean){
    this.subjectInitialAssessment.next({ boolean: shouldLoad });
  }

  loadDeclarationData(shouldLoad: boolean){
    this.subjectDeclaration.next({ boolean: shouldLoad });
  }

  loadPreview(shouldLoad: boolean) {
    this.subjectPreview.next({ boolean: shouldLoad });
  }

  clearPreview() {
    this.subjectPreview.next();
  }

  loadGrants(shouldLoad: boolean) {
    this.subjectGrant.next({ boolean: shouldLoad });
  }

  loadFiles(shouldLoad: boolean) {
    this.subjectIdentityCheck.next({ boolean: shouldLoad });
  }

  getAssessmentData(): Observable<boolean>{
    return this.subjectInitialAssessment.asObservable();
  }

  getGrants(): Observable<boolean> {
    return this.subjectGrant.asObservable();
  }

  getPreview():Observable<boolean>{
    return this.subjectPreview.asObservable();
  }

  getFiles(): Observable<boolean> {
    return this.subjectIdentityCheck.asObservable();
  }

  loadPaymentData(shouldLoad: boolean){
    this.subjectPayment.next({ boolean: shouldLoad });
  }

  getPaymentData(): Observable<boolean>{
    return this.subjectPayment.asObservable();
  }

  getDeclarationData(): Observable<boolean>{
    return this.subjectDeclaration.asObservable();
  }

  // ----------------------------
  //      Methods
  // ----------------------------

  loadLookups() {

    // Languages
    this.lookup_languages = localData.lookups.languages();
    if (!this.lookup_languages || this.lookup_languages.length === 0) {
      this.lookupService.getLanguages().subscribe(r => { this.lookup_languages = r; });
    }

    // Name Titles
    this.lookup_nameTitles = localData.lookups.nameTitles();
    if (!this.lookup_nameTitles || this.lookup_nameTitles.length === 0) {
      this.lookupService.getNameTitles().subscribe(r => { this.lookup_nameTitles = r; });
    }

    // Property Types
    this.lookup_propertyTypes = localData.lookups.propertyTypes();
    if (!this.lookup_propertyTypes || this.lookup_propertyTypes.length === 0) {
      this.lookupService.getPropertyTypes().subscribe(r => { this.lookup_propertyTypes = r; });
    }

    // Address Types
    this.lookup_addressTypes = localData.lookups.addressTypes();
    if (!this.lookup_addressTypes || this.lookup_addressTypes.length === 0) {
      this.lookupService.getAddressTypes().subscribe(r => { this.lookup_addressTypes = r; });
    }

  }

  /**
   * Sets existing submission model to null.
   */
  clearSubmission() {
    sessionData.submissionId(null);
    this.submissionModel = null;
    this.ignoreLoad = false;
  }

  selectSubmission(submissionId: number, referenceNumber: number = null) {
    this.clearSubmission();
    if (!submissionId) {
      submissionId = this.userSubmissions.find(a => a.referenceNumber === referenceNumber.toString()).submissionId;
    }
    sessionData.submissionId(submissionId.toString());
  }

  loadSubmission(): void { }

  // savesubmission
  saveSubmission(submission: any = null): Observable<SubmissionOutcome> {
    return this.post<Submission>(apis.submission.addOrUpdate, submission);
  }

  goToApplication(submissionUser: UserPersonModel, submissionId: number, referenceNumber: number) {

    this.submissionUser = submissionUser;

    // new application
    let url = routes.applicationBasic;

    // existing application
    if (submissionId || referenceNumber) {
      this.selectSubmission(submissionId, referenceNumber);
      url = routes.getApplicationBasic(referenceNumber);
    }

    // navigate
    this.router.navigateByUrl(url);
  }


}
