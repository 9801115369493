﻿import { Injectable } from '@angular/core';
import { AUTH_CONFIG } from '../auth/auth0-variables';
import * as auth0 from 'auth0-js';

import { AuthSessionModel } from '../models/auth-session.model';
import { sessionData } from '../shared/storage/session-data';
import { enums } from '../shared/enums/enums';
import { UserService } from './user.service';


(window as any).global = window;

@Injectable()
export class AuthService {

  loginSignupParam: string;

  auth0 = new auth0.WebAuth({
    clientID: AUTH_CONFIG.clientID,
    domain: AUTH_CONFIG.domain,
    responseType: 'token id_token',
    audience: AUTH_CONFIG.audience,
    redirectUri: AUTH_CONFIG.callbackURL,
    scope: 'openid email signUp'
  });


  expiresIn: number;
  userProfile: any;
  accessToken: string;
  authenticated: boolean;


  constructor(private userService: UserService) { }

  public login(): void {
    this.auth0.authorize();
  }

  /**
   * handles setting up session data / profile
   */
  public handleAuthentication(onSuccess: Function): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {

        const authSession = new AuthSessionModel(authResult as AuthSessionModel);
        this.setSession(authSession);

        onSuccess(authSession);


        // this.getProfile();

      } else if (err) { // todo: handle and make it so the user doenst see this.
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }


  private _setSession(authResult, profile) {
    // Save authentication data and update login status subject
    this.expiresIn = authResult.expiresIn * 1000 + Date.now();
    this.accessToken = authResult.accessToken;
    this.userProfile = profile;
    this.authenticated = true;
  }

  private setSession(authSession: AuthSessionModel): void {
    // Set the time that the access token will expire at
    authSession.expiresIn = (authSession.expiresIn * 1000) + new Date().getTime();
    sessionData.authSession(authSession);
  }

  // /**
  //  * This is the authentication handler currently being used in authguard/route
  //   * @param isExpired oject with the assignable value to pass expored information.
  //  */
  // public isAuthenticated(isExpired: { value: boolean; } = null): boolean {
  //   const session = sessionData.authSession();
  //   // TODO: we need a real authenticated session check here.
  //   const hasSession = session && session.accessToken;
  //   // check for existing session
  //   if (hasSession) {
  //     // check expired
  //     const _isExpired = this.isSessionExpired(session);
  //     if (isExpired) { isExpired.value = _isExpired; }
  //     // if there is no expiration in session then isAuthenticated returns false.
  //     return !_isExpired;
  //   } else {
  //     // no session object, no expiry message.
  //     return false;
  //   }
  // }

  public isSessionExpired(session): boolean {
    // Check whether the current time is past the access token's expiry time
    const now = new Date().getTime();
    // session is expired if there is no expiry or if the session is in the past.
    return (session.expiresIn ? session.expiresIn < now : true);
  }


  /**
  * checks for invalid session and logs out if invalid, returns true if session is valid.
  * @param doLogout used to when determining validity only (for load screens where we do the check bot wantt o avoid unnecessary reloads)
  * @param redirectRoute optional route to be redirected to after logout is completed
  * @param redirectState only redirect if sessionstate matches the provided redirectstate
  */
  isAuthenticatedHandler(
    doLogout: boolean = true,
    redirectState: string = null,
    redirectRoute: string = null): boolean {

    const currentUserSession = sessionData.user();
    const currentAuthSession = sessionData.authSession();
    let logoutState = null;
    let isValidSession = true;

    // if either the AuthSession or the UserSession is missing, the user should be logged out.
    if (!currentUserSession || (!currentAuthSession && currentAuthSession.accessToken)) {
      isValidSession = false;
      logoutState = enums.sessionState.error_sessionlost;
      // if there is a session, check if its expired
    } else if (this.isSessionExpired(currentAuthSession)) {
      isValidSession = false;
      logoutState = enums.sessionState.state_expired;
    }
    // if invalid session, logout with any specified state.
    if (!isValidSession && doLogout) {
      const returnRoute = logoutState === redirectState ? redirectRoute : null;
      this.userService.logout(logoutState, returnRoute);
    }
    return isValidSession;

  }

  public getProfile() {
    // todo: this check should be isAutheticated();
    const session = sessionData.authSession();
    if (!session || !session.accessToken) {
      alert('Access Token must exist to fetch profile');

    } else {
      this.auth0.client.userInfo(session.accessToken, function (err, profile) {
        if (profile) {
        }
      });
    }
  }

  getAccessToken() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.getUserInfo(authResult);
      }
    });
  }

  getUserInfo(authResult) {
    // Use access token to retrieve user's profile and set session
    this.auth0.client.userInfo(authResult.accessToken, (err, profile) => {
      if (profile) {
        this._setSession(authResult, profile);
      }
    });
  }

}
