
/**
 * This object literal contains format description messages that correspond to regex expressions inside regex.ts.
 * These descriptions should be used as a part of field validation messages related to given regex expressions for consistency.
 */
export const regexDescription = {

    numeric: 'numbers only',

    alphaNumeric: 'numbers and letters',

    alphaNumericWithSpaces: 'numbers, letters and spaces',

    alphaWithSpaces: 'letters and spaces',

    name_person: 'letters, dots(.), dashes(-), apostrophes(\'), spaces( )',

    centerlink_number: 'Centerlink number should be made up of 9 digits followed by 1 letter.',

    medicare_individualReference: 'Individual reference number should be a number from 1 to 9. It can only be 1 digit in length.'
};
