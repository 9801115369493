
  export class PersonDriverLicenceModel {
    personDriverLicenceId?: number = null;
    licenceNumber: number = null;
    licenceStateOfIssue: number = null;
    licenceExpiryDate: Date = null;
    licenceQueueReference: string = null;
    licenceIsValid: boolean = null;
    licenceGivenName: string = null;
    licenceMiddleName: string = null;
    licenceSurname: string = null;
    licenceCardNumber: string = null;
    constructor(obj: PersonDriverLicenceModel = null) { if (obj) { Object.assign(this, obj); } }
  }
