import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';

// Models
import { GrantModel } from 'src/app/models/grant.model';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { GrantService } from 'src/app/services/grant.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';

// Validators
import { isAtLeastOneSelectedValidator } from 'src/app/shared/validators/isAtLeastOneSelected-validator.directive';

// Constants
import { enums } from 'src/app/shared/enums/enums';

// Utils
import { formUtil } from 'src/app/shared/util/form-util';

// Base Classes
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationService } from '../../application.service';
import { ApplicationStep } from '../../application-step.enum';

@Component({
  selector: 'app-initial-outcome',
  templateUrl: './initial-outcome.component.html',
  styleUrls: ['./initial-outcome.component.scss']
})
export class InitialOutcomeComponent extends BaseApplicationStep implements OnInit {

  // ------------------------------------------
  //    Properties
  // ------------------------------------------

  titleOutcomeOfInitial = 'Grants you may be eligible for';

  loadingMessage_loadingAvailableGrants = 'Retrieving Available Grants...';

  form: FormGroup;

  grants: GrantModel[] = [];

  isSubmitInProgress: boolean = false;

  isAllGrantsAlreadySubmitted = false;

  error: string;

  firstITG: string = "EMPTY";

  // ------------
  //  Getters

  get f() { return this.form.controls; }
  get grantOptionsArray(): FormArray { return this.form.controls.grantOptions as FormArray; }
  get grantOptionsCtrls() { return this.grantOptionsArray.controls as FormControl[]; }
  get essentialGrants() { return enums.essentialGrants; }

  // ------------------------------------------
  //    Constructor
  // ------------------------------------------

  constructor(
    private grantService: GrantService,
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.InitialOutcome, appSvc, submissionService, authService, scrollerService);
  }


  // ------------------------------------------
  //    Methods
  // ------------------------------------------

  ngOnInit() {
    this.initForm();
  }

  initForm() {

    const options = this.grants.map(c => this.fb.control(false));

    this.form = this.fb.group({
      grantOptions: this.fb.array(options, isAtLeastOneSelectedValidator())
    });
  }


  bindModelToForm() {

    this.model.steps = [];

    this.loadGrants();
  }


  loadGrants() {

    // if no grants, load them from server based on poly ids
    if (this.grants.length === 0) {

      this.appService.showLoading(this.loadingMessage_loadingAvailableGrants);

      // ASYNC: Get grants for chosen coordinates
      this.grantService
        .findGrantsByPolyIds(this.model.polygonIds, this.model.dateOfImpact, this.model.userId, this.model.submissionId, this.model.isHomeOwner)
        .pipe(finalize(() => { this.appService.hideLoading(); }))
        .subscribe((grants: GrantModel[]) => {

          // add grant options
          grants.forEach((polyGrant: GrantModel) => {

            const grant = new GrantModel(polyGrant);

            // Essential grants are EHA, EHSA, EHCG, ESSRS, SAG
            // the grant type of the option No - I do not wish to apply for any financial assistance is null
            if (Object.values(this.essentialGrants).includes(grant.type) || grant.type == null) {
              this.grants.push(grant);
              if (this.firstITG == "EMPTY") { // If the first ITG hasn't been saved yet, write the first ITG grant that hasn't been applied for to the variable.
                if (grant.isITGGrant && !grant.hasBeenSubmitted) {
                  this.firstITG = grant.type;
                }
              }
            }
          });

          // -------------------------------- No Grants Available Logic ----------------------------------

          // if there are no activated grants for the given location and time => GOTO: Impact Description Step

          // Field uncheckAllOthers is only true for the "no grants option" - the checkbox that is used for unchecking all selected grants.
          // All the real grant options have this field set to false.
          // Therefore to find out if there are any real grant options available we check for options with this field set to false.
          this.model.hasActivatedGrantsAvailable = (this.grants && this.grants.find(g => g.uncheckAllOthers == false) ? true : false);

          // Are there any grants that have not been submitted yet (excluding the empty grant option "No Grants Selected")
          this.isAllGrantsAlreadySubmitted =
            (this.grants && this.grants.find(g => !g.hasBeenSubmitted && g.uncheckAllOthers == false) ? false : true);

          // if there are no activated grants available for the selected location and time, navigate to Impact Description
          if (!this.model.hasActivatedGrantsAvailable) {

            // Remove any grants that had been present.
            if (this.model.grants) {
              this.model.grants = [];
              //this.saveChanges(true, false);
            }

            // ASYNC: Remove Submission Persons and their documents
            this.submissionService.submissionPersons_remove().subscribe(
              () => {

                // this.submissionService.removeInheritedDetails_fromModel();

                // Remove form validation so that navigating to another step is possible
                this.f.grantOptions.clearValidators();
                this.form.reset();

                // GOTO: Impact Description
                this.appService.goToStep(ApplicationStep.ImpactDescription);
              },
              (error) => {
                this.error = error
              });

            // stop execution of this method and exit out of it
            return;
          }

          // ------------------------------------------------------------------------------------------------

          this.initForm();

          // update the form selections
          this.bindModelToForm_grantCheckboxes();

        });
    }
  }

  bindModelToForm_grantCheckboxes() {

    if (this.model.grants) {

      this.grants.forEach((grantOption, i) => {

        const selectedGrant = this.model.grants.find(g => g.activatedGrantId === grantOption.activatedGrantId);

        // set grant checkbox to checked if the grant is found in the model
        this.grantOptionsCtrls[i].setValue(selectedGrant ? true : false);
      });
    }

    if (this.model.requestsNoGrants === true) {
      this.grantOptionsCtrls[this.grantOptionsCtrls.length - 1].setValue(true);
    }
  }

  onChange_grantOption(selectedGrant): void {

    const i = this.grants.findIndex(g => g.uncheckAllOthers);

    // if this option unchecks all others, then uncheck all other options
    if (selectedGrant.uncheckAllOthers) {

      this.grantOptionsCtrls.forEach(o => o.setValue(false));
      this.grantOptionsCtrls[i].setValue(true);

    } else {

      // else if this option does not uncheck all others, then uncheck the option that does uncheck all others
      this.grantOptionsCtrls[i].setValue(false);
    }
  }

  onClick_Next() {

    this.hasAttemptedToProgress = true;

    // if form valid continue
    if (this.isFormValid) {
      this.isSubmitInProgress = true;
      const selectedGrants = this.getSelectedGrants();
      const atLeastOneGrantHasBeenSelected = (selectedGrants.length > 0);
      const doAdd = (this.model.hasActivatedGrantsAvailable && atLeastOneGrantHasBeenSelected);

      // ASYNC: Remove/Copy Persons and documents from previous submission
      this.submissionService.submissionPersons_copyOrRemove(doAdd, () => {

        // if (noGrantsAvailableOrSelected) {
        //   this.submissionService.removeInheritedDetails_fromModel();
        // }

        this.saveChanges(true, false);
      },
      // onAlways
      () => {
        this.isSubmitInProgress = false;
      });
    }
  }

  wizStep_onSaveSuccess() {
    // GOTO: Go Forward after the changes have been saved to the server succesfully
    this.appService.goForward();
  }

  bindFormToModel() {

    const selectedGrants = this.getSelectedGrants();

    // initialize model grants if null
    if (this.model.grants == null) { this.model.grants = []; }

    // Remove grants that are not selected
    for (let i = this.model.grants.length - 1; i >= 0; --i) {
      const previouslySelectedGrant = this.model.grants[i];
      const isStillSelected = selectedGrants.filter(g => g.activatedGrantId === previouslySelectedGrant.activatedGrantId).length > 0;
      // if previously selected grant is not still selected , then remove it from model
      if (!isStillSelected) { this.model.grants.splice(i, 1); }
    }

    // Add newly selected grants
    selectedGrants.forEach(selectedGrant => {
      this.model.eventId = selectedGrant.eventId;
      const isAlreadyAdded = this.model.grants.filter(g => g.activatedGrantId === selectedGrant.activatedGrantId).length > 0;
      if (!isAlreadyAdded) {
        this.model.grants.push(selectedGrant);
      }
    });

    this.model.requestsNoGrants = (this.model.grants.length === 0);

    // We need the eventId if requestNoGrants == true to display support referrals
    if (this.model.requestsNoGrants === true && this.grants.length > 0) {
      // Get the eventId of the first Grant.
      this.model.eventId = this.grants[0].eventId;
    }

    // Set to integrate Submission with CRM
    this.model.integrateWithCRM = true;
  }


  /** Returns selected Grants.  Sets model steps (for Impact assessment sub wizard) based on the selected grants.*/
  private getSelectedGrants(): GrantModel[] {
    const selectedGrants: GrantModel[] = [];

    this.model.steps = [];

    let stepCounter = 1;

    let addIncome: boolean;

    if (this.grantOptionsCtrls.length > 0) {
    this.grants.forEach((g, i) => {

      const isSelected = this.grantOptionsCtrls[i].value;

      if (isSelected && !g.uncheckAllOthers) {

        // Store selected grants to be submitted to the database
        selectedGrants.push(g);

        // Push data to display the correct steps in Impact Assessment based on grants chosen
        this.model.steps.push({
          type: g.type,
          stepNumber: stepCounter,
          show: (stepCounter === 1)
        });

        // If a means tested grant is chosen set flag to include the week income test step
        addIncome = (
          g.type === enums.impactAssessmentTypes.EHCG
          || g.type === enums.impactAssessmentTypes.ESSRS
          || g.type === enums.impactAssessmentTypes.SAG);

        stepCounter++;
      }
    });
    }

    // Add the weekly income test step if ehcg, essr or sag has been selected
    if (addIncome === true) {
      this.model.steps.push({
        type: enums.impactAssessmentTypes.Income,
        stepNumber: stepCounter,
        show: false
      });
    }

    return selectedGrants;
  }


  // ----------------
  //  Validation

  getInputCssClass_grantOptions(grant: AbstractControl) {
    const isInvalid = ((this.hasAttemptedToProgress || this.f.grantOptions.dirty) && grant.errors);
    return (isInvalid ? formUtil.validation.cssClasses.invalidInputControl : '');
  }



}
