import { SubmissionBasicService } from './../../../services/submission-basic.service';
import { Submission } from './../../../models/submission.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { WizardComponent } from 'angular-archwizard';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { regex } from 'src/app/shared/constants/regex';
import { PaymentMethodService } from 'src/app/pages/application/application-steps/payment-method/payment-method.service';
import { MatchValidation } from 'src/app/shared/directives/match-value.directive';
import { SubmissionService } from 'src/app/services/submission.service';
import { formUtil } from 'src/app/shared/util/form-util';
import { customFunctionValidator } from 'src/app/shared/validators/customFunction-validator.directive';
import { util } from 'src/app/shared/util/util';
import { AppService } from 'src/app/services/app.service';
import { Subscription } from 'rxjs';
import { UserPersonModel } from 'src/app/models/user-person.model';

@Component({
  selector: 'app-payment-method-basic',
  templateUrl: './payment-method-basic.component.html',
  styleUrls: ['./payment-method-basic.component.scss']
})
export class PaymentMethodBasicComponent implements OnInit {
  form: FormGroup;
  bankDetails = '';
  title = 'Grant Payment Method';
  model: Submission;
  formSubmitted: boolean;
  // ----------------------
  //  Lookups

  lookup_paymentOptions = Object.keys(lookupLists.paymentOptions).map(e => lookupLists.paymentOptions[e]);
  EFT = 'EFT';
  subscription: Subscription;

  // ----------------------
  //  Getters

  get f() { return this.form.controls; }

  // ----------------------
  //  Validators

  private vdtr_eftBankName = [Validators.required, Validators.pattern(regex.name_bank)];
  private vdtr_bsbvalid = customFunctionValidator((): boolean => this.f.hasUnattemptedOrVerifiedBSB.value, 'bsbValid');
  private vdtr_eftBsb = [Validators.required, Validators.pattern(regex.bsbNumber), this.vdtr_bsbvalid];
  private vdtr_confirmBsb = [Validators.required, this.matchVal.MatchValidator('eftBsb')];
  private vdtr_eftAccountNumber = [Validators.required, Validators.pattern(regex.bankAccountNumber)];
  private vdtr_confirmAccountNumber = [Validators.required, this.matchVal.MatchValidator('eftAccountNumber')];
  private vdtr_eftAccountName = [Validators.required, Validators.pattern(regex.name_person)];



  constructor(
    private pmService: PaymentMethodService,
    private matchVal: MatchValidation,
    private fb: FormBuilder,
    private awWizard: WizardComponent,
    private submissionService: SubmissionService,
    private submissionServiceBasic:SubmissionBasicService,
    private scrollerService:ScrollerService,
    private appService: AppService,) {
      const existingSubmissionId = this.submissionService.submissionId;
      this.subscription = this.submissionServiceBasic.getPaymentData().subscribe(message => {
        if (message) {
          const existingSubmissionId = this.submissionService.submissionId;
          if (existingSubmissionId > 0) {
            this.model = this.submissionService.submissionModel;
            this.bindModelToForm();
          }
        }
      });

     }


  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      paymentOption: [''],
      eftBankName: new FormControl('', Validators.required),
      eftBsb: new FormControl('', [Validators.required]),
      hasUnattemptedOrVerifiedBSB: true, // not part of model. // initialise as unattempted
      confirmBSBNumber: new FormControl('', [Validators.required]),
      eftAccountNumber: new FormControl('', [Validators.required]),
      confirmAccountNumber: new FormControl('', [Validators.required]),
      eftAccountName: new FormControl('', [Validators.required])

    });
    this.eftBsbChanged();

    this.eftAccountNumberChanged();

    this.onValidationChangeRevalidateBSB();
  }
   // calls whenever user changes selected payment method.
   onChange_paymentOption() {
    this.resetEftValidators();
  }

  // Set required validation on confirm BSB
  eftBsbChanged() {
    this.f.eftBsb.valueChanges.subscribe(
      (bsb: string) => {
        // handle validation for confirm bsb
        if (bsb === null || bsb === '') {
          // if irrelevant, clear validators
          if (this.f.confirmBSBNumber) { this.f.confirmBSBNumber.clearValidators(); }
          this.bankDetails = '';
          this.setValue_hasUnattemptedOrVerifiedBSB(true);
        } else {
          // otherwise, attempt to get branch details
          if (bsb.length >= 7) { // there is a mandatory hyphen picked up in the string, a 6 here would be "111-11"
            this.pmService.LookupBank(bsb).subscribe((data: string) => this.onBankDetailsReceived(data));
          } else {
            this.bankDetails = '';
            this.setValue_hasUnattemptedOrVerifiedBSB(true);
          }
          if (this.f.confirmBSBNumber) {
            this.f.confirmBSBNumber.setValidators(this.vdtr_confirmBsb);
          }
        }
        if (this.f.confirmBSBNumber) {
          this.f.confirmBSBNumber.updateValueAndValidity();
        }
      }
    );
  }

  // Set required validation on confirm account number
  eftAccountNumberChanged() {
    this.f.eftAccountNumber.valueChanges.subscribe(
      (account: string) => {
        if (account === null || account === '' ) {
          if (this.f.confirmAccountNumber) {
            this.f.confirmAccountNumber.clearValidators();
          }
        } else {
          if (this.f.confirmAccountNumber) {
            this.f.confirmAccountNumber.setValidators(this.vdtr_confirmAccountNumber);
          }
        }
        if (this.f.confirmAccountNumber) {
          this.f.confirmAccountNumber.updateValueAndValidity();
        }
      }
    );
  }

  // note this is validating off of the bankDetails field that gets updated based on callback from updating itself.
  // the form field validation occurs at the very beginning so will validate before the bankDetails update occurs
  // revalidating from withing a change using updateValueAndValidity triggers a change.
  onValidationChangeRevalidateBSB() {
    this.f.hasUnattemptedOrVerifiedBSB.valueChanges.subscribe(
      () => {
        this.f.eftBsb.updateValueAndValidity();
      });
  }
  // always check and only update if value has changed
  setValue_hasUnattemptedOrVerifiedBSB(newValue) {
    if (this.f.hasUnattemptedOrVerifiedBSB.value !== newValue) {
      this.f.hasUnattemptedOrVerifiedBSB.setValue(newValue);
    }
  }

  onBankDetailsReceived(bank: string): void {
    this.bankDetails = bank ? bank : '';
    this.setUnattemptedOrVerifiedBSB();
  }

   // called in bind
   private resetEftValidators(): void {
    // determine if EFT
    //const paymentOption = this.f.paymentOption.value;
    //const isEft =  (paymentOption === this.EFT);

    // reset validations based on payment method
    formUtil.validation.toggleControlsValidators(this.form, {
      'eftBankName': this.vdtr_eftBankName,
      'eftBsb': this.vdtr_eftBsb,
      'eftAccountNumber': this.vdtr_eftAccountNumber,
      'eftAccountName': this.vdtr_eftAccountName
    }, true, true);



    this.setUnattemptedOrVerifiedBSB();
  }

  setUnattemptedOrVerifiedBSB(): void {
    const attemptedConditions = this.f.eftBsb.value !== null && this.f.eftBsb.value.length === 7;
    const isVerified = this.bankDetails !== null && this.bankDetails !== '';
    const isUnattemptedOrVerifiedBSB = !attemptedConditions || isVerified;

    this.setValue_hasUnattemptedOrVerifiedBSB(isUnattemptedOrVerifiedBSB);
  }

  bindModelToForm(): void {

    this.model = this.submissionService.submissionModel;

    if (this.model.grants !== null) {
      const firstGrant = this.model.grants[0];
      const paymentDetails = firstGrant.eftDetails;

      // initialise validators for existing eft
      this.resetEftValidators();

      formUtil.mapModelToForm(paymentDetails, this.f);

      // add the values for confirmations.
      if (firstGrant.eftDetails.eftBsb) {
        this.f.confirmBSBNumber.setValue(this.f.eftBsb.value);
      }
      if (firstGrant.eftDetails.eftAccountNumber) {
        this.f.confirmAccountNumber.setValue(this.f.eftAccountNumber.value);
      }
    }

  }

  bindFormToModel(): void {
    this.model = this.submissionService.submissionModel;
    const f = this.form.value;
    //debugger;
    for (let index = 0; index < this.model.grants.length; index++) {
      const grant = this.model.grants[index];
      util.mapTo(grant, f);
      grant.paymentOption = 'EFT';//this.f.paymentOption.value;
      grant.eftDetails.eftAccountName = this.f.eftAccountName.value;
      grant.eftDetails.eftAccountNumber = this.f.eftAccountNumber.value;
      grant.eftDetails.eftBankName = this.f.eftBankName.value;
      grant.eftDetails.eftBsb = this.f.eftBsb.value;
    }
    //this.form.markAsDirty();
  }


  onClick_Next() {

    this.formSubmitted = true;
    if (this.form.invalid) {
      return;
    }

    if(this.form.dirty){
      this.bindFormToModel();
      this.scrollerService.scrollViewToTop();
      this.model = this.submissionService.submissionModel;
      this.submissionService.submissionUser = new UserPersonModel();
      this.submissionService.submissionUser.userId = this.model.userId;
      this.appService.loadingOverlayShow('Saving details, please wait...');
      this.submissionService.save(this.model).subscribe(result => {
        this.preLoadPreview();
        this.appService.loadingOverlayHide();
        this.awWizard.navigation.goToNextStep();
      },
        error => {
          console.log(error);
          this.appService.loadingOverlayHide();
        },
        () => {
          this.appService.loadingOverlayHide();
        }
      );
    } else {
      this.submissionService.submissionModel = this.model;
      this.preLoadPreview();
      this.awWizard.navigation.goToNextStep();
    }


  }

  preLoadPreview(): void {
    // send message to subscribers via observable subject
    this.submissionServiceBasic.loadPreview(true);
  }

}
