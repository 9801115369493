import { GrantModel } from './grant.model';
import { SupportReferralSubmissionModel } from './support-referral.model';
import { SubmissionPersonModel } from './submission-person.model';

// --------------------------------------------------------------------------------------------------------------------------
//            Concrete Classes
// --------------------------------------------------------------------------------------------------------------------------

/**
 * Object returned from serverside on api submit call success.
 */
export class SubmissionOutcome {
  submissionId: number;
  referenceNumber: string;
  hasDetectedDuplicate: boolean;
  duplicationDetected: string;
  hasDetectedDuplicateApplicant: boolean;
  hasDetectedDuplicatePartner: boolean;
  hasDetectedDuplicateDependant: boolean;
  duplicateAssessmentFlag: number;
  idValid: boolean;
  idQueued: boolean;
  constructor(obj: SubmissionOutcome = null) { if (obj) { Object.assign(this, obj); } }
}

export class Submission {

  submissionId: number = null;
  referenceNumber: string = null;
  hasAgreedToPrivacyStatement: boolean = null;
  phoneNumber: string = null;
  alternativePhoneNumber: string = null;
  emailAddress: string = null;
  contactMethod: string = null;
  eventId: string = null;
  eventName: string = null;

  /**
   * Defines if for this submission's selected address and date, there are disaster events and there are grants activated for those events.
   * Clientside-Only Property ... Not stored in database at the moment.
   */
  hasActivatedGrantsAvailable: boolean = null;
  integrateWithCRM: boolean = null;

  // --------------------------------------
  //    People Data

  hasPartner: boolean = null;
  hasDependants: boolean = null;

  applicant: SubmissionPersonModel = new SubmissionPersonModel();
  partner: SubmissionPersonModel = null;
  dependants: SubmissionPersonModel[] = null;


  // --------------------------------------
  //    Address Data

  dateOfImpact: Date = null;
  isAtTemporaryAddress: boolean = null;
  bestPostalAddressType: string = null;
  bestPostalAddressTypeId: number = null;
  isStrandedTraveller: boolean = null;
  isEvacuated: boolean = null;
  addresses: Address[] = [];

  // --------------------------------------
  //    Culture Data

  cultures: SubmissionCulture[] = [];

  // --------------------------------------

  isExperiencingHardship: boolean = null;
  isRequestingOtherAssistance: boolean = null;

  polygonIds: string[] = null;
  requestsNoGrants: boolean = null;
  steps: Step[] = null;
  grants: GrantModel[] = null;

  impactDescription: string = null;
  isHomeOwner: boolean = null;

  hasDeclaredProvidedTrueCorrectInformation: boolean = null;
  cRMAddressManuallyPassed: boolean = null;

  // Support Referral Data
  supportReferrals: SupportReferralSubmissionModel[] = [];
  requiresAHomeVisit: boolean = false; // Force set to false since form section has been removed.
  visitInformation: string = null;
  preferredVisitTime: string = null;

  isPartialSubmission: boolean = null;
  requiresInterpreter: boolean = null;
  interpreterLanguageSpoken: number = null;
  interpreterLanguage: string = null;
  interpreterLanguageOther: string = null;
  iamId: string = null;
  userId: number = null;
  submittedBy: number = null;

  hasISCANeeds: boolean = null;

  iscaNeeds: ISCANeeds = null;


  constructor(obj: Submission = null) { if (obj) { Object.assign(this, obj); } }
}

// ----------------------
//  Person related classes

export class Person {
  submissionPersonId: number = null;
  personId: number = null;
  title: string = null;
  otherTitle: string = null;
  givenName: string = null;
  middleName: string = null;
  middleInitial: string = null;
  surname: string = null;
  dateOfBirth: Date = null;
  gender: number = null;
  occupationIncomeSupportBenefit: string = null;
  hasNoId = null;
  hasMedicareCard: boolean = null;
  hasDriverLicence: boolean = null;
  hasCentrelinkCard: boolean = null;
  medicare: MedicareDetail = null;
  driverLicence: LicenceDetail = null;
  centrelinkNumber: number = null;
  incomeSource: string = null;
  weeklyIncome: number = null;
  incomeNotApplicable: boolean = null;
  relationshipToApplicant: string = null;
  typeId: number = null;
  constructor(obj: Person = null) { if (obj) { Object.assign(this, obj); } }
}

export class LicenceDetail {
  licenceNumber: number = null;
  licenceStateOfIssue: number = null;
  licenceExpiryDate: Date = null;
  licenceQueueReference: string = null;
  licenceIsValid: boolean = null;
  licenceGivenName: string = null;
  licenceMiddleName: string= null;
  licenceSurname: string = null;
  constructor(obj: LicenceDetail = null) { if (obj) { Object.assign(this, obj); } }
}

export class MedicareDetail {
  medicareNameMultilined: boolean = null;
  medicareNameLine1: string = null;
  medicareNameLine2: string = null;
  medicareNameLine3: string = null;
  medicareNameLine4: string = null;
  medicareNumber: number = null;
  medicareIndividualNumber: number = null;
  medicareExpiryDate: Date = null;
  medicareColour: string = null;
  medicareQueueReference: string = null;
  medicareIsValid: boolean = null;

  constructor(obj: MedicareDetail = null) { if (obj) { Object.assign(this, obj); } }
}

export class SubmissionCulture {
  submissionId: number = null;
  cultureId: number = null;
  name: string = null;
  constructor(obj: SubmissionCulture = null) { if (obj) { Object.assign(this, obj); } }
}

// ------------------------
//  Address related classes

export class Address {
  addressType: string = null;
  propertyType: string = null;
  addressTypeId: number = null;
  propertyTypeId: number = null;
  state: string = null;
  postcode: string = null;
  suburb: string = null;
  streetName: string = null;
  streetNumber: string = null;
  unitNumber: string = null;
  lotNumber: string = null;
  mooringNumber: string = null;
  marinaDockName: string = null;
  boatName: string = null;
  poBoxNumber: string = null;
  wasManuallyEntered: boolean = null;
  isMatched: boolean = null;
  isActivated: boolean = null;
  lga: string = null;
  geoX: string = null;
  geoY: string = null;
  country: string = null;
  internationalProvince: string = null;
  isPrimaryResidence: boolean = null;
  isPostal: boolean = null;

  constructor(obj: Address = null) { if (obj) { Object.assign(this, obj); } }
}


export class Step {

  type: string = null;

  stepNumber: number = null;

  show: boolean = null;

  constructor(obj: Step = null) { if (obj) { Object.assign(this, obj); } }
}

export class ISCANeeds {

  isInternationalStudent: boolean = null;
  isLivingInQld: boolean = null;
  isExperiencingFinancialHardship: boolean = null;
  financialHardshipReason: string = null;
  primaryReasonForFinancialSupport: string = null;
  isSavingsAmountLessThanFifteenHundred: boolean = null;
  educationInstitution:  string = null;
  providerCode: string = null;
  passportNumber: string = null;
  passportCountryIssued: string = null;
  visaType: string = null;
  visaNumber: string = null;
  studentId: string = null;
  studentEmail: string = null;
  coeNumber: string = null;
  passportFirstName: string = null;
  passportLastName: string = null;
  passportDateOfBirth: Date = null;
  constructor(obj: ISCANeeds = null) { if (obj) { Object.assign(this, obj); } }
}



