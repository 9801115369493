// Framework imports
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { MovingDirection } from 'angular-archwizard';


// Abstract Base classes
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';

// Constants
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { regex } from 'src/app/shared/constants/regex';
import { regexDescription } from 'src/app/shared/constants/regex-description';
import { apis } from 'src/app/shared/constants/apis';
import { ApplicationStep } from '../../application-step.enum';


// Validators
import { customFunctionValidator } from 'src/app/shared/validators/customFunction-validator.directive';
import { dateIsInPastValidator } from 'src/app/shared/validators/dateIsInPast-validator.directive';
import { ageIsLessThanMaxAgeValidator } from 'src/app/shared/validators/ageIsLessThanMaxAge-validator.directive';

// Components
import { MedicareCardComponent } from '../id-check/partials/medicare-card/medicare-card.component';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationService } from '../../application.service';

// Util
import { formUtil } from 'src/app/shared/util/form-util';
import { util } from 'src/app/shared/util/util';
import { enums } from 'src/app/shared/enums/enums';
import { DependantFormState } from './dependant-form-state';
import { MedicareCardFormModel } from '../id-check/partials/medicare-card/medicare-card-form.model';
import { LookupListItem } from 'src/app/models/lookup-list-item.model';
import { SubmissionPersonModel } from 'src/app/models/submission-person.model';
 


@Component({
  selector: 'app-dependants',
  templateUrl: './dependants.component.html',
  styleUrls: ['./dependants.component.scss']
})
export class DependantsComponent extends BaseApplicationStep implements OnInit {


  // ---------------------------------------------------------------------------
  //                    Properties
  // ---------------------------------------------------------------------------

  title = 'Partner and/or dependants';
  maxDOB: Date;


  // ---------------------
  //  Lookup List data

  lookup_genders = lookupLists.genders;
  lookup_relationships = lookupLists.relationshipToApplicant.filter(r => r.isDependant === true);
  typeahead_nameTitles: LookupListItem[] = [];


  // ---------------------
  //  Child Components

  partnerMedicareCardComponent: MedicareCardComponent;
  dependantMedicareCardComponent: MedicareCardComponent;

  // ---------------------
  //  Validators

  private vdtr_hasDependants =
    [
      Validators.required,
      customFunctionValidator((): boolean => {
        // evaluate to true if when hasDependants=true, number of dependants is more than 1
        const result = (this.hasDependants ? this.isAtLeastOneDependantAdded : true);
        return result;
      }, 'noDependantsAdded')
    ];

  vdtrMessage_personNameFormat = regexDescription.name_person;

  // ---------------------
  //  Forms

  form: FormGroup;
  frmDependant: FormGroup;


  /**
   * Currently selected dependant form properties
   */
  dependantFormEditState = new DependantFormState({
    isVisible: false,
    editItemIndex: null,
    saveClicked: false,
    isMedicareButtonClicked: false
  });


  // ---------------------
  //  Form Groups models

  private formGroupModel_Main = {
    hasPartner: [null, Validators.required],
    partner: this.fb.group({}),
    hasDependants: [null, Validators.required],
    dependants: this.fb.array([])
  };

  private formGroupModel_Partner = {
    personId: [],
    title: ['', Validators.required],
    givenName: ['', [Validators.required, Validators.pattern(regex.name_person), Validators.maxLength(27)]],
    middleName: ['', [Validators.pattern(regex.name_person), Validators.maxLength(27)]],
    surname: ['', [Validators.required, Validators.pattern(regex.name_person), Validators.maxLength(27)]],
    dateOfBirth: ['', [Validators.required, dateIsInPastValidator(false), ageIsLessThanMaxAgeValidator(120)]],
    genderId: [null, Validators.required]
  };

  private formGroupModel_Dependant = () => {
    return {
      personId: [],
      title: ['', Validators.required],
      givenName: ['', [Validators.required, Validators.pattern(regex.name_person), Validators.maxLength(27)]],
      middleName: ['', [Validators.pattern(regex.name_person), Validators.maxLength(27)]],
      surname: ['', [Validators.required, Validators.pattern(regex.name_person), Validators.maxLength(27)]],
      dateOfBirth: [null, [Validators.required, dateIsInPastValidator(false), ageIsLessThanMaxAgeValidator(120)]],
      genderId: [null, Validators.required],
      relationshipToApplicantId: ['', Validators.required],
      medicare: this.fb.group(MedicareCardComponent.formGroupModel),

      isAlreadyAddedInMemory: [false] // insures that dependant is not added again when user navigates to previous step and back 2 times
    };
  }

  // ---------------------
  //  Getters

  /** Form Main controls */
  get fm() { return this.form.controls; }

  /** Form Partner controls */
  get fp() { return (this.fm.partner as FormGroup).controls; }

  /** Form Dependant controls */
  get fd() { return this.frmDependant.controls; }

  get dependantsFormArray() { return this.fm.dependants as FormArray; }

  get dependantsCtrls() { return this.dependantsFormArray.controls as FormGroup[]; }

  get hasDependants() { return this.fm.hasDependants && this.fm.hasDependants.value === true ? true : false; }

  get isAtLeastOneDependantAdded() { return this.dependantsCtrls.length > 0; }

  get isDependantFormVisible() { return this.dependantFormEditState.isVisible; }

  get isDependantEditInProgress() { return (this.dependantFormEditState.editItemIndex !== null ? true : false); }

  get hasDependants_butNoneInTheList_andDepFormNotOpen() {
    return (this.hasDependants && !this.isAtLeastOneDependantAdded) && !this.isDependantFormVisible;
  }
  get hasDependants_andDepFormOpen_andTriedToProgress() {
    return (this.hasDependants && this.isDependantFormVisible && this.hasAttemptedToProgress);
  }



  // ---------------------------------------------------------------------------
  //    Constructor
  // ---------------------------------------------------------------------------

  constructor(
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.Dependants, appSvc, submissionService, authService, scrollerService);
    this.pageApiUrl = apis.submissionPersons.saveDependantsAndPartner;
  }

  // ---------------------------------------------------------------------------
  //    Methods
  // ---------------------------------------------------------------------------


  // -------------------------------------
  //    Events

  ngOnInit() {

    // Set maximum clickable date on the date picker for dateOfBirth
    // Note: this needs to match existing validations on the date field - particularly dateIsInPastValidator(false)
    this.maxDOB = new Date();
    this.maxDOB.setDate(new Date().getDate() - 1);

    this.initForm();
  }

  initForm() {

    // initialize forms

    this.form = this.fb.group(this.formGroupModel_Main);
    this.frmDependant = this.fb.group(this.formGroupModel_Dependant());

    // attach value change listeners

    this.fm.hasPartner.valueChanges.subscribe(
      (hasPartner: boolean) => {
        const formGroupModel = (hasPartner ? this.formGroupModel_Partner : {});
        this.fm.partner = this.fb.group(formGroupModel);
        this.form.updateValueAndValidity();

        // Must reset child controls so that validation does not get fired if they are hidden
        if (this.partnerMedicareCardComponent) { this.partnerMedicareCardComponent.f.hasMedicareCard.setValue(false); }

      });
  }



  // ---------------------------------------------------------------
  //        Partner


  onComponentInitialized_partnerMedicareCard(medicareCardComponent: MedicareCardComponent, instanceId: string) {
    medicareCardComponent.componentId = instanceId;
    this.partnerMedicareCardComponent = medicareCardComponent;
    this.partnerMedicareCard_bindModelToForm();
  }
  partnerMedicareCard_bindModelToForm() {
    // Bind partner's medicare details if partner has already been saved to database before
    if (this.model.partner) {
      const model = new MedicareCardFormModel();
      util.mapTo(model, this.model.partner.medicare);
      model.hasMedicareCard = this.model.partner.hasMedicareCard;
      this.partnerMedicareCardComponent.bindModelToForm(model);
    }
  }

  // Partner Title typeahead event handler
  onInput_partnerTitle(e) {
    const term = e.target.value;
    this.typeahead_nameTitles = this.lookup_nameTitles.filter(item => item.name.toLowerCase().startsWith(term.toLowerCase()));
  }


  // ---------------------------------------------------------------
  //        Dependants

  onChange_hasDependants() {

    this.dependant_reset();

    // Yes
    if (this.hasDependants) {

      // add back the validators to hasDependants field
      this.validation_toggleControlValidators(this.fm.hasDependants, this.vdtr_hasDependants);

      // No
    } else {
      // only leave required validator on hasDependants
      formUtil.validation.toggleRequired(this.fm.hasDependants, true);
      // remove dependant form validators
      this.validation_removeFormValidators(this.frmDependant);
    }
  }

  // Dependant Title typeahead event handler
  onInput_dependantTitle(e) {
    const term = e.target.value;
    this.typeahead_nameTitles = this.lookup_nameTitles.filter(item => item.name.toLowerCase().startsWith(term.toLowerCase()));
  }


  // ------
  // Reset

  private dependant_reset() {
    if (this.dependantMedicareCardComponent) {
      this.dependantMedicareCardComponent.hasAttemptedToProgress = false;
    }
    this.dependantFormEditState.isVisible = false;
    this.dependantFormEditState.editItemIndex = null;
    this.dependantFormEditState.saveClicked = false;
    this.frmDependant_init();
    // reset child control so that its validation does not get fired
    if (this.dependantMedicareCardComponent) { this.dependantMedicareCardComponent.f.hasMedicareCard.setValue(false); }
  }

  onComponentInitialized_dependantMedicareCard(medicareCardComponent: MedicareCardComponent, instanceId: string) {
    medicareCardComponent.componentId = instanceId;
    const selectedItemIndex = this.dependantFormEditState.editItemIndex;
    const isMedicareButtonClicked = this.dependantFormEditState.isMedicareButtonClicked;
    const isEdit = (selectedItemIndex != null);
    const isAdd = (!isEdit);

    // store reference to selected medicare component so we can call public members of the component
    this.dependantMedicareCardComponent = medicareCardComponent;

    // ----------
    //  Add mode

    if (isAdd) {

      // reset name to empty string, because it gets set to applicant name inside medicare component
      this.dependantMedicareCardComponent.form.controls.medicareNameLine1.setValue('');

      // ----------
      // Edit mode

    } else {

      // assign selected form to displayed dependant form
      const dependantModel = ((this.dependantsFormArray.controls[selectedItemIndex]) as FormGroup).getRawValue();
      this.frmDependant.setValue(dependantModel);

      // assign selected medicare form to medicare form in medicareCardComponent
      const selectedMedicareForm = this.fd.medicare as FormGroup;
      if (selectedMedicareForm.controls) {
        const formModel = selectedMedicareForm.getRawValue() as MedicareCardFormModel;
        if (isMedicareButtonClicked) {
          formModel.hasMedicareCard = true;
        }
        this.dependantMedicareCardComponent.initForm();
        this.dependantMedicareCardComponent.bindModelToForm(formModel);
      }
      this.dependantFormEditState.saveClicked = false;
    }

  }

  // ------
  // Init

  private frmDependant_init() {
    const fgModel = this.formGroupModel_Dependant();
    fgModel.medicare = this.fb.group(MedicareCardComponent.formGroupModel);
    this.frmDependant = this.fb.group(fgModel);

  }



  // ------
  // Add

  dependant_onAddClick() {

    this.frmDependant_init();
    // remove validators from hasDependants field
    this.validation_toggleControlValidators(this.fm.hasDependants);
    this.dependantFormEditState.isVisible = true;
  }

  // ------
  // Edit

  dependant_onEditClick(index: number, isMedicareButtonClicked = false) {

    this.frmDependant_init();
    this.dependantFormEditState.editItemIndex = index;
    this.dependantFormEditState.isMedicareButtonClicked = isMedicareButtonClicked;
    // NOTE:  this will trigger onComponentInitialized_dependantMedicareCard(),
    // because that is an event handler defined in (formReady) event emitter of app-medicare-card,
    // app-medicare-card is a child component inside frmDependant and therefore gets initialized everytime dependant form is set to visible
    this.dependantFormEditState.isVisible = true;
  }

  // ------
  // Cancel

  dependant_onCancelClick() {
    this.dependant_reset();
  }
  // ------
  // Save

  dependant_onSaveClick() {
    this.dependantFormEditState.saveClicked = true;
    const itemIndex = this.dependantFormEditState.editItemIndex;
    const isEdit = (itemIndex != null);
    const isAdd = (!isEdit);

    // set hasAttemptedToProgress inside MedicareCardComponent, to trigger field validation higlighting
    this.dependantMedicareCardComponent.hasAttemptedToProgress = true;

    this.frmDependant.updateValueAndValidity();

    // VALID - SAVE if form inputs are valid
    if (this.frmDependant.valid && this.dependantMedicareCardComponent.form.valid) {

      // get dependant from value, create fresh new form, copy value into it and then add it to dependants form collection
      const dependant = this.frmDependant.getRawValue();
      dependant.medicare = this.dependantMedicareCardComponent.formModel;
      const dependantFormsItem = this.fb.group(this.formGroupModel_Dependant());
      dependantFormsItem.setValue(dependant);

      // -----
      //  ADD new dependant

      if (isAdd) {
        // Create temporary negative int id, to distinguish newely added items from existing items
        let id = (this.dependantsCtrls.length === 0 ? -1 : null);
        if (id === null) {
          const dependantWithMinPersonId = this.dependantsCtrls.reduce(function (prev, curr) {
            return prev.controls.personId.value < curr.controls.personId.value ? prev : curr;
          });
          const minPersonId = Number(dependantWithMinPersonId.controls.personId.value);
          id = (minPersonId < 0 ? minPersonId - 1 : -1);
        }
        dependantFormsItem.controls.personId.setValue(id);
        this.dependantsCtrls.push(dependantFormsItem);

      } else {

        // -----
        //  UPDATE selected dependant

        this.dependantsFormArray.controls[itemIndex] = dependantFormsItem;

      }
      this.dependant_reset();
      // Must mark main form as dirty in order to update changes to server
      this.form.markAsDirty();
    }
  }

  // ------
  // Remove

  dependant_onRemoveClick(i) {
    // Must mark main form as dirty in order to update changes to server
    this.form.markAsDirty();
    const removedDependantForm = this.dependantsCtrls[i];
    const formModel = removedDependantForm.value;

    this.dependantsFormArray.removeAt(i);
    this.dependant_reset();
  }

  dependant_getRelationshipName(relationshipValue: string) {
    return (relationshipValue ? this.lookup_relationships.find(r => r.value === relationshipValue).name : '');
  }


  wizStep_onStepEnter(direction: MovingDirection) {
    // Must initialize form when re-entering from previous step, to reinitialize form controls
    // otherwise validation problems arise
    if (direction === MovingDirection.Forwards) {
      this.initForm();
    }
    // By default bindModelToForm is not called when stepping back
    // but in this case we need to do it in order to bind data to emptied form
    if (direction === MovingDirection.Backwards) {
      this.bindModelToForm();
    }
  }

  // -----------------------------------------------------------------------------------
  //  Abstract method implementations


  // NOTE: This parent bindModelToForm method runs before the child components are initialized.
  //        So we can't call bindModelToForm methods of children inside it
  //        Instead call bindModelToForm of children inside onComponentInitialized_[childComponentName] method
  bindModelToForm() {

    this.hasAttemptedToProgress = false;

    // Map first level fields
    formUtil.mapModelToForm(this.model, this.fm);

    // ----------------
    //    Map Partner

    if (this.model.partner) {

      formUtil.mapModelToForm(this.model.partner, this.fp, {
        dateOfBirth: function (val) {
          return (util.hasValue(val) ? new Date(val) : null);
        }
      });
      // only bind partner medicare if the component is already initialized,
      // otherwise the data will be bound inside onComponentInitialized_partnerMedicareCard when partner medicare card is ready
      if (this.partnerMedicareCardComponent) {
        this.partnerMedicareCard_bindModelToForm();
      }
    }

    // ----------------
    //  Map Dependants

    if (this.model.hasDependants && this.model.dependants) {

      // reset dependants FormArray
      this.fm.dependants = this.fb.array([]);

      // loop throught dependant models
      for (let i = 0; i < this.model.dependants.length; i++) {

        // create dependant FormGroup
        const dependant = this.model.dependants[i];
        const dependantFormGroup = this.fb.group(this.formGroupModel_Dependant());
        const dependantMedicareFormGroup = (dependantFormGroup.controls.medicare as FormGroup);

        // map top level Person object fields
        formUtil.mapModelToForm(dependant, dependantFormGroup.controls, {
          dateOfBirth: function (val) {
            const date = (util.hasValue(val) ? new Date(val) : null);
            return date;
          }
        });

        // map dependant medicare
        if (dependant.hasMedicareCard && dependant.medicare) {
          // map medicare model to medicare FormGroup
          formUtil.mapModelToForm(dependant.medicare, dependantMedicareFormGroup.controls, {
            medicareExpiryDate: function (val) {
              return (util.hasValue(val) ? new Date(val) : null);
            }
          });
        }
        // set hasMedicareCard field inside medicare card form
        dependantMedicareFormGroup.controls.hasMedicareCard.setValue(dependant.hasMedicareCard);

        // add dependant form group to form array of dependants
        this.dependantsFormArray.push(dependantFormGroup);
      }

    }
  }

  wizStep_onCanExitStep(isFormValid: boolean) {
    if (this.partnerMedicareCardComponent) {
      this.partnerMedicareCardComponent.hasAttemptedToProgress = true;
      return this.partnerMedicareCardComponent.form.valid;
    }
    return true;
  }

  private bindMedicareFormToModel(formModel: MedicareCardFormModel, model: SubmissionPersonModel) {
    model.hasMedicareCard = formModel.hasMedicareCard;
    if (model.hasMedicareCard === true) {
      model.hasNoId = false;
      model.medicare = MedicareCardComponent.getDataModel(formModel);
    } else {
      model.hasNoId = true;
      model.medicare = null;
    }
  }

  bindFormToModel(): void {
    const formModel = this.form.getRawValue();

    // --------------
    //  Map Partner

    // mark main form dirty if partner has been updated
    if (this.fm.partner.dirty) {
      this.form.markAsDirty();
    }

    this.model.hasPartner = formModel.hasPartner;

    if (formModel.hasPartner) {

      if (this.model.partner == null) { this.model.partner = new SubmissionPersonModel(); }

      util.mapTo(this.model.partner, formModel.partner, true);

      this.model.partner.typeId = enums.personTypes.Partner;
      this.model.partner.relationshipToApplicantId = '100000000';

      // Map Medicare Details

      if (this.partnerMedicareCardComponent.form.dirty) {
        this.form.markAsDirty();
      }

      this.bindMedicareFormToModel(this.partnerMedicareCardComponent.formModel, this.model.partner);


    } else {
      this.model.partner = null;
    }

    // -----------------
    //  Map Dependants

    this.model.hasDependants = formModel.hasDependants;

    if (formModel.hasDependants) {

      if (this.model.dependants == null) { this.model.dependants = []; }

      const dependantsToRemove: SubmissionPersonModel[] = [];

      // Add / Update
      formModel.dependants.forEach((dependantFormModel) => {

        const i = this.model.dependants.findIndex(d => d.personId === dependantFormModel.personId);

        const addAsNewDependant = (!dependantFormModel.isAlreadyAddedInMemory && dependantFormModel.personId < 0);

        // get existing person from model or create new one
        const model_dependant = (addAsNewDependant ? new SubmissionPersonModel() : this.model.dependants[i]);

        // --------------
        //    Update
        // ----------------

        // Map Person details

        util.mapTo(model_dependant, dependantFormModel, true);
        model_dependant.typeId = enums.personTypes.Dependant;

        // Map Medicare Details

        if (dependantFormModel.medicare) {
          this.bindMedicareFormToModel((dependantFormModel.medicare as MedicareCardFormModel), model_dependant);
        }

        // --------------
        //    Add
        // ----------------

        if (addAsNewDependant) {
          this.model.dependants.push(model_dependant);
          // mark it as added so that going step back then forward doesnt duplicate this dependant
          model_dependant['isAlreadyAddedInMemory'] = true;
        }
      });

      // ----------------
      //    Remove
      // ----------------

      // Check if model has dependants that are not inside form. That means that they have been removed. Therefore add them to removal list
      this.model.dependants.forEach(md => {
        const fd = (formModel.dependants as Array<any>).find(d => d.personId === md.personId);
        if (!util.hasValue(fd)) {
          dependantsToRemove.push(md);
        }
      });
      // remove deleted persons from dependants model
      dependantsToRemove.forEach(d => {
        const i = this.model.dependants.findIndex(md => md.personId === d.personId);
        this.model.dependants.splice(i, 1);
      });

    } else {
      // if dependants question answered NO, delete all dependants from model
      this.model.dependants = null;
    }
    // clear dependants form
    this.fm.dependants = this.fb.array([]);

    // --------------------
    // Construct page model that will get sent to page api

    this.pageModel = {
      submissionId: this.model.submissionId,
      hasPartner: this.model.hasPartner,
      hasDependants: this.model.hasDependants,
      partner: this.model.partner,
      dependants: this.model.dependants
    };
  }


  wizStep_onSaveSuccess(r) {
    this.model.partner = r.partner;
    this.model.dependants = r.dependants;
  }


  // ---------------------------------------------------------------
  //        Validation

  wizStep_onValidate(): boolean {
    const hasDeps = this.fm.hasDependants.value;
    const isHasDependantsValid = !hasDeps || (hasDeps && this.isAtLeastOneDependantAdded);

    return isHasDependantsValid && (!this.isDependantFormVisible);
  }

  validateDependants = (): boolean => {
    return this.form && this.fm.hasDependants ? this.isAtLeastOneDependantAdded : true;
  }


  // -----------------------------------------
  //  Validation class getters

  // ---------
  // Form Main validation css

  validation_getInputCssClass_hasDependants() {
    const cssInvalid = formUtil.validation.cssClasses.invalidInputControl;
    const isInvalid = formUtil.validation.isControlInvalid(this.fm.hasDependants, this.hasAttemptedToProgress);
    const cssClass = (isInvalid ? cssInvalid + (!this.hasDependants ? ' custom-control-validation-highlight' : '') : '');
    return cssClass;
  }

  // ------------
  // Form Partner Validation Css

  getFldClass_frmPartner(fieldName: string) {
    const ctrl = this.fp[fieldName];
    return this.validation_cssClass_forInputField(ctrl);
  }
  getFldClass_inputGrp_frmPartner(fieldName: string) {
    const ctrl = this.fp[fieldName];
    return this.validation_cssClass_forInputGroup(ctrl);
  }

  // --------------
  // Form Dependant Validation Css

  getFldClass_frmDependant(fieldName: string) {
    const control = this.fd[fieldName];
    return formUtil.validation.getCssClass_invalidInputControl(control, this.dependantFormEditState.saveClicked);
  }
  getFldClass_inputGrp_frmDependant(fieldName: string) {
    const control = this.fd[fieldName];
    return formUtil.validation.getCssClass_invalidInputControl(control, this.dependantFormEditState.saveClicked);
  }
  getClass_saveBtn_frmDependant() {
    return formUtil.validation.cssClasses.buttonSubmit;
    // return (this.frmDependant.valid ? formUtil.validation.cssClasses.buttonSubmit : formUtil.validation.cssClasses.buttonDisabled);
  }



}
