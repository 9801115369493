import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';


// Models
import { Submission } from '../models/submission.model';

// Base Classes
import { BaseApiService } from '../shared/base-classes/base-api-service';

// Constants
import { apis } from '../shared/constants/apis';

// Storage
import { localData } from '../shared/storage/local-data';
import { LookupListItem } from '../models/lookup-list-item.model';




@Injectable({
  providedIn: 'root'
})
export class LookupService extends BaseApiService {


  // ----------------------------
  //      Properties
  // ----------------------------

  /**
   * MAIN MODEL - Main submission model that gets passed from step to step of the wizard and form data is bound to it.
   */
  protected model: Submission;

  // ----------------------------
  //      Constructor
  // ----------------------------

  constructor(
    httpClient: HttpClient) {
    super(httpClient);
  }

  // ----------------------------
  //      Methods
  // ----------------------------

  // Languages

  getLanguages(): Observable<LookupListItem[]> {
    return this.get(apis.lookup.languages)
      .pipe(
        map(r => {
          const lookupList = r.map(item => new LookupListItem({ id: item.languageId, name: item.name })) as LookupListItem[];
          // store the response data into localStorage for later use
          localData.lookups.languages(lookupList);
          return lookupList;
        }),
        catchError(this.handleError)
      );
  }

  saveSubmission(submission: Submission) {
    this.post(apis.submission.addOrUpdate,submission)
    .subscribe(result=>{
      //console.log(result);
    })
  }

  // Essential Services

  getEssentialServices(eventId: string) {
    return this.get<LookupListItem[]>(apis.lookup.essentialServices(eventId))
      .pipe(
        map(r => {
          const lookupList = r.map(item => new LookupListItem({ id: item.id, name: item.name })) as LookupListItem[];
          return lookupList;
        }),
        catchError(this.handleError)
      );
  }

  // Essential Services Reconnection

  getEssentialServicesReconnection(eventId: string) {
    return this.get<LookupListItem[]>(apis.lookup.essentialservicesReconnection(eventId))
      .pipe(
        map(r => {
          const lookupList = r.map(item => new LookupListItem({ id: item.id, name: item.name })) as LookupListItem[];
          return lookupList;
        }),
        catchError(this.handleError)
      );
  }

  // Immediate Needs

  getImmediateNeeds(eventId: string) {
    return this.get<LookupListItem[]>(apis.lookup.essentialImmediateNeeds(eventId))
      .pipe(
        map(r => {
          const lookupList = r.map(item => new LookupListItem({ id: item.id, name: item.name })) as LookupListItem[];
          return lookupList;
        }),
        catchError(this.handleError)
      );
  }

  // Name Titles

  getNameTitles(): Observable<LookupListItem[]> {
    return this.get(apis.lookup.nameTitles)
      .pipe(
        map(r => {
          const lookupList = r.map(item => new LookupListItem({ id: item.nameTitleId, name: item.abbreviation })) as LookupListItem[];
          // store the response data into localStorage for later use
          localData.lookups.nameTitles(lookupList);
          return lookupList;
        }),
        catchError(this.handleError)
      );
  }

  // Property Types
  getPropertyTypes(): Observable<LookupListItem[]> {
    return this.get(apis.lookup.propertyTypes)
      .pipe(
        map(r => {
          const lookupList = r.map(item => new LookupListItem({ id: item.propertyTypeId, name: item.name })) as LookupListItem[];
          // store the response data into localStorage for later use
          localData.lookups.propertyTypes(lookupList);
          return lookupList;
        }),
        catchError(this.handleError)
      );
  }

  // Property Types
  getAddressTypes(): Observable<LookupListItem[]> {
    return this.get(apis.lookup.addressTypes)
      .pipe(
        map(r => {
          const lookupList = r.map(item => new LookupListItem({ id: item.addressTypeId, name: item.name })) as LookupListItem[];
          // store the response data into localStorage for later use
          localData.lookups.addressTypes(lookupList);
          return lookupList;
        }),
        catchError(this.handleError)
      );
  }

}
