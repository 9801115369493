import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupportReferralModel } from '../models/support-referral.model';

@Injectable()
export class SupportReferralService {
  API_URL = environment.APIUrl;
  private _reqOptionsArgs = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  constructor(private http: HttpClient) { }

  // Retrieves list of support referrals that are available
  public getSupportReferrals(eventId: string): Observable<SupportReferralModel[]> {
    return this.http.get<SupportReferralModel[]>(`${this.API_URL}/support-referral/lookup?eventId=${eventId}`, this._reqOptionsArgs);
  }
}
