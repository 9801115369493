
/**
 * Store all the static lookup lists inside this file instead of including them inside your component
 */
export const lookupLists = {

    genders: [
        { value: 1, name: '(M) Male' },
        { value: 2, name: '(F) Female' },
        { value: 100000003, name: '(X) Other/Prefer not to disclose' }
    ],

    relationshipToApplicant: [
        { value: '', name: '' },
        { value: '100000000', name: 'Partner', isDependant: false },
        { value: '100000001', name: 'Dependant Child', isDependant: true },
        { value: '100000002', name: 'Dependant Adult', isDependant: true }
    ],

    structuralDamage: [
        { value: 'yes', name: 'Yes' },
        { value: 'no', name: 'No' },
        { value: 'unsure', name: 'Unsure' }
    ],

    affectedRooms: [
        { id: 1, name: 'House' },
        { id: 2, name: 'Kitchen' },
        { id: 3, name: 'Laundry' },
        { id: 4, name: 'Dining Room' },
        { id: 5, name: 'Other' }
    ],

    insuredForHouseholdContents: [
        { id: 'no', name: 'Not Insured' },
        { id: 'unsure', name: 'Unsure' },
        { id: 'yes', name: 'Yes' }
    ],

    structuralInsuredForDisaster: [
        { value: 'yes', name: 'Yes' },
        { value: 'no', name: 'Not insured' },
        { value: 'under', name: 'Under insured' },
        { value: 'unsure', name: 'Unsure' }
    ],

    rentingOrOwn: [
        { value: true, name: 'Owner Occupied' },
        { value: false, name: 'Rented' }
    ],

    paymentOptions: [
        { id: 'EFT', name: 'Electronic Funds Transfer to your bank account (preferred method). Only available to Australian bank accounts' },
        { id: 'Debit', name: 'Recovery Debit Card (only for approved situations)' },
        { id: 'Cheque', name: 'Cheque (only for approved situations)' },
        { id: 'None', name: "I am unable to provide my bank account details. This will delay your application. We will contact you for alternative payment methods"}
    ],

    support_other: [
        { id: 1, name: 'Cultural Support' },
        { id: 2, name: 'Accommodation Support' },
        { id: 3, name: 'Caring Responsibility (eg Aged Care, Disability Services)' },
        { id: 4, name: 'Domestic and Family Violence' },
        { id: 5, name: 'Family Relationships' },
        { id: 6, name: 'Other not listed above (please specify)' }
    ],

    support_individual: [
        { id: 1, name: 'Counselling' },
        { id: 2, name: 'Grief Support' },
        { id: 3, name: 'Mental Health' },
        { id: 4, name: 'Personal Support' }
    ],

    support_financialCounselling: [
        { id: 1, name: 'Financial Counselling' },
        { id: 2, name: 'Income Support(not grant support)' }
    ],

    // ID numbers match Option Set values in Dynamics - Preferred Home Time Visit
    preferredVisitTime: [
        { id: 100000000, name: 'Morning' },
        { id: 100000001, name: 'Afternoon' },
        { id: 100000002, name: 'No Preference' }
    ],

    needOptions: [
        { id: 1, name: 'Essential Clothing' },
        { id: 2, name: 'Food' },
        { id: 3, name: 'Medication' },
        { id: 4, name: 'Temporary Emergency Accommodation' }
    ],

    /**
     * NOTE: Items MUST match exactly table lookup.Culture in database
     */
    culturalGroups: [
        { id: 1, name: 'Aboriginal' },
        { id: 2, name: 'Torres Strait Islander' },
        { id: 3, name: 'Australian South Sea Islander' },
        { id: 4, name: 'Other' },
        { id: 5, name: 'Do Not Disclose' },
        { id: 6, name: 'None' }
    ],

    grantStatus: [
        { id: 1, name: 'Pending' },
        { id: 2, name: 'Complete' },
        { id: 3, name: 'Pending2' },
        { id: 4, name: 'Pending3' },
        { id: 5, name: 'Pending4' }
    ],
    /**
     * NOTE: MUST match exactly enums.propertyTypes
     */
    propertyTypes: [
        { value: 'AgedCare', name: 'Aged care' },
        { value: 'Boat', name: 'Boat' },
        { value: 'Caravan', name: 'Caravan' },
        { value: 'House', name: 'House' },
        { value: 'RetirementVillage', name: 'Retirement village' },
        { value: 'Unit', name: 'Unit/Flat/Apartment/Townhouse' }
    ],

    australianStates: [
        { value: '100000007', name: 'Australian Capital Territory', code: 'ACT' },
        { value: '100000001', name: 'New South Wales', code: 'NSW' },
        { value: '100000006', name: 'Northern Territory', code: 'NT' },
        { value: '100000000', name: 'Queensland', code: 'QLD' },
        { value: '100000002', name: 'South Australia', code: 'SA' },
        { value: '100000003', name: 'Tasmania', code: 'TAS' },
        { value: '100000005', name: 'Victoria', code: 'VIC' },
        { value: '100000004', name: 'Western Australia', code: 'WA' }
    ],
    fianancialSupportServices: [
       { id: 1, name: 'Food' },
       { id: 2, name: 'Rent' },
       { id: 3, name: 'Internet'}
    ],
    educationalInstitutions: [
      { id: 1, name: 'Bond University Limited' },
      { id: 2, name: 'The University of Queensland'},
      { id: 3, name: 'Cairns Language Centre Pty Ltd'}
   ],
   financialHardshipReasons: [
    { id: 1, name: 'Reduction in employment' },
    { id: 2, name: 'Reduction in family support'},
    { id: 3, name: 'Unable to access financial bursary from institution'},
    { id: 4, name: 'Reduction in other source of income'}
  ],
  financialSupportReasons: [
    { id: 1, name: 'Unable to pay for food' },
    { id: 2, name: 'Unable to pay for rent'},
    { id: 3, name: 'Unable to pay utility bills'},
    { id: 4, name: 'Unable to pay for necessities (personal hygiene products, phone bills)'},
    { id: 5, name: 'Unable to pay to return home'}
  ],
};

