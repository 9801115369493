import { SubmissionPersonModel } from './submission-person.model';

 

export class SubmissionPersonListModel {

    submissionId: number = null;

    submissionPersons: SubmissionPersonModel[] = [];
  
    constructor(obj: SubmissionPersonListModel = null) { if (obj) { Object.assign(this, obj); } }
  }
  
  