import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';


// Models
import { GrantModel } from '../models/grant.model';
import { BaseApiService } from '../shared/base-classes/base-api-service';
import { apis } from '../shared/constants/apis';
import { Submission } from '../models/submission.model';


@Injectable()
export class GrantService extends BaseApiService {

  private _reqOptionsArgs = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  constructor(http: HttpClient, private datePipe: DatePipe) {

    super(http);
  }

  // Retrieves list of grants that are available based on a list of polygons IDs
  public findGrantsByPolyIds(polyIds: string[], dateOfImpact: Date, userId: number, submissionId: number, isHomeOwner: boolean): Observable<GrantModel[]> {
    if (!dateOfImpact) {
      return of();
    }
    const dateOfImpactString = this.datePipe.transform(dateOfImpact, 'yyyy-MM-dd');
    const url = this.getUrl(apis.grants.lookup(polyIds, dateOfImpactString, userId, submissionId, isHomeOwner));
    return this.http.get<GrantModel[]>(url, this._reqOptionsArgs);
  }

  saveGrants(model: Submission) {
    const url = this.getUrl(apis.grants.save);
    return this.http.post(url, model);
  }


}
