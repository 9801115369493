﻿import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentMethodService } from './payment-method.service';
import { MatchValidation } from 'src/app/shared/directives/match-value.directive';
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';
import { SubmissionService } from 'src/app/services/submission.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { formUtil } from 'src/app/shared/util/form-util';
import { regex } from 'src/app/shared/constants/regex';
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { customFunctionValidator } from 'src/app/shared/validators/customFunction-validator.directive';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationService } from '../../application.service';
import { ApplicationStep } from '../../application-step.enum';
import { util } from 'src/app/shared/util/util';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent extends BaseApplicationStep implements OnInit {

  // ---------------------------------------------------------------------------
  //                    Properties
  // ---------------------------------------------------------------------------

  title = 'Grant Payment Method';
  bankDetails = '';
  form: FormGroup;

  // ----------------------
  //  Lookups

  lookup_paymentOptions = Object.keys(lookupLists.paymentOptions).map(e => lookupLists.paymentOptions[e]);
  EFT = lookupLists.paymentOptions[0].id;

  // ----------------------
  //  Getters

  get f() { return this.form.controls; }

  // ----------------------
  //  Validators

  private vdtr_eftBankName = [Validators.required, Validators.pattern(regex.name_bank)];
  private vdtr_bsbvalid = customFunctionValidator((): boolean => this.f.hasUnattemptedOrVerifiedBSB.value, 'bsbValid');
  private vdtr_eftBsb = [Validators.required, Validators.pattern(regex.bsbNumber), this.vdtr_bsbvalid];
  private vdtr_confirmBsb = [Validators.required, this.matchVal.MatchValidator('eftBsb')];
  private vdtr_eftAccountNumber = [Validators.required, Validators.pattern(regex.bankAccountNumber)];
  private vdtr_confirmAccountNumber = [Validators.required, this.matchVal.MatchValidator('eftAccountNumber')];
  private vdtr_eftAccountName = [Validators.required, Validators.pattern(regex.name_person)];


  // ---------------------------------------------------------------------------
  //    Constructor
  // ---------------------------------------------------------------------------

  constructor(
    private pmService: PaymentMethodService,
    private matchVal: MatchValidation,
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.PaymentMethod, appSvc, submissionService, authService, scrollerService);
    this.createform();
  }

  ngOnInit() {
    // Set validators for BSB and account number match validators

  }

  // todo: create the form for the current step here
  createform() {
    this.form = this.fb.group({
      paymentOption: ['', Validators.required],
      eftBankName: [''],
      eftBsb: [''],
      hasUnattemptedOrVerifiedBSB: true, // not part of model. // initialise as unattempted
      confirmBSBNumber: [''],
      eftAccountNumber: [''],
      confirmAccountNumber: [''],
      eftAccountName: ['']
    });

    this.eftBsbChanged();

    this.eftAccountNumberChanged();

    this.onValidationChangeRevalidateBSB();
  }

  // calls whenever user changes selected payment method.
  onChange_paymentOption() {
    this.resetEftValidators();
  }

  // Set required validation on confirm BSB
  eftBsbChanged() {
    this.f.eftBsb.valueChanges.subscribe(
      (bsb: string) => {
        // handle validation for confirm bsb
        if (bsb === null || bsb === '' || this.f.paymentOption.value !== this.EFT) {
          // if irrelevant, clear validators
          if (this.f.confirmBSBNumber) { this.f.confirmBSBNumber.clearValidators(); }
          this.bankDetails = '';
          this.setValue_hasUnattemptedOrVerifiedBSB(true);
        } else {
          // otherwise, attempt to get branch details
          if (bsb.length >= 7) { // there is a mandatory hyphen picked up in the string, a 6 here would be "111-11"
            this.pmService.LookupBank(bsb).subscribe((data: string) => this.onBankDetailsReceived(data));
          } else {
            this.bankDetails = '';
            this.setValue_hasUnattemptedOrVerifiedBSB(true);
          }
          if (this.f.confirmBSBNumber) {
            this.f.confirmBSBNumber.setValidators(this.vdtr_confirmBsb);
          }
        }
        if (this.f.confirmBSBNumber) {
          this.f.confirmBSBNumber.updateValueAndValidity();
        }
      }
    );
  }

  // Set required validation on confirm account number
  eftAccountNumberChanged() {
    this.f.eftAccountNumber.valueChanges.subscribe(
      (account: string) => {
        if (account === null || account === '' || this.f.paymentOption.value !== this.EFT) {
          if (this.f.confirmAccountNumber) {
            this.f.confirmAccountNumber.clearValidators();
          }
        } else {
          if (this.f.confirmAccountNumber) {
            this.f.confirmAccountNumber.setValidators(this.vdtr_confirmAccountNumber);
          }
        }
        if (this.f.confirmAccountNumber) {
          this.f.confirmAccountNumber.updateValueAndValidity();
        }
      }
    );
  }

  // note this is validating off of the bankDetails field that gets updated based on callback from updating itself.
  // the form field validation occurs at the very beginning so will validate before the bankDetails update occurs
  // revalidating from withing a change using updateValueAndValidity triggers a change.
  onValidationChangeRevalidateBSB() {
    this.f.hasUnattemptedOrVerifiedBSB.valueChanges.subscribe(
      () => {
        this.f.eftBsb.updateValueAndValidity();
      });
  }
  // always check and only update if value has changed
  setValue_hasUnattemptedOrVerifiedBSB(newValue) {
    if (this.f.hasUnattemptedOrVerifiedBSB.value !== newValue) {
      this.f.hasUnattemptedOrVerifiedBSB.setValue(newValue);
    }
  }

  onBankDetailsReceived(bank: string): void {
    this.bankDetails = bank ? bank : '';
    this.setUnattemptedOrVerifiedBSB();
  }

  setUnattemptedOrVerifiedBSB(): void {
    const attemptedConditions = this.f.eftBsb.value !== null && this.f.eftBsb.value.length === 7;
    const isVerified = this.bankDetails !== null && this.bankDetails !== '';
    const isUnattemptedOrVerifiedBSB = !attemptedConditions || isVerified;

    this.setValue_hasUnattemptedOrVerifiedBSB(isUnattemptedOrVerifiedBSB);
  }

  // called in bind
  private resetEftValidators(): void {
    // determine if EFT
    const paymentOption = this.f.paymentOption.value;
    const isEft = (paymentOption === this.EFT);

    // reset validations based on payment method
    formUtil.validation.toggleControlsValidators(this.form, {
      'eftBankName': this.vdtr_eftBankName,
      'eftBsb': this.vdtr_eftBsb,
      'eftAccountNumber': this.vdtr_eftAccountNumber,
      'eftAccountName': this.vdtr_eftAccountName
    }, isEft, true);

    if (isEft == false) {
      this.f.eftBankName.setValue(null);
      this.f.eftBsb.setValue(null);
      this.f.eftAccountNumber.setValue(null);
      this.f.eftAccountName.setValue(null);
      this.f.confirmBSBNumber.setValue(null);
      this.f.confirmAccountNumber.setValue(null);
    }

    this.setUnattemptedOrVerifiedBSB();
  }

  bindModelToForm(): void {
    const firstGrant = this.model.grants[0];
    const allGrants = this.model.grants;
    let enableEFT = false;
    let enableRDC = false;
    let enableCHQ = false;
    let enableNON = false;
    // If EHA selected (which would be the first entry in the list) - Show only the methods for EHA (with EFT enabled regardless).
    if (firstGrant.type == "EHA") {
      enableEFT = true;
      enableNON = true;
      if (firstGrant.allowDebit) {
        enableRDC = true;
      }
      if (firstGrant.allowCheque) {
        enableCHQ = true;
      }
    } else {
      // If EHA isn't selected, only show the payment methods common to all grants.
      // Count the number of grants with each payment method, if the method count = total grants then enable that method.
      let totalEFT = 0;
      let totalRDC = 0;
      let totalCHQ = 0;
      for (let grant = 0; grant < allGrants.length; grant++) {
        if (allGrants[grant].allowEftpos) {
          totalEFT++;
        }
        if (allGrants[grant].allowDebit) {
          totalRDC++;
        }
        if (allGrants[grant].allowCheque) {
          totalCHQ++;
        }
      }
      if (totalEFT == allGrants.length) {
        enableEFT = true;
      }
      if (totalRDC == allGrants.length) {
        enableRDC = true;
      }
      if (totalCHQ == allGrants.length) {
        enableCHQ = true;
      }
      if (enableEFT && !enableRDC && !enableCHQ) {
        enableNON = true;
      }
    }
    
    // Filter out payment methods not enabled by the above code, in line with expected behaviour.
    if (!enableEFT) {
      this.lookup_paymentOptions = this.lookup_paymentOptions.filter(method => method.id != "EFT");
    }
    if (!enableRDC) {
      this.lookup_paymentOptions = this.lookup_paymentOptions.filter(method => method.id != "Debit");
    }
    if (!enableCHQ) {
      this.lookup_paymentOptions = this.lookup_paymentOptions.filter(method => method.id != "Cheque");
    }
    if (!enableNON) {
      this.lookup_paymentOptions = this.lookup_paymentOptions.filter(method => method.id != "None");
    }
    
    // If only one payment method is left after the above filter, and no method is saved for the first grant, select the only option.
    // Otherwise fill using the existing saved value.
    if (this.lookup_paymentOptions.length == 1 && firstGrant.paymentOption == null) {
      this.f.paymentOption.setValue(this.lookup_paymentOptions[0].id)
    } else {
      this.f.paymentOption.setValue(firstGrant.paymentOption);  
    }

    

    const paymentDetails = firstGrant.eftDetails;

    // initialise validators for existing eft
    this.resetEftValidators();

    formUtil.mapModelToForm(paymentDetails, this.f);

    // add the values for confirmations.
    if (firstGrant.eftDetails.eftBsb) {
      this.f.confirmBSBNumber.setValue(this.f.eftBsb.value);
    }
    if (firstGrant.eftDetails.eftAccountNumber) {
      this.f.confirmAccountNumber.setValue(this.f.eftAccountNumber.value);
    }

  }

  bindFormToModel(): void {
    const f = this.form.value;
    for (let index = 0; index < this.model.grants.length; index++) {
      const grant = this.model.grants[index];
      grant.paymentOption = this.f.paymentOption.value;
      util.mapTo(grant, f);
      grant.eftDetails.eftAccountName = this.f.eftAccountName.value;
      grant.eftDetails.eftAccountNumber = this.f.eftAccountNumber.value;
      grant.eftDetails.eftBankName = this.f.eftBankName.value;
      if (this.f.eftBsb.value.length === 6 && this.f.eftBsb.value.indexOf('-') === -1) {
        grant.eftDetails.eftBsb = this.f.eftBsb.value.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
      } else {
        grant.eftDetails.eftBsb = this.f.eftBsb.value;
      }
    }
    this.form.markAsDirty();
  }

}
