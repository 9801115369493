﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from 'src/app/services/user.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService, private userService: UserService) { }

    // if user not authenticated block the route
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
            return this.authService.isAuthenticatedHandler();
    }
}
