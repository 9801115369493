
export class UserPersonModel {

    iamId: string = null;

    userId: number = null;

    roleId: number = null;

    email: string = null;

    phone: string = null;

    firstName: string = null;

    middleName: string = null;

    lastName: string = null;

    genderId: number = null;

    dateOfBirth: Date = null;


    constructor(obj: UserPersonModel = null) { if (obj) { Object.assign(this, obj); } }
}
