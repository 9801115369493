
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

// Constants
import { sessionData } from '../shared/storage/session-data';
import { enums } from '../shared/enums/enums';
import { apis } from '../shared/constants/apis';
import { environment } from '../../environments/environment';

// Models
import { UserSubmissionModel } from '../models/grant.model';
import { IAMUserModel } from '../models/iam-user.model';

// Base Classes
import { BaseApiService } from '../shared/base-classes/base-api-service';

// Services
import { AppService } from './app.service';
import { map, catchError, finalize } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseApiService {

  constructor(
    private router: Router,
    httpClient: HttpClient,
    private appSvc: AppService) {
    super(httpClient);
  }

  public registerNewIAMUser(authToken: string, isApplicant: boolean) {
    const url = this.getUrl(apis.user.register);
    const params_RegisterUser = { auth0_idToken: authToken, isApplicant: isApplicant };
    return this.http.post<IAMUserModel>(url, params_RegisterUser);
  }

  public loginIAMUser(authToken: string, loadingMessage: string) {
    this.appSvc.loadingOverlayShow(loadingMessage);
    const url = this.getUrl(apis.user.login);
    return this.http.post<IAMUserModel>(url, { value: authToken })
      .pipe(
        map(r => r),
        catchError(this.handleError),
        finalize(() => {
          this.appSvc.loadingOverlayHide();
        })
      );
  }

  /**
   * handles the UI logout procedures for the portal including session handling and redirects
   * and calls the logout through auth 0.
   * @param sessionState provide a state from enums.sessionState
   * @param returnRoute if a return route is required after a logout that isnt default
   */
  public logout(sessionState: string = enums.sessionState.state_logout, returnRoute: string = null) {
    // clear all session data
    sessionStorage.clear();
    // set session state in preparation for logout (default will be logout state if none provided)
    sessionData.sessionState(sessionState);
    // calls the proper auth0 logout
    this.authLogout(false);
    // if a return route was specified, go to the location after the logout has completed (like options)
    if (returnRoute) {
      this.router.navigate([returnRoute]);
    }

  }

  public logoutSupport(sessionState: string = enums.sessionState.state_logout, returnRoute: string = null) {
    // clear all session data
    sessionStorage.clear();
    // set session state in preparation for logout (default will be logout state if none provided)
    sessionData.sessionState(sessionState);
    // calls the proper auth0 logout
    this.authLogout(true);
    // if a return route was specified, go to the location after the logout has completed (like options)
   // if (returnRoute) {
      this.router.navigate([returnRoute]);
   // }

  }

  /**
   *  auth 0 logout
   */
  public authLogout(isSupport: boolean): void {
    const logoutURL = isSupport ? environment.auth0LogoutSupportReturnURL: environment.auth0LogoutReturnURL;
    window.location.href = logoutURL;

  }



  /**
   * gets the current user as an IAMUser from service
   */
  public getIAMUser() {
    const url = this.getUrl(apis.user.load);
    return this.http.post<IAMUserModel>(url, null);
  }

  /**
   * saves back current IAMUser
   * @param user
   */
  public saveIAMUser(user: IAMUserModel) {
    const url = this.getUrl(apis.user.update);
    //debugger;
    return this.http.post<boolean>(url, user);
  }

  public getUserSubmissions(): Observable<UserSubmissionModel[]> {
    const url = this.getUrl(apis.user.getApplications);
    return this.http.post<UserSubmissionModel[]>(url, null);
  }

  /**
   * gets the access token from local storage
   */
  public getAccessToken(): string {
    const authSession = sessionData.authSession();
    return (authSession && authSession.accessToken ? authSession.accessToken : '');
  }

  /**
   * default new autorization headers for auth 0
   * @param accesstoken
   */
  private setNewAuthHeaders(accesstoken: string): HttpHeaders {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json');
    header = header.set('Authorization', `Bearer ${accesstoken}`);
    return header;
  }

}
