import { environment } from '../../environments/environment';

interface AuthConfig {
  clientID: string;
  domain: string;
  callbackURL: string;
  callbackRegisterURL: string;
  audience: string;
}

export const AUTH_CONFIG: AuthConfig = {
  clientID: environment.auth0clientID,
  domain: environment.auth0domain,
  callbackURL: environment.auth0LoginRedirectURL,
  callbackRegisterURL: environment.auth0RegisterRedirectURL,
  audience: environment.auth0audience
};
