import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import * as auth0 from 'auth0-js';
import { AUTH_CONFIG } from 'src/app/auth/auth0-variables';
import { sessionData } from 'src/app/shared/storage/session-data';


@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})

export class PrivacyComponent implements OnInit {
  title = 'Privacy Statement';
  submitted = false;
  privacyForm: FormGroup;
  IsGenericGrantEnabled = environment.isGenericGrantEnabled;
  ShowDefinitions = true;

  constructor(private formBuilder: FormBuilder, private router: Router, private auth: AuthService) { }

  ngOnInit() {
    this.privacyForm = this.formBuilder.group({
      hasAgreedToPrivacyStatement: [false, Validators.pattern('true')],
      hasAgreedToThirdPartyStatement: [false, Validators.pattern('true')]
    });
  }

  // Convenience getter for easy access to forms fields
  get f() { return this.privacyForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.privacyForm.invalid) {
      return;
    }

    this.auth.auth0 = new auth0.WebAuth({
      clientID: AUTH_CONFIG.clientID,
      domain: AUTH_CONFIG.domain,
      responseType: 'token id_token',
      audience: AUTH_CONFIG.audience,
      redirectUri: AUTH_CONFIG.callbackRegisterURL,
      scope: 'openid email signUp' // , _intstate:'login'
    });

    sessionData.loginLocation('recovery');
    this.auth.login();
  }
}
