﻿import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

// Services
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { SubmissionService } from 'src/app/services/submission.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationService } from '../../application.service';

// Base Classes
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';
import { BaseSubStep } from 'src/app/shared/base-classes/base-sub-step';

// Models
import { GrantModel } from 'src/app/models/grant.model';
import { Step } from 'src/app/models/submission.model';

// Constants
import { enums } from 'src/app/shared/enums/enums';
import { ApplicationStep } from '../../application-step.enum';
import { apis } from 'src/app/shared/constants/apis';
import { SubmissionPersonListModel } from 'src/app/models/submission-person-list.model';
import { SubmissionPersonModel } from 'src/app/models/submission-person.model';

@Component({
  selector: 'app-impact-assessment',
  templateUrl: './impact-assessment.component.html',
  styleUrls: ['./impact-assessment.component.scss']
})

export class ImpactAssessmentComponent extends BaseApplicationStep implements OnInit {

  // ---------------------------
  //  Properties
  // ---------------------------
  submitted = false;

  form: FormGroup;

  currentStep: BaseSubStep;


  stepNames = Object.keys(enums.impactAssessmentTypes).map(key => enums.impactAssessmentTypes[key]);

  currentStepNumber = 1;

  steps: { [key: string]: Step } = {};

  childComponents = {};

  // -------------
  //  Getters

  get f() { return this.form.controls; }
  get isLastStep() { return (this.currentStepNumber === this.model.steps.length); }

  // ---------------------------
  //  Constructor
  // ---------------------------

  constructor(
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.ImpactAssessment, appSvc, submissionService, authService, scrollerService);
  }

  // ---------------------------
  //  Methods
  // ---------------------------

  ngOnInit() {

    // init form
    this.form = this.fb.group({});

    this.stepNames.forEach((stepName, i) => {

      // init step components
      this.childComponents[stepName] = {};
    });
  }


  /**
   * initialises child component
   * @param name name of step being entered, should also match on the step type for accessing of grants.
   * @param subStep
   */
  onComponentInitialized(name: string, subStep: BaseSubStep) {

    this.childComponents[name] = subStep;

    this.currentStep = this.childComponents[name];

    // Income assessment
    if (name === enums.impactAssessmentTypes.Income) {
      this.currentStep.bindModelToForm(this.model);

      //  Grant Assessment
    } else {

      const unnormalizedGrantObj = this.model.grants.find(g => g.type === name) as GrantModel;
      if (unnormalizedGrantObj) {

        // extract the actual grant object from the unnormalized grant object that contains redundant fields of other grants

        const grant = unnormalizedGrantObj[name.toLowerCase()];

        this.currentStep.bindModelToForm(grant);
      }

    }
  }


  // called by Next button's (click) event for each step in the markup to exit the substep
  onNextSubStep(stepType: string) {

    // only attempt to leave any substep if passes check for session validity
    if (this.authService.isAuthenticatedHandler()) {

      const isValid = this.currentStep.onNext();

      if (isValid) {

        this.bindSubFormToModel(stepType);

        //---------------------
        // INCOME ASSESSMENT step

        const isIncomeStep = (this.steps.Income && stepType === this.steps.Income.type);

        if (isIncomeStep) {

          // bind data to model
          const submissionPersons: SubmissionPersonModel[] = [];

          submissionPersons.push(this.model.applicant);
          if (this.model.partner) { submissionPersons.push(this.model.partner); }
          if (this.model.dependants) { this.model.dependants.forEach(d => { submissionPersons.push(d); }); }

          const submissionPersonListModel = new SubmissionPersonListModel({
            submissionId: this.model.submissionId,
            submissionPersons: submissionPersons
          });

          // ASYNC: Call Api - Save persons income to database
          this.submissionService
            .savePage(apis.submissionPersons.saveIncome, submissionPersonListModel)
            .subscribe((r) => {

              // GOTO:
              this.progressStep();
            });


        } else {
          //---------------------
          // OTHER steps

          // GOTO:
          this.progressStep();
        }
      } // -- // End : IsValid

    }

  }



  bindModelToForm() {

    this.steps = {};

    this.model.steps.forEach((modelStep, i) => {

      this.steps[modelStep.type] = modelStep;

    });
  }

  // called on the exit of parent step
  bindFormToModel() {
    // bindings occur on exit of the substeps
  }

  // called just before progession to next sub step.
  bindSubFormToModel(grantType: string): void {

    const subComponent = this.childComponents[grantType];

    if (subComponent.form.dirty) { this.form.markAsDirty(); }

    // if not income update grant inside submission model
    if (grantType !== enums.impactAssessmentTypes.Income) {
      this.model.grants.forEach(modelGrant => {
        if (modelGrant.type === grantType) {
          modelGrant[grantType.toLowerCase()] = subComponent.model;
        }
      });
    }

  }

  // ------------------------------------------------------
  //    internal stepper helpers
  // ------------------------------------------------------

  // step progressor
  progressStep() {

    if (this.isLastStep) {

      // will call (stepExit) from the wizard.
      if (this.model.grants !== undefined) {
        if (this.model.grants.length === 1 && this.model.grants[0].eha !== null) {
          if (this.model.grants[0].eha.experiencingHardship === false) {
            this.appService.goForward();
            return;
          }
        }
      }
      this.gotoNextApplicationStep();
     } else {
      if (this.model.grants !== undefined) {

        if (this.model.grants.length === 1 && this.model.grants[0].eha !== null) {
          if (this.model.grants[0].eha.experiencingHardship === false) {
            this.appService.goForward();
            return;
          }
        }
      }
      this.goToSubstep('next');
    }
  }

  // internal sub step progression handler
  goToSubstep(direction: string) {
    switch (direction) {
      case 'next': this.currentStepNumber++; break;
      case 'prev': this.currentStepNumber--; break;
    }

    if (this.currentStepNumber < 1) {

      // if going previous from first step
      this.gotoPreviousApplicationStep();
    } else {

      // show/hide steps
      for (const stepName in this.steps) {
        if (this.steps.hasOwnProperty(stepName)) {
          const step = this.steps[stepName];
          step.show = (step.stepNumber === this.currentStepNumber);
          if (step.show) {
            this.currentStep = this.childComponents[step.type];
          }
        }
      }

    }
  }

  gotoNextApplicationStep() {

    this.appService.goToNextStep();

  }

  gotoPreviousApplicationStep() {
    this.appService.goToPreviousStep();
  }
}
