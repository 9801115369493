﻿import { ApplicantIdentityBasicComponent } from './modules/basic/applicant-identity-basic/applicant-identity-basic.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ErrorComponent } from './pages/error/error.component';
// imports
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthRoute } from './core/guards/auth.route';

// -------------
//  Public Pages

import { DisclaimerComponent } from 'src/app/pages/disclaimer/disclaimer.component';
import { LoginComponent } from 'src/app/pages/account/login/login.component';
import { PrivacyComponent } from 'src/app/pages/privacy/privacy.component';
import { ApplyComponent } from 'src/app/pages/apply/apply.component';
import { OptionsComponent } from 'src/app/pages/options/options.component';

// --------------------
//  Loged In User Pages

import { RegisterComponent } from 'src/app/pages/account/register/register.component';
import { ApplicationComponent } from 'src/app/pages/application/application.component';
import { DashboardComponent } from 'src/app/pages/account/dashboard/dashboard.component';
import { SubmissionResultsComponent } from 'src/app/pages/submission-results/submission-results.component';
import { EditContactComponent } from 'src/app/pages/account/dashboard/partials/edit-contact/edit-contact.component';
import { ApplicantDetailsBasicComponent } from './modules/basic/applicant-details-basic/applicant-details-basic.component';
import { ApplicationBasicComponent } from './modules/basic/application-basic.component';


const appRoutes: Routes = [
    // pre-login routes
    { path: 'disclaimer', component: DisclaimerComponent, canActivate: [AuthRoute] },
    { path: 'options', component: OptionsComponent, canActivate: [AuthRoute] },
    { path: 'privacy', component: PrivacyComponent, canActivate: [AuthRoute] },
    { path: 'apply', component: ApplyComponent, canActivate: [AuthRoute] },
    // non-state routes
    { path: 'register', component: RegisterComponent }, // Note: external registration success redirects here
    { path: 'login', component: LoginComponent }, // Note: external login success redirects here
    // post-login routes
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'editcontact', component: EditContactComponent, canActivate: [AuthGuard] },
    { path: 'application', component: ApplicationComponent, canActivate: [AuthGuard] },
    { path: 'application/:ref', component: ApplicationComponent, canActivate: [AuthGuard] },
    { path: 'results', component: SubmissionResultsComponent, canActivate: [AuthGuard] },

    //error
    { path: 'error', component: ErrorComponent, canActivate: [AuthRoute] },

    // support/
    { path: 'support', loadChildren: './modules/support/support.module#SupportModule' },

    //landing
    {path: 'landing', component: LandingComponent, canActivate: [AuthGuard]},
    {path: 'application-basic', component: ApplicationBasicComponent, canActivate: [AuthGuard]},
    {path: 'application-basic/:ref', component: ApplicationBasicComponent, canActivate: [AuthGuard]},

    // defaulting route
    { path: '', component: DisclaimerComponent, pathMatch: 'full' }, // default page
    { path: '**', redirectTo: '' } // determine via route/guard // todo? page not found?


];

export const routing = RouterModule.forRoot(appRoutes, { useHash: false });
