import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// Base Classes
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';

// Components
import { MedicareCardComponent } from './partials/medicare-card/medicare-card.component';
import { CentrelinkCardComponent } from './partials/centrelink-card/centrelink-card.component';
import { DriverLicenceComponent } from './partials/driver-licence/driver-licence.component';

// Utils
import { util } from 'src/app/shared/util/util';
import { formUtil } from 'src/app/shared/util/form-util';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationService } from '../../application.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';

// Constants
import { ApplicationStep } from '../../application-step.enum';
import { apis } from 'src/app/shared/constants/apis';

// Models
import { Submission } from 'src/app/models/submission.model';
import { SubmissionPersonModel } from 'src/app/models/submission-person.model';
import { PersonDriverLicenceModel } from 'src/app/models/person-driver-licence.model';
import { PersonMedicareModel } from 'src/app/models/person-medicare.model';
import { DriverLicenceFormModel } from './partials/driver-licence/driver-licence-form.model';
import { MedicareCardFormModel } from './partials/medicare-card/medicare-card-form.model';
import { CenterlinkCardFormModel } from './partials/centrelink-card/centerlink-card-form.model';
import { DVSQueueRequestModel } from 'src/app/models/dvs-queue-request.model';


@Component({
  selector: 'app-id-check',
  templateUrl: './id-check.component.html',
  styleUrls: ['./id-check.component.scss']
})
export class IdCheckComponent extends BaseApplicationStep implements OnInit {

  // ---------------------------------------------------------------------------
  //    Properties
  // ---------------------------------------------------------------------------

  titleIdentity = 'Proof of identity';
  enableChildValidation = false;

  // DVS queue request and types
  dvsQueue: DVSQueueRequestModel[] = [];
  queueTypeDL = 'DriverLicence';
  queueTypeMC = 'MedicareCard';

  // ---------------
  //  Child Components

  centerlinkCardComponent: CentrelinkCardComponent;
  driverLicenceComponent: DriverLicenceComponent;
  medicareCardComponent: MedicareCardComponent;

  private driverLicenceFormModel: DriverLicenceFormModel = null;
  private medicareFormModel: MedicareCardFormModel = null;
  private centerlinkCardFormModel: CenterlinkCardFormModel = null;

  // ---------------
  //  Form

  public form: FormGroup;

  // ---------------
  //  Getters

  get f() { return this.form.controls; }
  get hasId() { return !this.f.hasNoId.value; }
  get showIdentityDocs() { return util.hasValue(this.f.hasNoId.value) && (!this.f.hasNoId.value === true); }
  get isDriverLicence_initialized() { return (this.driverLicenceComponent ? true : false); }
  get isMedicareCard_initialized() { return (this.medicareCardComponent ? true : false); }
  get isCenterlinkCard_initialized() { return (this.centerlinkCardComponent ? true : false); }

  // -----------------
  //  Method variables

  // Driver licence payload builder
  driverLicencePayload = (applicant: SubmissionPersonModel) => {
    return {
      state: applicant.driverLicence.licenceStateOfIssue,
      licenceNumber: applicant.driverLicence.licenceNumber,
      givenName: applicant.givenName,
      middleName: applicant.middleName,
      familyName: applicant.surname,
      licenceCardNumber: applicant.driverLicence.licenceCardNumber,
      dateOfBirth: util.getDateInFormat(applicant.dateOfBirth, 'yyyy-MM-dd')
    };
  }

  // ---------------------------------------------------------------------------
  //    Constructor
  // ---------------------------------------------------------------------------

  constructor(
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.Dependants, appSvc, submissionService, authService, scrollerService);
    this.pageApiUrl = apis.submissionPersons.saveApplicantDocuments;
  }

  // ---------------------------------------------------------------------------
  //    Methods
  // ---------------------------------------------------------------------------


  // -------------------------------------
  //  Events

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      hasNoId: ['', Validators.required]
    });

    // attach value change listeners
    this.f.hasNoId.valueChanges.subscribe(
      (hasNoId: boolean) => {

        // changing to - NO identity documents -  hides questions for documents. Set all document's yes/no question to false in order to turn off validation
        // changing to - YES - shows questions for documents. Un check all document's yes/no question to force user to make a selection

        // if hasID then turn on driver licence validation
        this.enableChildValidation = (hasNoId === false);

        // Reset documents' model to empty
        if (this.isDriverLicence_initialized) {
          this.driverLicenceFormModel = new DriverLicenceFormModel();
          util.mapTo(this.driverLicenceFormModel, this.model.applicant);
          this.driverLicenceComponent.bindModelToForm(this.driverLicenceFormModel);
          formUtil.validation.toggleRequired(this.driverLicenceComponent.f.hasDriverLicence, this.enableChildValidation);
        }
        if (this.isMedicareCard_initialized) {
          this.medicareFormModel = new MedicareCardFormModel();
          this.medicareCardComponent.bindModelToForm(this.medicareFormModel);
          formUtil.validation.toggleRequired(this.medicareCardComponent.f.hasMedicareCard, this.enableChildValidation);
        }
        if (this.isCenterlinkCard_initialized) {
          this.centerlinkCardFormModel = new CenterlinkCardFormModel();
          this.centerlinkCardComponent.bindModelToForm(this.centerlinkCardFormModel);
          formUtil.validation.toggleRequired(this.centerlinkCardComponent.f.hasCentrelinkCard, this.enableChildValidation);
        }


      }
    );
  }

  onComponentInitialized_driverLicence(driverLicence: DriverLicenceComponent) {
    this.driverLicenceComponent = driverLicence;
    // if the data was previously set for this child component then bind it to the component's form
    if (this.driverLicenceFormModel) {

      this.driverLicenceFormModel.licenceGivenName = this.model.applicant.givenName;
      this.driverLicenceFormModel.licenceMiddleName = this.model.applicant.middleName;
      this.driverLicenceFormModel.licenceSurname = this.model.applicant.surname;

      util.mapTo(this.driverLicenceFormModel, this.model.applicant);
      this.driverLicenceComponent.bindModelToForm(this.driverLicenceFormModel);
    }
  }

  onComponentInitialized_medicareCard(medicareCard: MedicareCardComponent) {
    this.medicareCardComponent = medicareCard;
    // if the data was previously set for this child component then bind it to the component's form
    if (this.medicareFormModel) {
      this.medicareCardComponent.bindModelToForm(this.medicareFormModel);
    }
  }

  onComponentInitialized_centrelinkCard(centrelinkCard: CentrelinkCardComponent) {
    this.centerlinkCardComponent = centrelinkCard;
    // if the data was previously set for this child component then bind it to the component's form
    if (this.centerlinkCardFormModel) {
      this.centerlinkCardComponent.bindModelToForm(this.centerlinkCardFormModel);
    }
  }

  // -------------------------------------
  //  Base Class Methods impementations


  // NOTE: This parent bindModelToForm method runs before the child components are initialized.
  //        So we can't call bindModelToForm methods of children inside it
  //        To bind data to child components:
  //        1. first bind data here inside bindModelToForm of the parent to child form models that are declared inside parent
  //        2. then inside onComponentInitialized_[componentName] method, call [componentName].bindModelToForm(formModel)
  //            passing the form Model for that child
  bindModelToForm(): void {

    formUtil.mapModelToForm(this.model.applicant, this.f);

    if (this.model.applicant.hasNoId) {
      this.driverLicenceFormModel = new DriverLicenceFormModel();
      this.medicareFormModel = new MedicareCardFormModel();
      this.centerlinkCardFormModel = new CenterlinkCardFormModel();
    }

    // -----------------------
    //  Driver Licence

    if (!this.driverLicenceFormModel || (this.driverLicenceFormModel.licenceSurname == "" && this.driverLicenceFormModel.licenceNumber == null)) {
      this.driverLicenceFormModel = new DriverLicenceFormModel();
      if(this.model.applicant.driverLicence !== null){
      this.model.applicant.driverLicence.licenceGivenName = this.model.applicant.givenName;
      this.model.applicant.driverLicence.licenceMiddleName = this.model.applicant.middleName;
      this.model.applicant.driverLicence.licenceSurname = this.model.applicant.surname;

      util.mapTo(this.driverLicenceFormModel, this.model.applicant);
      util.mapTo(this.driverLicenceFormModel, this.model.applicant.driverLicence);
      if (this.driverLicenceComponent !== undefined) {
        this.driverLicenceComponent.bindModelToForm(this.driverLicenceFormModel);
      }
    }
    // console.log(this.driverLicenceFormModel.licenceNumber);
    }

    // ----------------------
    //  Medicare Card

    if (!this.medicareFormModel || (this.medicareFormModel.medicareNameLine1 == "" && this.medicareFormModel.medicareNumber == null)) {
      this.medicareFormModel = new MedicareCardFormModel();
      util.mapTo(this.medicareFormModel, this.model.applicant);
      util.mapTo(this.medicareFormModel, this.model.applicant.medicare);
      if (this.medicareCardComponent != undefined) {
        this.medicareCardComponent.bindModelToForm(this.medicareFormModel);
      }
      // if name line is empty, initialize it from applicant details
      if (!util.hasValue(this.medicareFormModel.medicareNameLine1)) {
        this.medicareFormModel.medicareNameLine1 =
          `${this.model.applicant.givenName} ${this.model.applicant.middleInitial || ''} ${this.model.applicant.surname}`;
      }
    }

    // -----------------------------
    //  Centerlink Card

    if (!this.centerlinkCardFormModel || this.centerlinkCardFormModel.centrelinkNumber == null) {
      this.centerlinkCardFormModel = new CenterlinkCardFormModel();
      util.mapTo(this.centerlinkCardFormModel, this.model.applicant);
      if (this.centerlinkCardComponent != undefined) {
        this.centerlinkCardComponent.bindModelToForm(this.centerlinkCardFormModel);
      }
    }

  }

  bindFormToModel(): void {

    const formModel = this.form.getRawValue();

    this.model.applicant.hasNoId = formModel.hasNoId;


    // -------------
    //    Medicare   - If applicant has a medicare card, add to application and queue to be validated
    // -------------

    if (this.isMedicareCard_initialized && this.medicareCardComponent.hasMedicareCard) {

      this.model.applicant.hasMedicareCard = true;

      if (this.model.applicant.medicare == null) {
        this.model.applicant.medicare = new PersonMedicareModel();
      }

      util.mapTo(this.model.applicant.medicare, this.medicareCardComponent.model, true);

      const medicareModel = this.model.applicant.medicare;

      // Add payload to the queue for DVS validation
      this.dvsQueue.push({
        LocalReference: '',
        QueueReference: '',
        Type: this.queueTypeMC,
        Payload: {
          cardColour: medicareModel.medicareColour,
          cardNumber: medicareModel.medicareNumber,
          individualNumber: medicareModel.medicareIndividualNumber,
          expiryDate: util.getExpiryDateForMedicareCardByCard(medicareModel.medicareExpiryDate, medicareModel.medicareColour),
          multilineName: medicareModel.medicareNameMultilined,
          nameLine1: medicareModel.medicareNameLine1,
          dateOfBirth: util.getDateInFormat(this.model.applicant.dateOfBirth, 'yyyy-MM-dd')
        }
      });
    } else {
      this.model.applicant.hasMedicareCard = false;
      this.model.applicant.medicare = new PersonMedicareModel();
    }

    // ------------
    //    Licence    - If applicant has a driver licence, add to application and queue to be validated
    // ------------


    if (this.isDriverLicence_initialized && this.driverLicenceComponent.hasDriverLicence) {
      this.model.applicant.hasDriverLicence = true;

      if (this.model.applicant.driverLicence == null) {
        this.model.applicant.driverLicence = new PersonDriverLicenceModel();

      }

      util.mapTo(this.model.applicant.driverLicence, this.driverLicenceComponent.model, true);



      const driverLicenceModel = this.model.applicant.driverLicence;

      // Add payload to the queue for DVS validation
      this.dvsQueue.push({
        LocalReference: '',
        QueueReference: '',
        Type: this.queueTypeDL,
        Payload: this.driverLicencePayload(this.model.applicant)
      });
    } else {
      this.model.applicant.hasDriverLicence = false;
      this.model.applicant.driverLicence = new PersonDriverLicenceModel();
    }

    // --------------------
    //    Centerlink Card    - If applicant has a centrelink card, add to application - no validation available for this
    // --------------------

    if (this.isCenterlinkCard_initialized && this.centerlinkCardComponent.hasCentrelinkCard) {
      this.model.applicant.hasCentrelinkCard = true;
      this.model.applicant.centrelinkNumber = this.centerlinkCardComponent.model;
    } else {
      this.model.applicant.hasCentrelinkCard = false;
      this.model.applicant.centrelinkNumber = null;
    }

    // Set not to integrate Submission with CRM
    this.model.integrateWithCRM = false;

    // ASYNC:
    this.submissionService.verifyID(this.dvsQueue).subscribe();

    // --------------------
    // Construct page model that will get sent to page api

    this.pageModel = this.model.applicant;

  }


  // Used for validation of child control forms and returning the validation status of all child controls combined.
  // It is necessary to do this becaue child forms are not included inside parent formgroup. THey are disconected.
  wizStep_onValidate(): boolean {

    // this is done to negate validation because user has changed their mind after entering DL details
    if (this.enableChildValidation === false) {
        this.driverLicenceFormModel = new DriverLicenceFormModel();
        this.medicareFormModel = new MedicareCardFormModel();
        this.centerlinkCardFormModel = new CenterlinkCardFormModel();
      return true;
    }

    // ----------------------
    // if children components are initialized then check their validity

    if (this.isDriverLicence_initialized) {
      // update main form status to dirty if child controls have been changed
      if (this.driverLicenceComponent.form.dirty || this.medicareCardComponent.form.dirty || this.centerlinkCardComponent.form.dirty) {
        this.form.markAsDirty();
      }

      const isDLValid = this.driverLicenceComponent.validate();
      const isMedicareValid = this.medicareCardComponent.validate();
      const isCenterlinkCardValid = this.centerlinkCardComponent.validate();

      // return the combined validity of all child controls
      return (isDLValid && isMedicareValid && isCenterlinkCardValid);

      // -----------------------
      // if no children return true;

    } else {

      return true;
    }
  }

  onClick_Next() {

    this.hasAttemptedToProgress = true;

    if (this.isFormValid) {

      // ASYNC:
      this.saveChanges(true, true, 'Updating applicant ID document details ...');
    }
  }
  wizStep_onNotSave() {
    // GOTO:
    this.appService.goToNextStep();
  }
  wizStep_onSaveSuccess(r: Submission) {
    // update clientside submission model with changes from serverside
    this.model.applicant = r.applicant;
    this.model.grants = r.grants;
    // GOTO:
    this.appService.goToNextStep();
  }

}
