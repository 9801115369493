import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  referer: string = null;
  privacyForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.privacyForm = this.formBuilder.group({
    });
    this.referer = document.referrer;

  }

  onSubmit() {
    this.router.navigateByUrl('options');
  }

}
