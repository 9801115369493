import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';

// Services
import { LookupService } from 'src/app/services/lookup.service';

// Models
import { GrantEshaModel } from 'src/app/models/grant.model';

// Validators
import { customFunctionValidator } from 'src/app/shared/validators/customFunction-validator.directive';

// Constants
import { localData } from 'src/app/shared/storage/local-data';
import { BaseSubStep } from 'src/app/shared/base-classes/base-sub-step';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { LookupListItem } from 'src/app/models/lookup-list-item.model';

@Component({
  selector: 'app-impact-assessment-esha',
  templateUrl: './impact-assessment-esha.component.html',
  styleUrls: ['./impact-assessment-esha.component.scss']
})
export class ImpactAssessmentEshaComponent extends BaseSubStep implements OnInit {


  // ------------------------------------------
  //    Properties
  // ------------------------------------------

  titleEsha = 'Impact Assessment for Essential Service Hardship Assistance Grant';
  @Input() eventId: string = null;

  lookup_services: any[] = [];

  isLoading_essentialServices = false;

  form: FormGroup = this.fb.group({
    impactedServices: this.fb.array([])
  });

  model: GrantEshaModel;

  // ------------
  //  Validators

  private validator_atLeastOneServiceSelected = customFunctionValidator((): boolean => {
    let isValid = false;
    this.impactedServicesCtrls.forEach(s => {
      if (s.value === true) {
        isValid = true;
      }
    });
    return isValid;
  }, 'noServicesSelected');

  // ----------
  //  Getters

  get impactedServicesCtrls() { return (this.f.impactedServices as FormArray).controls; }

  get impactedServices_atLeastOneSelected() {
    this.impactedServicesCtrls.forEach(s => {
      if (s.value === true) {
        return true;
      }
    });
    return false;
  }

  // ------------------------------------------
  //    Constructor
  // ------------------------------------------

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    private spinner: NgxSpinnerService,
    scrollerService: ScrollerService
  ) {
    super('Esha', scrollerService);
    this.componentInitialized = new EventEmitter<ImpactAssessmentEshaComponent>();
  }


  // ------------------------------------------
  //    Methods
  // ------------------------------------------

  ngOnInit() {
    this.loadLookup_essentialServices();
  }

  private loadLookup_essentialServices() {
      this.isLoading_essentialServices = true;
      this.lookupService.getEssentialServices(this.eventId).subscribe(
        // On Success
        r => {
          this.lookup_services = r;
          this.initForm();
        },
        // On Error
        error => { },
        // On Complete
        () => {
          this.isLoading_essentialServices = false;
        }
      );
  }

  initForm() {
    this.form = this.fb.group({
      impactedServices: this.fb.array(this.lookup_services.map(s => new FormControl(false)))
    });

    this.f.impactedServices.setValidators([this.validator_atLeastOneServiceSelected]);

    // Emit the component to its parents
    this.componentInitialized.emit(this);
  }


  private getSelectedServices() {
    const selectedServices: LookupListItem[] = [];
    for (let i = 0; i < this.impactedServicesCtrls.length; i++) {
      const isSelected = this.impactedServicesCtrls[i].value;
      if (isSelected) {
        const service = this.lookup_services[i] as LookupListItem;
        selectedServices.push(service);
      }
    }
    return selectedServices;
  }

  bindModelToForm(model: GrantEshaModel) {

    this.model = model || new GrantEshaModel();

    // map impacted services
    if (this.model.impactedServices) {
      this.model.impactedServices.forEach(s => {
        for (let i = 0; i < this.lookup_services.length; i++) {
          const lookupItem = this.lookup_services[i];
          if (s.id === lookupItem.id) {
            this.impactedServicesCtrls[i].setValue(true);
          }
        }
      });
    }
  }

  bindFormToModel(): void {
    this.model.impactedServices = this.getSelectedServices();
  }

}
