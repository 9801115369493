import { FormGroup } from '@angular/forms';
import { formUtil } from '../util/form-util';
import { Output, OnInit, EventEmitter, Input } from '@angular/core';

export abstract class BaseChildForm implements OnInit {


    // ---------------------------------------------------------------------------
    //        Properties
    // ---------------------------------------------------------------------------


    // -------------------------
    //  IO

    @Input() componentId;
    @Output() componentInitialized;


    // -------------------------
    //  Other

    /**
     * MAIN FORM - Main form of the wizard step. MUST IMPLEMENT in component that extends from this abstract class
     */
    public abstract form: FormGroup;


    hasAttemptedToProgress = false;


    // ---------------------------------------------------------------------------
    //        Constructor
    // ---------------------------------------------------------------------------


    constructor() { }


    // ---------------------------------------------------------------------------
    //    Methods
    // ---------------------------------------------------------------------------


    // -------------------------------------
    //  Events

    ngOnInit() {

        this.initForm();

        // Emit the component to its parents
        this.componentInitialized.emit(this);
    }

    public abstract initForm();

    protected abstract bindModelToForm(model);


    /**
     * Get unique elementid for the current component instance.
     * This is necessary because there could be multiple instances of this child control in the same page
     * and then they start mixing up because the ids are not unique
     *
     * @param elementId
     */
    getId(elementId: string) { return `${elementId}_${this.componentId}`; }
    

    // -------------------------------------
    //  Validation

    /**
     * Marks all form fields as touched which highlights them if they are invalid.
     * Returns validity flag of the form
     */
    validate(isAttemptingToProgress: boolean = true) {
        // if the form is attempting to progress then set the flag for progress attempted
        if (isAttemptingToProgress === true) {
            this.hasAttemptedToProgress = true;
        }
        //
        if (this.form.dirty && !this.form.valid) {
            formUtil.validation.markAsTouched_allFormFields(this.form);
        }
        return this.form.valid;
    }

    getInputCssClass(controlName: string) {
        const control = this.form.controls[controlName];
        return formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
    }

    getInputGroupCssClass(controlName: string) {
        const control = this.form.controls[controlName];
        return formUtil.validation.getCssClass_invalidInputGroup(control, this.hasAttemptedToProgress);
    }

}

