import { ValidatorFn, FormArray } from '@angular/forms';

/**
 *
 * @example USAGE:
 *      <div class="invalid-feedback"
 *          *ngIf="myFormArray.errors?.noneSelected" >
 *          You must check at least one option.
 *      </div>
 */
export function isAtLeastOneSelectedValidator(): ValidatorFn {
    return (formArray: FormArray): { [key: string]: any } | null => {
        let isAtLeastOneSelected = false;
        formArray.controls.forEach(ctrl => {
          if (ctrl.value == true) { isAtLeastOneSelected = true; }
        });
        return (isAtLeastOneSelected ? null : { noneSelected: true });
    };
}
