import { AbstractControl, ValidatorFn } from '@angular/forms';
import { util } from '../util/util';
import { enums } from '../enums/enums';

/**
 * @param {boolean} includeToday Optional - pass true to validate if date is today or in past
 * @example USAGE:
 *      <div class="invalid-feedback"
 *          *ngIf="myFormControl.errors?.dateIsNotInPast" >
 *          Date must be in the past
 *      </div>
 */
export function dateIsInPastValidator(includeToday: boolean = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            const selectedDate = new Date(util.getDateInFormat(control.value, enums.dateFormat.hyphen));
            const today = new Date(util.getDateInFormat((new Date), enums.dateFormat.hyphen));
            const isValid = (includeToday ? selectedDate <= today : selectedDate < today);
            return (isValid ? null : { dateIsNotInPast: true });
        } else { return null; }
    };
}
