﻿import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

// Base Classes
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';

// Constants
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { enums } from 'src/app/shared/enums/enums';
import { ApplicationStep } from '../../application-step.enum';

// Utils
import { util } from 'src/app/shared/util/util';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { AuthService } from 'src/app/services/auth.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { ApplicationService } from '../../application.service';

// Models
import { Address} from 'src/app/models/submission.model';
import { SupportReferralSubmissionModel } from 'src/app/models/support-referral.model';
import { SubmissionPersonModel } from 'src/app/models/submission-person.model';


@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent extends BaseApplicationStep implements OnInit {


  // ---------------------------------------------------
  //    Properties
  // ---------------------------------------------------


  // -------------------------------
  //    Strings

  titlePreview = 'Final Submission Preview';
  titleApplicant = 'Applicant';
  titleYourDetails = 'Your Details';
  titleContactDetails = 'Contact Details';
  titleAddress = 'Impacted Address';
  titlePartnerAndDependants = 'Partner and Dependants';
  titleGrants = 'Grants';
  titleSupportReferral = 'Further Information';

  private dateFormat = 'dd MMM yyyy';
  private medicareDateFormat = 'MM yyyy';

  // -------------------------------
  //    Other


  form: FormGroup = this.fb.group({});

  show = false;

  isCompletedSubmission = false;

  submitted = false;
  openedApplicant: Boolean = true;
  openedAddress: Boolean = true;
  openedGrants: Boolean = true;
  openedPartnerAndDependants: Boolean = true;
  openedSupportReferral: Boolean = true;

  otherSupportReferrals: SupportReferralSubmissionModel[] = [];
  individualSupportReferrals: SupportReferralSubmissionModel[] = [];
  financialCounsellingReferrals: SupportReferralSubmissionModel[] = [];

  cultureList: string[] = [];
  cultureDoNotDisclose: string;


  dateOfImpact: string;
  medicareExpiry: string;
  licenceExpiry: string;
  psychologicalList: string;
  impactedAddress = new Address();
  temporaryAddress = new Address();
  otherAddress = new Address();
  postalAddressType: string;
  paymentOption: string;

  // -------------------------------
  //    Lookups


  lookup_cultures = lookupLists.culturalGroups;
  lookup_supportOtherGroups = lookupLists.support_other;
  lookup_paymentOptions = lookupLists.paymentOptions;

  // -------------------------------
  //    Getters


  get f() { return this.form.controls; }



  get interpreterLanguageSpoken() {
    const lang = this.lookup_languages.find(item => item.id === this.model.interpreterLanguageSpoken);
    return lang ? lang.name : '';
  }
  get isStrandedTraveller() { return this.getYesNo(this.model.isStrandedTraveller === true); }
  get isEvacuated() { return this.getYesNo(this.model.isEvacuated === true); }


  get requiresAHomeVisit() { return (this.model.requiresAHomeVisit ? this.getYesNo(this.model.requiresAHomeVisit) : ''); }
  get isOtherPropertyPOBox(): boolean { return Number(this.otherAddress.propertyTypeId) === enums.propertyTypes.POBox; }
  get preferredVisitTime() {
    const preferredTime_lookupItem =
      (this.model.preferredVisitTime ? lookupLists.preferredVisitTime.find(t => t.id === Number(this.model.preferredVisitTime)) : null);
    const preferredTime = (preferredTime_lookupItem ? preferredTime_lookupItem.name : '');
    return preferredTime;
  }

  get hasImpactedDescription() {
    return this.model.impactDescription !== null && this.model.impactDescription.trim() !== '';
  }

  get showFurtherInformation() {
    return this.otherSupportReferrals.length > 0 || this.individualSupportReferrals.length > 0 ||
    this.financialCounsellingReferrals.length > 0 || this.requiresAHomeVisit || this.hasImpactedDescription;
  }

  get isTemporaryInAustralia() {
    return this.temporaryAddress.country === 'Australia';
  }

  get isOtherInAustralia() {
    return this.otherAddress.country === 'Australia';
  }

  get applicationStep() {
    return ApplicationStep;
  }

  get impactAssessmentTypes() {
    return enums.impactAssessmentTypes;
  }

  // ---------------------------------------------------
  //    Constructor
  // ---------------------------------------------------

  constructor(
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.Preview, appSvc, submissionService, authService, scrollerService);
  }

  // ---------------------------------------------------
  //    Methods
  // ---------------------------------------------------

  ngOnInit() { }



  getPersonName(person: SubmissionPersonModel) {
    const title = person.title;
    const givenName = person.givenName;
    const middleName = (person.middleName ? person.middleName : '');
    const surname = person.surname;
    return `${title} ${givenName} ${middleName} ${surname}`;
  }
  getPersonGender(person: SubmissionPersonModel) {
    const gender = lookupLists.genders.find(g => g.value === person.genderId);
    return (gender ? gender.name : '');
  }
  getPersonDOB(person: SubmissionPersonModel) { return util.getDateInFormat(person.dateOfBirth, this.dateFormat); }
  getRelationshipToApplicant(person: SubmissionPersonModel) {
    const relationship = lookupLists.relationshipToApplicant.find(g => g.value === person.relationshipToApplicantId);
    return (relationship ? relationship.name : '');
  }

  getYesNo(isTrue: boolean) { return isTrue ? 'Yes' : 'No'; }

  getGeoState(id) {
    if (id) {
      const state = lookupLists.australianStates.find(s => s.value == id);
      return (state ? state.name : '');
    }
    return;
  }

  getCultures(): string[] {
    const cultures: string[] = [];
    for (let i = 0; i < this.model.cultures.length; i++) {
      const cultureModel = this.model.cultures[i];
      if (cultureModel) {
        const cultureId = cultureModel.cultureId;
        const culture = this.lookup_cultures.find(c => c.id === cultureId);
        const isOther = (cultureId === 4);
        const isDoNotDisclose = (cultureId === 5);
        if (isDoNotDisclose) {
          this.cultureDoNotDisclose = 'Do Not Disclose';
        } else if (isOther) {
          cultures.push(cultureModel.name);
        } else {
          if (culture) {
            cultures.push(culture.name);
          }
        }
      }
    }
    return cultures;
  }


  toggleApplicant() {
    this.openedApplicant = !this.openedApplicant;
  }

  toggleAddress() {
    this.openedAddress = !this.openedAddress;
  }

  toggleGrants() {
    this.openedGrants = !this.openedGrants;
  }

  togglePartnerAndDependants() {
    this.openedPartnerAndDependants = !this.openedPartnerAndDependants;
  }

  toggleSupportReferral() {
    this.openedSupportReferral = !this.openedSupportReferral;
  }

  goToStep(stepIndex: ApplicationStep) {
    this.appService.goToStep(stepIndex);
    return false;
  }

  goToSubStep(stepIndex: ApplicationStep, grantType: string) {

    this.model.steps.forEach((modelStep, i) => {

      if (modelStep.type == grantType) {
        modelStep.show = true;
      } else {
        modelStep.show = false;
      }
    });

    this.appService.goToStep(stepIndex);
    return false;
  }


  bindModelToForm(): void {

    // There are many changes to the model that the API can update such as document validity and grant eligibility.
    // Therefore retrieve the lastest data

    const hasActivatedGrantsAvailable = this.model.hasActivatedGrantsAvailable;
    const steps = this.model.steps;
    const impactDescription = this.model.impactDescription;
    this.submissionService.getSubmissionById(this.model.submissionId).subscribe(
      data => {
        // This is client side only it should not be overwritten.
        util.mapTo(this.model.grants, data.grants);
        this.model.hasActivatedGrantsAvailable = hasActivatedGrantsAvailable;
        this.model.steps = steps;
        this.model.impactDescription = impactDescription;
      },
      error => {
        // todo: handle - could not retrieve submissions
      }
    );

    this.isCompletedSubmission = this.submissionService.isCompletedSubmission;

    if (this.model.cultures) {
      this.cultureList = this.getCultures();
    }

    this.dateOfImpact = util.getDateInFormat(this.model.dateOfImpact, this.dateFormat);

    if (this.model.applicant && this.model.applicant.medicare != null) {
      if (this.model.applicant.medicare.medicareExpiryDate != null) {
        this.medicareExpiry = util.getDateInFormat(this.model.applicant.medicare.medicareExpiryDate, this.medicareDateFormat);
      }
    }

    if (this.model.applicant && this.model.applicant.driverLicence != null) {
      if (this.model.applicant.driverLicence.licenceExpiryDate != null) {
        this.licenceExpiry = util.getDateInFormat(this.model.applicant.driverLicence.licenceExpiryDate, this.dateFormat);
      }
    }

    // payment option
    if (this.model.grants && this.model.grants.length) {
      const grant = this.model.grants[0];
      const grantPaymentOption = (grant ? grant.paymentOption : '');
      const paymentOption = this.lookup_paymentOptions.find(c => c.id === grantPaymentOption);
      this.paymentOption = (paymentOption ? paymentOption.name : '');
    }

    // Support Referral
    if (this.model.supportReferrals) {
      this.otherSupportReferrals =
        this.model.supportReferrals.filter(r => r.category === enums.supportReferralCategories.otherSupport);
      this.individualSupportReferrals =
        this.model.supportReferrals.filter(r => r.category === enums.supportReferralCategories.individualSupport);
      this.financialCounsellingReferrals =
        this.model.supportReferrals.filter(r => r.category === enums.supportReferralCategories.financialCounselling);
    }


    this.model.addresses.forEach(address => {
      if (address.addressTypeId === enums.addressTypes.Impacted) {
        this.impactedAddress = address;
        this.postalAddressType = enums.addressTypes.Impacted + ' Address';

      } else if (
        address.addressTypeId === enums.addressTypes.Temporary
        || this.model.bestPostalAddressTypeId === enums.addressTypes.Temporary
      ) {
        this.temporaryAddress = address;
        this.postalAddressType = enums.addressTypes.Temporary + ' Address';

      } else {
        this.otherAddress = address;
        this.postalAddressType = enums.addressTypes.Other + ' Address';
      }

    });

    // show preview only after all data is loaded
    this.show = true;
  }


  bindFormToModel(): void {

  }



}

