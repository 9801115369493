
  export class PersonMedicareModel {
    personMedicareId?: number = null;
    medicareNameMultilined: boolean = null;
    medicareNameLine1: string = null;
    medicareNameLine2: string = null;
    medicareNameLine3: string = null;
    medicareNameLine4: string = null;
    medicareNumber: number = null;
    medicareIndividualNumber: number = null;
    medicareExpiryDate: Date = null;
    medicareColour: string = null;
    medicareQueueReference: string = null;
    medicareIsValid: boolean = null;
  
    constructor(obj: PersonMedicareModel = null) { if (obj) { Object.assign(this, obj); } }
  }