﻿import { ImpactAssessmentBasicComponent } from './modules/basic/impact-assessment-basic/impact-assessment-basic.component';
import { LandingComponent } from './pages/landing/landing.component';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { SharedBootstrapModule } from './shared/modules/shared-bootstrap.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ArchwizardModule } from 'angular-archwizard';
import { AlertModule, PopoverModule } from 'ngx-bootstrap';

// Core modules - header, footer, help links
import { CoreModule } from './core/core.module';
import { routing } from './app.routing';

// Interceptors
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';

// Directives
import { MatchValidation } from './shared/directives/match-value.directive';
import { MultiCheckboxValidation, AddressValidation } from './shared/directives/custom-validators.directive';
import { BSBMaskDirective } from './shared/directives/bsb-mask.directive';

// Services
import { AddressService } from './services/address.service';
import { GrantService } from './services/grant.service';
import { SupportReferralService } from './services/support-referral.service';
import { AuthService } from './services/auth.service';
import { BrowserNavService } from './services/browserNav.service';
import { PaymentMethodService } from 'src/app/pages/application/application-steps/payment-method/payment-method.service';

// Pages
import { AppComponent } from './app.component';
import { DisclaimerComponent } from 'src/app/pages/disclaimer/disclaimer.component';
import { LoginComponent } from 'src/app/pages/account/login/login.component';
import { PrivacyComponent } from 'src/app/pages/privacy/privacy.component';
import { ApplyComponent } from 'src/app/pages/apply/apply.component';
import { RegisterComponent } from 'src/app/pages/account/register/register.component';
import { OptionsComponent } from 'src/app/pages/options/options.component';

import { DashboardComponent } from 'src/app/pages/account/dashboard/dashboard.component';
import { EditContactComponent } from 'src/app/pages/account/dashboard/partials/edit-contact/edit-contact.component';
import { ApplicationsComponent } from 'src/app/pages/account/dashboard/partials/applications/applications.component';
import { ApplicationComponent } from 'src/app/pages/application/application.component';
import { SubmissionResultsComponent } from 'src/app/pages/submission-results/submission-results.component';

// -----------------------------
//  Application-step components

import { ApplicantDetailsComponent } from 'src/app/pages/application/application-steps/applicant-details/applicant-details.component';
import { AddressDetailsComponent } from 'src/app/pages/application/application-steps/address-details/address-details.component';
import { WizardNavButtonsComponent } from './shared/components/wizard-nav-buttons/wizard-nav-buttons.component';
import { DependantsComponent } from 'src/app/pages/application/application-steps/dependants/dependants.component';
import { PreviewComponent } from 'src/app/pages/application/application-steps/preview/preview.component';
// tslint:disable-next-line:max-line-length
import { DriverLicenceComponent } from 'src/app/pages/application/application-steps/id-check/partials/driver-licence/driver-licence.component';
import { MedicareCardComponent } from 'src/app/pages/application/application-steps/id-check/partials/medicare-card/medicare-card.component';
// tslint:disable-next-line:max-line-length
import { CentrelinkCardComponent } from 'src/app/pages/application/application-steps/id-check/partials/centrelink-card/centrelink-card.component';
// tslint:disable-next-line:max-line-length
import { HouseholdIncomeTestComponent } from 'src/app/pages/application/application-steps/impact-assessment/partials/household-income-test/household-income-test.component';
import { EventSelectionComponent } from './pages/application/application-steps/event-selection/event-selection.component';
import { InitialOutcomeComponent } from 'src/app/pages/application/application-steps/initial-outcome/initial-outcome.component';
// tslint:disable-next-line:max-line-length
import { SubstepForwardBackButtonsComponent } from './shared/components/substep-forward-back-buttons/substep-forward-back-buttons.component';
import { ImpactAssessmentComponent } from 'src/app/pages/application/application-steps/impact-assessment/impact-assessment.component';
// tslint:disable-next-line:max-line-length
import { ImpactAssessmentEhcgComponent } from 'src/app/pages/application/application-steps/impact-assessment/partials/impact-assessment-ehcg/impact-assessment-ehcg.component';
// tslint:disable-next-line:max-line-length
import { ImpactAssessmentEshaComponent } from 'src/app/pages/application/application-steps/impact-assessment/partials/impact-assessment-esha/impact-assessment-esha.component';
// tslint:disable-next-line:max-line-length
import { ImpactAssessmentEssrsComponent } from 'src/app/pages/application/application-steps/impact-assessment/partials/impact-assessment-essrs/impact-assessment-essrs.component';
// tslint:disable-next-line:max-line-length
import { ImpactAssessmentSagComponent } from 'src/app/pages/application/application-steps/impact-assessment/partials/impact-assessment-sag/impact-assessment-sag.component';
import { ImpactDescriptionComponent } from 'src/app/pages/application/application-steps/impact-description/impact-description.component';
import { DeclarationComponent } from 'src/app/pages/application/application-steps/declaration/declaration.component';
import { SupportReferralComponent } from 'src/app/pages/application/application-steps/support-referral/support-referral.component';
// tslint:disable-next-line:max-line-length
import { ImpactAssessmentEhaComponent } from 'src/app/pages/application/application-steps/impact-assessment/partials/impact-assessment-eha/impact-assessment-eha.component';
import { PaymentMethodComponent } from 'src/app/pages/application/application-steps/payment-method/payment-method.component';
import { IdCheckComponent } from 'src/app/pages/application/application-steps/id-check/id-check.component';

// Util Components
import { MonthDatePickerComponent } from './shared/components/month-date-picker/month-date-picker.component';

import { ButtonGoToApplicantSupportComponent } from './_partials/button-go-to-applicant-support/button-go-to-applicant-support.component';;
import { ErrorComponent } from './pages/error/error.component'
import { ApplicantIdentityBasicComponent } from './modules/basic/applicant-identity-basic/applicant-identity-basic.component';
import { ApplicantDetailsBasicComponent } from './modules/basic/applicant-details-basic/applicant-details-basic.component';;
import { ApplicationBasicComponent } from './modules/basic/application-basic.component'
import { AddressDetailsBasicComponent } from './modules/basic/address-details-basic/address-details-basic.component';
import { PaymentMethodBasicComponent } from './modules/basic/payment-method-basic/payment-method-basic.component';;
import { InitialOutcomeBasicComponent } from './modules/basic/initial-outcome-basic/initial-outcome-basic.component'
;
import { PreviewBasicComponent } from './modules/basic/preview-basic/preview-basic.component'
;
import { DeclarationBasicComponent } from './modules/basic/declaration-basic/declaration-basic.component';
import { ApplicantPreviewBasicComponent } from './modules/basic/applicant-preview-basic/applicant-preview-basic.component';
@NgModule({
  declarations: [
    AppComponent,
    DisclaimerComponent,
    OptionsComponent,
    LoginComponent,
    PrivacyComponent,
    ApplyComponent,
    RegisterComponent,
    ApplicationComponent,
    ApplicantDetailsComponent,
    AddressDetailsComponent,
    IdCheckComponent,
    DriverLicenceComponent,
    WizardNavButtonsComponent,
    MonthDatePickerComponent,
    DependantsComponent,
    DashboardComponent,
    EditContactComponent,
    PaymentMethodComponent,
    MedicareCardComponent,
    CentrelinkCardComponent,
    InitialOutcomeComponent,
    EventSelectionComponent,
    ImpactAssessmentComponent,
    SubstepForwardBackButtonsComponent,
    ImpactAssessmentEhcgComponent,
    ImpactAssessmentEshaComponent,
    ImpactAssessmentEssrsComponent,
    ImpactAssessmentSagComponent,
    ImpactDescriptionComponent,
    DeclarationComponent,
    SupportReferralComponent,
    HouseholdIncomeTestComponent,
    ImpactAssessmentEhaComponent,
    SubmissionResultsComponent,
    ApplicationsComponent,
    PreviewComponent,
    BSBMaskDirective,
    ButtonGoToApplicantSupportComponent,
    ErrorComponent,
    LandingComponent,
    ApplicantDetailsBasicComponent,
    ApplicantIdentityBasicComponent ,
    ApplicationBasicComponent,
    ApplicationBasicComponent,
    AddressDetailsBasicComponent,
    PaymentMethodBasicComponent,
    ImpactAssessmentBasicComponent ,
    InitialOutcomeBasicComponent,
    DeclarationBasicComponent,
    PreviewBasicComponent,
    ApplicantPreviewBasicComponent],
  imports: [
    BrowserModule,
    FormsModule,
    CoreModule,
    ReactiveFormsModule,
    SharedBootstrapModule,
    routing,
    HttpClientModule,
    ArchwizardModule,
    NgxSpinnerModule,
    CommonModule,
    RouterModule,
    AlertModule.forRoot(),
    PopoverModule.forRoot()
  ],
  exports: [
    BSBMaskDirective
  ],
  providers: [
    AddressService,
    GrantService,
    SupportReferralService,
    PaymentMethodService,
    MatchValidation,
    AddressValidation,
    MultiCheckboxValidation,
    DatePipe,
    AuthService,
    BrowserNavService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
