import { FileUploadResultModel } from '../models/file-upload.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';


// Models
import { FileUploadModel } from '../models/file-upload.model';

// Base Classes
import { BaseApiService } from '../shared/base-classes/base-api-service';

// Constants
import { apis } from '../shared/constants/apis';

// Storage
import { environment } from 'src/environments/environment';
import { localData } from '../shared/storage/local-data';
import { LookupListItem } from '../models/lookup-list-item.model';



@Injectable({
  providedIn: 'root'
})
export class FileUploadBasicService extends BaseApiService {


  // ----------------------------
  //      Properties
  // ----------------------------
  API_URL = environment.APIUrl;

  fileuploadModel: FileUploadModel = null;

  

  /**
   * MAIN MODEL - Main fileupload model that gets passed from step to step of the wizard and form data is bound to it.
   */
  protected model: FileUploadModel;

  // ----------------------------
  //      Constructor
  // ----------------------------

  constructor(
    httpClient: HttpClient) {
    super(httpClient);
  }

  // ----------------------------
  //      Methods
  // ----------------------------

  // load file upload
  loadFile(filenameStartWith: string) {
    const url = this.getUrl(`${apis.fileUpload.list}/${filenameStartWith}`);
    return this.http.get<FileUploadResultModel[]>(url)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.handleError)
      );
  }

  // save file upload
  saveFile(fileupload: FileUploadModel) {
    return this.post<FileUploadResultModel>(apis.fileUpload.save, fileupload);
  }

  // delete file upload
  deleteFile(uriOfFile) { 
    const options = {
        headers: { 
            'Content-Type': 'application/json' 
        }
      };
    return this.post<FileUploadResultModel>(apis.fileUpload.delete, JSON.stringify(uriOfFile), options);
  }

}
