import { enums } from 'src/app/shared/enums/enums';
import { DatePartsModel } from 'src/app/models/date-parts.model';

export class MedicareCardFormModel {

    hasMedicareCard: boolean = null;
    personMedicareId?: number = null;
    medicareNameMultilined = false;
    medicareNameLine1 = '';
    medicareNameLine2 = '';
    medicareNameLine3 = '';
    medicareNameLine4 = '';
    medicareNumber: number = null;
    medicareIndividualNumber: number = null;
    medicareColour = enums.medicareCardColours.Green;
    medicareOtherExpiry: Date = null;
    medicareGreenExpiry: DatePartsModel = null;
    medicareExpiryDate: Date = null;

    constructor(obj: MedicareCardFormModel = null) { if (obj) { Object.assign(this, obj); } }

}
