import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubmissionService } from 'src/app/services/submission.service';
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';
import { formUtil } from 'src/app/shared/util/form-util';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { Router } from '@angular/router';
import { SubmissionOutcome } from 'src/app/models/submission.model';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationService } from '../../application.service';
import { ApplicationStep } from '../../application-step.enum';

@Component({
  selector: 'app-declaration',
  templateUrl: './declaration.component.html',
  styleUrls: ['./declaration.component.scss']
})
export class DeclarationComponent extends BaseApplicationStep implements OnInit {

  // -----------------------------
  //    Properties
  // -----------------------------

  public form: FormGroup;

  public titleDeclaration = 'Acknowledgements';

  ShowDefinitions: true;

  get f() { return this.form.controls; }

  // -----------------------------
  //    Constructor
  // -----------------------------

  constructor(
    private router: Router,
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.Declaration, appSvc, submissionService, authService, scrollerService);
  }

  // -----------------------------
  //    Methods
  // -----------------------------

  ngOnInit() {
    this.createform();
  }

  createform() {
    this.form = this.fb.group({
      hasDeclaredProvidedTrueCorrectInformation: [false, Validators.required]
    });
  }

  bindModelToForm(): void {
    formUtil.mapModelToForm(this.model, this.f);
  }

  bindFormToModel(): void {
    const formModel = this.form.getRawValue();
    this.model.hasDeclaredProvidedTrueCorrectInformation = formModel.hasDeclaredProvidedTrueCorrectInformation;
    // Set to integrate Submission with CRM
    this.model.integrateWithCRM = true;
  }

  wizStep_onSaveSuccess(r: SubmissionOutcome) {
    this.router.navigate(['/results'], { queryParams: { ref: r.referenceNumber } });
  }

  wizStep_onStepEnter() {
    this.hasAttemptedToProgress = false;
  }

}
