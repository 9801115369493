export const icons = {
    userAccount: 'fa fa-user-o fa-fw',
    users: 'fa fa-user-circle',
    applicants: 'fa fa-users',
    addUser: 'fa fa-user-plus',
    application: 'fa fa-file-text-o',
    audit: 'fa fa-history',
    search: 'fa fa-search',
    save: 'fa fa-floppy-o',
    sortAlfaAsc: 'fa fa-sort-alpha-asc',
    sortAlfaDesc: 'fa fa-sort-alpha-desc',
    close: 'fa fa-times',
    calendar: 'fa fa-calendar-o',
    phone: 'fa fa-phone',
    cog: 'fa fa-cog fa-fw',
    signOut: 'fa fa-sign-out fa-fw',
    table: 'fa fa-table',
    expand: 'fa fa-plus-square-o',
    collapse: 'fa fa-minus-square-o',
    chevronRight: 'fa fa-chevron-right',
    chevronDown: 'fa fa-chevron-down',
    success: 'fa fa-check',
    failed:  'fa fa-warning'
};
