﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

// Utils
import { util } from '../shared/util/util';

// Constants
import { environment } from '../../environments/environment';
import { apis } from '../shared/constants/apis';
import { sessionData } from '../shared/storage/session-data';
import { localData } from '../shared/storage/local-data';
import { enums } from '../shared/enums/enums';
import { routes } from 'src/app/shared/constants/routes';
import { routes as supportRoutes } from 'src/app/modules/support/_constants/routes';

// Services
import { BaseApiService } from '../shared/base-classes/base-api-service';
import { LookupService } from './lookup.service';

// Models
import { Submission, SubmissionOutcome } from '../models/submission.model';
import { DVSQueueRequestModel } from '../models/dvs-queue-request.model';
import { LookupListItem } from '../models/lookup-list-item.model';
import { SubmissionPersonModel } from '../models/submission-person.model';
import { UserSubmissionModel } from '../models/grant.model';
import { UserPersonModel } from '../models/user-person.model';
import { BrowserNavService } from './browserNav.service';


@Injectable({
  providedIn: 'root'
})
export class SubmissionService extends BaseApiService {

  // ----------------------------------------
  //    Properties
  // ----------------------------------------


  API_URL = environment.APIUrl;

  userSubmissions: UserSubmissionModel[];

  submissionModel: Submission = null;

  submissionUser: UserPersonModel;

  /**
   * used inside BaseApplicationStep to ingore loading if parent component Application has redirected back to dashboard
   */
  ignoreLoad = false;

  // ----------------
  //  Lookup Lists

  lookup_languages: LookupListItem[] = [];

  lookup_nameTitles: LookupListItem[] = [];

  lookup_propertyTypes: LookupListItem[] = [];

  lookup_addressTypes: LookupListItem[] = [];

  // ----------------
  //  Progress flags

  inProgress_gettingSubmissionData = false;

  inProgress_savingSubmissionData = false;


  // ---------------
  //  Getters

  get essentialGrantsText() { return enums.essentialGrantsText; }

  get submissionId() {
    const idFromSession = sessionData.submissionId();
    return Number(idFromSession ? idFromSession : 0);
  }
  get isSubmissionLoaded() { return (this.submissionModel ? true : false); }

  get isNewSubmission(): boolean { return this.submissionId === 0; }

  get isFirstSubmission(): boolean {

    // no other submissions
    if (!this.userSubmissions || this.userSubmissions.length === 0) { return true; }

    // new submission and there are others
    if (this.isNewSubmission) { return false; }

    // existing submission and there are others
    const minId =
      this.userSubmissions.reduce((min, s) => s.submissionId < min ? s.submissionId : min, this.userSubmissions[0].submissionId);
    return this.submissionId === minId;
  }

  get isCompletedSubmission() { return this.submissionModel.hasDeclaredProvidedTrueCorrectInformation; }

  get submissionUserId() {
    return this.submissionUser ? this.submissionUser.userId : null;
  }

  /** Describes if the current user is a support user filling in or viewing application on behalf of applicant */
  get isSupportUser_onBehalfOfApplicant() {
    const currentUserId = sessionData.user().userId;
    const submissionUserId = this.submissionUserId;
    return (submissionUserId !== null && submissionUserId !== currentUserId);
  }

  get supportUser_userId() { return (this.isSupportUser_onBehalfOfApplicant ? sessionData.user().userId : null); }

  get hasPendingSubmissions() {
    const hasSubmissions = this.userSubmissions && this.userSubmissions.length > 0;
    const pendingSubmissions = hasSubmissions ?
      this.userSubmissions.filter((s) => {
        return s.hasDeclaredProvidedTrueCorrectInformation !== true;
      }) :
      [];
    return (hasSubmissions && pendingSubmissions.length > 0);
  }

  get hasNonEssentialGrants() {
    var result = false;
    if (this.userSubmissions && this.userSubmissions.length > 0) {
      this.userSubmissions.forEach(us => {
        // us.name = null means Application with no Grants
        if (!Object.values(this.essentialGrantsText).includes(us.name) && us.name != null) {
          result = true;
        }
      });
    }

    return result;
  }

  // -------------
  //  Callbacks

  onSaveSuccess: Function;



  // ----------------------------------------
  //    Constructor
  // ----------------------------------------

  constructor(
    private httpClient: HttpClient,
    private lookupService: LookupService,
    private router: Router
  ) {
    super(httpClient);
    this.loadLookups();
  }


  // ----------------------------------------
  //    Methods
  // ----------------------------------------

  loadLookups() {

    // Languages
    this.lookup_languages = localData.lookups.languages();
    if (!this.lookup_languages || this.lookup_languages.length === 0) {
      this.lookupService.getLanguages().subscribe(r => { this.lookup_languages = r; });
    }

    // Name Titles
    this.lookup_nameTitles = localData.lookups.nameTitles();
    if (!this.lookup_nameTitles || this.lookup_nameTitles.length === 0) {
      this.lookupService.getNameTitles().subscribe(r => { this.lookup_nameTitles = r; });
    }

    // Property Types
    this.lookup_propertyTypes = localData.lookups.propertyTypes();
    if (!this.lookup_propertyTypes || this.lookup_propertyTypes.length === 0) {
      this.lookupService.getPropertyTypes().subscribe(r => { this.lookup_propertyTypes = r; });
    }

    // Address Types
    this.lookup_addressTypes = localData.lookups.addressTypes();
    if (!this.lookup_addressTypes || this.lookup_addressTypes.length === 0) {
      this.lookupService.getAddressTypes().subscribe(r => { this.lookup_addressTypes = r; });
    }

  }

  /**
   * Sets existing submission model to null.
   */
  clearSubmission() {
    sessionData.submissionId(null);
    this.submissionModel = null;
    this.ignoreLoad = false;
  }

  selectSubmission(submissionId: number, referenceNumber: number = null) {
    this.clearSubmission();
    if (!submissionId) {
      submissionId = this.userSubmissions.find(a => a.referenceNumber === referenceNumber.toString()).submissionId;
    }
    sessionData.submissionId(submissionId.toString());
  }


  loadSubmissionData_fromSubmissionUser() {

    const usr = this.submissionUser;

    this.submissionModel = new Submission();
    this.submissionModel.userId = usr.userId;
    this.submissionModel.emailAddress = usr.email;
    this.submissionModel.phoneNumber = usr.phone || '';

    const applicant = new SubmissionPersonModel();
    applicant.givenName = usr.firstName;
    applicant.middleName = usr.middleName;
    applicant.surname = usr.lastName;
    applicant.dateOfBirth = usr.dateOfBirth;
    applicant.genderId = usr.genderId;

    this.submissionModel.applicant = applicant;
  }

  loadSubmissionData_fromLastSubmission(lastSubmission: Submission) {
    this.submissionModel = new Submission();

    // Applicant basic details
    const applicant = new SubmissionPersonModel();
    applicant.personId = lastSubmission.applicant.personId;
    applicant.title = lastSubmission.applicant.title;
    applicant.givenName = lastSubmission.applicant.givenName;
    applicant.middleName = lastSubmission.applicant.middleName;
    applicant.surname = lastSubmission.applicant.surname;
    applicant.genderId = lastSubmission.applicant.genderId;
    applicant.dateOfBirth = lastSubmission.applicant.dateOfBirth;
    this.submissionModel.applicant = applicant;

    // Contact details
    const usr = this.submissionUser;
    this.submissionModel.emailAddress = usr.email;
    this.submissionModel.phoneNumber = usr.phone == null || '' ? lastSubmission.phoneNumber : usr.phone;
    this.submissionModel.alternativePhoneNumber = lastSubmission.alternativePhoneNumber;
    this.submissionModel.contactMethod = lastSubmission.contactMethod;

    // Culture and language
    this.submissionModel.cultures = lastSubmission.cultures;
    this.submissionModel.requiresInterpreter = lastSubmission.requiresInterpreter;
    this.submissionModel.interpreterLanguageSpoken = lastSubmission.interpreterLanguageSpoken;
    this.submissionModel.interpreterLanguageOther = lastSubmission.interpreterLanguageOther;

    // Address details
    lastSubmission.addresses.forEach(address => {
      if (address.addressTypeId === enums.addressTypes.Impacted) {
        this.submissionModel.addresses.push(address);
      }
    });

    this.submissionModel.submittedBy = lastSubmission.submittedBy;
  }

  /**
   * Add or Remove mapping of persons (Applicant, Partner, Dependants) and their ID documents from the previous application to current application
   *
   * @param add if set to true then persons and documents from previous submission will be mapped to this application too.
   * If set to false then the existing persons and documents will be unmapped.
   * @param onComplete callback function that will be run on completion of add/remove dependants task
   */
  submissionPersons_copyOrRemove(add: boolean, onComplete: Function, onAlways: Function) {

    const model = this.submissionModel;

    // ----------------------- Ignore and Return --------------------------

    // if action is add, but applicant documents or dependants or partner already exist in this submission, ignore add
    const ignoreAdd = (add &&
      (model.applicant.hasNoId === false || model.partner != null || (model.dependants != null && model.dependants.length > 0))
    );
    // if action is remove, but neither applicant documents nor dependants nor partner exist in this submission, ignore remove
    const ignoreDelete = (!add &&
      ((model.applicant.hasNoId === null || model.applicant.hasNoId === true)
        && model.partner == null && model.dependants == null)
    );
    // if ignore add/remove action - execute onComplete and exit immediately
    if (ignoreAdd || ignoreDelete) {
      onComplete();
      onAlways();
      return;
    }

    // ------------------------------ Proceed --------------------------

    // If adding, get last submission, map persons from last submission to model, and then save it into this submission
    if (add) {
      // ASYNC:
      this.submissionPersons_copy(onComplete)
      .pipe(finalize(() => { onAlways(); }))
      .subscribe();

    } else { // Remove Partner and Dependants mapping
      // ASYNC:
      this.submissionPersons_remove()
      .pipe(finalize(() => { onAlways(); }))
      .subscribe(
        () => {
          onComplete();
        },
        (error) => {
          console.log(error);
        });
    }
  }
  submissionPersons_map(submission: Submission) {
    this.submissionModel.hasDependants = submission.hasDependants;
    this.submissionModel.hasPartner = submission.hasPartner;
    this.submissionModel.applicant = submission.applicant;
    this.submissionModel.partner = submission.partner;
    this.submissionModel.dependants = submission.dependants;
  }


  // ----------------------------------
  //        Api Methods
  // ----------------------------------


  submissionPersons_copy(onSuccess: Function) {
    return this.post(
      apis.submissionPersons.copyFromLastSubmission,
      { userId: this.submissionUser.userId, submissionId: this.submissionModel.submissionId })
      .pipe(
        map((r) => {
          // Update client Submission model
          this.submissionPersons_map((r.body as Submission));
          if (onSuccess) { onSuccess(); }
        }),
        catchError(this.handleError)
      );
  }

  submissionPersons_remove() {// onSuccess: Function) {
    return this.post(
      apis.submissionPersons.remove,
      { UserId: this.submissionUser.userId, SubmissionId: this.submissionModel.submissionId })
      .pipe(
        map((r) => {
          // Update client Submission model
          this.submissionPersons_map((r.body as Submission));
          //if (onSuccess) { onSuccess(); }
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Adds new or updates existing submission record to database by calling server api.
   */
  save(model: any = null): Observable<SubmissionOutcome> {
    if (model) {
      this.submissionModel = model;
    }
    this.inProgress_savingSubmissionData = true;

    const url = apis.submission.addOrUpdate;

    const sid = this.submissionModel.submissionId;

    this.submissionModel.userId = this.submissionUser.userId;

    // New submission or at the final milestone
    if ((!util.hasValue(sid) || sid === 0) || this.submissionModel.hasDeclaredProvidedTrueCorrectInformation) {
      if (this.isSupportUser_onBehalfOfApplicant) {
        this.submissionModel.submittedBy = this.supportUser_userId;
      } else {
        this.submissionModel.submittedBy = null;
      }
    }

    // if submissionId not set, get it from session storage
    if (!util.hasValue(sid) || sid === 0) {
      this.submissionModel.submissionId = Number(sessionData.submissionId());
    }

    // make a request to server
    return this.post<Submission>(url, this.submissionModel)
      .pipe(
        map(r => {
          const outcome = r.body as SubmissionOutcome;
          // If newely opened submission : Save submissionId to submissionModel and sessionData
          if (this.isNewSubmission) {
            const id = outcome.submissionId;
            this.submissionModel.submissionId = id;
            this.submissionModel.referenceNumber = outcome.referenceNumber;
            sessionData.submissionId(id.toString());
          }
          // execute custom actions if defined
          if (this.onSaveSuccess) {
            this.onSaveSuccess();
            this.onSaveSuccess = null;
          }
          return outcome;
        }),
        catchError(this.handleError),
        finalize(() => {
          this.inProgress_savingSubmissionData = false;
        })
      );
  }

  savePage(url, model: any = null): Observable<any> {

    // make a request to server
    return this.post<Submission>(url, model)
      .pipe(
        map(r => {
          const pageModel = r.body as any;
          // execute custom actions if defined
          if (this.onSaveSuccess) {
            this.onSaveSuccess(pageModel);
            this.onSaveSuccess = null;
          }
          return pageModel;
        }),
        catchError(this.handleError),
        finalize(() => { this.inProgress_savingSubmissionData = false; })
      );
  }


  /**
   * Returns Submission object by given submissionId
   * @param submissionId
   */
  getSubmissionById(submissionId: number): Observable<Submission> {
    this.inProgress_gettingSubmissionData = true;
    const url = this.getUrl(`${apis.submission.get}${submissionId}`);
    return this.http.get<Submission>(url)
      .pipe(
        map(r => {
          this.submissionModel = r;
          return r;
        }),
        catchError(this.handleError),
        finalize(() => {
          this.inProgress_gettingSubmissionData = false;
        })
      );
  }

  /**
   * Returns last Submission by given userId
   * @param userId
   */
  getLastSubmission(): Observable<Submission> {
    this.inProgress_gettingSubmissionData = true;
    if(this.submissionUser) {
      const url = this.getUrl(`${apis.submission.getLastByUser}${this.submissionUser.userId}`);
      return this.http.get<Submission>(url)
        .pipe(
          map(r => {
            return r;
          }),
          catchError(this.handleError),
          finalize(() => {
            this.inProgress_gettingSubmissionData = false;
          })
        );
    }

  }

  verifyID(queue: DVSQueueRequestModel[]): Observable<DVSQueueRequestModel> {
    return this.httpClient.post<DVSQueueRequestModel>(`${this.API_URL}/verify/`, queue);
  }

  goToApplication(submissionUser: UserPersonModel, submissionId: number, referenceNumber: number) {

    this.submissionUser = submissionUser;

    // new application
    let url = routes.application;

    // existing application
    if (submissionId || referenceNumber) {
      this.selectSubmission(submissionId, referenceNumber);
      url = routes.getApplication(referenceNumber);
    }

    // navigate
    this.router.navigateByUrl(url);
  }


}
