import { util } from './util';

/**
 * Utility for Local Storage management.
 * Use this for CRUD operations to HTML localStorage.
 */
export const localStorageUtil = {

    /**
     * Get, Add/Edit, Delete a string in localStorage
     *
     * @param name Name of localStorage item
     * @param value Value of localStorage item
     */
    str(name: string, value: string = '') {
        // delete
        if (value === null) {
            localStorage.removeItem(name);
            return;
        }
        // get
        if (value === '') {
            return localStorage.getItem(name);
        }
        // set
        localStorage.setItem(name, value);
    },


    /**
     * Get, Add/Edit, Delete an object or an array of objects in localStorage
     *
     * @param name Name of localStorage item
     * @param value Value of localStorage item
     */
    json(name: string, value: any) {
        // delete
        if (value === null) {
            localStorage.removeItem(name);
            return;
        }
        // get
        if (util.isEmptyObject(value)) {
            const str = localStorage.getItem(name);
            const obj = JSON.parse(str);
            return obj;
        } else {
            // set
            const str = JSON.stringify(value);
            localStorage.setItem(name, str);
        }
    }

};

