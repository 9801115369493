/**
 * Reusable utility functions
 */
export const util = {

    /**
     * Given a value and a default value, this function will return default value if given value is not defined or empty.
     * Otherwise it will return a given value.
     * Definition of 'not defined or empty' is when value is either: null, undefined, empty string "", or empty object {}
     *
     * @param value
     * @param defaultValue
     */
    valueOrDefault(value, defaultValue) {
        return (util.hasValue(value) ? value : defaultValue);
    },

    hasValue(value) {
        return !(value === null || typeof value === 'undefined' || value === '');
    },

    /**
     * Returns true if object has no properties
     * @param obj object to inspect
     */
    isEmptyObject(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    },

    isDate(value) {
        return (Object.prototype.toString.call(value) === '[object Date]');
    },

    /**
     * return date string without timezone.
     * This will ensure that date received on server is exactly the same as the date selected on client without timezone conversion
     * @param date
     */
    getDateNoTimezone(date: Date): string {
        const y = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        return `${y}-${m}-${d}`;
    },

    /**
     * If string contains just a number/ is parsable to number, then return a number
     * This will ensure that properties of type number never become strings
     * @param str String value
     */
    tryParseNumber(str): number | string {
        let retValue = str;
        if (str !== null) {
            if (str.length > 0) {
                // if is number
                if (!isNaN(str)) {
                    retValue = Number(str);
                }
            }
        }
        return retValue;
    },

    /**
     * return date converted from either string delimited by '-' (format -> 'yyyy-MM-dd' or 'yyyy=M-d') or number
     * @param value date as a number or string
     */
    tryParseDate(value: any): Date | null {
        // First check if date is an ISO formatted string
        if (((typeof value === 'string') && (value.indexOf('T') > -1))) {
            return new Date(value);
        } else if ((typeof value === 'string') && (value.indexOf('-') > -1)) {
            const str = value.split('-');

            const year = Number(str[0]);
            const month = Number(str[1]) - 1;
            const date = Number(str[2]);

            return new Date(year, month, date);
        } else if ((typeof value === 'string') && value === '') {
            return new Date();
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    },

    /**
     * return date as a string in a given format
     * @param date date as a Date object or a date string
     * @param format string template for date format e.g. 'dd MMM yyyy HH:mm tt'
     */
    getDateInFormat(date: any, format, convertUtcToLocalTime = false) {


        date = (typeof date === 'object' ? date : (date.indexOf('Date') > -1 ? new Date(parseInt(date.substr(6), null)) : util.tryParseDate(date)));

        if (convertUtcToLocalTime) {
            const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
            date.setTime(date.getTime() - timeOffsetInMS);
        }

        const yyyy = date.getFullYear();
        const yy = yyyy.toString().substr(2, 2);

        const month = date.getMonth() + 1;
        const M = month.toString();
        const MM = month < 10 ? '0' + M : M;
        const MMM = (month === 1 ? 'Jan' : (month === 2 ? 'Feb' : (month === 3 ? 'Mar' : (month === 4 ? 'Apr' : (month === 5 ? 'May' : (month === 6 ? 'Jun' : (month === 7 ? 'Jul' : (month === 8 ? 'Aug' : (month === 9 ? 'Sep' : (month === 10 ? 'Oct' : (month === 11 ? 'Nov' : (month === 12 ? 'Dec' : ''))))))))))));

        const day = date.getDate();
        const d = day.toString();
        const dd = (day < 10 ? '0' + d : d);

        const H = date.getHours();
        const HH = H < 10 ? '0' + H : H;

        let h = H % 12;
        h = h ? h : 12; // the hour '0' should be '12'
        const hh = h < 10 ? '0' + h : h;

        const m = date.getMinutes();
        const mm = m < 10 ? '0' + m : m;

        const tt = H >= 12 ? 'PM' : 'AM';

        if (!format) {
            format = 'dd MMM yyyy hh:mm';
        }
        const dateStr = format
            .replace(/yyyy/g, yyyy)
            .replace(/yy/g, yy)
            .replace(/MMM/g, MMM)
            .replace(/MM/g, MM)
            .replace(/M/g, M)
            .replace(/3ar/g, 'Mar') // correct M in Mar if it has been replaced by 3
            .replace(/5ay/g, 'May') // correct M in May if it has been replaced by 5
            .replace(/dd/g, dd)
            .replace(/d/g, d)
            .replace(/HH/g, HH)
            .replace(/H/g, H)
            .replace(/hh/g, hh)
            .replace(/h/g, h)
            .replace(/mm/g, mm)
            .replace(/m/g, m)
            .replace(/tt/g, tt);
        return dateStr;
    },
    getLocalDateInFormat(date: any, format) {
        return util.getDateInFormat(date, format, true);
    },

    /**
     * Converts a UTC Date to local date without affecting the format.
     *
     * @param date the date as in UTC format
     */
    convertUTCDateToLocalDate(date: any) {
        const dateAsDate = new Date(date);
        const newDate = new Date(dateAsDate.getTime() + dateAsDate.getTimezoneOffset() * 60 * 1000);
        const offset = dateAsDate.getTimezoneOffset() / 60;
        const hours = dateAsDate.getHours();
        newDate.setHours(hours - offset);
        return newDate;
    },

    /**
     * Map values from properties of one object to properties with a same name of another object.
     *
     * @param target Target object - Object to map data to
     * @param source Source object - Object to map data from
     * @param datesIgnoreTimezone For date properties ignore timezone offset that javascript applies automagically
     * @param stringsToNumbers  Strings parsable to number will be converted to number.
     */
    mapTo(target, source, datesIgnoreTimezone = false, stringsToNumbers = false) {
        for (const key in source) {
            if (source.hasOwnProperty(key) && target.hasOwnProperty(key)) {
                let val = source[key];
                //
                if (datesIgnoreTimezone && util.isDate(val)) {
                    val = util.getDateNoTimezone(val);
                }
                if (stringsToNumbers) {
                    val = util.tryParseNumber(val);
                }
                target[key] = val;
            }
        }
    },

    /**
     * Returns true if the given text contains given string/term.
     * @param text
     * @param term
     */
    contains(text: string, term: string) { return text.indexOf(term) > -1; },

    /**
    * return date string for medicare card color as per dvs payload requirement.
    * MedicareRequest CardExpiry - String
       If CardColour = G then YYYY-MM
       If CardColour = B then YYYY-MM-DD
       If CardColour = Y then YYYY-MM-DD.
    * @param cardColor
    * @param cardExpiryDate
    */
    getExpiryDateForMedicareCardByCard(cardExpiryDate: Date, cardColor: string): string {
        let format: any;
        switch (cardColor) {
            case "Green":
                format = this.getDateInFormat(cardExpiryDate, 'yyyy-MM');
                break;
            case "Blue":
                format = this.getDateInFormat(cardExpiryDate, 'yyyy-MM-dd');;
                break;
            case "Yellow":
                format = this.getDateInFormat(cardExpiryDate, 'yyyyy-MM-dd');
                break;
        }
        return format;
    },





    getObjectListFromEnum<T>(enumeration: T): { id: Number, name: string }[] {
        return (Object.keys(enumeration)
            .filter((key) => isNaN(+key))
            .map(key => ({ id: Number(enumeration[key]), name: key }))
        ).sort();
    }
};

