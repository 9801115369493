
import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Constants
import { environment } from '../../../environments/environment';
import { sessionData } from '../storage/session-data';

/**
 * Abstract base class that provides convenience methods for services that make api calls using Httpclient.
 * ApiServiceBase wraps Angular’s HttpClient adding api root (defined in environment variables) as a prefix to relative api paths.
 * handlError method allows capturing error body from the response and customizing error text based on error code.
 * Errors are returned as observable.
 *
 */
export abstract class BaseApiService {

    // ------------------------------
    //      Properties
    // -----------------------------


    // ----------------------------
    //      Constructor
    // ----------------------------

    constructor(
        protected http: HttpClient) {
    }


    // ----------------------------
    //      Methods
    // ----------------------------



    /**
     * Pass a route/path to api action.
     * Returns full url to api action by prepending an api root to the path
     * @param pathFromRoot
     */
    protected getUrl(pathFromRoot: string) {
        const trimmedUrl = pathFromRoot.startsWith('/') ? pathFromRoot.substring(1) : pathFromRoot;
        return `${environment.APIUrl}/${trimmedUrl}`;
    }

    protected post<T>(path, body, options?) {
        const url = this.getUrl(path);
        return this.http.post<T>(url, body, options).source;
    }

    protected get<T>(path, options?): Observable<any> {
        const url = this.getUrl(path);
        return this.http.get<T>(url, options);
    }

    protected put<T>(path, body, options?) {
        const url = this.getUrl(path);
        return this.http.put<T>(url, body, options);
    }

    protected delete<T>(path, options?) {
        const url = this.getUrl(path);
        return this.http.delete<T>(url, options);
    }

    /**
     *  Allows capturing error body from the server response and customizing error text based on error code.
     *  Errors are returned as observable.
     * @param r http error response
     */
    protected handleError(r: Response | any) {
        const error = (r.error || r);
        const message = (error ? error.message || error : '');
        return observableThrowError(message);
    }

}
