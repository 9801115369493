
/**
 * Model that is filled from data received from Auth0 login call.
 * the data received is of type Auth0DecodedHash
 * Ref: http://definitelytyped.org/docs/auth0--auth0/interfaces/auth0decodedhash.html
 */
export class AuthSessionModel {

  accessToken: string = null;

  idToken: string = null;

  state: any = null;

  expiresIn = 0;

  constructor(obj: AuthSessionModel = null) { if (obj) { Object.assign(this, obj); } }
}
