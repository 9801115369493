import { APIAccountMessages } from './api-friendly-messages';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScrollerService {
    accountMessages: any;
    constructor() {
       
    }

    scrollViewToTop() {
        let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
              window.scrollTo(0, pos - 50); // how far to scroll on each step
            } else {
              window.clearInterval(scrollToTop);
            }
          }, 16); 
    }

    
}

