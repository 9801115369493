import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/services/app.service';
import { LookupService } from './../../../services/lookup.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { enums } from 'src/app/shared/enums/enums';
import { regex } from 'src/app/shared/constants/regex';
import { LookupListItem } from 'src/app/models/lookup-list-item.model';
import { regexDescription } from 'src/app/shared/constants/regex-description';
import { sessionData } from 'src/app/shared/storage/session-data';
import { util } from 'src/app/shared/util/util';
import { WizardComponent as WizardComponent } from 'angular-archwizard';
import { ageIsGreaterThanMinAgeValidator } from 'src/app/shared/validators/ageIsGreaterThanMinAge-validator.directive';
import { ageIsLessThanMaxAgeValidator } from 'src/app/shared/validators/ageIsLessThanMaxAge-validator.directive';
import { Submission, Address } from 'src/app/models/submission.model';
import { UserPersonModel } from 'src/app/models/user-person.model';
import { SubmissionBasicService } from 'src/app/services/submission-basic.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { formUtil } from 'src/app/shared/util/form-util';
import { AddressService } from 'src/app/services/address.service';
import { GrantService } from 'src/app/services/grant.service';
import { AddressSuggestedModel } from 'src/app/models/address.model';
import { Countries } from 'src/app/shared/constants/lookup-countries';
import { GrantModel } from 'src/app/models/grant.model';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import * as moment from 'moment';
import { SubmissionService } from 'src/app/services/submission.service';
@Component({
  selector: 'app-applicant-details-basic',
  templateUrl: './applicant-details-basic.component.html',
  styleUrls: ['./applicant-details-basic.component.scss']
})
export class ApplicantDetailsBasicComponent implements OnInit {
  headingDetails = 'Your Details';
  headingContactDetails = 'Contact Details';
  headingAddressDetails = 'Address Details';
  headingInterpreterServices = 'Interpreter Services';
  regexDescription = regexDescription;
  maxDOB: Date;
  formAppDetails: FormGroup;
  formAddress: FormGroup;
  lookup_genders = lookupLists.genders;
  lookup_contactMethods = (Object.keys(enums.contactMethodTypes).map(e => enums.contactMethodTypes[e])).sort();
  typeahead_nameTitles: LookupListItem[] = [];
  lookup_nameTitles: any;
  lookup_languages:any;
  applicantName: string;
  formSubmitted = false;
  model: Submission;

  // address properties
  titleImpactedAddress = 'Impacted Address';
  titleTemporaryAddress = 'Relocated Address';
  titleBestPostalAddress = 'Postal Address';
  enterAddressButtonLabel = 'Enter address manually';
  isImpactedPropertyBoat = false;
  isImpactedPropertyHouse = false;
  isImpactedPropertyUnit = false;

  isImpactedPropertyTypeSelected: boolean;
  addresses: AddressSuggestedModel[] = [];
  addressesLoading = false;
  addressesNoData = false;
  addressSelected: AddressSuggestedModel;
  disableAddressFields = true;
  addressErrorMessage = '';

  lookup_propertyTypes: LookupListItem[];
  lookup_propertyTypesWithoutImpactedAndPOBox: LookupListItem[];

  private isImpactedAddressManuallyEntered = false;
  /** Array of address suggestions found for manualy entered address */
  addressSuggestions: AddressSuggestedModel[] = [];
  isGenericGrantEnabled: boolean;


  // ------------------
  //    Lookup list data

  lookup_auStates = lookupLists.australianStates;
  countries = Countries;

  // --------------------
  //    Forms

  geoX: any;
  geoY: any;

  hasAttemptedToProgress = false;
  isOtherLanguageSelected = false;
  language_Other = 100000019;

  grants: GrantModel[];
  manualAddressFromMatchSelected: boolean;
  private validator_otherInterpreterLanguage = [Validators.required, Validators.maxLength(100)];

  get lookup_addressTypes(): LookupListItem[] { return this.submissionService.lookup_addressTypes; }


  get fcd() { return this.formAppDetails.controls; }

  get fadDetails() { return this.addressForm.controls; }
  get essentialGrants() { return enums.essentialGrants; }

  get gender() {
    const gender = lookupLists.genders.find(g => g.value == this.fcd.genderId.value);
    return gender ? gender.name : '';
  }

  private validator_dateOfBirth = [Validators.required, ageIsGreaterThanMinAgeValidator(18, true), ageIsLessThanMaxAgeValidator(120)];
  get addressForm() { return this.formAddress.controls; }
  //get otherInterpreterLanguage(): AbstractControl { return this.fcd.controls.interpreterLanguageOther; }

  get applicantDetailsForm() { return this.formAppDetails.controls; }

  loading: boolean = false;

  @ViewChild('WizardComponent') public child: WizardComponent;


  constructor(private fb: FormBuilder,
    private appService: AppService,
    private submissionService: SubmissionService,
    private lookupService: LookupService,
    private scrollerService: ScrollerService,
    private awWizard: WizardComponent,
    private addressService: AddressService,
    private grantService: GrantService,
    private submissionServiceBasic:SubmissionBasicService
   ) {

      const existingSubmissionId = this.submissionService.submissionId;
      if (existingSubmissionId !== 0) {
        this.appService.loadingOverlayShow('Loading details, please wait....');
        this.submissionService.getSubmissionById(existingSubmissionId).subscribe(result => {
          this.model = result;
          //redirect to preview page
          this.submissionService.submissionModel = result;
          if (this.submissionService.isCompletedSubmission === true ||
            (!this.isGenericGrantEnabled && this.IsNonEssentialGrant())){
            this.submissionServiceBasic.loadPreview(true);
            this.awWizard.disableNavigationBar = true;
            this.awWizard.navigation.goToStep(5);

          }
          this.isImpactedPropertyTypeSelected = true;
          this.bindModelToApplicationDetailsForm();
          this.bindModelToAddressForm();
          this.appService.loadingOverlayHide();
        },
        error => {
          console.log(error);
          this.appService.loadingOverlayHide();
        },
        () => {
          this.appService.loadingOverlayHide();
        }
      );
      }
     }



  ngOnInit() {
    this.manualAddressFromMatchSelected = true;
    this.model = this.submissionService.submissionModel;
    this.lookup_nameTitles = [];
    this.lookup_languages = [];
    this.isGenericGrantEnabled = environment.isGenericGrantEnabled;
    this.initForm();
    this.loadNameTitles();
    this.loadLanguages();
    this.warmESBUpByFakeCall();
    this.loadPropertyTypes();
    this.loadAutoLookupForAddress();

  }

  private initForm() {
    const user = sessionData.user();
    this.applicantName = `${user.firstName} ${user.lastName}`;
    const languageOptions = this.lookup_languages.map(o => this.fb.control(false));

    // create form group for applicaton details
    this.formAppDetails = this.fb.group({
      title: ['', Validators.required],
      givenName: [user.firstName, [Validators.required, Validators.pattern(regex.name_person), Validators.maxLength(27)]],
      middleName: ['', [Validators.pattern(regex.name_person), Validators.maxLength(27)]],
      surname: [user.lastName, [Validators.required, Validators.pattern(regex.name_person), Validators.maxLength(27)]],
      dateOfBirth: ['', this.validator_dateOfBirth],
      genderId: ['', Validators.required],
      // Validation is set based on the contact method chosen - if contact method is Phone then phone number is required
      phoneNumber: ['', Validators.pattern(regex.phone)],
      alternativePhoneNumber: ['', Validators.pattern(regex.phone)],
      // Validation is set based on the contact method chosen - if contact method is Email then email address is required
      emailAddress: [user.email],
      contactMethod: ['', Validators.required],
      requiresInterpreter:['', Validators.required],
      interpreterLanguageSpoken:[''],
      languageOptions: this.fb.array(languageOptions),
      interpreterLanguageOther:['']
    });

    this.formAddress = this.fb.group({
      // form fields
      propertyTypeId: new FormControl('', [Validators.required]),
      inputAddress: new FormControl(''),
      fullAddress: new FormControl(''),
      // database fields
      streetNumber: new FormControl('', [Validators.required]),
      streetName: new FormControl('', [Validators.required]),
      suburb: new FormControl('', [Validators.required]),
      postcode: new FormControl('', [Validators.required]),
      state: new FormControl('QLD'),
      country: new FormControl('Australia'),
      lotNumber: new FormControl('', [Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)]),
      unitNumber: new FormControl('', [Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)]),
      marinaDockName: new FormControl(''),
      mooringNumber: new FormControl(''),
      boatName: new FormControl(''),
      poBoxNumber: new FormControl(''),
      lga: [''],
      isPrimaryResidence: new FormControl(''),
      wasManuallyEntered: new FormControl('')
    });
    this.setConditionalValidators();
  }

  setConditionalValidators() {
    const phoneNumber = this.formAppDetails.get('phoneNumber');
    const emailAddress = this.formAppDetails.get('emailAddress');
    const interpreterLanguageSpoken = this.formAppDetails.get('interpreterLanguageSpoken');
    const interpreterLanguageOther = this.formAppDetails.get('interpreterLanguageOther');

    this.formAppDetails.get('contactMethod').valueChanges.subscribe(contactMethod => {
     // Clear validators
     phoneNumber.clearValidators();
     emailAddress.clearValidators();

     // Initialize validators array, add format validator for phone and email
     const phoneValidators = [Validators.pattern(regex.phone)];
     const emailValidators = [Validators.pattern(regex.email)];

     // Phone contact preferred, add required validator
     if (contactMethod === enums.contactMethodTypes.Phone) {
       phoneValidators.push(Validators.required);
     }

     // Email contact preferred, add required validator
     if (contactMethod === enums.contactMethodTypes.Email) {
       emailValidators.push(Validators.required);
     }

     // Set validators
     phoneNumber.setValidators(phoneValidators);
     emailAddress.setValidators(emailValidators);

     // Upadate field value and validity
     phoneNumber.updateValueAndValidity();
     emailAddress.updateValueAndValidity();
    });

    // Set validator for interpreterLanguageSpoken when requiresInterpreter is set to true
    this.fcd.requiresInterpreter.valueChanges.subscribe(
      (isInterpreterRequired: boolean) => {
        if (isInterpreterRequired === false) {
          this.formAppDetails.get('interpreterLanguageSpoken').setValue('');
          this.isOtherLanguageSelected = false;
          interpreterLanguageSpoken.setValidators(null);
          interpreterLanguageSpoken.updateValueAndValidity();
          interpreterLanguageOther.setValidators(null);
          interpreterLanguageOther.updateValueAndValidity();
        }else{
          interpreterLanguageSpoken.setValidators(Validators.required);
          interpreterLanguageSpoken.updateValueAndValidity();
        }
      }
    );

  }

  onInput_applicantTitle(e) {
    const term = e.target.value;
    this.typeahead_nameTitles = this.lookup_nameTitles.filter(item => item.name.toLowerCase().startsWith(term.toLowerCase()));
  }

  loadNameTitles() {
    this.lookupService.getNameTitles().subscribe(result => {
      this.lookup_nameTitles = result;
    });
  }

  loadLanguages() {
    this.lookupService.getLanguages().subscribe(result=>{
      this.lookup_languages = result;
    })
  }

  IsNonEssentialGrant() {
    if (this.model != null && this.model.grants != null && this.model.grants[0] != null) {
      if (!Object.values(this.essentialGrants).includes(this.model.grants[0].type)) {
        return true;
      }
    }

    return false;
  }

  bindApplicantDetailsToModel(): Submission {

    if (this.model === null || this.model === undefined) {
      this.model = new Submission;
    }

    const formModel = this.formAppDetails.getRawValue();
    const user = sessionData.user();

    const submissionUser = new UserPersonModel();

    util.mapTo(submissionUser, user);


    // Map Applicant data
    util.mapTo(this.model.applicant, formModel, true, true);
    this.model.applicant.typeId = enums.personTypes.Applicant;
    this.model.userId = submissionUser.userId;

    this.model.applicant.hasNoId = true;
    this.model.applicant.hasDriverLicence = false;
    this.model.applicant.hasCentrelinkCard = false;
    this.model.applicant.hasMedicareCard = false;


    // Map submission related data

    this.model.contactMethod = this.fcd.contactMethod.value;
    this.model.emailAddress = this.fcd.emailAddress.value;
    this.model.phoneNumber = formModel.phoneNumber;
    this.model.alternativePhoneNumber = formModel.alternativePhoneNumber;

    this.submissionService.submissionUser = submissionUser;

    // Set to not integrate Submission with CRM
    this.model.integrateWithCRM = false;

    // interpreter services
    this.model.requiresInterpreter = this.fcd.requiresInterpreter.value;
    this.model.interpreterLanguage = this.fcd.interpreterLanguageSpoken.value;
    this.model.interpreterLanguageOther = this.fcd.interpreterLanguageOther.value;
    this.model.interpreterLanguageSpoken = this.fcd.interpreterLanguageSpoken.value;



    return this.model;
  }

  onChange_languageOptions(id: number) {
    const interpreterLanguageOther = this.formAppDetails.get('interpreterLanguageOther');
     if (id == this.language_Other) {
      this.isOtherLanguageSelected = true;
      //set the validation
      interpreterLanguageOther.setValidators(Validators.required);
      interpreterLanguageOther.updateValueAndValidity();
    } else {
      this.isOtherLanguageSelected = false;
      interpreterLanguageOther.setValidators(null);
      interpreterLanguageOther.updateValueAndValidity();
    }
  }


  bindModelToApplicationDetailsForm() {

    // -------------------
    //  Impacted Address
    // -------------------
    const applicant = this.model.applicant;
    const dob = (util.hasValue(applicant.dateOfBirth) ? new Date(applicant.dateOfBirth) : null);
    applicant.dateOfBirth = dob;
    if (applicant) {
      formUtil.mapModelToForm(applicant, this.applicantDetailsForm);
    }
    this.applicantDetailsForm.contactMethod.setValue(this.model.contactMethod);
    this.applicantDetailsForm.phoneNumber.setValue(this.model.phoneNumber);
    this.applicantDetailsForm.alternativePhoneNumber.setValue(this.model.alternativePhoneNumber);

    // interpreter services
    this.applicantDetailsForm.requiresInterpreter.setValue(this.model.requiresInterpreter);
    this.applicantDetailsForm.interpreterLanguageSpoken.setValue(this.model.interpreterLanguageSpoken);
    this.applicantDetailsForm.interpreterLanguageOther.setValue(this.model.interpreterLanguageOther);

    if(this.model.interpreterLanguageSpoken == this.language_Other){
      this.isOtherLanguageSelected = true
    }

  }


  //address related methods

  loadPropertyTypes(): void {
    this.lookupService.getPropertyTypes().subscribe(result => {
      this.lookup_propertyTypes = result;
      this.lookup_propertyTypesWithoutImpactedAndPOBox = this.lookup_propertyTypes.filter((item) =>
        item.name.toLowerCase().replace(' ', '') !== 'pobox' && item.name.toLowerCase().replace(' ', '') !== 'impacted');
    });
  }

  /// HACK to warm esb address endpints as it takes long tim eto resolve for initial typeahead
  warmESBUpByFakeCall(): void {
    const dummyaddress = '111 George Street, Brisbane City, Qld';
    this.addressService.findAddressByInputAddress(dummyaddress)
      .subscribe(suggestions => {
        if (suggestions) {
          this.addresses = suggestions;
        } else {    // No matching addresses found for the partial address typed
          this.addresses = [];
        }
      });
  }

  loadAutoLookupForAddress(): void {
    // property type change - Impacted Address

    this.addressForm.propertyTypeId.valueChanges.subscribe(
      (type: string) => {

        // Boat
        const isBoat = (Number(type) === enums.propertyTypes.Boat);
        formUtil.validation.toggleRequired(this.addressForm.boatName, isBoat);
        formUtil.validation.toggleRequired(this.addressForm.mooringNumber, isBoat);

        // Unit No is required if the propertyselected is Unit/Flat/appartment/Townhouse and the unit no is NULL
        const isUnit = (Number(type) === enums.propertyTypes.Unit);
        formUtil.validation.toggleRequired(this.addressForm.unitNumber, isUnit);

      }
    );

    // input address change :: TypeAhead method to find address with partial address
    this.addressForm.inputAddress.valueChanges.subscribe(
      (partial: string) => {
        this.addressService.findAddressByInputAddress(partial)
          .subscribe(suggestions => {
            if (suggestions) {
              this.addresses = suggestions;
            } else {    // No matching addresses found for the partial address typed
              this.addresses = [];
            }
          });
      });

  }

  setAddressUI(event: any, isOnChanged: boolean): void {

    this.toggleAddressFields(!this.disableAddressFields);

    var propertyType = (isOnChanged ? event.target.value : event);

    switch (propertyType) {
      case enums.propertyTypes.House.toString():
        this.isImpactedPropertyTypeSelected = true;
        this.isImpactedPropertyHouse = true;
        this.isImpactedPropertyBoat = false;
        this.isImpactedPropertyUnit = false;
        break;
      case enums.propertyTypes.Boat.toString():
        this.isImpactedPropertyTypeSelected = true;
        this.isImpactedPropertyHouse = false;
        this.isImpactedPropertyBoat = true;
        this.isImpactedPropertyUnit = false;
        break;
      case enums.propertyTypes.Unit.toString():
        this.isImpactedPropertyTypeSelected = true;
        this.isImpactedPropertyUnit = true;
        this.isImpactedPropertyHouse = false;
        this.isImpactedPropertyBoat = false;
        break;
      default:
        this.isImpactedPropertyTypeSelected = true;
        this.isImpactedPropertyHouse = true;
        this.isImpactedPropertyBoat = false;
        this.isImpactedPropertyUnit = false;
        break;
    }
  }

  getAddressGeocodeFromEsb(addressSuggestion: AddressSuggestedModel, onSuccess: Function = null) {
    this.addressSelected = addressSuggestion;
    // Retrieve the address details using the magic key
    this.addressErrorMessage = '';
    this.addressService.getAddressDetails(addressSuggestion.text, addressSuggestion.magicKey)
      .subscribe(
        (addresses => {
          if (addresses) {
            const address = addresses[0];   // Gets first address from response
            const street = (address.streetName == null ? '' : address.streetName) + ' ' + (address.streetType == null ? '' : address.streetType) +
              (address.streetDirection !== null && address.streetDirection !== undefined ? ' ' + address.streetDirection : '');
            this.addressForm.fullAddress.setValue(address.address);
            this.addressForm.streetName.setValue(street);
            this.addressForm.suburb.setValue(address.locality);
            this.addressForm.postcode.setValue(address.postcode);
            this.addressForm.state.setValue(address.state);
            this.addressForm.streetNumber.setValue(address.house);
            this.addressForm.unitNumber.setValue(address.unitNumber);
            // Store Geo codes to retrieve the available polygons for the address - used when submit
            this.geoX = address.geocode.x;
            this.geoY = address.geocode.y;
            // Revalidate the address
            this.addressForm.inputAddress.updateValueAndValidity();
            this.addressErrorMessage = '';

            if(this.model !== null){
              this.model.addresses[0].geoX = this.geoX;
              this.model.addresses[0].geoY = this.geoY;
            }

            // validate UNIT NO is there
            this.validateUnitNo(this.addressForm['unitNumber'].value);
          } else {
            // tslint:disable-next-line: max-line-length
            this.addressErrorMessage = this.addressSelected.text + ' was not found, please retype your address or click on the Enter address manually button.';
          }
          if (onSuccess) {
            onSuccess(this);

          }
        })
      );
  }

  getAddressFromFormFields(): string {

    // get impacted address model from form
    const a = this.addressForm;// as Address;
    let address = '';

    // get address parts
    const streetNumber = (this.addressForm.streetNumber ? this.addressForm.streetNumber.value + ' ' : '');
    const streetName = (this.addressForm.streetName ? this.addressForm.streetName.value + ', ' : '');
    const suburb = (this.addressForm.suburb ? this.addressForm.suburb.value + ', ' : '');
    const postcode = (this.addressForm.postcode ? this.addressForm.postcode.value + ' ' : '');
    const state = (this.addressForm.state ? this.addressForm.state.value + ' ' : '');
    const country = (this.addressForm.country ? this.addressForm.country.value + ' ' : '');
    const lotNumber = (this.addressForm.lotNumber ? this.addressForm.lotNumber.value + ' ' : '');
    const unitNumber = (this.addressForm.unitNumber ? this.addressForm.unitNumber.value + '/' : '');
    const marinaDockName = (this.addressForm.marinaDockName ? this.addressForm.marinaDockName.value + ' ' : '');
    const mooringNumber = (this.addressForm.mooringNumber ? this.addressForm.mooringNumber.value + ' ' : '');
    const boatName = (this.addressForm.boatName ? this.addressForm.boatName.value + ' ' : '');

    // boat
    if (this.isImpactedPropertyBoat) {
      address += (`${marinaDockName}${this.addressForm.mooringNumber}${this.addressForm.boatName}`);
    }

    // unit
    if (this.isImpactedPropertyUnit) {
      address += unitNumber;
    }

    // main address part
    address += `${streetNumber}${streetName}${suburb}${state}`; // + postcode

    if (this.isImpactedPropertyHouse) {
      address += lotNumber;
    }

    return address;
  }


  onAddressSelect(event: TypeaheadMatch): void {
    this.getAddressGeocodeFromEsb(event.item);
  }

  onSelect_ManuallyEnteredAddressSuggestion(event) {
    const suggestedAddressAndMagicKey: string = event.target.value;

    // if one of the sugested addresses is selected
    // find geocodes for the suggested address and then find grants for those geocodes
    if (suggestedAddressAndMagicKey) {
      // parse pipe separated addres|magicKey pair
      const parts = suggestedAddressAndMagicKey.split('|');
      if (parts.length > 1) {
        const text = parts[0];
        const magicKey = parts[1];
        const suggestedAddressModel = new AddressSuggestedModel({ text: text, magicKey: magicKey });

        // get polygons geocodes, find grants and save address
        this.getAddressGeocodeFromEsb(suggestedAddressModel, null);
     //   debugger;
      }
      this.manualAddressFromMatchSelected = true;
    }
  }

  validateManualAddres() {
    if (this.isImpactedAddressManuallyEntered) {

      // assemble searchable address string from manually filled in form fields
      const address = this.getAddressFromFormFields();

      // call address service
      this.addressService.findAddressByInputAddress(address)
        .subscribe((suggestions: AddressSuggestedModel[]) => {

          // Matching addresses found for the partial address typed
          if (suggestions) {
            this.addressSuggestions = suggestions;
            // Address not found
          } else {
            this.geoX = null;
            this.geoY = null;
          //  this.findPolygonIds(this);

          }
        });

  }
}

  validateUnitNo(unitno: string) {
    if (unitno === null) {
      this.addressForm['unitNumber'].enable();
    } else {
      this.addressForm['unitNumber'].disable();
    }
  }

  findPolygonIds() {

    const geoX = this.geoX;
    const geoY = this.geoY;

    this.model.polygonIds = null;

    // if no coordinates provided, just save changes
    if (!geoX || !geoY) {

      // this.onAfterFindPolygonIds(self);
     // console.log('no geoX or geoY provideded');

      // if coordinates provided get polygon ids first and then save changes
    } else {

      // self.appService.showLoading(self.loadingMessage_savingAddressDetails);
      // get polygon ids from server
      this.addressService.getPolygonIds(geoX, geoY)
        .subscribe(
          // onSuccess
          (polygons) => {
           // debugger;
            // There are events for the given address and date
            if (polygons) {

              // assign polyigonIds to model
              const polyIds = [];
              polygons.forEach(polygon => {
                const polygonId = polygon.objectId;
                polyIds.push(polygonId);
              });

              this.model.polygonIds = polyIds;
              // There are no events for the given addres and date, therefore grants can not be requested
            } else {
              this.model.polygonIds = null;
              this.model.eventId = null;
              this.model.requestsNoGrants = null;
            }

          },
          // onError
          () => {
          }
        );
    }
  }


  changeAddressesLoading(e: boolean): void {
    this.addressesLoading = e;
  }

  addressesNoResults(e: boolean): void {
    this.addressesNoData = e;
  }

  toggleAddressFields(isManuallyEntered: boolean = null) {

    // remove any address errors
    this.addressErrorMessage = '';

    // toggle current status of manual entry address fields
    if (isManuallyEntered != null) {
      this.disableAddressFields = !isManuallyEntered;
    } else {
      this.disableAddressFields = !this.disableAddressFields;
    }

    const manualAddressFieldStatus = this.disableAddressFields ? 'disable' : 'enable';
    const autoAddressFieldStatus = this.disableAddressFields ? 'enable' : 'disable';
    this.enterAddressButtonLabel = this.disableAddressFields ? 'Enter address manually' : 'Find Automatically';

    // set manual address input fields
    this.addressForm.streetName[manualAddressFieldStatus]();
    this.addressForm.suburb[manualAddressFieldStatus]();
    this.addressForm.postcode[manualAddressFieldStatus]();
    this.addressForm.streetNumber[manualAddressFieldStatus]();
    this.addressForm.lotNumber[manualAddressFieldStatus]();
    this.addressForm.unitNumber[manualAddressFieldStatus]();
    this.addressForm.state[manualAddressFieldStatus]();
    const enableManualFieldsValidators = !this.disableAddressFields;

   // this.toggleAddressValidators(this.formAddress as FormGroup, enableManualFieldsValidators, true);

    // set auto address input field
    this.addressForm.inputAddress[autoAddressFieldStatus]();
    // reset address search field, if manual address fields are enabled
    if (!this.disableAddressFields) {
    }
    // if value is not passed for isManuallyEntered, then derive it from toggle action
    if (isManuallyEntered == null) {
      this.isImpactedAddressManuallyEntered = !this.disableAddressFields;
    }
    this.manualAddressFromMatchSelected = !this.manualAddressFromMatchSelected;
   // console.log(this.manualAddressFromMatchSelected);
  }

  bindModelToAddressForm() {

    // -------------------
    //  Impacted Address
    // -------------------

    const impactedAddress = this.model.addresses.find(a => a.addressTypeId === enums.addressTypes.Impacted);
    if (impactedAddress) {
      // get state display code for the given id
      const state = lookupLists.australianStates.find(s => s.value === impactedAddress.state);
      impactedAddress.state = (state ? state.code : 'QLD');
      formUtil.mapModelToForm(impactedAddress, this.addressForm);
      this.isImpactedAddressManuallyEntered = impactedAddress.wasManuallyEntered;
      this.toggleAddressFields(this.isImpactedAddressManuallyEntered);
      this.setAddressUI(impactedAddress.propertyTypeId.toString(), false);
      this.geoX = this.model.addresses[0].geoX;
      this.geoY = this.model.addresses[0].geoY;
    }

  }

  bindAddressDetailsFormToModel(): Submission {

    // continue with address details processing...
    const formModel = this.formAddress.getRawValue();

    // Map : dateOfImpact, isAtTemporaryAddress, isStrandedTraveller

    util.mapTo(this.model, formModel, true);
    // Map bestPostalAddressType and bestPostalAddressTypeId to model
    this.model.bestPostalAddressTypeId = enums.addressTypes.Impacted;
    this.model.dateOfImpact = moment(new Date(2020, 4, 29)).toDate();
    // Map : Impacted, Temporary and Other Address
    // clear existing address data
    this.model.addresses = [];

    // -------------------
    //  Impacted Address
    // -------------------

    const impactedAddress = this.getAddressModel(this.formAddress,
      enums.addressTypes.Impacted,
      +this.formAddress.get('propertyTypeId').value,
      this.isImpactedAddressManuallyEntered,
      true);
    this.model.addresses.push(impactedAddress);
    // Set to integrate Submission with CRM
    this.model.integrateWithCRM = true;

    return this.model;
  }

  private getAddressModel(formModel: any, addressTypeId: number, propertyTypeId: number, wasManuallyEntered: boolean, isPostal: boolean) {
    // address type property prefix
    const isImpactedAddress = (addressTypeId === enums.addressTypes.Impacted);
    const isAustralianAddress = (formModel.get('country').value === 'Australia');
    const isUnit = propertyTypeId === enums.propertyTypes.Unit
      || propertyTypeId === enums.propertyTypes.AgedCare
      || propertyTypeId === enums.propertyTypes.Caravan
      || propertyTypeId === enums.propertyTypes.RetirementVillage;
    const isHouse = propertyTypeId === enums.propertyTypes.House;
    const isBoat = propertyTypeId === enums.propertyTypes.Boat;
    const isPOBox = propertyTypeId === enums.propertyTypes.POBox;

    // create object, map data from form and return it
    return new Address({

      addressTypeId: addressTypeId,
      addressType: 'Impacted',
      propertyTypeId: propertyTypeId,
      propertyType: this.lookup_propertyTypes.GetTextFromId(propertyTypeId),

      // if impactedaddres : get state id for the given display code.
      state: lookupLists.australianStates.find(s => s.code ===
        formModel.get('state').value.toUpperCase()).value,
      postcode: formModel.get('postcode').value,
      suburb: formModel.get('suburb').value,
      streetName: formModel.get('streetName').value,
      streetNumber: formModel.get('streetNumber').value,
      country: formModel.get('country').value,

      isMatched: null,
      isActivated: null,
      wasManuallyEntered: wasManuallyEntered,
      isPostal: isPostal,

      unitNumber: (isUnit ? formModel.get('unitNumber').value : null),
      lotNumber: (isHouse ? formModel.get('lotNumber').value : null),
      mooringNumber: (isBoat ? formModel.get('mooringNumber').value : null),
      marinaDockName: (isBoat ? formModel.get('marinaDockName').value : null),
      boatName: (isBoat ? formModel.get('boatName').value : null),

      poBoxNumber: (isPOBox ? formModel.get('poBoxNumber').value : null),

      // fields only provided for impacted address
      lga: (isImpactedAddress ? formModel.get('lga').value : null),
      geoX: (isImpactedAddress ? this.geoX : null),
      geoY: (isImpactedAddress ? this.geoY : null),

      // International Province only provided for temporary and other addresses when another country, not Australia, is selected
      internationalProvince: (isImpactedAddress || isAustralianAddress ? null : formModel.get('internationalProvince').value),

      isPrimaryResidence: (isImpactedAddress ? formModel.get('isPrimaryResidence').value : null),
    });
  }



  // --------------
  // Form Validation Css

  getFldClass_impacted(fieldName: string) {
    const control = this.addressForm[fieldName];
    return formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
  }
  getFldClass_inputGrp_impacted(fieldName: string) {
    const control = this.addressForm[fieldName];
    return formUtil.validation.getCssClass_invalidInputGroup(control, this.hasAttemptedToProgress);
  }
  getFldClass_temporary(fieldName: string) {
    const control = this.addressForm[fieldName];
    return formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
  }
  getFldClass_other(fieldName: string) {
    const control = this.addressForm[fieldName];
    const cls = formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
    return cls;
  }


  onClick_Next(): void {

    this.formSubmitted = true;

    if (this.formAppDetails.invalid || this.formAddress.invalid) {
      return;
    }

    //perform check if the address selected is manual and the lat long hasn't been populated
    // &&
    //this.model.addresses[0].geoX === null || this.model.addresses[0].geoY === null


    if(this.formAppDetails.dirty || this.formAddress.dirty){


      /// bind personal details form values to model
      this.bindApplicantDetailsToModel();

      /// bind address details form values to model
      this.bindAddressDetailsFormToModel();

      if(this.isImpactedAddressManuallyEntered && !this.manualAddressFromMatchSelected) {
        this.validateManualAddres();
        return;
      }
      this.scrollerService.scrollViewToTop();


      this.appService.loadingOverlayShow('Saving details, please wait...');
      this.loading = true;
      this.submissionService.save(this.model).subscribe(result => {
       if(result.submissionId > 0) {
        this.forceLoadImpactAssessmentMethod();
        this.awWizard.navigation.goToStep(1);
        this.loading = false;
       }
      },
        error => {
          console.log(error);
          this.loading = false;
          this.appService.loadingOverlayHide();
        },
        () => {

         // this.awWizard.navigation.goToStep(1);
          this.loading = false;
          this.appService.loadingOverlayHide();
        }
      );

    } else {
      this.scrollerService.scrollViewToTop();
      this.submissionService.submissionModel = this.model;
      this.forceLoadImpactAssessmentMethod();
      this.awWizard.navigation.goToStep(1);
    }
  }

  forceLoadImpactAssessmentMethod(): void {
    // send message to subscribers via observable subject
    this.submissionServiceBasic.loadImpactAssessment(true);
  }

}

