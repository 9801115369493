import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseSubStep } from 'src/app/shared/base-classes/base-sub-step';
import { GrantEssrsModel, GrantModel } from 'src/app/models/grant.model';
import { formUtil } from 'src/app/shared/util/form-util';
import { util } from 'src/app/shared/util/util';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-impact-assessment-essrs',
  templateUrl: './impact-assessment-essrs.component.html',
  styleUrls: ['./impact-assessment-essrs.component.scss']
})
export class ImpactAssessmentEssrsComponent extends BaseSubStep implements OnInit {

  // ----------------------------------------
  //  Properties
  // ----------------------------------------

  titleEssr = 'Impact Assessment for Essential Services Safety and Reconnection Scheme';
  @Input() eventId: string = null;

  lookup_services: any[] = [];

  isLoading_essentialServices = false;

  model: GrantEssrsModel;
  form: FormGroup;

  get f() { return this.form.controls; }

  get noServicesSelected() { return !this.isAtLeastOneEssentialServiceSelected && this.hasAttemptedToProgress; }
  get isAtLeastOneEssentialServiceSelected() {
    return (this.f.electricityService.value || this.f.gasService.value || this.f.waterService.value || this.f.sewerageService.value);
  }

  supportServiceExist(name) {
    return this.lookup_services.find(x => x.name == name) != undefined ? true : false;
  }

  // ----------------------------------------
  //  Constructor
  // ----------------------------------------

  constructor(private fb: FormBuilder,
    private lookupService: LookupService,
    scrollerService: ScrollerService) {
    super('Essr', scrollerService);
    this.componentInitialized = new EventEmitter<ImpactAssessmentEssrsComponent>();
  }


  // ----------------------------------------
  //  Methods
  // ----------------------------------------

  ngOnInit() {
    this.loadLookup_essentialServices()
  }


  initForm() {
    this.form = this.fb.group({
      electricityService: [],
      gasService: [],
      waterService: [],
      sewerageService: [],
      servicesInsuredForDisaster: [null, Validators.required]
    });

    // Emit the component to its parents
    this.componentInitialized.emit(this);
  }

  private loadLookup_essentialServices() {
    this.isLoading_essentialServices = true;
    this.lookupService.getEssentialServicesReconnection(this.eventId).subscribe(
      // On Success
      r => {
        this.lookup_services = r;
        this.initForm();
      },
      // On Error
      error => { },
      // On Complete
      () => {
        this.isLoading_essentialServices = false;
      }
    );
}

  bindModelToForm(model: GrantEssrsModel) {

    this.model = model || new GrantEssrsModel();

    formUtil.mapModelToForm(this.model, this.f);
  }

  bindFormToModel() {

    const formModel = this.form.getRawValue();

    util.mapTo(this.model, formModel);
  }

  onNextValidate(): boolean {
    return this.isAtLeastOneEssentialServiceSelected;
  }

}
