import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { SharedBootstrapModule } from '../shared/modules/shared-bootstrap.module';


// Services
import { LoggerService } from './logger.service';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LanguageSliderComponent } from './language-slider/language-slider.component';
import { HelpLinksComponent } from './help-links/help-links.component';
import { UserNavigationComponent } from '../_partials/user-navigation/user-navigation.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedBootstrapModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    HelpLinksComponent,
    UserNavigationComponent
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    LanguageSliderComponent,
    HelpLinksComponent,
    UserNavigationComponent
  ],
  providers: [
    LoggerService,
    FooterComponent,
    HelpLinksComponent
  ]
})
export class CoreModule { }
