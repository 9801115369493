import { sessionStorageUtil } from '../util/session-storage-util';
import { AuthSessionModel } from 'src/app/models/auth-session.model';
import { IAMUserModel } from 'src/app/models/iam-user.model';

/**
 *  Property-functions - for altering specific sessionStorage items without using strings and fiddling with json parsing.
 *  Encapsulates sessionStorage item name.
 *  Add here new items as the system grows.
 *
 * @example USAGE:
 * GET      : localData.applicationData();
 * DELETE   : localData.applicationData(null);
 * ADD/EDIT : localData.applicationData({ "firstName": "Jon", "lastName": "Smith" ... });
 *
 */
export const sessionData = {

    submissionId(value: string = '') { return sessionStorageUtil.str('submissionId', value); },

    user(value: any = {}): IAMUserModel { return sessionStorageUtil.json('user', value); },

    authSession(value: any = {}) { return sessionStorageUtil.json('authSession', value) as AuthSessionModel; },

    sessionState(value: string = '') { return sessionStorageUtil.str('sessionState', value); },

    loginLocation(value: string = '') { return sessionStorageUtil.str('loginLocation', value); },

};

