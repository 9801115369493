import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubmissionService } from '../../services/submission.service';
import { UserService } from '../../services/user.service';
import { GrantModel } from '../../models/grant.model';

@Component({
  selector: 'app-submission-results',
  templateUrl: './submission-results.component.html',
  styleUrls: ['./submission-results.component.scss']
})

export class SubmissionResultsComponent implements OnInit {

  // --------------------
  //    Properties
  // --------------------


  referenceNumber = '';

  showMakeNewApplicationLink: boolean;

  userGrants: GrantModel[] = [];


  // Getters

  get hasGrants() { return (Array.isArray(this.userGrants) && this.userGrants.length > 0); }
  get submissionId() { return this.submissionSvc.submissionId; }


  // --------------------
  //    Constructor
  // --------------------


  constructor(
    private router: Router,
    private submissionSvc: SubmissionService,
    private userService: UserService,
    private route: ActivatedRoute) {

    this.route.queryParams.subscribe(params => {
      this.referenceNumber = params['ref'];
    });

  }


  // --------------------
  //    Methods
  // --------------------


  ngOnInit(): void {

    this.getGrantsForCurrentSubmission();
  }


  getGrantsForCurrentSubmission() {
    this.submissionSvc.getSubmissionById(this.submissionId).subscribe(
      data => {
        this.userGrants = data.grants;
      },
      error => {
        // todo: handle - could not retrieve submissions
      }
    );
    return true;
  }


}
