import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { sessionData } from 'src/app/shared/storage/session-data';
import { enums } from 'src/app/shared/enums/enums';
import { routes } from 'src/app/shared/constants/routes';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})

export class DisclaimerComponent {

  title = 'Apply for disaster support';

  Notification = '';
  Error = false;
  ForceHeader = true;
  //activates portal
  IsActivated = environment.isActivated;
  IsGenericGrantEnabled = environment.isGenericGrantEnabled;

  status_expiry = enums.sessionState.state_expired;
  status_logout = enums.sessionState.state_logout;
  status_error_reg = enums.sessionState.error_registration;
  status_error_login = enums.sessionState.error_login;
  status_error_inactive = enums.sessionState.error_inactive;
  status_error_sessionlost = enums.sessionState.error_sessionlost;

  // TODO: proper messaging?
  msg_ext_tryagain = ' - if this issue persists, please contact Support';
  msg_expiry = 'The current session has timed out.  '
    + 'To proceed, please Register or Log In with an existing account.';
  msg_logout = 'The current session has been logged out.  '
    + 'To proceed, please Register or Log In with an existing account.';
  msg_error_reg = 'Unable to Register at this time, please try again' + this.msg_ext_tryagain;
  msg_error_login = 'Unable to Login at this time, please try again' + this.msg_ext_tryagain;
  msg_error_inactive = 'Your account has been deactivated. Please contact your administrator.';
  msg_error_sessionlost = 'Something went wrong, please sign in and try again' + this.msg_ext_tryagain;

  constructor(
    private router: Router
  ) {

    // set the notification messages from session if there are any session message
    if (sessionData.sessionState()) {
      this.Notification = sessionData.sessionState();
      // maybe use a status flag but I don't want to overburden usage of the logout()
      if (this.Notification === enums.sessionState.error_registration
        || this.Notification === enums.sessionState.error_login
        || this.Notification === enums.sessionState.error_inactive
        || this.Notification === enums.sessionState.error_sessionlost) {
        this.Error = true;
      }
    }
    // clear the session of any notification messages
    sessionData.sessionState(null);
  }

  nextStep() {
    this.router.navigateByUrl(routes.options);
  }

  clearAllNotifications() {
    this.Notification = null;
    this.Error = false;
  }
}
