import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';

import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { formUtil } from 'src/app/shared/util/form-util';

import { GrantEhaModel } from 'src/app/models/grant.model';
import { BaseSubStep } from 'src/app/shared/base-classes/base-sub-step';
import { customFunctionValidator } from 'src/app/shared/validators/customFunction-validator.directive';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { LookupListItem } from 'src/app/models/lookup-list-item.model';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-impact-assessment-eha',
  templateUrl: './impact-assessment-eha.component.html',
  styleUrls: ['./impact-assessment-eha.component.scss']
})
export class ImpactAssessmentEhaComponent extends BaseSubStep implements OnInit {

  // --------------------------------
  //      Properties
  // --------------------------------

  titleEhag = 'Impact Assessment for Emergency Hardship Assistance Grant';
  @Input() eventId: string = null;

  lookup_services: any[] = [];

  isLoading_immediateNeeds = false;

  form: FormGroup = this.fb.group({
    experiencingHardship: [],
    needOptions: this.fb.array([])
  });

  //form: FormGroup;

  model: GrantEhaModel;

  // -------------
  //  Validators

  private validator_atLeastOneServiceSelected = customFunctionValidator((): boolean => {
    let isValid = false;
    this.needOptionsArray.controls.forEach(s => {
      if (s.value === true) {
        isValid = true;
      }
    });
    return isValid;
  }, 'noNeedsSelected');


  // ------------------
  //  Getters

  get hasHardship() { return this.f.experiencingHardship.value === true; }
  get needOptionsArray(): FormArray { return (this.f.needOptions as FormArray); }

  // --------------------------------
  //      Constructor
  // --------------------------------

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    scrollerService: ScrollerService) {
    super('Ehag', scrollerService);
    this.componentInitialized = new EventEmitter<ImpactAssessmentEhaComponent>();
  }

  // --------------------------------
  //      Methods
  // --------------------------------

  ngOnInit() {
    this.loadLookup_immediateNeeds();
  }

  private loadLookup_immediateNeeds() {
    this.isLoading_immediateNeeds = true;
    this.lookupService.getImmediateNeeds(this.eventId).subscribe(
      // On Success
      r => {
        this.lookup_services = r;
        this.initForm();
      },
      // On Error
      error => { },
      // On Complete
      () => {
        this.isLoading_immediateNeeds = false;
      }
    );
  }


  initForm() {
    this.form = this.fb.group({
      experiencingHardship: [null, Validators.required],
      needOptions: this.fb.array(this.lookup_services.map(s => new FormControl(false)))
    });

    this.f.needOptions.setValidators([this.validator_atLeastOneServiceSelected]);

    this.f.experiencingHardship.valueChanges.subscribe(
      (isTrue: boolean) => {
        this.toggleForms(isTrue);
      });

    // Emit the component to its parents
    this.componentInitialized.emit(this);
  }

  toggleForms(isHardshipTrue: boolean) {
    if (isHardshipTrue) {
      this.f.needOptions.enable();
    } else {
      this.f.needOptions.disable();
    }
  }


  bindModelToForm(model: GrantEhaModel) {

    this.model = model || new GrantEhaModel();

    // map to form
    formUtil.mapModelToForm(this.model, this.f);

    // map needs

    if (this.model.experiencingHardship && this.model.needs) {
      this.model.needs.forEach(need => {
        for (let i = 0; i < this.lookup_services.length; i++) {
          // todo: use id reference instead of names once lookups are implemented
          if (need.name === this.lookup_services[i].name) {
            this.needOptionsArray.controls[i].setValue(true);
          }
        }
      });
    }
  }

  bindFormToModel() {
    const formData = this.form.getRawValue() as GrantEhaModel;

    this.model.experiencingHardship = formData.experiencingHardship;

    // empty array (or check exist and remove if unchecked)
    this.model.needs = [];

    // Match option name from formarray
    for (let i = 0; i < this.lookup_services.length; i++) {
      if (this.needOptionsArray.status != 'DISABLED' && this.needOptionsArray.controls.length > 0) {
        const isOptionSelected = this.needOptionsArray.controls[i].value;
        if (isOptionSelected) {
          const needItem = this.lookup_services[i];
          this.model.needs.push(new LookupListItem({ id: needItem.id, name: needItem.name }));
        }
      }
    }
  }



}
