import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as auth0 from 'auth0-js';
import { AUTH_CONFIG } from 'src/app/auth/auth0-variables';
import { sessionData } from 'src/app/shared/storage/session-data';
import { enums } from 'src/app/shared/enums/enums';
import { routes } from 'src/app/shared/constants/routes';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})

export class OptionsComponent implements OnInit {
  title = 'Login or Register';

  constructor(private router: Router, private auth: AuthService) { }

  ngOnInit() {
  }

  nextStep(stepName) {
    switch (stepName) {
      case ('New'): { this.router.navigateByUrl(routes.privacy); break; }
      case ('Existing'): { this.prepareAuth0_Login(); break; }
    }
  }

  prepareAuth0_Login() {

    this.auth.auth0 = new auth0.WebAuth({
      clientID: AUTH_CONFIG.clientID,
      domain: AUTH_CONFIG.domain,
      responseType: 'token id_token',
      audience: AUTH_CONFIG.audience,
      redirectUri: AUTH_CONFIG.callbackURL,
      scope: 'openid email login' // , _intstate:'login'
    });

    // sessionData.loginLocation(enums.loginTypes.Recovery);
    this.auth.login();


  }

  prepareAuth0_Signup() {

    this.auth.auth0 = new auth0.WebAuth({
      clientID: AUTH_CONFIG.clientID,
      domain: AUTH_CONFIG.domain,
      responseType: 'token id_token',
      audience: AUTH_CONFIG.audience,
      redirectUri: AUTH_CONFIG.callbackRegisterURL,
      scope: 'openid email signUp' // , _intstate:'login'
    });

    // sessionData.loginLocation(enums.loginTypes.Recovery);
    sessionData.loginLocation('recovery');
    this.auth.login();


  }
}

