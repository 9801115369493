import { FormControl, FormArray, AbstractControl, ValidatorFn } from '@angular/forms';

export class DOBValidation {

  static dobMinimumAgeValidator(ac: FormControl) {
    return new Promise<any>((resolve) => {
      let minimumAge = 18;
      let dob = new Date(ac.value);

      // Get current date
      const today = new Date();
      // Now calculate the difference
      var age = today.getFullYear() - dob.getFullYear();
      var m = today.getMonth() - dob.getMonth();
      // If current month is before dob month in current year or is equal to dob month and day is before - reduce age by 1
      if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        age--;
      }

      if (age >= minimumAge) {
        resolve(null);  // All ok, age is over 18!
      } else {
        resolve({ "notOver18": true })
      }
    });
  }
}

export class MultiCheckboxValidation {

  public RequireOneValidator(): ValidatorFn {
    return (ac: AbstractControl): { [key: string]: boolean } | null => {     
      let valid = false;
      const fa = ac.parent as FormArray;

      for (let i = 0; i < fa.length; ++i) {
        if (fa.at(i).value) {
          valid = true;
          break;
        }
      }

      return (valid ? null : { "noneSelected": true });
    }
  }
}

export class AddressValidation {

  public AddressValidator(): ValidatorFn {
    return (ac: AbstractControl): { [key: string]: boolean } | null => { 
      if (ac.parent === undefined) {
        return null;
      }

      let streetName = ac.parent.controls['streetName'].value;
      let suburb = ac.parent.controls['suburb'].value;
      let postcode = ac.parent.controls['postcode'].value;
      let state = ac.parent.controls['state'].value;

      let isNotValid = streetName === '' || suburb === '' || postcode === '' || state === '';

      if (isNotValid) {
        return { "invalidAddress": true };
      } else {
        return null;
      }
    }
  }
}