import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
// Constants
import { lookupLists } from 'src/app/shared/constants/lookup-lists';

import { formUtil } from 'src/app/shared/util/form-util';
import { BaseSubStep } from 'src/app/shared/base-classes/base-sub-step';
import { GrantEhcgModel } from 'src/app/models/grant.model';
import { util } from 'src/app/shared/util/util';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';

@Component({
  selector: 'app-impact-assessment-ehcg',
  templateUrl: './impact-assessment-ehcg.component.html',
  styleUrls: ['./impact-assessment-ehcg.component.scss']
})
export class ImpactAssessmentEhcgComponent extends BaseSubStep implements OnInit {


  // ---------------------------------
  //        Properties
  // ---------------------------------

  titleEhcg = 'Impact Assessment for Essential Household Contents Grant';

  titleEhcg2 = 'Essential Household Contents Grant';

  private lookup_insOptions = lookupLists.insuredForHouseholdContents;

  form: FormGroup;

  model: GrantEhcgModel;

  insOptions = this.lookup_insOptions;

  // -----------
  //  Getters

  get isDamageToHouseholdContents() { return this.f.ehcgAvailable.value === true; }


  // ---------------------------------
  //        Constructor
  // ---------------------------------

  constructor(private fb: FormBuilder,
    scrollerService: ScrollerService) {
    super('Ehcg', scrollerService);
    this.componentInitialized = new EventEmitter<ImpactAssessmentEhcgComponent>();
  }



  // ---------------------------------
  //        Methods
  // ---------------------------------

  ngOnInit() {

    this.initForm();

    // Emit the component to its parents
    this.componentInitialized.emit(this);
  }

  initForm() {

    this.form = this.fb.group({
      ehcgAvailable: [null, Validators.required],
      contentsIsHomeOwner: [null, Validators.required],
      contentsInsuranceType: [null, Validators.required]
    });
  }

  toggleFormHiddenFields() {
    if (this.form.controls.ehcgAvailable.value) {
      this.f.contentsIsHomeOwner.enable();
      this.f.contentsInsuranceType.enable();
    } else {
      this.f.contentsIsHomeOwner.disable();
      this.f.contentsInsuranceType.disable();
    }
  }


  bindModelToForm(model: GrantEhcgModel) {

    this.model = model || new GrantEhcgModel();

    formUtil.mapModelToForm(this.model, this.f);


    // toggle field enabled status to match visibility for validation
    this.toggleFormHiddenFields();
  }

  bindFormToModel(): void {

    util.mapTo(this.model, this.form.value);
  }

}
