
/**
 * Model that is filled from data received  on apicalls:  'api/user/login', 'api/user/load' ...
 * Serverside type returned is IAMUser.
 */
export class IAMUserModel {

    userId: number = null;
    iamId: string = null;
    firstName: string = null;
    lastName: string = null;
    email: string = null;
    roleId: number = null;
    phone: string = null;
    crmContactId: string = null;
    isExported: boolean = null;
    dateExported: Date = null;

    constructor(obj: IAMUserModel = null) { if (obj) { Object.assign(this, obj); } }
}
