/**
 * Store all the route paths inside this file instead of hardcoding them
 * */
export const routes = {

    // routes
    contactUs: '/',
    editcontact: '/editcontact',
    dashboard: '/dashboard',
    dashboardFromRegistration: '/dashboard;fromReg=true',
    application: '/application',
    applicationBasic: '/application-basic',
    privacy: '/privacy',
    options: '/options',
    disclaimer: '/disclaimer',
    basicLanding: '/landing',

    // getter methods
    getApplication: (referenceNumber) => `${routes.application}/${referenceNumber}`,
    getApplicationBasic: (referenceNumber) => `${routes.applicationBasic}/${referenceNumber}`,
};
