import { PersonMedicareModel } from './person-medicare.model';
import { PersonDriverLicenceModel } from './person-driver-licence.model';

export class SubmissionPersonModel {

  submissionPersonId: number = null;

  personId: number = null;

  title: string = null;
  otherTitle: string = null;
  givenName: string = null;
  middleName: string = null;
  middleInitial: string = null;
  surname: string = null;
  dateOfBirth: Date = null;
  genderId: number = null;
  occupationIncomeSupportBenefit: string = null;

  // -------------
  //  Documents

  hasNoId = null;

  hasMedicareCard: boolean = null;
  hasDriverLicence: boolean = null;
  hasCentrelinkCard: boolean = null;

  medicare: PersonMedicareModel = null;
  driverLicence: PersonDriverLicenceModel = null;
  centrelinkNumber: number = null;

  // -------------

  incomeSource: string = null;
  weeklyIncome: number = null;
  incomeNotApplicable: boolean = null;
  relationshipToApplicantId: string = null;
  typeId: number = null;
  constructor(obj: SubmissionPersonModel = null) { if (obj) { Object.assign(this, obj); } }
}

