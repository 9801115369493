import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Calculates derived age from selected date of birth and passes as valid if age is less than provided maxAge
 *
 * @param {number} maxAge maximum age allowed
 * @param {boolean} includeMaxAge Default : false - if set to true, the validation will pass also when age is equal to maximum age
 * @example USAGE:
 *
 * TS:
 *      const formGroup = this.fb.group({
 *          dateOfBirth: ['', ageIsLessThanMaxAgeValidator(120,true)]
 *          ...
 *      });
 *
 * HTML:
 *      <div class="invalid-feedback"
 *          *ngIf="myFormControl.errors?.ageIsNotLessThanMaxAge" >
 *          Maximum age allowed is 120.
 *      </div>
 */
export function ageIsLessThanMaxAgeValidator(maxAge: number, includeMaxAge: boolean = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

        const today = new Date();
        const birthDate = new Date(control.value);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age = age - 1;
        }
        const isValid = (includeMaxAge ? age <= maxAge : age < maxAge);
        return (isValid ? null : { ageIsNotLessThanMaxAge: true });
    };
}
