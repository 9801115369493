﻿import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


// Models
import { PersonMedicareModel } from 'src/app/models/person-medicare.model';
import { MedicareCardFormModel } from './medicare-card-form.model';

// Utils
import { formUtil } from 'src/app/shared/util/form-util';
import { util } from 'src/app/shared/util/util';

// Constants
import { enums } from 'src/app/shared/enums/enums';

// Base Classes
import { BaseChildForm } from 'src/app/shared/base-classes/base-child-form';

// Validators
import { dateIsInFutureValidator } from 'src/app/shared/validators/dateIsInFuture-validator.directive';

// Constants
import { regex } from 'src/app/shared/constants/regex';
import { regexDescription } from 'src/app/shared/constants/regex-description';

@Component({
  selector: 'app-medicare-card',
  templateUrl: './medicare-card.component.html',
  styleUrls: ['./medicare-card.component.scss']
})
export class MedicareCardComponent extends BaseChildForm implements OnInit {

  // ---------------------------------------------------------------------------
  //    Properties
  // ---------------------------------------------------------------------------


  // -------------------------
  //  Form

  public static formGroupModel = {
    hasMedicareCard: [null],
    personMedicareId: [null],
    medicareNameMultilined: [false],
    medicareNameLine1: [''],
    medicareNameLine2: [''],
    medicareNameLine3: [''],
    medicareNameLine4: [''],
    medicareNumber: [''],
    medicareIndividualNumber: [''],
    medicareColour: [enums.medicareCardColours.Green],
    medicareExpiryDate: [''],
    medicareGreenExpiry: ['']
  };
  form: FormGroup;


  // -------------------------
  //  IO

  @Input() displayTitle = true;
  @Input() questionHaveMedicareCard = 'Do you have a valid Medicare Card? Please enter only your details exactly as they appear on your Medicare Card.';


  // -------------------------
  //  Other

  hasAttemptedToProgress = false;
  titleMedicare = 'My Medicare Card';
  private currentDate = new Date();
  currentMonth = this.currentDate.getMonth() + 1;
  currentYear = this.currentDate.getFullYear();
  defaultMedicareGreenExpiry = { year: this.currentYear, month: this.currentMonth };
  lookup_medicareColours = Object.keys(enums.medicareCardColours).map(e => enums.medicareCardColours[e]);

  regexDescription = regexDescription;
  private vdtr_namePerson_maxLen10_required = [Validators.required, Validators.pattern(regex.name_person), Validators.maxLength(27)];
  private vdtr_namePerson_maxLen10 = [Validators.pattern(regex.name_person), Validators.maxLength(27)];


  // -------------------------
  //  Getters

  get f() { return this.form.controls; }
  get hasMedicareCard(): boolean { return this.f.hasMedicareCard.value === true; }
  get isNameDisplayedOnMultiLines() { return this.f.medicareNameMultilined.value === true; }
  get isGreen() { return this.f.medicareColour.value === enums.medicareCardColours.Green; }
  get model(): PersonMedicareModel { return MedicareCardComponent.getDataModel(this.form.value); }
  get formModel(): MedicareCardFormModel { return this.form.getRawValue() as MedicareCardFormModel; }


  // -------------------------
  //  Static methods

  /**
   * We need to expose this public static method because in some parent controls like dependants
   * we do not have access to the whole Medicare Card component but only to the medicare form.
   *
   * @param {MedicareCardFormModel} formModel - model used to bind data to medicare form
   */
  public static getDataModel(formModel: MedicareCardFormModel): PersonMedicareModel {
    const dataModel = new PersonMedicareModel();
    util.mapTo(dataModel, formModel, true);
    return dataModel;
  }


  // ---------------------------------------------------------------------------
  //    Constructor
  // ---------------------------------------------------------------------------

  constructor(private fb: FormBuilder) {
    super();
    this.componentId = 'medicareCard';
    this.componentInitialized = new EventEmitter<MedicareCardComponent>();
  }

  // ---------------------------------------------------------------------------
  //    Methods
  // ---------------------------------------------------------------------------


  // -------------------------------------
  //  Public Methods

  bindModelToForm(model: MedicareCardFormModel) {

    this.f.hasMedicareCard.setValue(model.hasMedicareCard);

    if (model.hasMedicareCard === true) {

      // map form model
      formUtil.mapModelToForm(model, this.f, {
        // must convert date string to date object to bind it to control
        medicareExpiryDate: function (val) { return (util.hasValue(val) ? new Date(val) : null); }
      });
    }
  }


  // -------------------------------------
  //  Private Methods

  public initForm() {

    // create form group
    this.form = this.fb.group(MedicareCardComponent.formGroupModel);

    // initialize hasMedicareCard to required
    formUtil.validation.toggleRequired(this.f.hasMedicareCard, true);

    // attach value change listeners

    this.f.hasMedicareCard.valueChanges.subscribe(
      (hasMedicare: boolean) => {
        this.toggleFormFieldValidators(hasMedicare);
      }
    );

    this.f.medicareNameMultilined.valueChanges.subscribe(
      (isNameMultilined: boolean) => {
        const vdtr_nameLine2 = (isNameMultilined ? this.vdtr_namePerson_maxLen10_required : this.vdtr_namePerson_maxLen10);
        formUtil.validation.toggleControlValidators(this.f.medicareNameLine2, vdtr_nameLine2, true);

        // clear extra lines, if not multilined is selected
        if (!isNameMultilined) {
          this.f.medicareNameLine2.setValue(null);
          this.f.medicareNameLine3.setValue(null);
          this.f.medicareNameLine4.setValue(null);
        }
      }
    );
  }

  private toggleFormFieldValidators(hasMedicareCard: boolean) {
    formUtil.validation.toggleControlsValidators(this.form, {
      'medicareNameLine1': this.vdtr_namePerson_maxLen10_required,
      'medicareNameLine3': this.vdtr_namePerson_maxLen10,
      'medicareNameLine4': this.vdtr_namePerson_maxLen10,
      'medicareNumber': [Validators.required, Validators.pattern(regex.numeric), Validators.minLength(10), Validators.maxLength(10)],
      'medicareIndividualNumber': [Validators.required, Validators.pattern(regex.medicare_individualReference)],
      'medicareColour': [Validators.required],
      'medicareExpiryDate': [Validators.required, dateIsInFutureValidator(true)]
    }, hasMedicareCard);
  }




}
