import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Submission } from 'src/app/models/submission.model';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { BaseSubStep } from 'src/app/shared/base-classes/base-sub-step';
import { formUtil } from 'src/app/shared/util/form-util';
import { enums } from 'src/app/shared/enums/enums';
import { util } from 'src/app/shared/util/util';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';

@Component({
    selector: 'app-household-income-test',
    templateUrl: './household-income-test.component.html',
    styleUrls: ['./household-income-test.component.scss']
})
export class HouseholdIncomeTestComponent extends BaseSubStep implements OnInit {

    // ----------------------------------------
    //  Properties
    // ----------------------------------------

    titleIncome = 'Household Income Test';

    model: Submission;

    form: FormGroup;

    private formGroupModel_personIncome = {
        personId: [null],
        typeId: [null],
        givenName: [null],
        surname: [null],
        incomeNotApplicable: [null],
        incomeSource: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z\s]*$/)]],
        weeklyIncome: [0.00, [Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]]
    };


    // --------------
    //  Getters

    get peopleFormArray() { return this.f.people as FormArray; }
    get peopleCtrls() { return this.peopleFormArray.controls as FormGroup[]; }


    // ----------------------------------------
    //  Constructor
    // ----------------------------------------

    constructor(private fb: FormBuilder,
        scrollerService: ScrollerService) {
        super('Income', scrollerService);
        this.componentInitialized = new EventEmitter<HouseholdIncomeTestComponent>();
    }


    // ----------------------------------------
    //  Methods
    // ----------------------------------------

    ngOnInit() {

        this.initForm();

        // Emit the component to its parents
        this.componentInitialized.emit(this);
    }

    initForm() {

        this.form = this.fb.group({
            people: this.fb.array([])
        });
    }

    bindModelToForm(model: Submission) {
        this.model = model;
        const peopleFormGroupArray = [];

        // Add applicant Income
        const formGroup_ApplicantIncome = this.fb.group(this.formGroupModel_personIncome);
        formUtil.mapModelToForm(this.model.applicant, formGroup_ApplicantIncome.controls);
        peopleFormGroupArray.push(formGroup_ApplicantIncome);

        // Partner Income
        if (this.model.hasPartner) {
            const formGroup_PartnerIncome = this.fb.group(this.formGroupModel_personIncome);
            formUtil.mapModelToForm(this.model.partner, formGroup_PartnerIncome.controls);
            peopleFormGroupArray.push(formGroup_PartnerIncome);
        }

        // Dependants Income
        if (this.model.hasDependants) {
            this.model.dependants.forEach(dependant => {
                const formGroup_DependantIncome = this.fb.group(this.formGroupModel_personIncome);
                formUtil.mapModelToForm(dependant, formGroup_DependantIncome.controls);
                peopleFormGroupArray.push(formGroup_DependantIncome);
            });
        }

        this.f.people = this.fb.array(peopleFormGroupArray);

        this.notApplicable_setFormForDisplay();
    }

    bindFormToModel(): void {
        this.notApplicable_setFormForSave();
        this.peopleCtrls.forEach(formGroup_person => {

            const typeId = formGroup_person.controls.typeId.value;
            const personId = formGroup_person.controls.personId.value;
            const formModel_personIncome = formGroup_person.value;

            // Applicant
            if (typeId === enums.personTypes.Applicant) {
                util.mapTo(this.model.applicant, formModel_personIncome);
            }

            // Partner
            if (typeId === enums.personTypes.Partner) {
                util.mapTo(this.model.partner, formModel_personIncome);
            }

            // Dependant
            if (typeId === enums.personTypes.Dependant) {
                const dependant = this.model.dependants.find(d => d.personId === personId);
                util.mapTo(dependant, formModel_personIncome);
            }
        });

        // reset for display
        this.notApplicable_setFormForDisplay();
        this.dirtyDirtiedForm();
    }

    // set state and values based on NotApplicable selection
    onChange_NotApplicable(index: number) {
        const person = this.peopleCtrls[index];
        // if checked clear and disable other fields, else enable fields
        if (person.controls.incomeNotApplicable.value) {
            person.controls.weeklyIncome.setValue('');
            person.controls.incomeSource.setValue('');
            person.controls.weeklyIncome.disable();
            person.controls.incomeSource.disable();
        } else {
            person.controls.weeklyIncome.enable();
            person.controls.incomeSource.enable();
        }
    }

    // initialise income details for displaying
    notApplicable_setFormForDisplay() {
        if (this.peopleCtrls) {
            for (let i = 0; i < this.peopleCtrls.length; i++) {
                this.onChange_NotApplicable(i);
            }
        }
    }

    // set income details for save
    notApplicable_setFormForSave() {
        this.peopleCtrls.forEach(person => {
            if (person.controls.incomeNotApplicable.value) {
                // must be set this way so as not to interrupt the formgroup.value binding
                person.value.weeklyIncome = 0;
                person.value.incomeSource = 'Not Applicable';
            }
        });
    }

    public getPersonInputCssClass(index: number, controlName: string) {
        const control = this.peopleCtrls[index].controls[controlName];
        return formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
    }

    // this is a workaround to allow for saves coming from this page
    // parent form does not detect as dirty even when child controls are dirty possibly due to value accessing and assignments.
    dirtyDirtiedForm() {
        // if any child is dirty, the parent should be dirty too.
        if (this.form.controls.people.dirty) {
            this.form.markAsDirty();
        }
    }
}
