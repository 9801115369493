import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, AbstractControl, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

// Models
import { GrantModel } from 'src/app/models/grant.model';
import { EventModel } from 'src/app/models/event.model';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { GrantService } from 'src/app/services/grant.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';

// Validators
import { isAtLeastOneSelectedValidator } from 'src/app/shared/validators/isAtLeastOneSelected-validator.directive';

// Constants
import { enums } from 'src/app/shared/enums/enums';

// Utils
import { formUtil } from 'src/app/shared/util/form-util';

// Base Classes
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationService } from '../../application.service';
import { ApplicationStep } from '../../application-step.enum';

import { sessionData } from 'src/app/shared/storage/session-data';

@Component({
  selector: 'app-event-selection',
  templateUrl: './event-selection.component.html',
  styleUrls: ['./event-selection.component.scss']
})
export class EventSelectionComponent extends BaseApplicationStep implements OnInit {

  // ------------------------------------------
  //    Properties
  // ------------------------------------------

  titleOutcomeOfInitial = 'Event Selection';

  loadingMessage_loadingAvailableGrants = 'Retrieving Available Events...';

  form: FormGroup;

  events: EventModel[] = [];
  eventsUnique: EventModel[] = [];

  grants: GrantModel[] = [];

  isSubmitInProgress: boolean = false;

  error: string;

  hasSelectedOneEvent: boolean = false;

  // ------------
  //  Getters

  get f() { return this.form.controls; }
  get grantOptionsArray(): FormArray { return this.form.controls.event as FormArray; }
  get grantOptionsCtrls() { return this.grantOptionsArray.controls as FormControl[]; }
  get essentialGrants() { return enums.essentialGrants; }

  // ------------------------------------------
  //    Constructor
  // ------------------------------------------

  constructor(
    private grantService: GrantService,
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.EventSelection, appSvc, submissionService, authService, scrollerService);
  }


  // ------------------------------------------
  //    Methods
  // ------------------------------------------

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      'event': new FormControl('', {validators: [isAtLeastOneSelectedValidator]})
    });
  }


  bindModelToForm() {

    this.model.steps = [];

    this.loadGrants();
  }


  loadGrants() {

    // if no grants, load them from server based on poly ids
    if (this.events.length === 0) {

      this.appService.showLoading(this.loadingMessage_loadingAvailableGrants);

      // If no polygons in model, assume none are available and go to Impact Description.
      if (this.model.polygonIds.length == 0) {
        this.appService.goToStep(ApplicationStep.ImpactDescription);
        this.appService.hideLoading();
        return;
      }

      // ASYNC: Get events for chosen coordinates, using the grant lookup
      this.grantService
        .findGrantsByPolyIds(this.model.polygonIds, this.model.dateOfImpact, this.model.userId, this.model.submissionId, this.model.isHomeOwner)
        .pipe(finalize(() => { this.appService.hideLoading(); }))
        .subscribe((grants: GrantModel[]) => {


          // add event options
          grants.forEach((polyGrant: GrantModel) => {

            const grant = new GrantModel(polyGrant);

            // Create event model, if event is the "No" option set fixed values.
            const event = new EventModel();

            if (!grant.hasBeenSubmitted) { // Additional check to remove already submitted grants, which were impacting expected behaviour.
              if (grant.polyId == null) {
                event.eventId = null;
                event.name = "No - I do not wish to apply for any personal financial assistance";
                event.polyId = 0;
                event.isNoEvent = true;
              } else {
                event.eventId = grant.eventId;
                event.name = grant.eventName;
                event.polyId = grant.polyId;
              }
              this.events.push(event);
            }
            });

          // Convert array of events to Set then back to array to remove duplicates.
          let eventSet = new Set(this.events.map(event => JSON.stringify(event)));
          this.eventsUnique = Array.from(eventSet).map(element => JSON.parse(element));

          // Logic for No Events Available
          // If no events are returned, bypass grant page.
          let hasEventsAvailable = (this.eventsUnique.length > 1 ? true: false);
          if (!hasEventsAvailable) {
            this.model.hasActivatedGrantsAvailable = false;

            this.submissionService.submissionPersons_remove().subscribe(
              () => {

                // this.submissionService.removeInheritedDetails_fromModel();

                // Remove form validation so that navigating to another step is possible
            this.f.event.clearValidators();
            this.form.reset();

            // GOTO: Impact Description
            this.appService.goToStep(ApplicationStep.ImpactDescription);
              },
              (error) => {
                this.error = error
              });

            // stop execution of this method and exit out of it
            return;
          }

          // If only one event (excluding no event), update variables and proceed to grant page
          let hasMultipleEvents = (this.eventsUnique.length > 2 ? true: false)
          if (!hasMultipleEvents) {
            this.model.eventId = this.eventsUnique[1]['eventId'];
            this.model.polygonIds = [this.eventsUnique[1]['polyId'].toString()];
            this.f.event.clearValidators();
            this.form.reset();
            this.appService.goToStep(ApplicationStep.InitialOutcome);
          }

          // ------------------------------------------------------------------------------------------------

          this.initForm();

          // update the form selections
          this.bindModelToForm_grantCheckboxes();

        });
    }
  }

  bindModelToForm_grantCheckboxes() {
    // If any events other than the "No" event are returned
    // Loop through all returned events, if their UUID matches the one saved for the submission
    // push that event's info into the form to prefill existing data.
    if (this.eventsUnique.length > 1) {
      this.eventsUnique.forEach((grantOption, i) => {
        const existingPoly = this.model.eventId;
        const newPoly = grantOption.eventId;

        if (existingPoly != null) {
          if (newPoly == existingPoly) {
            this.hasSelectedOneEvent = true;
            this.form.get('event').setValue(this.eventsUnique[i]);
          }
        }
      });
    }
  }

  onChange_grantOption(selEvent: EventModel): void {
    this.form.get('event').setValue(selEvent);
    this.hasSelectedOneEvent = true;

  }

  onClick_Next() {

    if (this.hasSelectedOneEvent) {
      this.hasAttemptedToProgress = true;

      // if form valid continue
      if (this.isFormValid) {
        this.isSubmitInProgress = true;
        let selectEvent = this.form.get('event').value;
        if (selectEvent.isNoEvent) { // If "No" event was selected, move to Support Referral page.
          this.appService.goToStep(ApplicationStep.SupportReferral);
        } else {
          this.model.polygonIds = [this.form.get('event').value.polyId.toString()]; // Update Polygon and Event IDs in the model and proceed to existing grant selection page.
          this.model.eventId = this.form.get('event').value.eventId;
        }
        this.saveChanges(true, false);
        this.isSubmitInProgress = false;
      }
    }
  }

  wizStep_onSaveSuccess() {
    // GOTO: Go Forward after the changes have been saved to the server succesfully
    this.appService.goToNextStep();
  }

  bindFormToModel() {

  }


  // ----------------
  //  Validation

  getInputCssClass_grantOptions(grant: AbstractControl) {
    const isInvalid = ((this.hasAttemptedToProgress || this.f.event.dirty) && grant.errors);
    return (isInvalid ? formUtil.validation.cssClasses.invalidInputControl : '');
  }



}
