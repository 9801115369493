import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { BaseSubStep } from 'src/app/shared/base-classes/base-sub-step';
import { GrantSagModel, GrantModel } from 'src/app/models/grant.model';
import { formUtil } from 'src/app/shared/util/form-util';
import { util } from 'src/app/shared/util/util';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';

@Component({
  selector: 'app-impact-assessment-sag',
  templateUrl: './impact-assessment-sag.component.html',
  styleUrls: ['./impact-assessment-sag.component.scss']
})

export class ImpactAssessmentSagComponent extends BaseSubStep implements OnInit {

  // ----------------------------------------
  //  Properties
  // ----------------------------------------

  titleSag = 'Impact Assessment for Structural Assistance Grant';

  form: FormGroup;

  model: GrantSagModel;

  // -------------
  //  Lookups

  lookup_structuralDamages = lookupLists.structuralDamage;

  lookup_insured = lookupLists.structuralInsuredForDisaster;

  lookup_rentingOrOwn = lookupLists.rentingOrOwn;


  // ----------------------------------------
  //  Constructor
  // ----------------------------------------

  constructor(private fb: FormBuilder,
    scrollerService: ScrollerService) {
    super('Sag', scrollerService);
    this.componentInitialized = new EventEmitter<ImpactAssessmentSagComponent>();
  }

  ngOnInit() {

    this.initForm();

    // Emit the component to its parents
    this.componentInitialized.emit(this);
  }

  initForm() {
    this.form = this.fb.group({
      structuralInsuredForDisaster: [null, Validators.required],
      structuralDamage: [null, Validators.required]
    });
  }

  bindModelToForm(model: GrantSagModel) {

    this.model = model || new GrantSagModel();

    formUtil.mapModelToForm(this.model, this.f);
  }

  bindFormToModel() {

    const formModel = this.form.getRawValue();

    util.mapTo(this.model, formModel);
  }


}
