import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApplicationService } from 'src/app/pages/application/application.service';
import { SubmissionService } from 'src/app/services/submission.service';
import { AuthService } from 'src/app/services/auth.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { formUtil } from 'src/app/shared/util/form-util';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';
import { SubmissionBasicService } from 'src/app/services/submission-basic.service';
import { Subscription } from 'rxjs';
import { UserPersonModel } from 'src/app/models/user-person.model';

@Component({
  selector: 'app-declaration-basic',
  templateUrl: './declaration-basic.component.html',
  styleUrls: ['./declaration-basic.component.scss']
})
export class DeclarationBasicComponent implements OnInit {

  public form: FormGroup;

  public titleDeclaration = 'Declaration';
  model: any;
  formSubmitted: boolean;

  get f() { return this.form.controls; }

  subscription:Subscription;
  //
  constructor(private fb: FormBuilder,
    private appSvc: ApplicationService,
    private appService: AppService,
    private submissionService: SubmissionService,
    private authService: AuthService,
    private scrollerService: ScrollerService,
    private submissionServiceBasic:SubmissionBasicService,
    private router:Router) {
      this.subscription = this.submissionServiceBasic.getDeclarationData().subscribe(message => {
        if (message) {
          this.bindModelToForm();
        }
      });

    }

  ngOnInit() {
    this.createform();
  }

  createform() {
    this.form = this.fb.group({
      hasDeclaredProvidedTrueCorrectInformation: [false, Validators.required]
    });
  }

  bindModelToForm(): void {
    this.model = this.submissionService.submissionModel;
    formUtil.mapModelToForm(this.model, this.f);
  }

  bindFormToModel(): void {
    this.model = this.submissionService.submissionModel;
    const formModel = this.form.getRawValue();
    this.model.hasDeclaredProvidedTrueCorrectInformation = formModel.hasDeclaredProvidedTrueCorrectInformation;
    // Set to integrate Submission with CRM
    this.model.integrateWithCRM = true;
  }
  onClick_Next(): void{
    this.bindFormToModel();
    this.formSubmitted = true;
    if(this.form.invalid){
      return;
    }

    this.scrollerService.scrollViewToTop();

    this.appService.loadingOverlayShow('Saving details, please wait...');
    this.submissionService.submissionUser = new UserPersonModel();
    this.submissionService.submissionUser.userId = this.model.userId;
    this.submissionService.save(this.model).subscribe(result => {
      this.appService.loadingOverlayHide();
      this.router.navigate(['/results'], { queryParams: { ref: result.referenceNumber } });
     },
      error => {
        console.log(error);
        this.appService.loadingOverlayHide();
      },
      () => {
        this.appService.loadingOverlayHide();
      }
    );
  }

}
