import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-language-slider',
  templateUrl: './language-slider.component.html'
})
export class LanguageSliderComponent implements OnInit {
  private _languages: any[] = [];
  public current;

  constructor() {
    this._languages = [{
      'lang': 'ar',
      'text': 'العربية'
    }, {
      'lang': 'el',
      'text': 'Ελληνικά'
    }, {
      'lang': 'pl',
      'text': 'Polski'
    }, {
      'lang': 'bs',
      'text': 'Bosanksi'
    }, {
      'lang': 'id',
      'text': 'Bahasa'
    }, {
      'lang': 'ru',
      'text': 'Русский'
    }, {
      'lang': 'zh',
      'text': '中文'
    }, {
      'lang': 'it',
      'text': 'Italiano'
    }, {
      'lang': 'sr',
      'text': 'српски'
    }, {
      'lang': 'hr',
      'text': 'Hrvatski'
    }, {
      'lang': 'ja',
      'text': '日本語'
    }, {
      'lang': 'es',
      'text': 'Español'
    }, {
      'lang': 'fr',
      'text': 'Français'
    }, {
      'lang': 'ko',
      'text': '한국어'
    }, {
      'lang': 'tl',
      'text': 'Tagalog'
    }, {
      'lang': 'de',
      'text': 'Deutsch'
    }, {
      'lang': 'fa',
      'text': 'فارسی'
    }, {
      'lang': 'vi',
      'text': 'Tiếng'
    }];
    this.getRandom();
  }

  private getRandom() {
    this.current = this._languages[Math.floor(Math.random() * this._languages.length)];
  }

  ngOnInit() {
    window.setInterval(() => this.getRandom(), 3000);
  }

}
