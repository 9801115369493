/**
   * values must match the array index for aw-wizard-step elements in the wizard markup.
   */
export enum ApplicationStep {
    ApplicantDetails = 0,
    AddressDetails,
    EventSelection,
    InitialOutcome,
    IdentityCheck,
    Dependants,
    ImpactAssessment,
    PaymentMethod,
    SupportReferral,
    ImpactDescription,
    Preview,
    Declaration
}
