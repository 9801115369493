import {  Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BrowserNavService } from 'src/app/services/browserNav.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  titleContact: string;
  constructor(private router: Router,private browserNavSvc: BrowserNavService) { }

  ngOnInit() {
    this.titleContact = '';
  }

  nextStep(decision: string) {
    switch (decision) {
      case 'YES':
        this.router.navigate(['application-basic']);
        break;
      case 'NO':
        this.router.navigate(['dashboard']);
        break;
    }
  }

}
