export class AddressSuggestedModel {
    text: string;
    magicKey: string;
    constructor(obj: AddressSuggestedModel = null) { if (obj) { Object.assign(this, obj); } }
}

export class AddressDetailsModel {
    address: string;
    unitNumber: string;
    unitType: string;
    house: string;
    streetName: string;
    streetType: string;
    streetDirection: string;
    locality: string;
    state: string;
    postcode: string;
    geocode: GeocodeModel;
    constructor(obj: AddressDetailsModel = null) { if (obj) { Object.assign(this, obj); } }
}

export interface GeocodeModel {
    x: string;
    y: string;
}

export interface AddressEventModel {
    objectId: string;       // This is the polygon ID
    eventShort: string;
}
