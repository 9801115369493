import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Calculates derived age from selected date of birth and passes as valid if age is no less than provided minAge
 *
 * @param {number} minAge minimum age allowed
 * @param {boolean} includeMinAge Default : false - if set to true, the validation will pass also when age is equal to minimum age
 * @example USAGE:
 *
 * TS:
 *      const formGroup = this.fb.group({
 *          dateOfBirth: ['', ageIsGreaterThanMinAgeValidator(18,true)]
 *          ...
 *      });
 *
 * HTML:
 *      <div class="invalid-feedback"
 *          *ngIf="myFormControl.errors?.ageIsNotGreaterThanMinAge" >
 *          You must be 18 or older to apply.
 *      </div>
 */
export function ageIsGreaterThanMinAgeValidator(minAge: number, includeMinAge: boolean = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const today = new Date();
        const birthDate = new Date(control.value);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age = age - 1;
        }
        const isValid = (includeMinAge ? age >= minAge : age > minAge);
        return (isValid ? null : { ageIsNotGreaterThanMinAge: true });
    }

}
