
export const enums = {

    /**
     * NOTE: MUST match exactly PropertyTypeEnum on the serverside Api
     */
    propertyTypes: {
        House: 100000000,
        Unit: 100000001,
        Boat: 100000002,
        Caravan: 100000003,
        AgedCare: 100000004,
        RetirementVillage: 100000005,
        CycloneShelter: 100000006,
        Hotel: 100000007,
        POBox: 100000010
    },

    /**
     * NOTE: MUST match exactly AddressTypeEnum on the serverside
     */
    addressTypes: {
        Impacted: 100000000,
        Temporary: 100000002,
        Other: 100000003
    },

    contactMethodTypes: {
        Any: 'Any',
        Email: 'Email',
        Phone: 'Phone',
        Mail: 'Mail'
    },

    impactAssessmentTypes: {
        EHA: 'EHA',
        EHCG: 'EHCG',
        ESHA: 'ESHA',
        ESSRS: 'ESSRS',
        SAG: 'SAG',
        Income: 'Income'
    },

    loginTypes: {
        Recovery: 'recovery',
        Support: 'support'
    },

    /**
     * NOTE: MUST match exactly PersonTypeEnum on the serverside
     */
    personTypes: {
        Applicant: 1,
        Partner: 2,
        Dependant: 3
    },

    medicareCardColours: {
        Green: 'Green',
        Blue: 'Blue',
        Yellow: 'Yellow'
    },

    dateFormat: {
        // note: yyyy-MM-dd format is being used specifically for ie/edge
        hyphen: 'yyyy-MM-dd'
    },

    sessionState: {
        state_expired: 'expired',
        state_logout: 'logout',
        error_registration: 'error_registration',
        error_login: 'error_login',
        error_inactive: 'error_inactive',
        error_sessionlost: 'error_sessionlost',
        state_loggedin: 'state_loggedin'
    },

    supportReferralCategories: {
        otherSupport: 'Other Support',
        individualSupport: 'Individual Support',
        financialCounselling: 'Financial Counselling'
    },

    roleType: {
        generalPublic: 'https://crps.portal.role/'
    },

    essentialGrants: {
        EHA: 'EHA',
        EHCG: 'EHCG',
        ESHA: 'ESHA',
        ESSRS: 'ESSRS',
        SAG: 'SAG',
    },

    essentialGrantsText: {
        EHA: 'Emergency Hardship Assistance Grant',
        EHCG: 'Essential Household Contents Grant',
        ESHA: 'Essential Services Hardship Assistance Grant',
        ESSRS: 'Essential Services Safety and Reconnection Scheme',
        SAG: 'Structural Assistance Grant',
    },

    documentTypes: {
        Passport: 'Passport',
        COE: 'COE',
        ProofOfAddress: 'ProofOfAddress',
        BankStatement: 'BankStatement',
        VEVO: 'VEVO',
    }

};


