import { SubmissionBasicService } from 'src/app/services/submission-basic.service';
import { Countries } from './../../../shared/constants/lookup-countries';
import { ScrollerService } from './../../../core/helpers/view-scroller.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { lookupLists } from './../../../shared/constants/lookup-lists';// //'../src/app/shared/constants/lookup-lists';
import { WizardComponent } from 'angular-archwizard';
import { Submission, ISCANeeds } from 'src/app/models/submission.model';
import { GrantModel } from 'src/app/models/grant.model';
import { SubmissionService } from 'src/app/services/submission.service';
import { AppService } from 'src/app/services/app.service';
import { util } from 'src/app/shared/util/util';
import { formUtil } from 'src/app/shared/util/form-util';
import { EducationalInstitutions } from 'src/app/shared/constants/lookup-educational-institutions';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { sessionData } from 'src/app/shared/storage/session-data';
import { SubmissionPersonModel } from 'src/app/models/submission-person.model';
import { UserPersonModel } from 'src/app/models/user-person.model';
import { isNgTemplate } from '@angular/compiler';
import { ageIsGreaterThanMinAgeValidator } from 'src/app/shared/validators/ageIsGreaterThanMinAge-validator.directive';
import { ageIsLessThanMaxAgeValidator } from 'src/app/shared/validators/ageIsLessThanMaxAge-validator.directive';
import { regex } from 'src/app/shared/constants/regex';
import { regexDescription } from 'src/app/shared/constants/regex-description';

@Component({
  selector: 'app-impact-assessment-basic',
  templateUrl: './impact-assessment-basic.component.html',
  styleUrls: ['./impact-assessment-basic.component.scss']
})
export class ImpactAssessmentBasicComponent implements OnInit {

  form: FormGroup;
  lookup_financialSupportServices: any;
  formSubmitted: boolean;
  maxDOB: any;
  lookup_educationalInstitutions: any[];
  model: Submission;
  lookup_countries: { name: string; code: string; }[];
  grants: GrantModel[];
  lookup_primaryReasonForFinancialSupport: { id: number; name: string; }[];
  lookup_financialHardshipReasons: { id: number; name: string; }[];
  selectedEducationalInstitution: any[];
  subscription: any;
  applicantName: string;
  typeAhead_educationalInstitutions: any[];
  lookup_educationalInstitutionsForTA: any[];
  regexDescription = regexDescription;
  hasSelectedValidEducationalInstitution: any;


  get bf() { return this.form.controls; }
  private validator_dateOfBirth = [Validators.required, ageIsGreaterThanMinAgeValidator(18, true), ageIsLessThanMaxAgeValidator(120)];

  constructor(private formBuilder: FormBuilder,
    private awWizard: WizardComponent,
    private scrollerService: ScrollerService,
    private submissionService: SubmissionService,
    private submissionServiceBasic: SubmissionBasicService,
    private appService: AppService,
    private datePipe: DatePipe,
  ) {
    this.subscription = this.submissionServiceBasic.getAssessmentData().subscribe(message => {
      if (message) {
        this.hasSelectedValidEducationalInstitution = false;
        const existingSubmissionId = this.submissionService.submissionId;
        if (existingSubmissionId > 0) {
          this.model = this.submissionService.submissionModel;
          this.bindModelToForm();
        }
      }
    });


    }

  ngOnInit() {
    this.initForm();
    this.setConditionalValidators();
    this.loadLookupValues();

  }

  initForm() {
    const user = sessionData.user();
    let dob: any;
    if (this.model !== undefined){
       dob = (util.hasValue(this.model.applicant.dateOfBirth) ? new Date(this.model.applicant.dateOfBirth) : null);
    }
    this.form = this.formBuilder.group({
      isInternationalStudent: new FormControl('', [Validators.required]),
      isLivingInQld: new FormControl('', [Validators.required]),
      isExperiencingFinancialHardship: new FormControl('', [Validators.required]),
      financialHardshipReason: new FormControl(''),
      primaryReasonForFinancialSupport: new FormControl('', [Validators.required]),
      isSavingsAmountLessThanFifteenHundred: new FormControl('',[Validators.required]),
      educationInstitution: new FormControl('', [Validators.required]),
      passportNumber: new FormControl('',[Validators.required]),
      passportCountryIssued: new FormControl('', [Validators.required]),
      studentId: new FormControl('', [Validators.required]),
      studentEmail: new FormControl('', [Validators.required, Validators.pattern(regex.email)]),
      coeNumber: new FormControl('', [Validators.required]),
      visaType: new FormControl('', [Validators.required]),
      visaNumber: new FormControl('', [Validators.required]),
      passportFirstName: new FormControl(user.firstName, [Validators.required, Validators.pattern(regex.name_person),
        Validators.maxLength(27)]),
      passportLastName: new FormControl(user.lastName, [Validators.required, Validators.pattern(regex.name_person),
        Validators.maxLength(27)]),
      passportDateOfBirth: new FormControl('', this.validator_dateOfBirth)

    });
  }

  setConditionalValidators() {
    const financialHardshipReason = this.form.get('financialHardshipReason');
    const primaryReasonForFinancialSupport = this.form.get('primaryReasonForFinancialSupport');
    this.form.get('isExperiencingFinancialHardship').valueChanges.subscribe(experiencingFinancialHardship => {
      if (experiencingFinancialHardship === true) {
        financialHardshipReason.setValidators([Validators.required]);
        primaryReasonForFinancialSupport.setValidators([Validators.required]);
      }
      if (experiencingFinancialHardship === false) {
        financialHardshipReason.setValidators(null);
        primaryReasonForFinancialSupport.setValidators(null);
      }
      financialHardshipReason.updateValueAndValidity();
      primaryReasonForFinancialSupport.updateValueAndValidity();
    });

  }

  loadLookupValues(): void {

    this.lookup_financialSupportServices = lookupLists.fianancialSupportServices;
    this.lookup_educationalInstitutions = EducationalInstitutions;
    this.lookup_primaryReasonForFinancialSupport = lookupLists.financialSupportReasons;
    this.lookup_financialHardshipReasons = lookupLists.financialHardshipReasons;
    this.lookup_countries = Countries;
    this.lookup_educationalInstitutionsForTA = this.lookup_educationalInstitutions;
    this.lookup_educationalInstitutions.forEach(function(e){
    if (typeof e === 'object' ) { e['lookup'] = e['code'] + ' - ' + e['name']; }});
    this.lookup_educationalInstitutionsForTA = this.lookup_educationalInstitutions;
  }

  loadImpactAssessment() {
    this.bindModelToForm();
  }

  bindModelToForm() {
    // -------------------
    // ISCA Needs
    // -------------------
    const iscaneeds = this.model.iscaNeeds;
    if (iscaneeds) {

      formUtil.mapModelToForm(iscaneeds, this.form.controls);

      if (this.model.iscaNeeds.passportFirstName === null && this.model.iscaNeeds.passportLastName === null) {
        this.bf.passportFirstName.setValue(this.model.applicant.givenName);
        this.bf.passportLastName.setValue(this.model.applicant.surname);
      }
      if (this.model.iscaNeeds.passportDateOfBirth !== null) {
        const dob = (util.hasValue(this.model.iscaNeeds.passportDateOfBirth) ? new Date(this.model.iscaNeeds.passportDateOfBirth) : null);
        this.bf.passportDateOfBirth.setValue(dob);
      } else {
        const dob = (util.hasValue(this.model.applicant.dateOfBirth) ? new Date(this.model.applicant.dateOfBirth) : null);
        this.bf.passportDateOfBirth.setValue(dob);
      }
    } else {
      const dob = (util.hasValue(this.model.applicant.dateOfBirth) ? util.tryParseDate(this.model.applicant.dateOfBirth) : null);
      this.bf.passportFirstName.setValue(this.model.applicant.givenName);
      this.bf.passportLastName.setValue(this.model.applicant.surname);
      this.bf.passportDateOfBirth.setValue(dob);
    }

  }

  onInput_educationalInstitution(e) {
    const term = e.target.value;
    this.typeAhead_educationalInstitutions = this.lookup_educationalInstitutions
    .filter(item => item.code.toLowerCase().startsWith(term.toLowerCase()) || item.code.toLowerCase().startsWith(term.toLowerCase()));

    this.selectedEducationalInstitution = this.lookup_educationalInstitutionsForTA.
    filter(x => x.lookup === this.form.get('educationInstitution').value);

    if (this.selectedEducationalInstitution !== undefined && this.selectedEducationalInstitution.length > 0 ) {
      this.hasSelectedValidEducationalInstitution = true;
      this.form.controls['educationInstitution'].setErrors(null);
    } else {
      this.hasSelectedValidEducationalInstitution = false;
      this.form.controls['educationInstitution'].setErrors({'incorrect': true});
    }
  }

  ngOnDestroy() {
    this.submissionServiceBasic.subjectInitialAssessment.next();
  }

  bindFormToModel(): Submission {
    const f = this.form.value;
    this.model = this.submissionService.submissionModel;
    const iscaneeds = new ISCANeeds;
    util.mapTo(iscaneeds, this.form.value);
    this.selectedEducationalInstitution = this.lookup_educationalInstitutionsForTA.
    filter(x => x.lookup === this.form.get('educationInstitution').value);

    if (this.selectedEducationalInstitution !== undefined && this.selectedEducationalInstitution.length > 0 ) {
      iscaneeds.providerCode = this.selectedEducationalInstitution[0]['code'];
    } else {
      iscaneeds.providerCode = 'OTHER';
    }

    iscaneeds.passportDateOfBirth = util.getDateInFormat(iscaneeds.passportDateOfBirth, 'yyyy-MM-dd');
    
    this.model.hasISCANeeds = true;
    this.model.iscaNeeds = iscaneeds;
    return this.model;
  }

  onSelect(event: any): void {
      this.hasSelectedValidEducationalInstitution = true;
      this.form.controls['educationInstitution'].setErrors(null);
  }

  onClick_Next(): void {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.form.dirty) {

      this.model = this.bindFormToModel();

      if (!this.hasSelectedValidEducationalInstitution && this.model.iscaNeeds.providerCode === 'OTHER' ) {
        this.scrollerService.scrollViewToTop();
        this.form.controls['educationInstitution'].setErrors({'incorrect': true});
        return;
      }


      this.scrollerService.scrollViewToTop();
      this.appService.loadingOverlayShow('Saving details, please wait...');

      this.submissionService.submissionModel = this.model;
      this.submissionService.submissionUser = new UserPersonModel();
      this.submissionService.submissionUser.userId = this.model.userId;
      this.submissionService.save(this.model).subscribe(result => {
       this.forceLoadGrantsMethod();
        this.appService.loadingOverlayHide();
        this.awWizard.navigation.goToNextStep();
      },
        error => {
          console.log(error);
          this.appService.loadingOverlayHide();
        },
        () => {
          this.appService.loadingOverlayHide();
        }
      );
    } else {
      this.submissionService.submissionModel = this.model;
      this.forceLoadGrantsMethod();
      this.scrollerService.scrollViewToTop();
      this.awWizard.navigation.goToNextStep();
    }


  }


  forceLoadGrantsMethod(): void {
    // send message to subscribers via observable subject
    this.submissionServiceBasic.loadGrants(true);
  }

}
