import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'portal-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public isFooterCollapsed = false;
  versionNumber: string = environment.versionNum;
  dateRange: string;

  constructor() { }

  ngOnInit() { 
    this.dateRange = "1995-" + (new Date()).getFullYear();
  }

}
