import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
/**
 * Common service for the whole app. Used to control the flow of the app.
 */
export class AppService {

  // ---------------------
  //    Properties
  // ---------------------

  loadingMessage = '';


  // ---------------------
  //    Constructor
  // ---------------------

  constructor(
    private loadingIndicatorSvc: NgxSpinnerService) { }


  // ---------------------
  //    Methods
  // ---------------------

  loadingOverlayShow(message: string = '') {
    this.loadingMessage = message;
    this.loadingIndicatorSvc.show();
  }
  loadingOverlayHide() {
    this.loadingMessage = '';
    this.loadingIndicatorSvc.hide();
  }


}
