export const EducationalInstitutions = [
  {
    "code": "03614A",
    "name": "AAAR Institute of Business and Technology"
  },
  {
    "code": "03322B",
    "name": "Academique Pty Ltd"
  },
  {
    "code": "03005D",
    "name": "Ace Tennis Academy Gold Coast, Ace Sports Academy, ACE GOLF COLLEGE"
  },
  {
    "code": "03655C",
    "name": "Advocate Training Pty Ltd"
  },
  {
    "code": "02465F",
    "name": "Aero Dynamic Flight Academy"
  },
  {
    "code": "03250B",
    "name": "Aeropower Flight School"
  },
  {
    "code": "03610E",
    "name": "AIBT Global, Australia Institute of Business & Technology - International Pty Ltd"
  },
  {
    "code": "01272A",
    "name": "AICOL"
  },
  {
    "code": "03273F",
    "name": "Air Gold Coast Pty Ltd"
  },
  {
    "code": "02392G",
    "name": "Airwork Helicopters"
  },
  {
    "code": "01888B",
    "name": "Asian Pacific Institute, International Christian College"
  },
  {
    "code": "03637E",
    "name": "Australasian College of Care Leadership and Management"
  },
  {
    "code": "03379G",
    "name": "Australasian Institute of Ayurvedic Studies"
  },
  {
    "code": "03430J",
    "name": "Australia Institute of Business and Technology"
  },
  {
    "code": "02906G",
    "name": "Australia National Institute of Business"
  },
  {
    "code": "03798K",
    "name": "Australia Unity Institute of Technology"
  },
  {
    "code": "03486D",
    "name": "Australian Academy of Trades, Queensland International Institute of technology"
  },
  {
    "code": "03029G",
    "name": "Australian Careers College Pty Ltd"
  },
  {
    "code": "02483D",
    "name": "Australian Child Care Career Options"
  },
  {
    "code": "03768E",
    "name": "Australian College of Digital Technologies, ACIT,Institute of Film and Television"
  },
  {
    "code": "02771G",
    "name": "Australian College of Information Technology"
  },
  {
    "code": "03834M",
    "name": "Australian College of Professional Careers Pty Ltd"
  },
  {
    "code": "03164M",
    "name": "Australian College of Technology and Business Pty Ltd"
  },
  {
    "code": "01600A",
    "name": "Australian Dance Performance Institute (ADPI)"
  },
  {
    "code": "03646D",
    "name": "Australian Design College Pty Ltd"
  },
  {
    "code": "03811G",
    "name": "Australian Future Wellbeing Education Pty Ltd"
  },
  {
    "code": "03784E",
    "name": "Australian Global Institute and Australian Institute of Sport and Recreation"
  },
  {
    "code": "03469E",
    "name": "Australian Institute of Advanced Studies Pty Ltd"
  },
  {
    "code": "03583C",
    "name": "Australian Open College; Bright Minds Point"
  },
  {
    "code": "03645E",
    "name": "Australian Programming College Pty. Ltd."
  },
  {
    "code": "03493E",
    "name": "Australian Skills Group"
  },
  {
    "code": "03442E",
    "name": "Australian Skills Management Institute, Australian Skills Management Institute NSW"
  },
  {
    "code": "01302M",
    "name": "Australian Wings Academy Pty Ltd"
  },
  {
    "code": "03569A",
    "name": "Australis College"
  },
  {
    "code": "03659K",
    "name": "Austrasia College"
  },
  {
    "code": "03473J",
    "name": "Aventia Institute Pty Ltd"
  },
  {
    "code": "02425C",
    "name": "Aviation Australia"
  },
  {
    "code": "03452C",
    "name": "Axial Training Pty Ltd"
  },
  {
    "code": "03640K",
    "name": "Axis Institute"
  },
  {
    "code": "02613K",
    "name": "Ayurveda College Pty Ltd"
  },
  {
    "code": "03552K",
    "name": "Barrington College Australia"
  },
  {
    "code": "03574D",
    "name": "Beauty Therapy Training Australia Pty Ltd"
  },
  {
    "code": "01742J",
    "name": "Becker Helicopters"
  },
  {
    "code": "03774G",
    "name": "Bella College Australia"
  },
  {
    "code": "03647C",
    "name": "Blue Bay College"
  },
  {
    "code": "03786C",
    "name": "Bluewater Flight Training; Diamond Airline Academy"
  },
  {
    "code": "03635G",
    "name": "Brighton College Pty Ltd"
  },
  {
    "code": "02615G",
    "name": "Brisbane College of Australia ; Brisbane College of English"
  },
  {
    "code": "03226B",
    "name": "Brisbane College of Horticulture"
  },
  {
    "code": "03517B",
    "name": "Brisbane Professional College "
  },
  {
    "code": "03319G",
    "name": "Brisbane School of Hairdressing, Brisbane School of Beauty, Gold Coast School of Hairdressing, Brisbane School of Barbering"
  },
  {
    "code": "02663M",
    "name": "Browns English Language School, Browns Professional"
  },
  {
    "code": "02462J",
    "name": "Burleigh Heads Language College"
  },
  {
    "code": "00202A",
    "name": "Cairns Business College Limited, Cairns College of Education"
  },
  {
    "code": "03251A",
    "name": "Cairns College of English & Business"
  },
  {
    "code": "00078M",
    "name": "Cairns Language Centre"
  },
  {
    "code": "01796F",
    "name": "Cambridge Academy of English"
  },
  {
    "code": "02938M",
    "name": "Canterbury Technical Institute Pty Ltd"
  },
  {
    "code": "03799J",
    "name": "Charleston English"
  },
  {
    "code": "02476C",
    "name": "Charlton Brown"
  },
  {
    "code": "03822D",
    "name": "Charlton Brown"
  },
  {
    "code": "03652F",
    "name": "Choice Academic College"
  },
  {
    "code": "03444C",
    "name": "Choice Business College"
  },
  {
    "code": "00296A",
    "name": "Chopperline Flight Training, Coastline Flight Training, Airways Aviation"
  },
  {
    "code": "01016F",
    "name": "Christian Heritage College"
  },
  {
    "code": "03513F",
    "name": "College Australia "
  },
  {
    "code": "03605B",
    "name": "College of English Education and Training Australia (CEETA)"
  },
  {
    "code": "03748J",
    "name": "Diversity Education: Health Science and Community Services Training , DIVTEC Training and Further Education"
  },
  {
    "code": "03817A",
    "name": "Edinburgh International College Pty Ltd"
  },
  {
    "code": "03669G",
    "name": "Educare College"
  },
  {
    "code": "03220G",
    "name": "EIM International Training Pty Ltd"
  },
  {
    "code": "03546G",
    "name": "Elite Education Vocational Institute, Elite Education Institute "
  },
  {
    "code": "03795B",
    "name": "Emerald College"
  },
  {
    "code": "03382A",
    "name": "English Direct"
  },
  {
    "code": "03021D",
    "name": "English in Paradise (EIP)"
  },
  {
    "code": "03296K",
    "name": "English Unlimited Brisbane Pty Ltd"
  },
  {
    "code": "03587K",
    "name": "Enhance College of Technology"
  },
  {
    "code": "03432G",
    "name": "Entrepreneur Education"
  },
  {
    "code": "03094J",
    "name": "Envirotech Education"
  },
  {
    "code": "03804F",
    "name": "Fit Education "
  },
  {
    "code": "03789M",
    "name": "Flylink Aviation College"
  },
  {
    "code": "03520G",
    "name": "Frontier Education "
  },
  {
    "code": "03687F",
    "name": "Galaxy College Pty Ltd"
  },
  {
    "code": "03580F",
    "name": "Gamma Education & Training Pty Ltd"
  },
  {
    "code": "03435D",
    "name": "Gateway College of Technology, Gateway College Australia"
  },
  {
    "code": "03464K",
    "name": "Gold Coast Dive Centre & Marine Training College"
  },
  {
    "code": "03568B",
    "name": "Gold Coast International College"
  },
  {
    "code": "03268C",
    "name": "Gold Coast Learning Centre"
  },
  {
    "code": "03813E",
    "name": "GrayClay"
  },
  {
    "code": "03505F",
    "name": "Griffin College"
  },
  {
    "code": "03791F",
    "name": "Health Institute Australasia"
  },
  {
    "code": "01647G",
    "name": "Holmes Colleges "
  },
  {
    "code": "02727M",
    "name": "Holmes Institute"
  },
  {
    "code": "01697J",
    "name": "IES College"
  },
  {
    "code": "02885G",
    "name": "IH Brisbane - ALS, Australian Fitness Schools, Australian Language Schools, ALS Certificates and Diplomas, International House Brisbane - ALS"
  },
  {
    "code": "02137M",
    "name": "ILSC - Australia; ILSC - Brisbane , Greystone College; ILSC - Sydney; ILSC Business College; ILSC TESOL College, ILSC Melbourne"
  },
  {
    "code": "02695C",
    "name": "Imagine Education Australia"
  },
  {
    "code": "02894G",
    "name": "Inforum Education Australia"
  },
  {
    "code": "03699B",
    "name": "Ingress Training Academy Pty Ltd"
  },
  {
    "code": "03793D",
    "name": "Institute of Culinary Excellence"
  },
  {
    "code": "03526A",
    "name": "Institute of Intellect Blending"
  },
  {
    "code": "02035F",
    "name": "InTech Institute of Technology"
  },
  {
    "code": "01941B",
    "name": "International College of Queensland"
  },
  {
    "code": "01351B",
    "name": "International College of Queensland Australia"
  },
  {
    "code": "03149K",
    "name": "International Institute of Management & American College"
  },
  {
    "code": "03720K",
    "name": "Jazz Music Institute"
  },
  {
    "code": "02369F",
    "name": "Kaplan International Languages"
  },
  {
    "code": "03105M",
    "name": "King's Institute of Management and Technology"
  },
  {
    "code": "03576B",
    "name": "Kirana Colleges "
  },
  {
    "code": "03515D",
    "name": "Kool Kids Training College "
  },
  {
    "code": "02630J",
    "name": "Langports English Language College"
  },
  {
    "code": "01678A",
    "name": "Language Studies International"
  },
  {
    "code": "03056D",
    "name": "Languages Across Borders"
  },
  {
    "code": "03636F",
    "name": "Lead College Pty Ltd"
  },
  {
    "code": "03732F",
    "name": "Leaders Institute Pty Ltd"
  },
  {
    "code": "02499G",
    "name": "Lexis English"
  },
  {
    "code": "01632D",
    "name": "Lexis English Noosa, Lexis English Sunshine Coast"
  },
  {
    "code": "03459G",
    "name": "Lexis TESOL Training Centre"
  },
  {
    "code": "03621B",
    "name": "Liberty Construction College"
  },
  {
    "code": "02762G",
    "name": "Lifetime International Training College"
  },
  {
    "code": "03468F",
    "name": "Macallan College Pty Ltd"
  },
  {
    "code": "01854A",
    "name": "Massage Schools of Queensland"
  },
  {
    "code": "03542A",
    "name": "Mastery Institute Australia"
  },
  {
    "code": "03589G",
    "name": "Mayfair College Pty Ltd"
  },
  {
    "code": "03490G",
    "name": "Melbourne Advanced Commerce Institute (MACI)"
  },
  {
    "code": "03439M",
    "name": "Mercury Institute of Victoria"
  },
  {
    "code": "03627G",
    "name": "Mid City College"
  },
  {
    "code": "03586M",
    "name": "Mindroom Innovation PTY LTD"
  },
  {
    "code": "03471M",
    "name": "Montere eCollege, Royal Gateway"
  },
  {
    "code": "03384K",
    "name": "MTA Institute"
  },
  {
    "code": "03686G",
    "name": "National College of Vocational Education"
  },
  {
    "code": "03590D",
    "name": "National Institute of Education and Technology"
  },
  {
    "code": "03431G",
    "name": "New England College"
  },
  {
    "code": "03113M",
    "name": "New England Institute of Technology Pty Ltd"
  },
  {
    "code": "03502J",
    "name": "New York College"
  },
  {
    "code": "03366A",
    "name": "New York Film Academy Australia Pty Ltd"
  },
  {
    "code": "03598G",
    "name": "Newton College"
  },
  {
    "code": "03413K",
    "name": "Oceania College of Technology"
  },
  {
    "code": "03713J",
    "name": "Outsource Institute of Technology"
  },
  {
    "code": "03215E",
    "name": "Pacific English Study"
  },
  {
    "code": "03841A",
    "name": "Page Institute"
  },
  {
    "code": "02754G",
    "name": "PGA International Golf Institute"
  },
  {
    "code": "03584B",
    "name": "Pollard English, Noble English"
  },
  {
    "code": "03415G",
    "name": "Protea College"
  },
  {
    "code": "03755K",
    "name": "Q Academy"
  },
  {
    "code": "03000J",
    "name": "Queens College"
  },
  {
    "code": "03010G",
    "name": "Queensford College, Q Learning"
  },
  {
    "code": "02746G",
    "name": "Queensland Academy of Technology"
  },
  {
    "code": "03466G",
    "name": "Queensland College of Music Pty Ltd"
  },
  {
    "code": "01515J",
    "name": "Queensland International Business Academy (QIBA)"
  },
  {
    "code": "02763G",
    "name": "Queensland International Institute"
  },
  {
    "code": "00663E",
    "name": "Queensland School of Beauty Therapy"
  },
  {
    "code": "03267D",
    "name": "Queensland School of Film and Television"
  },
  {
    "code": "02370B",
    "name": "Royal Brisbane International College; Royal Brisbane Institute of Technology"
  },
  {
    "code": "03441F",
    "name": "Russo Institute of Higher Education, RIHE, Russo Business School"
  },
  {
    "code": "00607B",
    "name": "Sarina Russo Institute"
  },
  {
    "code": "03528K",
    "name": "Sero Institute"
  },
  {
    "code": "01542F",
    "name": "Shafston International College"
  },
  {
    "code": "03475G",
    "name": "Site Institute Brisbane"
  },
  {
    "code": "03328G",
    "name": "Skills Institute Australia"
  },
  {
    "code": "03093K",
    "name": "Spencer College; Spencer College Pty Ltd"
  },
  {
    "code": "03650G",
    "name": "Spencer Technical College"
  },
  {
    "code": "03559C",
    "name": "St Peters International College"
  },
  {
    "code": "03808B",
    "name": "Stellar College Pty Ltd"
  },
  {
    "code": "03474G",
    "name": "Step Into Training Services (SITS)"
  },
  {
    "code": "03623M",
    "name": "Strategix Training Group Pty Ltd"
  },
  {
    "code": "03600G",
    "name": "Study Play Care (SPC Group)"
  },
  {
    "code": "02748F",
    "name": "Sun Pacific College"
  },
  {
    "code": "03349B",
    "name": "Sunshine Coast International College"
  },
  {
    "code": "03815C",
    "name": "Techie International College Pty Ltd"
  },
  {
    "code": "02480G",
    "name": "The Eagle Academy"
  },
  {
    "code": "03816B",
    "name": "The Healey International College"
  },
  {
    "code": "03758G",
    "name": "The Language Academy Pty Ltd"
  },
  {
    "code": "03604C",
    "name": "The Peach Institute; Peach Institute"
  },
  {
    "code": "02403J",
    "name": "The Queensland Institute of Commerce and Technology Pty Ltd"
  },
  {
    "code": "03833A",
    "name": "Total Training Group"
  },
  {
    "code": "03378G",
    "name": "Townsville International English School"
  },
  {
    "code": "03510J",
    "name": "Training Masters Pty Ltd"
  },
  {
    "code": "03556F",
    "name": "Trinity Institute (Australia)"
  },
  {
    "code": "02529F",
    "name": "Union Institute of Language"
  },
  {
    "code": "03579K",
    "name": "Unity School of healing and Naturopathic Centre"
  },
  {
    "code": "03500M",
    "name": "Vibe College"
  },
  {
    "code": "03773G",
    "name": "Vocation Education Training Australia Pty Ltd"
  },
  {
    "code": "02500G",
    "name": "Whitsundays College of English"
  },
  {
    "code": "00080F",
    "name": "You Study International College"
  },
  {
    "code": "02205D",
    "name": "YWAM Training Townsville"
  },
  {
    "code": "02634e",
    "name": "Academia International & Academia Australia - Brisbane"
  },
  {
    "code": "03071E",
    "name": "Australian Learning Group (ALG)"
  },
  {
    "code": "01331F",
    "name": "Australian Pacific College"
  },
  {
    "code": "03812F",
    "name": "EC English Australia: EC English"
  },
  {
    "code": "02644C",
    "name": "ECA College; ELSIS - Brisbane"
  },
  {
    "code": "02997M",
    "name": "ECA Graduate Institute - QLD"
  },
  {
    "code": "01682E",
    "name": "Embassy English/Study Group Australia "
  },
  {
    "code": "03320D",
    "name": "Future Academy - QLD"
  },
  {
    "code": "03581E",
    "name": "IKON Institute of Australia - QLD"
  },
  {
    "code": "02995B",
    "name": "Impact English College"
  },
  {
    "code": "02426B",
    "name": "Kaplan Business School "
  },
  {
    "code": "00289M ",
    "name": "Navitas English - Brisbane"
  },
  {
    "code": "03448K",
    "name": "Pacific Training College"
  },
  {
    "code": "02800G",
    "name": "Warwick Institute of Australia - Brisbane"
  },
  {
    "code": "01679M",
    "name": "Hope College"
  },
  {
    "code": "00231G",
    "name": "Endeavour College of Natural Health, College of Natural Beauty, FIAFitnation"
  },
  {
    "code": "01328A",
    "name": "Australian College of Applied Psychology"
  },
  {
    "code": "00246M",
    "name": "Australasian College of Natural Therapies"
  },
  {
    "code": "00246M",
    "name": "Think Education"
  },
  {
    "code": "01682E",
    "name": "Martin College Pty Ltd, Embassy CES Language Training Pty Ltd, Taylors College"
  },
  {
    "code": "01259J",
    "name": "JMC Academy"
  },
  {
    "code": "02650E",
    "name": "Australian College of Theology"
  },
  {
    "code": "02650E",
    "name": "Queensland Baptist college of Ministries"
  },
  {
    "code": "02494A",
    "name": "Australian College of Agriculture & Horticulture (ACAH)"
  },
  {
    "code":"03553J",
    "name": "Albright Institute of Business and Language - QLD"
  },
  {
    "code": "03173K",
    "name": "Australis Institute of Technology And Education"
  },
  {
    "code": "02380M",
    "name": "Le Cordon Bleu - QLD"
  },
  {
    "code": "03448K",
    "name": "Pacific Training Group - QLD"
  },
  {
    "code": "02898C",
    "name": "Everthought College of Construction Pty Ltd"
  },
  {
    "code": "01856K",
    "name": "Wells International College Pty Ltd - QLD"
  },
  {
    "code": "02140E",
    "name": "Sydney School of Business and Technology - QLD"
  },
  {
    "code": "02398A",
    "name": "Academies Australasia Institute - QLD"
  },
  {
    "code": "02607G",
    "name": "Business Institute of Australia - QLD"
  },
  {
    "code": "03491G",
    "name": "Milcom Institute - QLD"
  },
  {
    "code": "01070M",
    "name": "EF International Language Schools - QLD"
  },
  {
    "code": "00312F",
    "name": "SAE Qantm Creative Media Institute - QLD"
  },
  {
    "code":"03069K",
    "name":"Sydney Institute of Interpreting & Translating (SIIT) - QLD"
  },
  {
    "code": "00197D",
    "name": "Stott's Colleges, Australian Surf Industry Training School, Front Cooking School, Melbourne Language Centre, Affectors Academy"
  },
  {
    "code": "00005F",
    "name": "CSU International Study Centre - Brisbane"
  },
  {
    "code": "02649J",
    "name": "Flight Training Adelaide – QLD"
  }
]
