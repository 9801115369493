import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { sessionData } from 'src/app/shared/storage/session-data';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private userService: UserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(catchError(err => {

                // only log out a user that is logged in "currently"
                if (sessionData.user()) {
                    if (err.status === 401) {
                        // auto logout if 401 response returned from api
                        this.userService.logout();
                    }
                }
                return throwError(err);
            }));
    }
}
