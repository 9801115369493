export class FileUploadModel {
    submissionId: number;
    referenceNumber: string;
    documentType: string;
    fileName: string;
    fileDataBase64Encoded: string;
    uriOfFileToRemove: string;
    constructor(obj: FileUploadModel = null) { if (obj) { Object.assign(this, obj); } }
}

export class FileUploadResultModel {
    uriOfFile: string;
    statusMessage: string;
    filename: string;
    fileDataBase64Encoded: string;
    fileExtractedText: string;
    constructor(obj: FileUploadResultModel = null) { if (obj) { Object.assign(this, obj); } }
}