import { LookupService } from 'src/app/services/lookup.service';
import { formUtil } from 'src/app/shared/util/form-util';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl, FormControl } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { AddressValidation } from 'src/app/shared/directives/custom-validators.directive';

// Models
import { Submission, Address } from 'src/app/models/submission.model';
import { AddressSuggestedModel, AddressEventModel } from 'src/app/models/address.model';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { AddressService } from 'src/app/services/address.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { AuthService } from 'src/app/services/auth.service';

// Constants
import { Countries } from 'src/app/shared/constants/lookup-countries';
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { enums } from 'src/app/shared/enums/enums';

// Validators
import { dateIsInPastValidator } from 'src/app/shared/validators/dateIsInPast-validator.directive';

// Base Classes
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';

// Utils
import { util } from 'src/app/shared/util/util';

import { finalize } from 'rxjs/operators';
import { customFunctionValidator } from 'src/app/shared/validators/customFunction-validator.directive';
import { regex } from 'src/app/shared/constants/regex';
import { GrantService } from 'src/app/services/grant.service';

// Extensions
import 'src/app/shared/util/extend-lookup';
import { ApplicationService } from 'src/app/pages/application/application.service';
import { ApplicationStep } from 'src/app/pages/application/application-step.enum';
import { SubmissionBasicService } from 'src/app/services/submission-basic.service';
import { LookupListItem } from 'src/app/models/lookup-list-item.model';
import * as moment from 'moment';
import { AppService } from 'src/app/services/app.service';
import { WizardComponent } from 'angular-archwizard';
import { GrantModel } from 'src/app/models/grant.model';

@Component({
  selector: 'app-address-details-basic',
  templateUrl: './address-details-basic.component.html',
  styleUrls: ['./address-details-basic.component.scss']
})

export class AddressDetailsBasicComponent implements OnInit {


  // ------------------------------------------------------
  //        Properties
  // ------------------------------------------------------



  model: Submission;

  titleImpactedAddress = 'Impacted Address';
  titleTemporaryAddress = 'Relocated Address';
  titleBestPostalAddress = 'Postal Address';
  enterAddressButtonLabel = 'Enter address manually';

  isImpactedPropertyBoat = false;
  isImpactedPropertyHouse = false;
  isImpactedPropertyUnit = false;

  isImpactedPropertyTypeSelected: boolean;

  private loadingMessage_savingAddressDetails = 'Saving address details ...';
  form: FormGroup;

  addresses: AddressSuggestedModel[] = [];
  addressesLoading = false;
  addressesNoData = false;
  addressSelected: AddressSuggestedModel;
  disableAddressFields = true;
  addressErrorMessage = '';

  lookup_propertyTypes: LookupListItem[];
  lookup_propertyTypesWithoutImpactedAndPOBox: LookupListItem[];

  private isImpactedAddressManuallyEntered = false;

  public maxImpactDate: Date;

  /** Array of address suggestions found for manualy entered address */
  addressSuggestions: AddressSuggestedModel[] = [];


  // ------------------
  //    Lookup list data

  lookup_auStates = lookupLists.australianStates;
  countries = Countries;

  // --------------------
  //    Forms



  hasAttemptedToProgress = false;

  grants: GrantModel[];

  // ---------------
  //    Getters

  get f() { return this.form.controls; }

  get lookup_addressTypes(): LookupListItem[] { return this.submissionService.lookup_addressTypes; }

  get addressForm()  { return this.form.controls; }


  // ------------------------------------------------------
  //    Constructor
  // ------------------------------------------------------

  constructor(
    private fb: FormBuilder,
    private addressService: AddressService,
    private grantService: GrantService,
    private submissionService: SubmissionBasicService,
    private scrollerService: ScrollerService,
    private lookupService: LookupService,
    private appService: AppService,
    private awWizard: WizardComponent,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {

    this.loadPropertyTypes();
    this.initForm();
  }

  initForm() {

  this.scrollerService.scrollViewToTop();
   this.form =  this.formBuilder.group({
      // form fields
      propertyTypeId: new FormControl('', [Validators.required]),
      inputAddress: new FormControl(''),
      fullAddress: new FormControl(''),
      // database fields
      streetNumber: new FormControl('',[]),
      streetName: new FormControl(''),
      suburb: new FormControl(''),
      postcode: new FormControl(''),
      state: new FormControl('QLD'),
      country: new FormControl('Australia'),
      lotNumber: new FormControl('', [Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)]),
      unitNumber: new FormControl('',[Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)]),
      marinaDockName: new FormControl(''),
      mooringNumber: new FormControl(''),
      boatName: new FormControl(''),
      geoX: new FormControl(''),
      geoY: new FormControl(''),
      poBoxNumber: new FormControl(''),
      lga: new FormControl(''),
      isPrimaryResidence: new FormControl(null, [Validators.required]),
      wasManuallyEntered: new FormControl('')
    });



    // property type change - Impacted Address

    this.addressForm.propertyTypeId.valueChanges.subscribe(
      (type: string) => {

           // Boat
          const isBoat = (Number(type) === enums.propertyTypes.Boat);
          formUtil.validation.toggleRequired(this.addressForm.boatName, isBoat);
          formUtil.validation.toggleRequired(this.addressForm.mooringNumber, isBoat);

          // Unit No is required if the propertyselected is Unit/Flat/appartment/Townhouse and the unit no is NULL
          const isUnit = (Number(type) === enums.propertyTypes.Unit);
          formUtil.validation.toggleRequired(this.addressForm.unitNumber, isUnit);

      }
    );

    // input address change :: TypeAhead method to find address with partial address
    this.addressForm.inputAddress.valueChanges.subscribe(
      (partial: string) => {
        this.addressService.findAddressByInputAddress(partial)
          .subscribe(suggestions => {
            if (suggestions) {
              this.addresses = suggestions;
            } else {    // No matching addresses found for the partial address typed
              this.addresses = [];
            }
          });
      });


  }

  loadPropertyTypes(): void {
    this.lookupService.getPropertyTypes().subscribe(result => {
      this.lookup_propertyTypes = result;
      this.lookup_propertyTypesWithoutImpactedAndPOBox =   this.lookup_propertyTypes.filter((item) =>
      item.name.toLowerCase().replace(' ', '') !== 'pobox' && item.name.toLowerCase().replace(' ','') !== 'impacted');
    });
  }

  setAddressUI(event: any): void {

    switch(event.target.value) {
      case enums.propertyTypes.House.toString():
        this.isImpactedPropertyTypeSelected = true;
        this.isImpactedPropertyHouse = true;
        this.isImpactedPropertyBoat = false;
        this.isImpactedPropertyUnit = false;
        break;
      case enums.propertyTypes.Boat.toString():
        this.isImpactedPropertyTypeSelected = true;
        this.isImpactedPropertyHouse = false;
        this.isImpactedPropertyBoat = true;
        this.isImpactedPropertyUnit = false;
        break;
      case enums.propertyTypes.Unit.toString():
        this.isImpactedPropertyTypeSelected = true;
        this.isImpactedPropertyUnit = true;
        this.isImpactedPropertyHouse = false;
        this.isImpactedPropertyBoat = false;
        break;
        default:
          this.isImpactedPropertyTypeSelected = true;
          this.isImpactedPropertyHouse = true;
          this.isImpactedPropertyBoat = false;
          this.isImpactedPropertyUnit = false;
          break;
    }
  }

  getAddressGeocodeFromEsb(addressSuggestion: AddressSuggestedModel, onSuccess: Function = null) {
    this.addressSelected = addressSuggestion;
    // Retrieve the address details using the magic key
    this.addressErrorMessage = '';
    this.addressService.getAddressDetails(addressSuggestion.text, addressSuggestion.magicKey)
      .subscribe(
        (addresses => {
          if (addresses) {

            const address = addresses[0];   // Gets first address from response
            const street = (address.streetName == null ? '' : address.streetName) + ' ' + (address.streetType == null ? '' : address.streetType) +
              (address.streetDirection !== null && address.streetDirection !== undefined ? ' ' + address.streetDirection : '');
            this.addressForm.fullAddress.setValue(address.address);
            this.addressForm.streetName.setValue(street);
            this.addressForm.suburb.setValue(address.locality);
            this.addressForm.postcode.setValue(address.postcode);
            this.addressForm.state.setValue(address.state);
            this.addressForm.streetNumber.setValue(address.house);
            this.addressForm.unitNumber.setValue(address.unitNumber);
            // Store Geo codes to retrieve the available polygons for the address - used when submit
            this.addressForm.geoX.setValue(address.geocode.x);
            this.addressForm.geoY.setValue(address.geocode.y);
            // Revalidate the address
            this.addressForm.inputAddress.updateValueAndValidity();
            this.addressErrorMessage = '';
            // validate UNIT NO is there
            this.validateUnitNo(this.addressForm['unitNumber'].value);
          } else {
            // tslint:disable-next-line: max-line-length
            this.addressErrorMessage = this.addressSelected.text + ' was not found, please retype your address or click on the Enter address manually button.';
          }
          if (onSuccess) {
            onSuccess(this);

          }
        })
      );
  }

  getAddressFromFormFields(): string {

    // get impacted address model from form
    const a = this.form.getRawValue().impactedAddress as Address;
    let address = '';

    // get address parts
    const streetNumber = (a.streetNumber ? a.streetNumber + ' ' : '');
    const streetName = (a.streetName ? a.streetName + ', ' : '');
    const suburb = (a.suburb ? a.suburb + ', ' : '');
    const postcode = (a.postcode ? a.postcode + ' ' : '');
    const state = (a.state ? a.state + ' ' : '');
    const country = (a.country ? a.country + ' ' : '');
    const lotNumber = (a.lotNumber ? a.lotNumber + ' ' : '');
    const unitNumber = (a.unitNumber ? a.unitNumber + '/' : '');
    const marinaDockName = (a.marinaDockName ? a.marinaDockName + ' ' : '');
    const mooringNumber = (a.mooringNumber ? a.mooringNumber + ' ' : '');
    const boatName = (a.boatName ? a.boatName + ' ' : '');

    // boat
     if (this.isImpactedPropertyBoat) {
       address += (`${marinaDockName}${a.mooringNumber}${a.boatName}`);
     }

    // unit
     if(this.isImpactedPropertyUnit){
       address += unitNumber;
     }

    // main address part
    address += `${streetNumber}${streetName}${suburb}${state}`; // + postcode

     if (this.isImpactedPropertyHouse) {
       address += lotNumber;
     }

    return address;
  }


  onAddressSelect(event: TypeaheadMatch): void {

    this.getAddressGeocodeFromEsb(event.item);
  }

  onSelect_ManuallyEnteredAddressSuggestion(event) {
    const suggestedAddressAndMagicKey: string = event.target.value;

    // if one of the sugested addresses is selected
    // find geocodes for the suggested address and then find grants for those geocodes
    if (suggestedAddressAndMagicKey) {
      // parse pipe separated addres|magicKey pair
      const parts = suggestedAddressAndMagicKey.split('|');
      if (parts.length > 1) {
        const text = parts[0];
        const magicKey = parts[1];
        const suggestedAddressModel = new AddressSuggestedModel({ text: text, magicKey: magicKey });

        // get polygons geocodes, find grants and save address
        this.getAddressGeocodeFromEsb(suggestedAddressModel, this.findPolygonIds);
      } else {
        this.scrollerService.scrollViewToTop();
       // this.saveChanges(true, false, this.loadingMessage_savingAddressDetails);
      }
    }
  }

  validateUnitNo(unitno: string) {
    if (unitno === null) {
      this.addressForm['unitNumber'].enable();
    } else {
      this.addressForm['unitNumber'].disable();
    }
  }

  /**
   * Manually progress form. This should only be used in complex cases where we need to do somethign extra befor saving form data.
   * In this case we need to make an ajax call to server to get polygon Ids
   * and we need to stop form submission until we receive the response with polygonIds.
   */
  onClick_Next() {

    this.awWizard.navigation.goToNextStep();
/*
    //this.hasAttemptedToProgress = true;
    this.model = this.submissionService.submissionModel;
    this.model.dateOfImpact = moment(new Date()).toDate(); // util.getDateInFormat(new Date(), '', true);
    //debugger;
    // if form valid continue
   // if (this.isFormValid) {

      // if address manually added - call address service to try and get AddressSuggestedModel with magic key
      if (this.isImpactedAddressManuallyEntered) {

        // assemble searchable address string from manually filled in form fields
        const address = this.getAddressFromFormFields();

        // call address service
        this.addressService.findAddressByInputAddress(address)
          .subscribe((suggestions: AddressSuggestedModel[]) => {

            // Matching addresses found for the partial address typed
            if (suggestions) {
              this.addressSuggestions = suggestions;


              // Address not found
            } else {
              this.fImpactedAddress.geoX.setValue(null);
              this.fImpactedAddress.geoY.setValue(null);
              this.findPolygonIds(this);

            }
          });

      } else {
        this.findPolygonIds(this);
      //  debugger;
      }

   // }
   */
  }

  findPolygonIds(context = null) {

    const self = (context ? context : this);
    const formModel = self.form.getRawValue();
    const geoX = formModel.impactedAddress.geoX;
    const geoY = formModel.impactedAddress.geoY;
    //debugger;

    // const submissionModel = this.submissionService.submissionModel;
    const m = this.model;

    this.model.polygonIds = null;


    this.scrollerService.scrollViewToTop();

    // if no coordinates provided, just save changes
    if (!geoX || !geoY) {
      debugger;
      self.onAfterFindPolygonIds(self);

      // if coordinates provided get polygon ids first and then save changes
    } else {

     // self.appService.showLoading(self.loadingMessage_savingAddressDetails);
      // get polygon ids from server
      this.addressService.getPolygonIds(geoX, geoY)
        .subscribe(

          // onSuccess
          (polygons) => {
            // There are events for the given address and date
            debugger;
            if (polygons) {

              //console.log(polygons)
              // assign polyigonIds to model
              const polyIds = [];
              polygons.forEach(polygon => {
                console.log('objectid' + polygon);
                const polygonId = polygon.objectId;
                polyIds.push(polygonId);
              });

              this.model.polygonIds = polyIds;
              //this.submissionService.submissionModel = submissionModel;


              // There are no events for the given addres and date, therefore grants can not be requested
            } else {
              this.model.polygonIds = null;
              this.model.eventId = null;
              this.model.requestsNoGrants = null;
              //self.appService.hideLoading();
            }

            self.onAfterFindPolygonIds(self);

          },

          // onError
          () => {
            // remove loading indicator on error
            // NOTE: No need to remove loading indicator on finalize, because it will be removed inside saveChanges call
            //self.appService.hideLoading();
          }
        )
    }
  }

  onAfterFindPolygonIds(context: AddressDetailsBasicComponent) {


    this.appService.loadingOverlayShow('Saving address details, please wait...');
    this.submissionService.saveSubmission(this.bindFormToModel()).subscribe(result => {
      this.appService.loadingOverlayHide();
    if (result['body'].submissionId > 0) {

        this.submissionService.submissionModel = this.bindFormToModel();
        this.submissionService.submissionModel.submissionId = result['body'].submissionId;
        this.submissionService.submissionModel.referenceNumber = result['body'].referenceNumber;
        this.awWizard.navigation.goToStep(3);
      }
    });

  }


  // manually navigate to next step after the changes have been saved to the server succesfully
  wizStep_onSaveSuccess() {
    //this.appService.goForward();
  }


  toggleAddressValidators(addressFormGroup: FormGroup, on: boolean, isImpactedAddress: boolean = false) {
/*
    // if isImpacted address then postcode is validated as australian postcode, otherwise it is validated as international postcode
    const vdtr_postcode = (isImpactedAddress ?
      [Validators.required, Validators.maxLength(4), Validators.pattern(regex.numeric)] :
      [Validators.required, Validators.maxLength(11), Validators.pattern(regex.alphaNumeric)]);

    // if PO Box selected for other Property selection then validate PO Box Number otherwise validate Street Number and Name
    const vdtr_poboxnumber = (this.isOtherPropertyPOBox ?
      [Validators.required, Validators.maxLength(50), Validators.pattern(regex.alphaNumericWithSpaces)] :
      null);

     const vdtr_streetnumber = (this.isOtherPropertyPOBox ?
       null :
       [Validators.required, Validators.maxLength(6), Validators.pattern(regex.alphaNumeric)]);

     const vdtr_streetname = (this.isOtherPropertyPOBox ?
      null :
      [Validators.required, Validators.pattern(regex.alphaNumericWithSpaces)]);

    formUtil.validation.toggleControlsValidators(addressFormGroup, {
      streetNumber: vdtr_streetnumber,
      streetName: vdtr_streetname,
      suburb: [Validators.required, Validators.pattern(regex.alphaNumericWithSpaces)],
      postcode: vdtr_postcode,
      state: [Validators.required],
      country: [Validators.required],
      poBoxNumber: vdtr_poboxnumber,
    }, on);
    */
  }

  changeAddressesLoading(e: boolean): void {
    this.addressesLoading = e;
  }

  addressesNoResults(e: boolean): void {
    this.addressesNoData = e;
  }

  toggleAddressFields(isManuallyEntered: boolean = null) {

    // remove any address errors
    this.addressErrorMessage = '';

    // toggle current status of manual entry address fields
    if (isManuallyEntered != null) {
      this.disableAddressFields = !isManuallyEntered;
    } else {
      this.disableAddressFields = !this.disableAddressFields;
    }

    const manualAddressFieldStatus = this.disableAddressFields ? 'disable' : 'enable';
    const autoAddressFieldStatus = this.disableAddressFields ? 'enable' : 'disable';
    this.enterAddressButtonLabel = this.disableAddressFields ? 'Enter address manually' : 'Find Automatically';

    // set manual address input fields
    this.addressForm.streetName[manualAddressFieldStatus]();
    this.addressForm.suburb[manualAddressFieldStatus]();
    this.addressForm.postcode[manualAddressFieldStatus]();
    this.addressForm.streetNumber[manualAddressFieldStatus]();
    this.addressForm.lotNumber[manualAddressFieldStatus]();
    this.addressForm.unitNumber[manualAddressFieldStatus]();
    const enableManualFieldsValidators = !this.disableAddressFields;
    this.toggleAddressValidators(this.f.impactedAddress as FormGroup, enableManualFieldsValidators, true);

    // set auto address input field
    this.addressForm.inputAddress[autoAddressFieldStatus]();
    // reset address search field, if manual address fields are enabled
    if (!this.disableAddressFields) {
    }
    // if value is not passed for isManuallyEntered, then derive it from toggle action
    if (isManuallyEntered == null) {
      this.isImpactedAddressManuallyEntered = !this.disableAddressFields;
    }
  }



  // -----------------------------------------
  //  Abstract method implementations


  bindModelToForm() {

    formUtil.mapModelToForm(this.model, this.f, {
      // must convert date string to date object to bind it to control
      dateOfImpact: function (val) { return (util.hasValue(val) ? new Date(val) : null); }
    });

    // -------------------
    //  Impacted Address
    // -------------------
    const impactedAddress = this.model.addresses.find(a => a.addressTypeId === enums.addressTypes.Impacted);
    if (impactedAddress) {
      // get state display code for the given id
      const state = lookupLists.australianStates.find(s => s.value === impactedAddress.state);
      impactedAddress.state = (state ? state.code : 'QLD');
      formUtil.mapModelToForm(impactedAddress, this.addressForm);
      this.isImpactedAddressManuallyEntered = impactedAddress.wasManuallyEntered;
      this.toggleAddressFields(this.isImpactedAddressManuallyEntered);
    }

   }


  // --------------------------------------------------

  bindFormToModel(): Submission {

    // continue with address details processing...
    const formModel = this.form.getRawValue();
   // const isPostalImpacted = (this.bestPostalAddressTypeId === enums.addressTypes.Impacted);
    //const isPostalTemporary = (this.bestPostalAddressTypeId === enums.addressTypes.Temporary);
   // const isPostalOther = (this.bestPostalAddressTypeId === enums.addressTypes.Other);
    const wasManuallyEntered_false = false;
    const wasManuallyEntered_true = true;

    // Map : dateOfImpact, isAtTemporaryAddress, isStrandedTraveller

    util.mapTo(this.model, formModel, true);
    // Map bestPostalAddressType and bestPostalAddressTypeId to model
    this.model.bestPostalAddressTypeId = enums.addressTypes.Impacted;
    this.model.dateOfImpact = moment(new Date()).toDate();

    //this.model.bestPostalAddressType = this.lookup_addressTypes.GetTextFromId(this.bestPostalAddressTypeId);

    // Map : Impacted, Temporary and Other Address
    // clear existing address data
    this.model.addresses = [];

    // -------------------
    //  Impacted Address
    // -------------------

    const impactedAddress = this.getAddressModel(formModel.impactedAddress,
      enums.addressTypes.Impacted,
      +formModel.impactedAddress.propertyTypeId,
      this.isImpactedAddressManuallyEntered,
      true);
    this.model.addresses.push(impactedAddress);

    // Set to integrate Submission with CRM
    this.model.integrateWithCRM = true;

    this.grantService.findGrantsByPolyIds(this.model.polygonIds,
      this.model.dateOfImpact,this.model.userId,this.model.submissionId, this.model.isHomeOwner).subscribe(result=>{
      this.grants = result;
      //console.log(this.grants);
     // debugger;
    });

    return this.model;
  }

  /**
   * Maps form address model to new Address model
   */
  private getAddressModel(formModel: any, addressTypeId: number, propertyTypeId: number, wasManuallyEntered: boolean, isPostal: boolean) {

    // address type property prefix
    const isImpactedAddress = (addressTypeId === enums.addressTypes.Impacted);
    const isAustralianAddress = (formModel.country === 'Australia');
    const isUnit = propertyTypeId === enums.propertyTypes.Unit
      || propertyTypeId === enums.propertyTypes.AgedCare
      || propertyTypeId === enums.propertyTypes.Caravan
      || propertyTypeId === enums.propertyTypes.RetirementVillage;
    const isHouse = propertyTypeId === enums.propertyTypes.House;
    const isBoat = propertyTypeId === enums.propertyTypes.Boat;
    const isPOBox = propertyTypeId === enums.propertyTypes.POBox;




    // create object, map data from form and return it
    return new Address({

      addressTypeId: addressTypeId,
      addressType: this.lookup_addressTypes.GetTextFromId(addressTypeId),
      propertyTypeId: propertyTypeId,
      propertyType: this.lookup_propertyTypes.GetTextFromId(propertyTypeId),

      // if impactedaddres : get state id for the given display code.
      state: (isImpactedAddress ? lookupLists.australianStates.find(s => s.code === formModel.state).value : formModel.state),
      postcode: formModel.postcode,
      suburb: formModel.suburb,
      streetName: formModel.streetName,
      streetNumber: formModel.streetNumber,
      country: formModel.country,

      isMatched: null,
      isActivated: null,
      wasManuallyEntered: wasManuallyEntered,
      isPostal: isPostal,

      unitNumber: (isUnit ? formModel.unitNumber : null),
      lotNumber: (isHouse ? formModel.lotNumber : null),
      mooringNumber: (isBoat ? formModel.mooringNumber : null),
      marinaDockName: (isBoat ? formModel.marinaDockName : null),
      boatName: (isBoat ? formModel.boatName : null),

      poBoxNumber: (isPOBox ? formModel.poBoxNumber : null),

      // fields only provided for impacted address
      lga: (isImpactedAddress ? formModel.lga : null),
      geoX: (isImpactedAddress ? formModel.geoX : null),
      geoY: (isImpactedAddress ? formModel.geoY : null),

      // International Province only provided for temporary and other addresses when another country, not Australia, is selected
      internationalProvince: (isImpactedAddress || isAustralianAddress ? null : formModel.internationalProvince),

      isPrimaryResidence: (isImpactedAddress ? formModel.isPrimaryResidence : null),
    });
  }



  // --------------
  // Form Validation Css

  getFldClass_impacted(fieldName: string) {
    const control = this.addressForm[fieldName];
    return formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
  }
  getFldClass_inputGrp_impacted(fieldName: string) {
    const control = this.addressForm[fieldName];
    return formUtil.validation.getCssClass_invalidInputGroup(control, this.hasAttemptedToProgress);
  }
  getFldClass_temporary(fieldName: string) {
    const control = this.addressForm[fieldName];
    return formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
  }
  getFldClass_other(fieldName: string) {
    const control = this.addressForm[fieldName];
    const cls = formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
    return cls;
  }



}
