import { FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { formUtil } from '../util/form-util';
import { Output } from '@angular/core';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';

/**
 * Put here optional abstract members
 */
export interface BaseSubStep {


    /**
     * OPTIONAL Hook: EXTRA Validation logic - besides the form validators.
     * Allows for custom validation before proceeding onto next step.
     * Called inside last validation function inside WizardStepBase, before attempting to move on to the next step.
     * Use only if you need extra custom validation or you need to validate forms that are outside of form.
     * MUST RETURN boolean
     */
    onNextValidate?(): boolean;
}
export abstract class BaseSubStep {


    // -----------------------
    //        Properties
    // -----------------------

    @Output() componentInitialized;

    name: string;

    /**
     * Set to true once the user has clicked on button Next to progress to next step.
     */
    protected hasAttemptedToProgress = false;

    /**
     * MAIN FORM - Main form of the wizard step. MUST IMPLEMENT in component that extends from this abstract class
     */
    public abstract form: FormGroup;

    // --------------
    //  Getters

    get f() { return this.form.controls; }


    // -----------------------
    //        Constructor
    // -----------------------

    constructor(
        name: string = null,
        private scrollerService: ScrollerService = null
    ) { if (name) { this.name = name; } }

    // -----------------------
    //        Method
    // -----------------------


    /**
     * Initializes main form for this component
     */
    public abstract initForm();

    public abstract bindModelToForm(model: any);

    public abstract bindFormToModel(): void;

    public isValid(): boolean {
        this.form.updateValueAndValidity();
        let isValid = this.form.valid;
        // HOOK: onNextValidate
        // if onNextValidate is defined, then logical-and (&&) its result with validity of main form
        // allows for custom validation before proceeding onto next step
        if (this.onNextValidate) { isValid = isValid && this.onNextValidate(); }
        return isValid;
    }

    public validation_getInputCssClass(controlName: string) {
        const control = this.form.controls[controlName];
        return formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
    }

    public validation_getInputGroupCssClass(controlName: string) {
        const control = this.form.controls[controlName];
        return formUtil.validation.getCssClass_invalidInputGroup(control, this.hasAttemptedToProgress);
    }
    /**
     * Scroll content to top
     */
    protected scrollToTop() {
        if (this.scrollerService) {
            this.scrollerService.scrollViewToTop();
        }
    }

    // -----------------------
    //        Events
    // -----------------------

    /**
     * Checks step's form validity and returns the validity result.
     * Binds form to model if it is valid.
     */
    onNext() {
        this.hasAttemptedToProgress = true;
        if (this.isValid()) {
            this.bindFormToModel();
            this.scrollToTop();
            return true;
        } else {
            return false;
        }
    }

}
