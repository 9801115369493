import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-substep-forward-back-buttons',
  templateUrl: './substep-forward-back-buttons.component.html',
  styleUrls: ['./substep-forward-back-buttons.component.scss']
})
export class SubstepForwardBackButtonsComponent implements OnInit {

  // This component dynamically creates wizard sub steps in a form, can have multiple components on 1 form.

  // Previous Button
  // Shows the previous button
  @Input('show-previous') showPrev: string;
  // Shows the previous step function eg awPreviousStep
  @Input('show-previous-step') showPrevStep: string;

  // Next Button
  // Shows the next button
  @Input('show-next') showNext: string;
  // Shows the next button step function eg awNextStep
  @Input('show-next-step') showNextStep: string;

  //define the event emitters
 @Output() clickNext: EventEmitter<number> = new EventEmitter<number>();
 @Output() clickPrev: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  handleEventNext(){
    this.clickNext.emit();
  }

  handleEventPrev(){
    this.clickPrev.emit();
  }
}
