import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router'; 

@Injectable()
export class BrowserNavService {

    private _previousUrl: string;
    private _currentUrl: string;


    // Getters
    get previousUrl() { return this._previousUrl; }
    get currentUrl() { return this._currentUrl; }

    constructor(private router: Router) {
        this._currentUrl = this.router.url;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._previousUrl = this._currentUrl;
                this._currentUrl = event.url;
            }
        });
    }

    hasComeFrom(url) { return this.previousUrl.indexOf(url) > -1; }


}
