import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyComponent implements OnInit {
  title = 'New or Existing Application';

  constructor(private router: Router) { }

  ngOnInit() {
  }
  
  nextStep(stepName) {
    switch (stepName)
    { 
      case ('New'): { this.router.navigate(['/privacy']); break; } 
      case ('Existing'): { this.router.navigate(['/login']); break;} 
    }
  }
}
