import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Constants
import { icons } from 'src/app/shared/constants/icons';
import { routes as supportRoutes } from 'src/app/modules/support/_constants/routes';
import { sessionData } from 'src/app/shared/storage/session-data';

// Services
import { SubmissionService } from 'src/app/services/submission.service';


@Component({
  selector: 'app-button-go-to-applicant-support',
  templateUrl: './button-go-to-applicant-support.component.html',
  styleUrls: ['./button-go-to-applicant-support.component.scss']
})
export class ButtonGoToApplicantSupportComponent implements OnInit {


  // ------------------------------------------
  //    Properties
  // ------------------------------------------

  icons = icons;

  // Getters

  get isSupportUser_onBehalfOfApplicant() { return this.submissionSvc.isSupportUser_onBehalfOfApplicant; }

  get applicantName() {
    const model = this.submissionSvc && this.submissionSvc.submissionModel && this.submissionSvc.submissionModel.applicant ?
                 this.submissionSvc.submissionModel.applicant : null;
    return  model ? `${model.givenName} ${model.surname}` : '';
  }
  get submissionUserId() { return this.submissionSvc.submissionUserId; }
  get submissionId() { return this.submissionSvc.submissionId; }

  // ------------------------------------------
  //    Constructor
  // ------------------------------------------

  constructor(
    private submissionSvc: SubmissionService,
    private router: Router,
  ) { }


  // ------------------------------------------
  //    Methods
  // ------------------------------------------

  ngOnInit() {
  }


  onClick_goToApplicantSupport() {
    if (this.submissionUserId) {
      this.router.navigateByUrl(supportRoutes.getApplicantDetails(this.submissionUserId, this.submissionId));
    } else {
      this.router.navigateByUrl(supportRoutes.applicantSearch);
    }

  }


}
