import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

// ***
// Directive to provide input mask for Australian BSB number

@Directive({
    selector: '[appBSBMask]',
  })
  export class BSBMaskDirective {

    constructor(public ngControl: NgControl) { }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
      this.onInputChange(event);
    }

    onInputChange(event) {
      if(event!==null){
        // remove all mask characters (keep only numeric)
        var newVal = event.replace(/\D/g, '');

        // don't show dash for empty value
        if (newVal.length === 0) {
          newVal = '';
        }
        // don't show dash for empty groups at the end
        else if (newVal.length <= 3) {
          newVal = newVal.replace(/^(\d{0,3})/, '$1');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
        }

        // set the new value
        this.ngControl.valueAccessor.writeValue(newVal);
      }
    }

  }
