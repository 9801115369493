import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';

// Base Classes
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { util } from 'src/app/shared/util/util';
import { formUtil } from 'src/app/shared/util/form-util';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { AuthService } from 'src/app/services/auth.service';
import { SupportReferralModel, SupportReferralSubmissionModel } from 'src/app/models/support-referral.model';
import { SupportReferralService } from 'src/app/services/support-referral.service';
import { enums } from 'src/app/shared/enums/enums';
import { ApplicationService } from '../../application.service';
import { ApplicationStep } from '../../application-step.enum';
import { finalize } from 'rxjs/operators';

// Constants


@Component({
  selector: 'app-support-referral',
  templateUrl: './support-referral.component.html',
  styleUrls: ['./support-referral.component.scss']
})
export class SupportReferralComponent extends BaseApplicationStep implements OnInit {

  // -----------------------------------------
  //  Properties
  // ----------------------------------------

  title_supportReferrals = 'Support Referrals';
  title_homeVisit = 'Home Visit';
  hidePrevButton = false;
  otherSelected = false;

  // -------------
  // Lookups


  public lookup_preferredVisitTimes = lookupLists.preferredVisitTime;

  // -------------
  // Form SetUp
  form: FormGroup;

  // dynamic validators
  private validator_otherName = [Validators.required, Validators.maxLength(100)];

  // -------------
  // Getters

  get f() { return this.form.controls; }
  get otherOptionsArray(): FormArray { return this.f.otherOptions as FormArray; }
  get otherName(): AbstractControl { return this.f.other; }
  get individualSupportCtrls() { return (this.f.individualSupport as FormArray).controls; }
  get financialCounsellingSupportCtrls() { return (this.f.financialCounsellingSupport as FormArray).controls; }
  get isHomeVisitRequired() { return this.f.requiresAHomeVisit.value; }

  get supportReferralOptionsArray(): FormArray { return this.form.controls.supportReferralOptions as FormArray; }
  get supportReferralOptionsCtrls() { return this.supportReferralOptionsArray.controls as FormControl[]; }

  get supportReferralOtherOptionsArray(): FormArray { return this.form.controls.supportReferralOtherOptions as FormArray; }
  get supportReferralOtherOptionsCtrls() { return this.supportReferralOtherOptionsArray.controls as FormControl[]; }

  get supportReferralIndividualOptionsArray(): FormArray { return this.form.controls.supportReferralIndividualOptions as FormArray; }
  get supportReferralIndividualOptionsCtrls() { return this.supportReferralIndividualOptionsArray.controls as FormControl[]; }

  get supportReferralFinancialOptionsArray(): FormArray { return this.form.controls.supportReferralFinancialOptions as FormArray; }
  get supportReferralFinancialOptionsCtrls() { return this.supportReferralFinancialOptionsArray.controls as FormControl[]; }

  supportReferrals: SupportReferralModel[] = [];
  supportReferralsOther: SupportReferralModel[] = [];
  supportReferralsIndividual: SupportReferralModel[] = [];
  supportReferralsFinancial: SupportReferralModel[] = [];

  loadingMessage_loadingSupportReferrals = 'Retrieving Support Referrals...';

  // -----------------------------------------
  //  Constructor
  // ----------------------------------------

  constructor(
    private supportReferralService: SupportReferralService,
    private fb: FormBuilder,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.SupportReferral, appSvc, submissionService, authService, scrollerService);
  }



  // -----------------------------------------
  //  Methods
  // ----------------------------------------


  ngOnInit() {
    this.initForm();
  }

  initForm() {

    const options = this.supportReferrals.map(c => this.fb.control(false));
    const otherOptions = this.supportReferralsOther.map(c => this.fb.control(false));
    const individualOptions = this.supportReferralsIndividual.map(c => this.fb.control(false));
    const financialOptions = this.supportReferralsFinancial.map(c => this.fb.control(false));

    // create form group for applicaton details
    this.form = this.fb.group({
      supportReferralOptions: this.fb.array(options),
      supportReferralOtherOptions: this.fb.array(otherOptions),
      supportReferralIndividualOptions: this.fb.array(individualOptions),
      supportReferralFinancialOptions: this.fb.array(financialOptions),
      other: [''],
      preferredVisitTime: this.fb.control(null),
      visitInformation: this.fb.control(''),
      requiresAHomeVisit: [null]
    });

  }



  onChangeOther(event, index) {
    if (this.supportReferralsOther[index].name.indexOf('Other') > -1) {
      this.otherSelected = (this.supportReferralOtherOptionsArray.controls[index].value === true);
      formUtil.validation.toggleControlValidators(this.otherName, (this.otherSelected ? this.validator_otherName : null), true);
    }
  }

  onChangeIsHomeVisitRequired() {
    // YES - dynamically add required validator to radio list preferredHomeVisitTime
    if (this.f.requiresAHomeVisit.value) {
      this.validation_toggleControlValidators(this.f.preferredVisitTime, [Validators.required]);
      // NO - dynamically remove validators
    } else {
      this.validation_toggleControlValidators(this.f.preferredVisitTime);
    }
  }

  onClick_Previous() {
    this.appService.goBackward(this.previousStep);
  }
  onClick_Next() {
    this.appService.goToNextStep();
  }


  bindModelToForm_supportReferralCheckboxes() {

    if (this.model.supportReferrals) {

      // Other
      this.supportReferralsOther.forEach((option, i) => {
        const selected = this.model.supportReferrals.find(sr => sr.supportServiceId === option.supportServiceId);
        this.supportReferralOtherOptionsCtrls[i].setValue(selected ? true : false);

        if (selected && this.supportReferralsOther[i].name.indexOf('Other') > -1) {
          this.f.other.setValue(selected.other);
          this.otherSelected = selected.other !== '';
        }
      });

      // Individual
      this.supportReferralsIndividual.forEach((option, i) => {
        const selected = this.model.supportReferrals.find(sr => sr.supportServiceId === option.supportServiceId);
        this.supportReferralIndividualOptionsCtrls[i].setValue(selected ? true : false);
      });

      // Financial
      this.supportReferralsFinancial.forEach((option, i) => {
        const selected = this.model.supportReferrals.find(sr => sr.supportServiceId === option.supportServiceId);
        this.supportReferralFinancialOptionsCtrls[i].setValue(selected ? true : false);
      });
    }
  }


  loadSupportReferrals_async() {

    if (this.model.eventId) {

      // Show Loading
      this.appService.showLoading(this.loadingMessage_loadingSupportReferrals);

      // make api call
      this.supportReferralService
        .getSupportReferrals(this.model.eventId.toString())
        .pipe(finalize(() => { this.appService.hideLoading(); }))
        .subscribe((supportReferrals: any[]) => {

          this.supportReferrals = [];

          // add support referrals
          supportReferrals.forEach(suportReferral => {

            this.supportReferrals.push(new SupportReferralModel({
              supportServiceId: suportReferral.SupportServiceId,
              category: suportReferral.Category,
              name: suportReferral.Name,
              sortOrder: suportReferral.SortOrder,
              isEnabled: suportReferral.IsEnabled
            }));

          });

          this.supportReferralsOther = this.supportReferrals.filter(
            supportRefferal => supportRefferal.category === enums.supportReferralCategories.otherSupport);
          this.supportReferralsIndividual = this.supportReferrals.filter(
            supportRefferal => supportRefferal.category === enums.supportReferralCategories.individualSupport);
          this.supportReferralsFinancial = this.supportReferrals.filter(
            supportRefferal => supportRefferal.category === enums.supportReferralCategories.financialCounselling);

          this.initForm();

          // update the form selections
          this.bindModelToForm_supportReferralCheckboxes();

          // this.hideLoadingIndicator.emit();
          // map
          formUtil.mapModelToForm(this.model, this.f);

          if (this.model.preferredVisitTime != null) {
            this.f.preferredVisitTime.setValue(+this.model.preferredVisitTime);
          }

          // if submission date and address selection resulted in no grants available,
          // then hide the previous button to stop user from altering their address and date details
          this.hidePrevButton = !this.model.hasActivatedGrantsAvailable;


        });

    } else {

      // if they entered an address within an impacted area and then entered an address outside the impacted area, clear the array.
      this.model.supportReferrals = [];
      // setting form to dirty for base to save update to API.
      this.form.markAsDirty();
    }
  }

  bindModelToForm() {

    // map
    formUtil.mapModelToForm(this.model, this.f);

    if (this.f.requiresAHomeVisit.value === true) {
      this.validation_toggleControlValidators(this.f.preferredVisitTime, [Validators.required]);
    }

    this.loadSupportReferrals_async();
  }

  bindFormToModel(): void {

    const formModel = this.form.getRawValue();
    util.mapTo(this.model, formModel);

    const selectedSupportReferrals: SupportReferralSubmissionModel[] = [];

    // Other
    this.supportReferralsOther.forEach((supportReferral, i) => {
      const isSelected = this.supportReferralOtherOptionsCtrls[i].value;

      if (isSelected) {
        selectedSupportReferrals.push({
          supportReferralId: null,
          submissionId: this.model.submissionId,
          supportServiceId: supportReferral.supportServiceId,
          supportService: supportReferral.name,
          other: (supportReferral.name.indexOf('Other') > -1) ? this.f.other.value : null,
          crmSupportReferralId: null,
          category: supportReferral.category
        });
      }

    });

    // Individual
    this.supportReferralsIndividual.forEach((supportReferral, i) => {
      const isSelected = this.supportReferralIndividualOptionsCtrls[i].value;

      if (isSelected) {
        selectedSupportReferrals.push({
          supportReferralId: null,
          submissionId: this.model.submissionId,
          supportServiceId: supportReferral.supportServiceId,
          supportService: supportReferral.name,
          other: null,
          crmSupportReferralId: null,
          category: supportReferral.category
        });
      }

    });

    // Financial
    this.supportReferralsFinancial.forEach((supportReferral, i) => {
      const isSelected = this.supportReferralFinancialOptionsCtrls[i].value;

      if (isSelected) {
        selectedSupportReferrals.push({
          supportReferralId: null,
          submissionId: this.model.submissionId,
          supportServiceId: supportReferral.supportServiceId,
          supportService: supportReferral.name,
          other: null,
          crmSupportReferralId: null,
          category: supportReferral.category
        });
      }

    });

    this.model.supportReferrals = selectedSupportReferrals;
  }
}
