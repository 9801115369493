﻿import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { WizardComponent as ArchWizard } from 'angular-archwizard';
import { ActivatedRoute, Router } from '@angular/router';

// Constants
import { ApplicationStep } from './application-step.enum';
import { routes } from 'src/app/shared/constants/routes';
import { routes as supportRoutes } from 'src/app/modules/support/_constants/routes';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { BrowserNavService } from 'src/app/services/browserNav.service';
import { ApplicationService } from './application.service';
import { AppService } from 'src/app/services/app.service';



@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit, OnDestroy {


  // ------------------------------------------
  //    Properties
  // ------------------------------------------

  // ---------------
  //    Subscriptions

  url_subscription;
  loadingIndicator_subscription;
  goToStep_subscription;
  goToNextStep_subscription;
  goToPreviousStep_subscription;
  completeApplication_subscription;

  // ---------------
  //    Other

  @ViewChild('awWizard')
  wizard: ArchWizard;
  title = 'Application Wizard';
  isCompleted = false;
  isApplicationLoading = false;

  hasComeFromDashboardPage;
  hasComeFromApplicationPage;
  hasComeFromSupportApplicantPage;
  hasComeFromRegisterApplicantPage;
  hasComeFromApplicantSearchPage;
  hasComeFromBasicLandingdPage: boolean;


  // ---------------
  //    Getters

  get error() { return this.applicationService.error; }

  get submissionUserId() { return this.submissionSvc.submissionUserId; }
  get submissionId() { return this.submissionSvc.submissionId; }


  // ------------------------------------------
  //    Constructor
  // ------------------------------------------

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private submissionSvc: SubmissionService,
    private browserNavSvc: BrowserNavService, // NOTE: DO NOT REMOVE - keeps record of current and previous url and subscribes to router events
    public applicationService: ApplicationService,
    private appSvc: AppService
  ) { }


  // ------------------------------------------
  //    Methods
  // ------------------------------------------

  // Unsubscribe all subscriptions on exit from this component
  ngOnDestroy() {
    this.url_subscription.unsubscribe();
    this.loadingIndicator_subscription.unsubscribe();
    this.goToStep_subscription.unsubscribe();
    this.goToNextStep_subscription.unsubscribe();
    this.goToPreviousStep_subscription.unsubscribe();
  }

  ngOnInit() {
    // call this in case application broke silently and indicator is stuck being displayed
    this.applicationService.hideLoading();
    this.hasComeFromBasicLandingdPage =  this.browserNavSvc.hasComeFrom(routes.basicLanding);
    this.hasComeFromDashboardPage =  this.browserNavSvc.hasComeFrom(routes.dashboard);
    this.hasComeFromApplicationPage = this.browserNavSvc.hasComeFrom(routes.application);
    this.hasComeFromSupportApplicantPage = this.browserNavSvc.hasComeFrom(supportRoutes.applicant);
    this.hasComeFromRegisterApplicantPage = this.browserNavSvc.hasComeFrom(supportRoutes.userRegistration);
    this.hasComeFromApplicantSearchPage = this.browserNavSvc.hasComeFrom(supportRoutes.applicantSearch);
    // subscribe to application url change
    this.url_subscription = this.route.queryParams.subscribe(params => {

      // GET submissionId from url
      const submissionId = Number(params['submissionId']);

      this.submissionSvc.clearSubmission();

      // Coming from PAGE Support/Applicant
      if (this.hasComeFromSupportApplicantPage && submissionId) {

        this.submissionSvc.selectSubmission(submissionId);

        // Coming from other pages
      } else {

        // GET reference number from url
        const referenceNumber = this.route.snapshot.params.ref;

        // If user didn't come to this application from dashboard or application, send him to dashboard
        // force user to select application on dashboard.
        if (referenceNumber
          && !this.hasComeFromDashboardPage && !this.hasComeFromApplicationPage && !this.hasComeFromSupportApplicantPage) {

          this.submissionSvc.ignoreLoad = true;
          this.router.navigateByUrl(routes.dashboard);

        } else if (referenceNumber && this.hasComeFromDashboardPage) {
          this.submissionSvc.selectSubmission(null, referenceNumber);

          // New application
        } else if ((!referenceNumber && this.hasComeFromDashboardPage)   // New application from My Applications page
          || (!referenceNumber && this.hasComeFromSupportApplicantPage)  // New application from Support Applicant Details
          || (!referenceNumber && this.hasComeFromRegisterApplicantPage) // New application from Support Register User page
          || (!referenceNumber && this.hasComeFromApplicantSearchPage)   // New application from Support Applicant Search page
          || (!referenceNumber && this.hasComeFromBasicLandingdPage)
        ) {
          // making new appliaction: continue...
        } else {
          this.submissionSvc.ignoreLoad = true;
          this.router.navigateByUrl(routes.dashboard);
        }
      }

    });

    // subscribe to application events

    this.loadingIndicator_subscription =
      this.applicationService.loadingIndicatorChange$.subscribe((value) => { this.onLoadingIndicatorChange(value); });

    this.goToStep_subscription = this.applicationService.goToStep$.subscribe((step) => { this.goToStep(step); });

    this.goToNextStep_subscription = this.applicationService.goToNextStep$.subscribe(() => { this.wizard.navigation.goToNextStep(); });

    this.goToPreviousStep_subscription = this.applicationService.goToPreviousStep$.subscribe(() => { this.wizard.navigation.goToPreviousStep(); });

    this.completeApplication_subscription = this.applicationService.completeApplication$.subscribe(() => { this.onCompleteApplication(); });
  }

  goToStep(step) { this.wizard.navigation.goToStep(step); }


  onLoadingIndicatorChange(value: string | boolean) {
    const isValueString = (typeof value === 'string');
    const isShow = (isValueString || value === true);

    if (isShow) {
      this.appSvc.loadingOverlayShow(isValueString ? value as string : '');
    } else {
      this.appSvc.loadingOverlayHide();
    }
    this.isApplicationLoading = isShow;
  }

  onCompleteApplication() {
    this.isCompleted = true;
    this.wizard.navigation.goToStep(ApplicationStep.Preview);
  }


}
