﻿import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { sessionData } from 'src/app/shared/storage/session-data';
import { util } from 'src/app/shared/util/util';

// Constants
import { routes } from 'src/app/shared/constants/routes';

// Services
import { UserService } from '../../../services/user.service';
import { SubmissionService } from '../../../services/submission.service';
import { AuthService } from 'src/app/services/auth.service';
import { BrowserNavService } from 'src/app/services/browserNav.service';

// Models
import { UserPersonModel } from 'src/app/models/user-person.model';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // ----------------------------
  //    Properties
  // ----------------------------

  routes = routes;
  title = 'Dashboard';
  applicantName = '';
  showApplications = true;
  isGenericGrantEnabled: boolean;

  // ------------------
  //  Getters

  // do not allow user to create new applications if incomplete applications exist
  get showNewApplicationLink() {
    const show = this.submissionService && !this.submissionService.hasPendingSubmissions && !this.submissionService.hasNonEssentialGrants;
    return show;
  }

  // ----------------------------
  //    Constructor
  // ----------------------------

  constructor(private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private submissionService: SubmissionService,
    private authService: AuthService,
    private browserNavSvc: BrowserNavService // keeps record of current and previous url and subscribes to router events
  ) { }

  // ----------------------------
  //    Methods
  // ----------------------------

  ngOnInit() {
    const user = sessionData.user();
    this.applicantName = `${user.firstName} ${user.lastName}`;
    this.showApplications = this.route.snapshot.params['fromReg'] === 'true' ? false : true;
    this.isGenericGrantEnabled = environment.isGenericGrantEnabled;
  }

  onClick_logout() {
    this.userService.logout();
  }

  showScreen(item: string) {
    // log out before hand if not authenticated
    this.authService.isAuthenticatedHandler();

    switch (item) {
      case ('currentapplications'): { this.showApplications = true; break; }
      case ('contactdetails'): { this.showApplications = false; break; }
    }
  }

  goToApplication() {
    const user = sessionData.user();
    const submissionUser = new UserPersonModel();
    util.mapTo(submissionUser, user);
    this.submissionService.goToApplication(submissionUser, null, null);
  }


}
