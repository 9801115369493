import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-wizard-nav-buttons',
  templateUrl: './wizard-nav-buttons.component.html',
  styleUrls: ['./wizard-nav-buttons.component.scss']
})
export class WizardNavButtonsComponent implements OnInit {

  // ----------------------------------
  //    Properties
  // ----------------------------------

  // set false to disable both Next and Previous buttons
  @Input() isActive = true;


  // set custom text for button Previous
  @Input() previous_text = 'Previous';

  // set true to hide button Previous
  @Input() previous_hide = false;

  @Input() next_hide = false;

  // set true if you want to provide a custom onPrevious click handler
  @Input() previous_isCustom = false;

  // Output event fired on previous button click, but only if previous_isCustom=true
  @Output() previous_click = new EventEmitter();



  // set custom text for button Next
  @Input() next_text = 'Next';

  // set true if you want to provide a custom onNext click handler
  @Input() next_isCustom = false;

  @Input() hide_button = false;

  // Output event fired on next button click, but only if next_isCustom=true
  @Output() next_click = new EventEmitter();


  // ----------------------------------
  //    Constructor
  // ----------------------------------

  constructor() { }


  // ----------------------------------
  //    Methods
  // ----------------------------------

  ngOnInit() { }

  onClick_previous() {
    // Emit previous event
    this.previous_click.emit();
  }

  onClick_next() {
    // Emit next event
    this.next_click.emit();
  }

}
