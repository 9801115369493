/**
 * Generic Event object caters for all types of events.
 */
export class EventModel {
    eventId: string = null;
    name: string = null;
    polyId = null;
    isNoEvent: boolean = false;
    constructor(obj: EventModel = null) { if (obj) { Object.assign(this, obj); } }
}
