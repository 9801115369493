import { AbstractControl, ValidatorFn } from '@angular/forms';

export class MatchValidation {

  // Used to match input value with value from a different field in the same form group
  public MatchValidator(controlName: string): ValidatorFn {
    return (ac: AbstractControl): { [key: string]: boolean } | null => {
      let fieldToMatch = ac.parent.controls[controlName].value;
      let confirmMatch = ac.value;

      if (fieldToMatch === confirmMatch) {
        return null;  // All ok, fields match!
      } else {
        return { "mismatch": true };
      }
    };
  }
}