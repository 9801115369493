import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { AddressDetailsModel, AddressSuggestedModel, AddressEventModel } from '../models/address.model';
import { sessionData } from '../shared/storage/session-data';

@Injectable()
export class AddressService {

  Address_Suggest_URL = environment.addressSuggestUrl;

  Address_Details_URL = environment.addressDetailsUrl;
  Address_Geo_URL = environment.addressFindByGeoUrl;
  Address_Geo_URL_ByPassESB = environment.addressFindByGeoUrlByPassESB;

  header: any;

  constructor(private http: HttpClient) {
    this.header = { headers: this.getHeader() };
  }


  // -----------------------------------------------------------------------------
  // depending upon the switch in config to use ESB or bypass esb  the following method will retrieve address suggestions from ESB for typeahead using partial address
  // Returns up to five suggestions

  public findAddressByInputAddress(address: string): Observable<AddressSuggestedModel[]> {
    if (!address) {
      return of();
    }
    if (environment.bypassESB) {
      //TODO: investigate if the x and y cover whole of QLD 153.005948%2C-27.459202
      this.Address_Suggest_URL = environment.addressSuggestUrlByPassESB;
      const url = `${this.Address_Suggest_URL}?text=${address}&maxSuggestions=5&category=&countryCode=AU&searchExtent=&location=153.005948%2C-27.459202&distance=&f=pjson`;

      return this.http.get<any[]>(url )
        .pipe(map(res => {
          if (res) {
            return res['suggestions'];
          }
        }, (error) => {
          return error;
        }));

      } else {

      this.Address_Suggest_URL = environment.addressSuggestUrl;
      const url = `${this.Address_Suggest_URL}?text=${address}`;

      return this.http.get<any[]>(url, this.header )
        .pipe(map(res => {
          if (res) {
            return res[0];
          }
        }, (error) => {
          return error;
        }));
    }
  }

  // -----------------------------------------------------------------------------
  // Retrieve address details from ESB using the full address string and magic key

  public getAddressDetails(address: string, key: string): Observable<any> {
    if (!address || !key) {
      return of();
    }
    if (environment.bypassESB) {

      this.Address_Details_URL = `${environment.addressDetailsUrlByPassESB}?SingleLine=${address}&outFields=*&magicKey=${key}&f=pjson`;

      return this.http.get<any[]>(`${this.Address_Details_URL}`).pipe(map(res => {
        if (res) {
         return res;
        }
      }, (error) => {
        return error;
      }));
    } else {
    return this.http.get<any[]>(`${this.Address_Details_URL}?text=${address}&magicKey=${key}`, this.header)
      .pipe(map(res => {
        if (res) {
          return res[0];
        }
      }, (error) => {
        return error;
      }));
    }
  }

  // -----------------------------------------------------------------------------
  // Retrieve event ID and event short name from ESB using the geocodes (x and y)
  // return this.http.get<any[]>(`${this.Address_Geo_URL}?position=${x},${y}`, this.header)
  public getPolygonIds(x: string, y: string): Observable<AddressEventModel[]> {
    if(!environment.bypassESB){
      return this.http.get<any[]>(`${this.Address_Geo_URL}?position=${x},${y}`, this.header)
      .pipe(map(res => {
        if (res) {
         // console.log(res[0]);
          return res[0];
        }
      }, (error) => {
        return error;
      }));
    } else {
      return this.http.get<any[]>(`${this.Address_Geo_URL_ByPassESB}?geometry=${x},${y}`)
      .pipe(map(res => {
        if (res) {
          //console.log(res['features']);
          return res['features'];
        }
      }, (error) => {
        return error;
      }));
    }

  }

  public getAccessToken(): string {
    const authSession = sessionData.authSession();
    return (authSession && authSession.accessToken ? authSession.accessToken : '');
  }

  private getHeader(): any {
    let header = new HttpHeaders();
    header = header.set( 'Content-Type', 'application/json' );
    header = header.set( 'eip-authorization', this.getAccessToken());
    header = header.set( 'eip-client' ,'COMREC');
    return header;
  }

}
