/**
 * Store all the api paths inside this file instead of hardcoding them inside your api service
 */
export const apis = {

    // Lookups
    lookup: {
        essentialServices: (eventId: string) => {
            return `lookup/essentialservices?eventId=${eventId}`
        },
        essentialservicesReconnection: (eventId: string) => {
            return `lookup/essentialservices-reconnection?eventId=${eventId}`
        },
        essentialImmediateNeeds: (eventId: string) => {
            return `lookup/immediate-needs?eventId=${eventId}`
        },
        languages: 'lookup/languages',
        nameTitles: 'lookup/nametitles',
        propertyTypes: 'lookup/propertytypes',
        addressTypes: 'lookup/addresstypes'
    },

    submission: {
        addOrUpdate: 'submit',
        get: 'submission/get?submissionId=',
        getLastByUser: 'submission/getlast?userId='
    },

    submissionPersons: {
        saveApplicantDocuments: 'submission-persons/save-applicant-documents',
        saveDependantsAndPartner: 'submission-persons/save-dependants-and-partner',
        remove: 'submission-persons/remove',
        copyFromLastSubmission: 'submission-persons/copy-from-last-submission',
        saveIncome: 'submission-persons/save-income'
    },

    user: {
        register: 'user/register',
        login: 'user/login',
        load: 'user/load',
        update: 'user/contact-update',
        getApplications: 'getuserapplications'
    },

    grants: {
        lookup: (polyIds: string[], dateOfImpact: string, userId: number, submissionId: number, isHomeOwner: boolean) => {
            return `/grant/lookup?polyIds=${polyIds}&dateOfImpact=${dateOfImpact}&userId=${userId}&submissionId=${submissionId}&isHomeOwner=${isHomeOwner}`;
        },
        save: 'grants-save'
    },

    authentication: {
        publicPortal: '/dashboard',
        partnerPortal: '/support/dashboard'
    },

    fileUpload: {
        save: 'fileupload/save',
        list: 'fileupload/list',
        delete: 'fileupload/delete'
    }

};
