import { SubmissionBasicService } from './../../../services/submission-basic.service';
import { GrantService } from './../../../services/grant.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { WizardComponent } from 'angular-archwizard';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { GrantModel } from 'src/app/models/grant.model';
import { isAtLeastOneSelectedValidator } from 'src/app/shared/validators/isAtLeastOneSelected-validator.directive';
import { finalize } from 'rxjs/operators';
import { Submission } from 'src/app/models/submission.model';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { SubmissionService } from 'src/app/services/submission.service';
import { isDefaultChangeDetectionStrategy } from '@angular/core/src/change_detection/constants';
import { AddressService } from 'src/app/services/address.service';
import { UserPersonModel } from 'src/app/models/user-person.model';
import { enums } from 'src/app/shared/enums/enums';

@Component({
  selector: 'app-initial-outcome-basic',
  templateUrl: './initial-outcome-basic.component.html',
  styleUrls: ['./initial-outcome-basic.component.scss']
})
export class InitialOutcomeBasicComponent implements OnInit {
  formGrants: FormGroup;
  isAllGrantsAlreadySubmitted: boolean;
  titleOutcomeOfInitial = 'Outcome of Initial Assessment';

  loadingMessage_loadingAvailableGrants = 'Retrieving Available Grants...';

  form: FormGroup;

  grants: GrantModel[] = [];
  selectedGrants: GrantModel[] = [];

  isSubmitInProgress: boolean = false;
  model: Submission;
  isLoading: boolean = true;


  error: string;
  hidePrevious: boolean;

  // ------------
  //  Getters

  get f() { return this.form.controls; }
  get grantOptionsArray(): FormArray { return this.formGrants.controls.grantOptions as FormArray; }
  get grantOptionsCtrls() { return this.grantOptionsArray.controls as FormControl[]; }
  get essentialGrants() { return enums.essentialGrants; }

  messages: any[] = [];
  subscription: Subscription;
  hasExistingGrant: boolean = false;
  geoX: any;
  geoY: any;
  hideNext:boolean;
  atLeastOneGrantHasBeenSelected:boolean;
  hasSelectedNoGrantRequired:boolean;

  constructor(private formBuilder: FormBuilder,
    private awWizard: WizardComponent,
    private grantService: GrantService,
    private submissionServiceBasic: SubmissionBasicService,
    private submissionService: SubmissionService,
    private scrollerService: ScrollerService,
    private appService: AppService,
    private addressService: AddressService
  ) {
    this.subscription = this.submissionServiceBasic.getGrants().subscribe(message => {
      if (message) {
        this.atLeastOneGrantHasBeenSelected = true;
        this.hasSelectedNoGrantRequired = false;
        this.hideNext = true;
        this.hasExistingGrant = true;
        this.isLoading = true;
        this.appService.loadingOverlayShow('Loading available grants, please wait...');
        this.bindModelToForm();
        this.hidePrevious = true;
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
   // this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.initForm();
  }


  initForm() {

    const options = this.grants.map(c => this.formBuilder.control(false));

    this.formGrants = this.formBuilder.group({
      grantOptions: this.formBuilder.array(options, isAtLeastOneSelectedValidator())
    });
  }

  findPolygonIds(locX: any, locY: any) {

    const geoX = locX;
    const geoY = locY;

    this.model.polygonIds = null;

    // if no coordinates provided, just save changes
    if (!geoX || !geoY) {

      // this.onAfterFindPolygonIds(self);
      this.addressService.getPolygonIds('153.02548', '-27.472607')
      .subscribe(
        // onSuccess
        (polygons) => {
          // There are events for the given address and date
          if (polygons) {
              // assign polyigonIds to model
              const polyIds = [];

              polygons.forEach(polygon => {
                const polygonId = polygon.objectId;
                polyIds.push(polygonId);
              });

              this.model.polygonIds = polyIds;
              // There are no events for the given addres and date, therefore grants can not be requested
              this.loadGrants();
            } else {
              this.model.polygonIds = null;
              this.model.eventId = null;
              this.model.requestsNoGrants = true;
              this.appService.loadingOverlayHide();
              //redirect to preview page:
              this.model = this.submissionService.submissionModel;
              this.forceLoadPreview();
              this.awWizard.navigation.goToStep(5);
          }
        },
        // onError
        () => {
        }
      );

      // if coordinates provided get polygon ids first and then save changes
    } else {
      // get polygon ids from server
      this.addressService.getPolygonIds(geoX, geoY)
        .subscribe(
          // onSuccess
          (polygons) => {

            // There are events for the given address and date
            if (polygons) {

              // assign polyigonIds to model
              const polyIds = [];
              polygons.forEach(polygon => {
                const polygonId = polygon.objectId;
                polyIds.push(polygonId);
              });

              this.model.polygonIds = polyIds;
              // There are no events for the given addres and date, therefore grants can not be requested
              this.loadGrants();
            } else {
              this.model.polygonIds = null;
              this.model.eventId = null;
              this.model.requestsNoGrants = true;
              this.appService.loadingOverlayHide();
              //redirect to preview page:
              this.model = this.submissionService.submissionModel;
              this.forceLoadPreview();
              this.awWizard.navigation.goToStep(5);
            }

          },
          // onError
          () => {
          }
        );
    }
  }

  bindModelToForm() {
    this.model = this.submissionService.submissionModel;
    this.findPolygonIds(this.model.addresses[0].geoX, this.model.addresses[0].geoY);
  }

  loadSelectedGrants() {
    this.model = this.submissionService.submissionModel;
    this.selectedGrants = this.model.grants;
  }

  loadGrants() {

    // if no grants, load them from server based on poly ids
    if (this.grants.length === 0 && this.model !== undefined) {


      // ASYNC: Get grants for chosen coordinates
      this.grantService
        .findGrantsByPolyIds(this.model.polygonIds, this.model.dateOfImpact, this.model.userId, this.model.submissionId, this.model.isHomeOwner)
        .pipe(finalize(() => { }))
        .subscribe((grants: GrantModel[]) => {

          // add grant options
          grants.forEach((polyGrant: GrantModel) => {

            const grant = new GrantModel(polyGrant);

            // Non-essential grants, e.g. ISCA
            // the grant type of the option No - I do not wish to apply for any financial assistance is null
            if (!Object.values(this.essentialGrants).includes(grant.type) || grant.type == null) {
              this.grants.push(grant);
            }
          });

          this.isLoading = false;

          // -------------------------------- No Grants Available Logic ----------------------------------

          // if there are no activated grants for the given location and time => GOTO: Impact Description Step

          // Field uncheckAllOthers is only true for the "no grants option" - the checkbox that is used for unchecking all selected grants.
          // All the real grant options have this field set to false.
          // Therefore to find out if there are any real grant options available we check for options with this field set to false.
          this.model.hasActivatedGrantsAvailable = (this.grants && this.grants.find(g => g.uncheckAllOthers == false) ? true : false);

          // Are there any grants that have not been submitted yet (excluding the empty grant option "No Grants Selected")
          this.isAllGrantsAlreadySubmitted =
            (this.grants && this.grants.find(g => !g.hasBeenSubmitted && g.uncheckAllOthers == false) ? false : true);

          // if there are no activated grants available for the selected location and time, navigate to Impact Description
          if (!this.model.hasActivatedGrantsAvailable) {

            // Remove any grants that had been present.
            if (this.model.grants) {
              this.model.grants = [];
            }

            // stop execution of this method and exit out of it

            this.hideNext = false;
            return;
          }

          // ------------------------------------------------------------------------------------------------

          this.initForm();

          // update the form selections
          this.bindModelToForm_grantCheckboxes();

          this.appService.loadingOverlayHide();

          this.hideNext = false;
        });
    }
  }

  bindModelToForm_grantCheckboxes() {

    if (this.model.grants) {

      this.grants.forEach((grantOption, i) => {

        const selectedGrant = this.model.grants.find(g => g.activatedGrantId === grantOption.activatedGrantId);

        // set grant checkbox to checked if the grant is found in the model
        this.grantOptionsCtrls[i].setValue(selectedGrant ? true : false);
      });
    }

    if (this.model.requestsNoGrants === true) {
      this.atLeastOneGrantHasBeenSelected = true;
      this.grantOptionsCtrls[this.grantOptionsCtrls.length - 1].setValue(true);
    }
  }

  onChange_grantOption(selectedGrant): void {

    const i = this.grants.findIndex(g => g.uncheckAllOthers);
    this.atLeastOneGrantHasBeenSelected = true;


    // if this option unchecks all others, then uncheck all other options
    if (selectedGrant.uncheckAllOthers) {

      this.hasSelectedNoGrantRequired = true;

      this.grantOptionsCtrls.forEach(o => o.setValue(false));
      this.grantOptionsCtrls[i].setValue(true);

    } else {

      // else if this option does not uncheck all others, then uncheck the option that does uncheck all others
      this.grantOptionsCtrls[i].setValue(false);
    }


  }

  checkIfAtleastOneOptionIsChecked(): boolean {
    this.atLeastOneGrantHasBeenSelected = false;
    this.grantOptionsCtrls.forEach(ctrl => {
      if (ctrl.value === true) { this.atLeastOneGrantHasBeenSelected = true; }
    });
    return this.atLeastOneGrantHasBeenSelected;
  }

  onClick_Next() {

    if (!this.checkIfAtleastOneOptionIsChecked()) {
      return;
    }

    /// check if the user has selected 'no grant required option ' then redirect it to preview page
    const selectedGrants = this.getSelectedGrants();
    const atLeastOneGrantHasBeenSelected = (selectedGrants.length > 0);


    if (this.formGrants.dirty) {
      this.bindFormToModel();
      this.scrollerService.scrollViewToTop();
      this.appService.loadingOverlayShow('Saving details, please wait...');
      this.submissionService.submissionUser = new UserPersonModel();
      this.submissionService.submissionUser.userId = this.model.userId;
      this.submissionService.save(this.model).subscribe(result => {
        this.appService.loadingOverlayHide();
        this.submissionService.submissionModel = this.model;
        /// user has slected no grant option so redirect to preview page
        if (!atLeastOneGrantHasBeenSelected){
          this.forceLoadPreview();
          this.awWizard.navigation.goToStep(5);
        } else{
          this.forceLoadFilesMethod();
          this.awWizard.navigation.goToNextStep();
        }
      },
        error => {
          this.appService.loadingOverlayHide();
        },
        () => {
          this.appService.loadingOverlayHide();
        }
      );
    } else {
      this.scrollerService.scrollViewToTop();
      this.submissionService.submissionModel = this.model;
      /// user has slected no grant option so redirect to preview page
      if (!atLeastOneGrantHasBeenSelected) {
        this.forceLoadPreview();
        this.awWizard.navigation.goToStep(5);
      } else{
        this.forceLoadFilesMethod();
        this.awWizard.navigation.goToNextStep();
      }
    }

  }

  forceLoadFilesMethod(): void {
    // send message to subscribers via observable subject
    this.submissionServiceBasic.loadFiles(true);
  }

  forceLoadPreview(): void {
    // send message to subscribers via observable subject
    this.submissionServiceBasic.loadPreview(true);
  }



  bindFormToModel(): Submission {

    const selectedGrants = this.getSelectedGrants();
    // initialize model grants if null
    if (this.model.grants == null) { this.model.grants = []; }

    // Remove grants that are not selected
    for (let i = this.model.grants.length - 1; i >= 0; --i) {
      const previouslySelectedGrant = this.model.grants[i];
      const isStillSelected = selectedGrants.filter(g => g.activatedGrantId === previouslySelectedGrant.activatedGrantId).length > 0;
      // if previously selected grant is not still selected , then remove it from model
      if (!isStillSelected) { this.model.grants.splice(i, 1); }
    }

    // Add newly selected grants
    selectedGrants.forEach(selectedGrant => {
      this.model.eventId = selectedGrant.eventId;
      const isAlreadyAdded = this.model.grants.filter(g => g.activatedGrantId === selectedGrant.activatedGrantId).length > 0;
      if (!isAlreadyAdded) {
        this.model.grants.push(selectedGrant);
      }
    });

    this.model.requestsNoGrants = (this.model.grants.length === 0);

    // We need the eventId if requestNoGrants == true to display support referrals
    if (this.model.requestsNoGrants === true && this.grants.length > 0) {
      // Get the eventId of the first Grant.
      this.model.eventId = this.grants[0].eventId;
    }

    // Set to integrate Submission with CRM
    this.model.integrateWithCRM = true;
    return this.model;
  }


  /** Returns selected Grants.  Sets model steps (for Impact assessment sub wizard) based on the selected grants.*/
  private getSelectedGrants(): GrantModel[] {
    const selectedGrants: GrantModel[] = [];

    this.model.steps = [];

    let stepCounter = 1;

    if (this.grantOptionsCtrls.length > 0) {
      this.grants.forEach((g, i) => {

        const isSelected = this.grantOptionsCtrls[i].value;

        if (isSelected && !g.uncheckAllOthers) {

          // Store selected grants to be submitted to the database
          selectedGrants.push(g);

          // Push data to display the correct steps in Impact Assessment based on grants chosen
          this.model.steps.push({
            type: g.type,
            stepNumber: stepCounter,
            show: (stepCounter === 1)
          });

          // If a means tested grant is chosen set flag to include the week income test step

          stepCounter++;
        }
      });
    }

    return selectedGrants;
  }


  // ----------------
  //  Validation

  getInputCssClass_grantOptions(grant: AbstractControl) {
    // const isInvalid = ((this.hasAttemptedToProgress || this.f.grantOptions.dirty) && grant.errors);
    // return (isInvalid ? formUtil.validation.cssClasses.invalidInputControl : '');
    return null;
  }



}
