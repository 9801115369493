import { environment } from 'src/environments/environment';
import { SubmissionBasicService } from './../../../services/submission-basic.service';
import { Submission, Address, ISCANeeds } from './../../../models/submission.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationService } from 'src/app/pages/application/application.service';
import { SubmissionService } from 'src/app/services/submission.service';
import { AuthService } from 'src/app/services/auth.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { enums } from 'src/app/shared/enums/enums';
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { SubmissionPersonModel } from 'src/app/models/submission-person.model';
import { util } from 'src/app/shared/util/util';
import { FileUploadBasicService } from '../../../services/file-upload-basic.service';
import { WizardComponent } from 'angular-archwizard';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-preview-basic',
  templateUrl: './preview-basic.component.html',
  styleUrls: ['./preview-basic.component.scss']
})
export class PreviewBasicComponent implements OnInit {

  // ---------------------------------------------------
  //    Properties
  // ---------------------------------------------------
  model: Submission;
  isGenericGrantEnabled: boolean;

  // -------------------------------
  //    Strings

  titlePreview = 'Final Submission Preview';
  titleApplicant = 'Applicant';
  titleYourDetails = 'Your Details';
  titleContactDetails = 'Contact Details';
  titleAddress = 'Current Residential Address';
  titlePartnerAndDependants = 'Partner and Dependants';
  titleGrants = 'Grants';
  titleRequiredDocuments = 'Required Documents';
  titleSupportReferral = 'Further Information';

  private dateFormat = 'dd MMM yyyy';
  private medicareDateFormat = 'MM yyyy';

  // -------------------------------
  //    Other


  form: FormGroup = this.fb.group({});

  show = false;

  isCompletedSubmission = false;

  submitted = false;
  openedApplicant: Boolean = true;
  openedAddress: Boolean = true;
  openedGrants: Boolean = true;
  openedPartnerAndDependants: Boolean = true;
  openedSupportReferral: Boolean = true;
  openedRequiredDocuments: Boolean = true;




  dateOfImpact: string;
  medicareExpiry: string;
  licenceExpiry: string;
  psychologicalList: string;
  impactedAddress = new Address();
  temporaryAddress = new Address();
  otherAddress = new Address();
  postalAddressType: string;
  paymentOption: string;


  passport: string;
  coe: string;
  proofOfAddress: string;
  bankStatement: string;
  vevo: string;
  showPassport: boolean = false;
  showCoe: boolean = false;
  showProofOfAddress: boolean = false;
  showBankStatement: boolean = false;
  showVevo: boolean = false;

  iscaNeeds: ISCANeeds = null;

  // -------------------------------
  //    Lookups


  lookup_cultures = lookupLists.culturalGroups;
  lookup_supportOtherGroups = lookupLists.support_other;
  lookup_paymentOptions = lookupLists.paymentOptions;
  lookup_languages: any;
  subscription: any;

  // -------------------------------
  //    Getters


  get f() { return this.form.controls; }
  get documentTypes() { return enums.documentTypes; }

  hidePrevious: boolean;
  hideNext: boolean;

  get interpreterLanguageSpoken() {
    const lang = this.lookup_languages.find(item => item.id == this.model.interpreterLanguageSpoken);
    return lang ? lang.name : '';
  }
  get isStrandedTraveller() { return this.getYesNo(this.model.isStrandedTraveller === true); }

  get isEvacuated() { return this.getYesNo(this.model.isEvacuated === true); }

  get requiresAHomeVisit() { return (this.model.requiresAHomeVisit ? this.getYesNo(this.model.requiresAHomeVisit) : ''); }
  get isOtherPropertyPOBox(): boolean { return Number(this.otherAddress.propertyTypeId) === enums.propertyTypes.POBox; }
  get preferredVisitTime() {
    const preferredTime_lookupItem =
      (this.model.preferredVisitTime ? lookupLists.preferredVisitTime.find(t => t.id === Number(this.model.preferredVisitTime)) : null);
    const preferredTime = (preferredTime_lookupItem ? preferredTime_lookupItem.name : '');
    return preferredTime;
  }

  get hasImpactedDescription() {
    return this.model.impactDescription !== null && this.model.impactDescription.trim() !== '';
  }

  get showFurtherInformation() {
    return null;
  }

  get isTemporaryInAustralia() {
    return this.temporaryAddress.country === 'Australia';
  }

  get isOtherInAustralia() {
    return this.otherAddress.country === 'Australia';
  }

  get applicationStep() {
    return null;// ApplicationStep;
  }

  get impactAssessmentTypes() {
    return enums.impactAssessmentTypes;
  }
  constructor(private fb: FormBuilder,
    private appSvc: ApplicationService,
    private submissionService: SubmissionService,
    private authService: AuthService,
    private scrollerService: ScrollerService,
    private submissionServiceBasic: SubmissionBasicService,
    private fileUploadBasicService: FileUploadBasicService,
    private lookupService: LookupService,
    private awWizard: WizardComponent, ) {

    this.subscription = this.submissionServiceBasic.getPreview().subscribe(message => {
      if (message) {
        if (this.submissionService.isCompletedSubmission || !this.isGenericGrantEnabled) {
          this.hidePrevious = true;
          this.hideNext = true;
        }
        if (this.submissionService.submissionModel.requestsNoGrants == null || this.submissionService.submissionModel.requestsNoGrants) {
          this.hidePrevious = true;
        }
        this.bindModelToForm();
      }
    });
  }

  ngOnInit() {
    this.isGenericGrantEnabled = environment.isGenericGrantEnabled;
    this.model = this.submissionService.submissionModel;
    this.loadLanguages();

  }

  getPersonName(person: SubmissionPersonModel) {
    const title = person.title;
    const givenName = person.givenName;
    const middleName = (person.middleName ? person.middleName : '');
    const surname = person.surname;
    return `${title} ${givenName} ${middleName} ${surname}`;
  }
  getPersonGender(person: SubmissionPersonModel) {
    const gender = lookupLists.genders.find(g => g.value === person.genderId);
    return (gender ? gender.name : '');
  }
  getPersonDOB(person: SubmissionPersonModel) { return person.dateOfBirth.toString().indexOf('14:00') > -1 ? util.convertUTCDateToLocalDate(person.dateOfBirth) : util.getDateInFormat(person.dateOfBirth, this.dateFormat); }
  getPassportDateOfBirth(iscaNeeds: ISCANeeds) { return iscaNeeds.passportDateOfBirth.toString().indexOf('14:00') > -1 ? util.convertUTCDateToLocalDate(iscaNeeds.passportDateOfBirth) : util.getDateInFormat(iscaNeeds.passportDateOfBirth, this.dateFormat); }
  getRelationshipToApplicant(person: SubmissionPersonModel) {
    const relationship = lookupLists.relationshipToApplicant.find(g => g.value === person.relationshipToApplicantId);
    return (relationship ? relationship.name : '');
  }

  getYesNo(isTrue: boolean) { return isTrue ? 'Yes' : 'No'; }
  getGeoState(id) {
    if (id) {
      const state = lookupLists.australianStates.find(s => s.value == id || s.code == id);
      return (state ? state.name : '');
    }
  }

  toggleApplicant() {
    this.openedApplicant = !this.openedApplicant;
  }

  toggleAddress() {
    this.openedAddress = !this.openedAddress;
  }

  toggleGrants() {
    this.openedGrants = !this.openedGrants;
  }

  toggleRequiredDocuments() {
    this.openedRequiredDocuments = !this.openedRequiredDocuments;
  }

  loadLanguages() {
    this.lookupService.getLanguages().subscribe(result=>{
      this.lookup_languages = result;
    })
  }

  bindModelToForm(): void {

    this.model = this.submissionService.submissionModel;

    if (this.model == null) return;
    // There are many changes to the model that the API can update such as document validity and grant eligibility.
    // Therefore retrieve the lastest data

    const hasActivatedGrantsAvailable = this.model.hasActivatedGrantsAvailable;
    const steps = this.model.steps;
    const impactDescription = this.model.impactDescription;
    this.submissionService.getSubmissionById(this.model.submissionId).subscribe(
      data => {
        // This is client side only it should not be overwritten.
        util.mapTo(this.model.grants, data.grants);
        this.model.hasActivatedGrantsAvailable = hasActivatedGrantsAvailable;
        this.model.steps = steps;
        this.model.impactDescription = impactDescription;
      },
      error => {
        // todo: handle - could not retrieve submissions
      }
    );

    this.isCompletedSubmission = this.submissionService.isCompletedSubmission;

    if (this.model.cultures) {
      //this.cultureList = this.getCultures();
    }

    this.dateOfImpact = util.getDateInFormat(this.model.dateOfImpact, this.dateFormat, true);

    if (this.model.applicant && this.model.applicant.medicare != null) {
      if (this.model.applicant.medicare.medicareExpiryDate != null) {
        this.medicareExpiry = util.getDateInFormat(this.model.applicant.medicare.medicareExpiryDate, this.medicareDateFormat);
      }
    }

    if (this.model.applicant && this.model.applicant.driverLicence != null) {
      if (this.model.applicant.driverLicence.licenceExpiryDate != null) {
        this.licenceExpiry = util.getDateInFormat(this.model.applicant.driverLicence.licenceExpiryDate, this.dateFormat);
      }
    }

    // payment option
    if (this.model.grants && this.model.grants.length) {
      const grant = this.model.grants[0];
      const grantPaymentOption = (grant ? grant.paymentOption : '');
      const paymentOption = this.lookup_paymentOptions.find(c => c.id === grantPaymentOption);
      this.paymentOption = (paymentOption ? paymentOption.name : '');
    }

    // Support Referral
    if (this.model.supportReferrals) {
    }


    this.model.addresses.forEach(address => {
      if (address.addressTypeId === enums.addressTypes.Impacted) {
        this.impactedAddress = address;
        this.postalAddressType = enums.addressTypes.Impacted + ' Address';

      } else if (
        address.addressTypeId === enums.addressTypes.Temporary
        || this.model.bestPostalAddressTypeId === enums.addressTypes.Temporary
      ) {
        this.temporaryAddress = address;
        this.postalAddressType = enums.addressTypes.Temporary + ' Address';

      } else {
        this.otherAddress = address;
        this.postalAddressType = enums.addressTypes.Other + ' Address';
      }

    });


    if (this.model.hasISCANeeds || this.model.iscaNeeds != null) {
      // Assessment
      this.iscaNeeds = this.model.iscaNeeds;

      // Required Documents

      var referenceNumber = this.model.referenceNumber;
      if (referenceNumber != null) {
        this.fileUploadBasicService.loadFile(referenceNumber).subscribe(result => {
          if (result != null && result.length > 0) {
            result.forEach(s => {
              if (s.uriOfFile != null) {
                if (s.uriOfFile.indexOf('/') > -1 && s.uriOfFile.indexOf('-') > -1) {
                  var documentType = s.uriOfFile.split('/')[4].split('-')[1];
                  this.setFileName(documentType, s.filename);
                }
              }
            });
          }
        });
      }
    }

    // show preview only after all data is loaded
    this.show = true;
  }

  setFileName(documentType, fileName) {
    switch (documentType) {
      case this.documentTypes.Passport:
        this.passport = fileName;
        this.showPassport = true;
        break;
      case this.documentTypes.COE:
        this.coe = fileName;
        this.showCoe = true;
        break;
      case this.documentTypes.ProofOfAddress:
        this.proofOfAddress = fileName;
        this.showProofOfAddress = true;
        break;
      case this.documentTypes.BankStatement:
        this.bankStatement = fileName;
        this.showBankStatement = true;
        break;
      case this.documentTypes.VEVO:
        this.vevo = fileName;
        this.showVevo = true;
        break;
    }
  }


  bindFormToModel(): void {

  }

  preLoadDeclaration(): void {
    // send message to subscribers via observable subject
    this.submissionServiceBasic.loadDeclarationData(true);
  }

  onClick_Next() {
    if (this.submissionService.isCompletedSubmission === true) {
      return;
    }
    else {
      this.awWizard.navigation.goToNextStep();
    }
  }

}
