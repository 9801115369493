import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentMethodService {
  API_URL = environment.APIUrl;
  bankDetails = '';

  constructor(private httpClient: HttpClient) { }

  public LookupBank(bsb: string): Observable<string> {
    return this.httpClient.get<string>(`${this.API_URL}/lookup/branchdetails?bsb=${bsb}`)
      .pipe(
        map((response: string) => { return response; }));
  }
}
