import { Component, OnInit, ViewChild } from '@angular/core';
import { WizardComponent as ArchWizard } from 'angular-archwizard';
import { SubmissionService } from 'src/app/services/submission.service';
@Component({
  selector: 'app-application-basic',
  templateUrl: './application-basic.component.html',
  styleUrls: ['./application-basic.component.scss']
})
export class ApplicationBasicComponent implements OnInit {
 // @ViewChild('awWizard') public wizard: ArchWizard;
  name = 'Click Me';
  constructor(private submissionService:SubmissionService) { }

  ngOnInit() {
    //console.log('wizard initted');

  }

  finishFunction(): void {

  }

  goToStep(step) {

  }

  public onButtonClick(event: MouseEvent): void {
   // console.log("Clicked");
  }
}
