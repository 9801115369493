import { NgModule } from '@angular/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationModule } from 'ngx-bootstrap/pagination';

@NgModule({
    imports: [
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TypeaheadModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxSpinnerModule,
        PaginationModule.forRoot()
    ],
    exports: [
        CollapseModule,
        BsDatepickerModule,
        ModalModule,
        TabsModule,
        TypeaheadModule,
        BsDropdownModule,
        NgxSpinnerModule,
        PaginationModule
    ],
    declarations: [],
    providers: []
})

export class SharedBootstrapModule {

}
