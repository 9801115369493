import { UserRoleEnum } from 'src/app/shared/enums/user-role.enum';

export const routes = {

    // routes
    dashboard: '/support/dashboard',
    // -----------
    userSearch: '/support/user-search',
    userRegistration: '/support/user-registration',
    userRegistrationBatch: '/support/user-registration-batch',
    userDetails: '/support/user-details',
    // -----------
    applicant: '/support/applicant',
    applicantContactDetails: '/support/applicant-contact-details',
    applicantSearch: '/support/applicant-search',
    auditLogSearch: '/support/audit-search',
    userRegistrationLog: '/support/user-registration-log',

    // ------------------
    // getter methods

    getUserRegistration(role: UserRoleEnum = null) {
        return `${routes.userRegistration}` + (role ? `/${role}` : '');
    },

    getMultipleUserRegistration(role: UserRoleEnum = null) {
        return `${routes.userRegistrationBatch}` + (role ? `/${role}` : '');
    },

    getMultipleUserRegistrationLog(role: UserRoleEnum = null) {
        return `${routes.userRegistrationLog}` + (role ? `/${role}` : '');
    },

    getApplicantDetails: (userId, submissionId = null) => {
        return `${routes.applicant}/${userId}` + (submissionId ? `/${submissionId}` : '');
    },

    getApplicantContactDetails: (userId) => {
        return `${routes.applicantContactDetails}/${userId}`;
    },

    getUserDetails: (userId) => `${routes.userDetails}/${userId}`,


};
