import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

// Constants
import { util } from 'src/app/shared/util/util';
import { sessionData } from 'src/app/shared/storage/session-data';
import { enums } from 'src/app/shared/enums/enums';

// Services
import { UserService } from 'src/app/services/user.service';
import { SubmissionService } from 'src/app/services/submission.service';
import { SubmissionBasicService } from 'src/app/services/submission-basic.service';

// Models
import { ApplicationListItemModel } from './_models/application-list-item.model';
import { UserPersonModel } from 'src/app/models/user-person.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {

  // ----------------------------
  //    Properties
  // ----------------------------


  isGenericGrantEnabled: boolean;
  applicationList: ApplicationListItemModel[] = [];

  get userSubmissions() { return this.submissionService.userSubmissions; }
  get essentialGrantsText() { return enums.essentialGrantsText; }

  // ----------------------------
  //    Constructor
  // ----------------------------

  constructor(
    private appService: AppService,
    private submissionService: SubmissionService,
    private submissionBasicService: SubmissionBasicService,
    private userService: UserService) {
  }

  // ----------------------------
  //    Methods
  // ----------------------------

  ngOnInit() {
    this.appService.loadingOverlayShow('Loading applications ...');
    // remove any existing submission session that might be stored
    this.submissionService.clearSubmission();
    this.submissionBasicService.clearSubmission();
    this.loadUserSubmissions();
    this.isGenericGrantEnabled = environment.isGenericGrantEnabled;
  }

  loadUserSubmissions() {
    this.userService.getUserSubmissions().subscribe(
      submissions => {
        this.submissionService.userSubmissions = submissions;
        this.submissionBasicService.userSubmissions = submissions;
        this.loadApplicationList();
        this.appService.loadingOverlayHide();
      },
      error => {
        // todo: handle - could not retrieve submissions
        this.appService.loadingOverlayHide();
      }
    );
  }

  loadApplicationList() {

    let lastSubmissionId = 0;

    this.userSubmissions.forEach(appGrant => {

      let application: ApplicationListItemModel;

      // -------------
      // ADD new application
      if (appGrant.submissionId !== lastSubmissionId) {

        application = new ApplicationListItemModel({
          submissionId: appGrant.submissionId,
          referenceNumber: appGrant.referenceNumber,
          grants: [],
          status: appGrant.hasDeclaredProvidedTrueCorrectInformation ? 'Completed' : 'Pending'
        });

        this.applicationList.push(application);

        // ----------------
        // UPDATE existing application
      } else {
        application = this.applicationList.find(a => a.submissionId === appGrant.submissionId);
      }

      application.grants.push(appGrant.name || 'Application with no Grants');

      lastSubmissionId = appGrant.submissionId;
    });
  }


  onClick_application(referenceNumber: number, grants: string) {
    const user = sessionData.user();
    const submissionUser = new UserPersonModel();
    util.mapTo(submissionUser, user);
    if ((grants != null && grants[0] != null)) {   
      if ((grants[0] != 'Application with no Grants' && !Object.values(this.essentialGrantsText).includes(grants[0])) ||
      (this.isGenericGrantEnabled && grants[0] == 'Application with no Grants')) {
        this.submissionService.goToApplication(submissionUser, null, referenceNumber); // Temp duplicate of other version.
        //this.submissionBasicService.goToApplication(submissionUser, null, referenceNumber); // IDK what this is, started having pending apps redirect to it.
        return;
      }
    }
    this.submissionService.goToApplication(submissionUser, null, referenceNumber);
  }
}
